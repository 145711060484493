import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Message from 'rsuite/Message';

import CalculatedPrice from '../../components/CalculatedPrice/CalculatedPrice';
import { Container } from '../../components/Container';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { useStore } from '../../hooks/useStore';
import { QueryStore } from '../../stores/Query.store';
import { Utils } from '../../stores/utils';
import SendRentRequestForm from './components/SendRentRequestForm';

const SendRentRequest: React.FC = () => {
    const { rentRequestStore, advertDetailsStore } = useStore();
    const params = useParams();
    const navigate = useNavigate();
    const slug = params.slug;
    const { t } = useTranslation();

    useEffect(() => {
        if (advertDetailsStore.advertDetails?.slug === slug) {
            return;
        }
        advertDetailsStore.resetAdvertDetails();

        const uploadAdvertDetails = async () => {
            if (slug) {
                await advertDetailsStore.fetchAdvertDetail(slug);
                QueryStore.handleError(navigate, advertDetailsStore.errorStatus);
            } else {
                navigate(`/filter`);
            }
        };

        uploadAdvertDetails().catch(console.error);
    }, [advertDetailsStore, navigate, slug]);

    const car = rentRequestStore.advertDetailsStore.advertDetails?.car;
    const creator = rentRequestStore.advertDetailsStore.advertDetails?.creator;
    return (
        <div>
            <NavBar />
            <Container className="container-height my-12">
                <h3 className="text-gray-800 mb-12">{t('reservationPage.title')}</h3>

                <div className="flex justify-between flex-wrap">
                    <div className="w-full lg:w-1/2">
                        <SendRentRequestForm />
                    </div>
                    <div className="w-full lg:w-1/3">
                        <div className="w-full border border-solid border-gray-200 rounded-2xl p-5 bg-white mb-8">
                            {car && creator && (
                                <div className="flex flex-row items-center">
                                    <img className="object-cover w-24 h-24 mr-4" src={car.mainPhoto} alt="" />
                                    <div>
                                        <h6>{Utils.generateCarName(car)}</h6>
                                        <p>
                                            {creator.firstName} {creator.lastName}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        {rentRequestStore.dateRange && (
                            <div className="w-full border border-solid border-gray-200 rounded-2xl p-5 bg-white">
                                <CalculatedPrice rentRequestStore={rentRequestStore} />
                            </div>
                        )}
                        <p className="my-12">
                            <InfoOutlineIcon className="mr-2" />
                            {t('reservationPage.ownerNeedApprove')}
                        </p>
                        <Message showIcon className="text-base" type="warning">
                            {t('reservationPage.payToCarOwner')}
                        </Message>
                    </div>
                </div>
            </Container>

            <Footer />
        </div>
    );
};

export default observer(SendRentRequest);
