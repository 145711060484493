import UserInfo from '@rsuite/icons/UserInfo';
import intlFormatDistance from 'date-fns/intlFormatDistance';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/esm/Button';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';

import AvatarPreview from '../../../components/AvatarPreview/AvatarPreview';
import { GAActions, GACategories } from '../../../constants';
import { UserEntityResponse } from '../../../generated/api';

interface Props {
    userInfo: UserEntityResponse;
    isEditable: boolean;
}

const CreatorDescription: React.FC<Props> = ({ userInfo, isEditable }) => {
    const [isNumberShow, setIsNumberShow] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToUserDetails = () => {
        navigate(`/user-details/${userInfo.id}`);
    };

    const showPhoneNumber = () => {
        setIsNumberShow(true);

        ReactGA.event({
            category: GACategories.RentRequest,
            action: GAActions[GACategories.RentRequest].showPhoneNumber,
        });
    };
    return (
        <div className="w-full mt-12 md:w-9/12 lg:w-full xl:w-10/12 border border-solid border-gray-200 rounded-2xl p-5 bg-white">
            <p className="text-base mb-4">{t('advertDetailsPAge.creator')}</p>

            <div className="flex mb-6">
                <div className="w-20 h-20 cursor-pointer" onClick={navigateToUserDetails}>
                    <AvatarPreview
                        avatar={userInfo.avatar}
                        lastName={userInfo.lastName}
                        firstName={userInfo.firstName}
                    />
                </div>
                <div className="ml-4">
                    <div className="flex mb-2">
                        <p className="text-base cursor-pointer" onClick={navigateToUserDetails}>
                            {userInfo.firstName} {userInfo.lastName}
                        </p>
                        {userInfo.isVerified ? (
                            <Whisper speaker={<Tooltip>{t('advertDetailsPAge.profileVerified')}</Tooltip>}>
                                {<UserInfo className="text-green-600 h-6 w-6 mx-2" />}
                            </Whisper>
                        ) : (
                            <Whisper speaker={<Tooltip>{t('advertDetailsPAge.profileNotVerified')}</Tooltip>}>
                                {<UserInfo className="text-red-400 h-6 w-6 mx-2" />}
                            </Whisper>
                        )}
                    </div>

                    <p className="text-gray-500">
                        Last seen {intlFormatDistance(new Date(userInfo.updatedAt), new Date())}
                    </p>
                </div>
            </div>

            <div className="flex">
                {!isEditable && (
                    <Button appearance="ghost" onClick={showPhoneNumber}>
                        {!isNumberShow ? t('advertDetailsPAge.showANumber') : `${userInfo.phoneNumber}`}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default CreatorDescription;
