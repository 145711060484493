import { makeAutoObservable } from 'mobx';
import queryString from 'query-string';
import ReactGA from 'react-ga4';

import { GAActions, GACategories } from '../../constants';
import { bodyTypeData } from '../../data/bodyType.data';
import { brandTypes } from '../../data/brandType.data';
import { cities } from '../../data/cities.data';
import { fuels, transmissions } from '../../data/common.data';
import { FindAllBody, FullAdvertEntityResponse } from '../../generated/api';
import { ChipListItem } from '../../pages/full-filter/components/ChipList';
import { api } from '../../server/server';
import { FilterQueryObject } from '../../types';
import { QueryStore } from '../Query.store';
import { CheckBoxStore } from './CheckBox.store';
import { FromToStore } from './FromTo.store';
import { RentPriceStore } from './RentPrice.store';
import { ReservationPeriodStore } from './ReservationPeriod.store';

export class FullFilterStore {
    constructor() {
        makeAutoObservable(this);
    }

    brandTypes = new CheckBoxStore(brandTypes);
    bodyTypes = new CheckBoxStore(bodyTypeData);
    cities = new CheckBoxStore(cities);
    transmissions = new CheckBoxStore(transmissions);
    fuels = new CheckBoxStore(fuels);
    reservationPeriod = new ReservationPeriodStore();
    dateOfIssue = new FromToStore({
        min: 1999,
        max: new Date().getFullYear(),
        translationTitle: 'filterPage.dateOfIssue',
    });
    rentPrice = new RentPriceStore({ min: 5, max: 150000 });
    consumption = new FromToStore({ min: 1, max: 100, translationTitle: 'filterPage.consumption' });
    mileage = new FromToStore({ min: 1, max: 1000, translationTitle: 'filterPage.mileage' });

    adverts: FullAdvertEntityResponse[] = [];
    totalAmount = 0;
    isLoading = false;
    activePage = 1;
    limit = 50;

    errorStatus?: number;

    fetchAdverts = async () => {
        this.setIsLoading(true);

        const { errorStatus, response } = await QueryStore.fetch(() =>
            api.adverts.advertControllerFindAll(this.bodyForFetchAdvert)
        );
        this.errorStatus = errorStatus;

        if (response) {
            this.setAdverts(response.adverts);
            this.totalAmount = response.total;
        }

        if (this.bodyForFetchAdvert.offset && this.bodyForFetchAdvert.offset > this.totalAmount) {
            this.setActivePage(1);
        }

        this.setIsLoading(false);
    };

    resetFilter = () => {
        this.brandTypes = new CheckBoxStore(brandTypes);
        this.bodyTypes = new CheckBoxStore(bodyTypeData);
        this.cities = new CheckBoxStore(cities);
        this.reservationPeriod = new ReservationPeriodStore();
        this.transmissions = new CheckBoxStore(transmissions);
        this.fuels = new CheckBoxStore(fuels);
        this.dateOfIssue = new FromToStore({
            min: 1999,
            max: new Date().getFullYear(),
            translationTitle: 'filterPage.dateOfIssue',
        });
        this.rentPrice = new RentPriceStore({ min: 5, max: 10_000 });
        this.consumption = new FromToStore({ min: 3, max: 50, translationTitle: 'filterPage.consumption' });
        this.mileage = new FromToStore({ min: 0, max: 1000, translationTitle: 'filterPage.mileage' });
        this.activePage = 1;
        this.limit = 50;
    };

    setAdverts = (adverts: FullAdvertEntityResponse[]) => {
        this.adverts = adverts;
    };

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    get filterObject(): FilterQueryObject {
        return {
            brandTypes: this.brandTypes.valueForSearching,
            bodyTypes: this.bodyTypes.valueForSearching,
            cities: this.cities.valueForSearching,
            fuels: this.fuels.valueForSearching,
            transmissions: this.transmissions.valueForSearching,
            dateOfIssue: this.dateOfIssue.valueForSearching,
            reservationPeriod: this.reservationPeriod.valueForQuery,
            rentPrice: this.rentPrice.valueForSearching,
            rentType: this.rentPrice.valueForSearching ? this.rentPrice.rentType : undefined,
            consumption: this.consumption.valueForSearching,
            mileage: this.mileage.valueForSearching,
            limit: this.limit,
            offset: (this.activePage - 1) * this.limit,
        };
    }

    get appliedFiltersCount() {
        return this.getChipList().length;
    }

    setActivePage = (activePage: number) => {
        ReactGA.event({
            category: GACategories.FullFilter,
            action: GAActions[GACategories.FullFilter].setActivePage,
            value: activePage,
        });

        this.activePage = activePage;
    };

    setLimit = (limit: number) => {
        ReactGA.event({
            category: GACategories.FullFilter,
            action: GAActions[GACategories.FullFilter].setLimit,
            value: limit,
        });

        this.limit = limit;
    };

    get bodyForFetchAdvert(): FindAllBody {
        return {
            brandTypes: this.brandTypes.valueForSearching,
            bodyTypes: this.bodyTypes.valueForSearching,
            cities: this.cities.valueForSearching,
            fuels: this.fuels.valueForSearching,
            transmissions: this.transmissions.valueForSearching,
            reservationPeriod: this.reservationPeriod.valueForSearching,
            dateOfIssue: this.dateOfIssue.valueToQuery,
            consumption: this.consumption.valueToQuery,
            mileage: this.mileage.valueToQuery,
            rent: this.rentPrice.rentTypeToQuery,
            limit: this.limit,
            offset: (this.activePage - 1) * this.limit,
        };
    }

    getWholeCheckBoxList = () => [this.brandTypes, this.bodyTypes, this.cities, this.fuels, this.transmissions];

    getWholeSliderList = () => [
        this.rentPrice,
        this.dateOfIssue,
        this.consumption,
        this.mileage,
        this.reservationPeriod,
    ];

    getChipList = () => {
        return [
            ...this.brandTypes.getChipOfSelectedCheckboxes(),
            ...this.bodyTypes.getChipOfSelectedCheckboxes(),
            ...this.cities.getChipOfSelectedCheckboxes(),
            ...this.fuels.getChipOfSelectedCheckboxes(),
            ...this.transmissions.getChipOfSelectedCheckboxes(),
            ...this.getWholeSliderList().map((info) => info.chipData),
        ].filter(Boolean) as ChipListItem[];
    };

    checkOutAll = () => {
        this.resetFilter();

        ReactGA.event({
            category: GACategories.FullFilter,
            action: GAActions[GACategories.FullFilter].resetAll,
        });
    };

    checkOutCurrent = (itemName: string) => {
        const foundItem = [...this.getWholeSliderList()].find((item) => item.chipData?.value === itemName);

        [...this.getWholeCheckBoxList()].forEach((checkBoxStore) => {
            checkBoxStore.deleteOne(itemName);
        });

        ReactGA.event({
            category: GACategories.FullFilter,
            action: GAActions[GACategories.FullFilter].resetCurrent,
            label: itemName,
        });

        foundItem?.onDelete();
    };

    get filterUrlParam() {
        return queryString.stringify(this.filterObject);
    }
}
