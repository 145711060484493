import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/Button';
import Message from 'rsuite/Message';
import Panel from 'rsuite/Panel';
import toaster from 'rsuite/toaster';

import { Container } from '../../components/Container';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { useStore } from '../../hooks/useStore';

const Unsubscribe: React.FC = () => {
    const { userStore } = useStore();
    const navigate = useNavigate();
    const params = useParams();
    const email = params.email;
    const { t } = useTranslation();

    if (!email) {
        navigate('/');
    }

    const subscribeBack = async () => {
        if (email) {
            await userStore.resubscribe(email);
            if (userStore.errorStatus) {
                toaster.push(
                    <Message showIcon type="error" header="Error">
                        {t('global.somethingWentWrong')}
                    </Message>
                );
            } else {
                toaster.push(
                    <Message showIcon type="success" header="Success">
                        {t('unsubscribe.resubscribedSuccess')}
                    </Message>
                );
                navigate('/');
            }
        }
    };

    useEffect(() => {
        setTimeout(async () => {
            if (!userStore.user) {
                navigate('/login', {
                    state: {
                        redirectBack: `/unsubscribe/${email || ''}`,
                    },
                });
            } else if (userStore.user.email !== email) {
                navigate('/');
            }

            if (email) {
                await userStore.unsubscribe(email);
                if (userStore.errorStatus) {
                    toaster.push(
                        <Message showIcon type="error" header="Error">
                            {t('global.somethingWentWrong')}
                        </Message>
                    );
                }
            }
        }, 50);
    }, [userStore.user]);

    return (
        <div>
            <NavBar />
            <Container className="container-height">
                <div className="pt-24 mb-12 flex justify-center items-center flex-col gap-8">
                    <Panel
                        header={<h4 className="text-center">{t('unsubscribe.unsubscribe')}</h4>}
                        shaded
                        className="bg-white p-4 w-11/12 md:w-128"
                    >
                        <p className="text-base">
                            {t('unsubscribe.emailAddress')} <b>{email}</b>
                        </p>
                        <p className="text-base">
                            {t('unsubscribe.willNotReceive')} <a>contact@roadieze.com</a>
                        </p>
                    </Panel>

                    <div className="text-base flex items-center justify-center gap-4 flex-wrap">
                        <div>{t('unsubscribe.byAccident')}</div>
                        <Button appearance="ghost" onClick={subscribeBack}>
                            {t('unsubscribe.reSubscribe')}
                        </Button>
                    </div>
                </div>
            </Container>
            <div className="mt-24">
                <Footer />
            </div>
        </div>
    );
};

export default Unsubscribe;
