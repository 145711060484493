import GridIcon from '@rsuite/icons/Grid';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import Button from 'rsuite/Button';
import Modal from 'rsuite/esm/Modal';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 0 },
        items: 1,
    },
};

interface Props {
    photos: string[];
}

const ImageGallery: React.FC<Props> = ({ photos }) => {
    const carouselRef = useRef<Carousel | null>();
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const [mainPhoto, ...otherPhotos] = photos;
    const desktopImages = otherPhotos.slice(0, 4);

    return (
        <div className="w-full mb-16">
            {/*FOR MOBILE*/}
            <div className="w-full block lg:hidden">
                <Carousel
                    className="h-128 bg-black"
                    ref={(el) => (carouselRef.current = el)}
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                >
                    {photos.map((photo) => (
                        <div className="h-full flex items-center" key={photo}>
                            <img className="object-cover w-full" key={photo} src={photo} alt="" />
                        </div>
                    ))}
                </Carousel>
            </div>

            {/*FOR DESKTOP*/}
            <div className="h-96 hidden lg:flex relative">
                <div className="h-full w-6/12 p-1 hover:opacity-90 cursor-pointer" key={mainPhoto} onClick={handleOpen}>
                    <img className="object-cover w-full h-full" key={mainPhoto} src={mainPhoto} alt="" />
                </div>
                <div className="h-full flex items-center w-6/12 flex-wrap">
                    {desktopImages.map((photo) => (
                        <div
                            className="h-1/2 w-6/12 p-1 hover:opacity-90 cursor-pointer"
                            key={photo}
                            onClick={handleOpen}
                        >
                            <img className="object-cover w-full h-full" key={photo} src={photo} alt="" />
                        </div>
                    ))}
                </div>

                <Button
                    className="text-base absolute bg-white right-4 bottom-4 px-4 hover:bg-gray-200"
                    type="submit"
                    appearance="ghost"
                    onClick={handleOpen}
                >
                    <GridIcon className="mr-2" /> {t('advertDetailsPAge.showAll')}
                </Button>
            </div>

            <Modal size="lg" open={open} onClose={handleClose}>
                <Modal.Header className="px-8">
                    <h5>{t('advertDetailsPAge.photoGallery')}</h5>
                </Modal.Header>
                <Modal.Body className="px-8">
                    {photos.map((photo) => (
                        <img className="w-full h-full pb-4" key={photo} src={photo} alt="" />
                    ))}
                </Modal.Body>
                <Modal.Footer className="px-8 mt-2">
                    <Button className="px-8 text-base" onClick={handleClose} appearance="primary">
                        {t('global.ok')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ImageGallery;
