import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'rsuite';

import AdvertMobileTile from '../../../components/Tiles/AdvertMobileTile';
import { useStore } from '../../../hooks/useStore';

function MyAdvertsMobile() {
    const { myAdverts } = useStore();
    const loading = myAdverts.isLoading && !myAdverts.myAdvertsList.length;
    const { t } = useTranslation();

    return (
        <div>
            {loading && (
                <div className="flex justify-center" style={{ backgroundColor: 'rgb(252,252,253)' }}>
                    <Loader size="md" content={t('global.loading')} />
                </div>
            )}
            {myAdverts.myAdvertsList.map((advert) => (
                <AdvertMobileTile key={advert.id} data={advert} />
            ))}
        </div>
    );
}

export default observer(MyAdvertsMobile);
