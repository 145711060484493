import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'rsuite/Button';
import Message from 'rsuite/esm/Message';
import toaster from 'rsuite/esm/toaster';
import Uploader from 'rsuite/esm/Uploader';

import AvatarPreview from '../../../components/AvatarPreview/AvatarPreview';
import config from '../../../config';
import { FileResponse } from '../../../generated/api';
import { useStore } from '../../../hooks/useStore';
import { getTokenFromLocalStorage } from '../../../server/localStorage';

const ProfileAvatar: React.FC = () => {
    const token = getTokenFromLocalStorage();
    const { t } = useTranslation();

    const {
        userStore: { user, changeUserAvatar },
    } = useStore();

    if (!user) {
        return null;
    }

    return (
        <div className="w-64 h-64">
            <Uploader
                fileListVisible={false}
                listType="picture"
                name="file"
                className="w-full h-full"
                action={config.backendUrl + 'files/upload'}
                headers={{
                    Authorization: `Bearer ${token || ''}`,
                }}
                onSuccess={(response: FileResponse) => {
                    toaster.push(<Message type="success">{t('userProfilePage.uploadSuccessfully')}</Message>);
                    return changeUserAvatar(response.url);
                }}
                onError={() => {
                    toaster.push(<Message type="error">{t('userProfilePage.uploadFailed')}</Message>);
                }}
            >
                <Button style={{ width: '16rem', height: '16rem' }} className="w-64 h-64">
                    <AvatarPreview avatar={user.avatar} lastName={user.lastName} firstName={user.firstName} />
                </Button>
            </Uploader>
        </div>
    );
};

export default observer(ProfileAvatar);
