import { observer } from 'mobx-react-lite';
import React, { useImperativeHandle } from 'react';

import { PhotosStore } from '../../../components/PhotoUploader/PhotosStore';
import PhotoUploader from '../../../components/PhotoUploader/PhotoUploader';
import { FormRefType } from '../../../components/Steps/FormRef';

interface Props {
    photoStore: PhotosStore;
}

const AddPhoto = React.forwardRef<FormRefType, Props>(({ photoStore }, ref) => {
    const onSubmit = () => !photoStore.isValidationError;

    useImperativeHandle(
        ref,
        () => {
            return {
                onSubmitStep: onSubmit,
            };
        },
        []
    );

    return <PhotoUploader photoStore={photoStore} />;
});

export default observer(AddPhoto);
