import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import UserInfo from '@rsuite/icons/UserInfo';
import isEqualWith from 'lodash/isEqualWith';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/Button';
import InputPicker from 'rsuite/esm/InputPicker';
import Message from 'rsuite/esm/Message';
import Form from 'rsuite/Form';
import IconButton from 'rsuite/IconButton';
import Loader from 'rsuite/Loader';
import Schema from 'rsuite/Schema';
import toaster from 'rsuite/toaster';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';

import { Container } from '../../components/Container';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { Textarea } from '../../components/Textarea/Textarea';
import { genders } from '../../data/common.data';
import { UpdateUserDtoSex } from '../../generated/api';
import { useStore } from '../../hooks/useStore';
import { Utils } from '../../stores/utils';
import ProfileAvatar from './components/ProfileAvatar';
import VerifyProfileModal from './components/VerifyProfileModal';

const user = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    gender: '',
    description: '',
    verified: false,
};

const { StringType } = Schema.Types;

function MyProfile() {
    const [form, setForm] = useState<typeof user>({ ...user });
    const navigate = useNavigate();
    const { userStore } = useStore();
    const { t } = useTranslation();

    const [isVerifyOpen, setIsVerifyOpen] = React.useState(false);
    const handleVerifyOpen = () => setIsVerifyOpen(true);
    const handleVerifyClose = () => setIsVerifyOpen(false);

    const myProfileSchema = useMemo(
        () =>
            Schema.Model({
                firstName: StringType()
                    .isRequired(t('formErrors.isRequired') || '')
                    .maxLength(16, t('formErrors.maxSymbol', { amount: '16' }) || ''),
                lastName: StringType()
                    .isRequired(t('formErrors.isRequired') || '')
                    .maxLength(16, t('formErrors.maxSymbol', { amount: '16' }) || ''),
                phoneNumber: StringType().isRequired(t('formErrors.isRequired') || ''),
                email: StringType().isEmail(t('formErrors.validEmail') || ''),
                description: StringType().maxLength(1000, t('formErrors.maxSymbol', { amount: '1 000' }) || ''),
            }),
        [t]
    );

    useEffect(() => {
        if (userStore.user) {
            const { firstName, lastName, email, phoneNumber, sex, description, isVerified } = userStore.user;

            setForm({
                ...user,
                firstName,
                lastName,
                gender: sex,
                description,
                email: email || '',
                phoneNumber,
                verified: isVerified,
            });
        } else if (!userStore.isLoading) {
            // User is not loading
            navigate('/login');
        }
    }, [userStore.user]);

    const onSubmit = async (checkStatus: boolean) => {
        if (checkStatus) {
            await userStore.updateUserInfo({
                firstName: form.firstName,
                lastName: form.lastName,
                email: form.email || undefined,
                sex: (form.gender as UpdateUserDtoSex) || undefined,
                description: form.description || undefined,
            });

            if (userStore.errorStatus) {
                toaster.push(
                    <Message showIcon type="error">
                        {userStore.errorMessage}
                    </Message>
                );
            } else {
                toaster.push(
                    <Message showIcon type="success">
                        {t('userProfilePage.userUpdated')}
                    </Message>
                );
            }
        }
    };

    const goBack = () => {
        if (history.state.idx) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };
    const isThereSomeChanges = () => isEqualWith(user, form);
    return (
        <div>
            <NavBar />

            {userStore.isLoading ? (
                <Container className="container-height mb-12">
                    <div className="flex justify-center items-center h-96">
                        <Loader size="md" content={t('global.loading')} />
                    </div>
                </Container>
            ) : (
                <Container className="container-height mb-12">
                    <div className="my-12 flex items-center">
                        <IconButton onClick={goBack} className="mr-4" size="md" circle icon={<PagePreviousIcon />} />

                        <h3>
                            {form.firstName} {form.lastName}
                        </h3>
                        {form.verified ? (
                            <Whisper speaker={<Tooltip>{t('userProfilePage.profileVerified')}</Tooltip>}>
                                {<UserInfo className="text-green-600 h-6 w-6 mx-2" />}
                            </Whisper>
                        ) : (
                            <Whisper speaker={<Tooltip>{t('userProfilePage.profileNotVerified')}</Tooltip>}>
                                {<UserInfo className="text-red-400 h-6 w-6 mx-2" />}
                            </Whisper>
                        )}
                    </div>

                    <div className="flex flex-wrap">
                        <div className="mb-8 w-64">
                            <ProfileAvatar />
                            <div className="hidden md:block mt-8">
                                <FeedbackModal />
                            </div>
                        </div>
                        <div className="ml-0 md:ml-12 w-96">
                            <Form
                                model={myProfileSchema}
                                onSubmit={onSubmit}
                                autoComplete="off"
                                fluid
                                onChange={setForm as any}
                                formValue={form}
                            >
                                <Form.Group>
                                    <Form.ControlLabel>{t('userProfile.firstName')}</Form.ControlLabel>
                                    <Form.Control placeholder="Jack" name="firstName" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.ControlLabel>{t('userProfile.lastName')}</Form.ControlLabel>
                                    <Form.Control placeholder="Jonson" name="lastName" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.ControlLabel>{t('userProfile.phoneNumber')}</Form.ControlLabel>
                                    <Form.Control disabled placeholder="+380 123 4567" name="phoneNumber" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.ControlLabel>{t('userProfile.email')}</Form.ControlLabel>
                                    <Form.Control placeholder="test@gmail.com" name="email" />
                                </Form.Group>
                                <Form.Group>
                                    <Form.ControlLabel>{t('userProfile.gender')}</Form.ControlLabel>
                                    <Form.Control
                                        name="gender"
                                        className="w-full"
                                        placeholder={t('addAdvert.select')}
                                        accepter={InputPicker}
                                        data={Utils.makeDataTranslated(genders, t)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.ControlLabel>{t('userProfile.aboutMe')}</Form.ControlLabel>
                                    <Form.Control
                                        placeholder={t('userProfile.aboutMePlaceholder')}
                                        name="description"
                                        accepter={Textarea}
                                        rows={4}
                                    />
                                </Form.Group>
                                {!form.verified && (
                                    <Message className="mb-8" type="error">
                                        {t('userProfilePage.verificationTip')}
                                    </Message>
                                )}
                                <div className="flex flex-wrap">
                                    {!form.verified && (
                                        <Button className="mr-4 mt-2" appearance="ghost" onClick={handleVerifyOpen}>
                                            {t('userProfilePage.verifyProfileButton')}
                                        </Button>
                                    )}
                                    <Button
                                        className="mt-2"
                                        type="submit"
                                        disabled={isThereSomeChanges()}
                                        appearance="primary"
                                    >
                                        {t('userProfilePage.updateInfo')}
                                    </Button>
                                </div>
                            </Form>

                            <div className="block md:hidden mt-12">
                                <FeedbackModal />
                            </div>
                        </div>
                    </div>

                    <VerifyProfileModal isOpen={isVerifyOpen} onClose={handleVerifyClose} />
                </Container>
            )}

            <Footer />
        </div>
    );
}

export default observer(MyProfile);
