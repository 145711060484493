import React from 'react';

import { Container } from '../../components/Container';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';

const TermsAndConditions: React.FC = () => {
    return (
        <div>
            <NavBar />
            <Container className="container-height">
                <h3 className="pt-24 mb-12">Terms & Conditions</h3>

                <ul className="text-base">
                    <li className="my-4">
                        Renter Qualifications: In order to rent a car on our platform, you must meet the minimum age and
                        driving requirements set by your country's laws. You must have a valid driver's license and meet
                        any other requirements set by the car owner.
                    </li>
                    <li className="my-4">
                        Vehicle Condition: The car owner is responsible for ensuring that the car is in good working
                        condition prior to the rental. The renter is responsible for reporting any damages or issues
                        that they encounter during the rental period. The renter should also inspect the car at the
                        beginning of the rental period to ensure that it is in good condition and free of any
                        pre-existing damage.
                    </li>
                    <li className="my-4">
                        Rental Period: The rental period starts at the agreed-upon time and date, and ends at the
                        agreed-upon time and date. Any extensions or changes to the rental period must be agreed upon by
                        both the renter and the owner. If the renter returns the car later than the agreed-upon time,
                        they may be subject to additional fees.
                    </li>
                    <li className="my-4">
                        Payment: The renter is responsible for paying the rental fee, which will be determined by the
                        car owner. The renter will also be responsible for any additional fees, such as fuel charges or
                        cleaning fees, as agreed upon by the renter and the owner. The platform may charge a service fee
                        for facilitating the rental agreement.
                    </li>
                    <li className="my-4">
                        Insurance: The renter must have valid insurance that covers the rental car during the rental
                        period. The car owner may also have their own insurance policy that covers the car, but this
                        does not negate the requirement for the renter to have insurance. The renter should provide
                        proof of insurance to the car owner prior to the rental period.
                    </li>
                    <li className="my-4">
                        Liability: The renter is responsible for any damage caused to the car during the rental period.
                        The car owner is not responsible for any accidents or damages that occur while the car is being
                        rented. The renter should report any accidents or damage to the car owner and the platform as
                        soon as possible.
                    </li>
                    <li className="my-4">
                        Cancellation: The renter may cancel their reservation at any time, but may be subject to
                        cancellation fees as determined by the car owner. The car owner may also cancel the reservation
                        at any time, but will be required to provide a full refund to the renter. If the rental period
                        is cancelled due to the fault of the car owner, the renter will receive a full refund.
                    </li>
                    <li className="my-4">
                        Disputes: Any disputes between the renter and the car owner must be resolved between the two
                        parties. The platform is not responsible for any disputes that arise during the rental period.
                        If the dispute cannot be resolved, the platform may assist in mediating the dispute.
                    </li>
                    <li className="my-4">
                        Termination: The platform reserves the right to terminate any rental agreement that violates
                        these terms and conditions or any applicable laws. The platform may also suspend or terminate a
                        user's account for any violation of these terms and conditions or for any other reason deemed
                        appropriate by the platform.
                    </li>
                    <li className="my-4">
                        Indemnification: The renter agrees to indemnify and hold harmless the platform, its affiliates,
                        and its officers, directors, agents, and employees, from any claims, damages, or liabilities
                        arising out of the renter's use of the rental car.
                    </li>
                    <li className="my-4">
                        Governing Law: These terms and conditions shall be governed by and construed in accordance with
                        the laws of the jurisdiction in which the car rental takes place.
                    </li>
                    <li className="my-4">
                        Modifications: The platform may modify these terms and conditions at any time, without prior
                        notice. It is the renter's responsibility to review these terms and conditions periodically to
                        ensure that they are aware of any changes.
                    </li>
                </ul>
            </Container>
            <div className="mt-24">
                <Footer />
            </div>
        </div>
    );
};

export default TermsAndConditions;
