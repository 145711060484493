import { TFunction } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'rsuite/esm/Button';
import Modal from 'rsuite/esm/Modal';
import Radio from 'rsuite/esm/Radio';
import RadioGroup from 'rsuite/esm/RadioGroup';
import Form from 'rsuite/Form';
import Message from 'rsuite/Message';
import Schema from 'rsuite/Schema';
import toaster from 'rsuite/toaster';

import { Textarea } from '../../../components/Textarea/Textarea';
import { useStore } from '../../../hooks/useStore';
import { disableReason, getLabelFromValue } from '../consts';

interface Props {
    open: boolean;
    userOrCreator: string;
    close(): void;
    onSubmit(): void;
}

const { StringType } = Schema.Types;

export const declineSchemaBuilder = (t: TFunction) =>
    Schema.Model({
        radioReason: StringType().isRequired(t('formErrors.isRequired') || ''),
        declineReason: StringType()
            .isRequired(t('formErrors.isRequired') || '')
            .maxLength(500, t('formErrors.maxSymbol', { amount: '500' }) || '')
            .minLength(20, t('formErrors.minSymbol', { amount: '20' }) || ''),
    });

const DeclineModal: React.FC<Props> = ({ open, close, onSubmit, userOrCreator }) => {
    const [declineReason, setDeclineReason] = useState('');
    const [radioReason, setRadioReason] = useState('');
    const { rentRequestListStore } = useStore();
    const { t } = useTranslation();

    const declineSchema = useMemo(() => declineSchemaBuilder(t), [t]);

    useEffect(() => {
        setDeclineReason('');
        setRadioReason('');
    }, [open]);

    const onSubmitHandler = async (checkStatus: boolean) => {
        if (checkStatus) {
            try {
                const builtReason = ` ${t(getLabelFromValue(radioReason))}. ${declineReason}`;
                await rentRequestListStore.declineRentRequest({
                    declineReasonHeader: t('declineReason.declineTitle', { user: userOrCreator }),
                    declineReasonMessage: `${builtReason}`,
                });
                toaster.push(
                    <Message showIcon type="success">
                        {t('declineModal.declined')}
                    </Message>
                );
                close();
                onSubmit();
            } catch (error) {
                close();
                toaster.push(
                    <Message showIcon type="error" header="Error">
                        {t('global.somethingWentWrong')}
                    </Message>
                );
            }
        }
    };

    const RadioReasonGroup: React.FC<any> = React.forwardRef((props, ref) => (
        <RadioGroup {...props} name="radioReason" ref={ref}>
            {disableReason.map((reason) => (
                <Radio key={reason.value} value={reason.value}>
                    {t(reason.label)}
                </Radio>
            ))}
        </RadioGroup>
    ));

    return (
        <Modal open={open} onClose={close}>
            <Form model={declineSchema} fluid onSubmit={onSubmitHandler}>
                <Modal.Header>
                    <h5>{t('declineModal.title')}</h5>
                </Modal.Header>
                <Modal.Body>
                    <Message className="my-4" type="warning" showIcon header={t('declineModal.warning')}>
                        {t('declineModal.subtitle')}
                    </Message>
                    <Form.Group controlId="radioReason">
                        <Form.ControlLabel className="text-base text-left">
                            {t('declineModal.selectReason')}
                        </Form.ControlLabel>
                        <Form.Control
                            className="w-full text-base"
                            value={radioReason}
                            onChange={setRadioReason}
                            name="radioReason"
                            accepter={RadioReasonGroup}
                        />
                    </Form.Group>
                    <Form.Group controlId="declineReason">
                        <Form.ControlLabel className="text-base text-left">
                            {t('declineModal.comment')}
                        </Form.ControlLabel>
                        <Form.Control
                            style={{ width: '100%' }}
                            rows={4}
                            value={declineReason}
                            name="declineReason"
                            accepter={Textarea}
                            onChange={setDeclineReason}
                            placeholder={t('declineModal.commentPlaceholder')}
                        />
                        <Form.HelpText className="text-right pt-2">{declineReason.length}/500</Form.HelpText>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="text-base">
                    <Button appearance="primary" type="submit">
                        {t('global.ok')}
                    </Button>
                    <Button onClick={close} appearance="subtle">
                        {t('global.cancel')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default DeclineModal;
