export enum GACategories {
    Recommendation = 'Recommendation',
    Main = 'Main',
    FullFilter = 'FullFilter',
    CarOwnerLanding = 'CarOwnerLanding',
    CreateAdvert = 'CreateAdvert',
    RentRequest = 'RentRequest',
    User = 'User',
    Advert = 'Advert',
}

export const GAActions = {
    [GACategories.Recommendation]: {
        setRecommendationCity: 'setRecommendationCity',
        onRecommendationClick: 'onRecommendationClick',
    },
    [GACategories.FullFilter]: {
        onBoxClick: 'onBoxClick',
        setActivePage: 'setActivePage',
        setLimit: 'setLimit',
        resetAll: 'resetAllFilter',
        resetCurrent: 'resetCurrentFilter',
        setRentPeriodType: 'setRentPeriodType',
        setReservationPeriod: 'setReservationPeriod',
        checkFilterBox: 'checkFilterBox',
    },
    [GACategories.Main]: {
        onSearchClick: 'onSearchClick',
        bodyTypeModified: 'bodyTypeModified',
        priceModified: 'priceModified',
        cityModified: 'cityModified',
        periodModified: 'periodModified',
        createAdvertButtonNav: 'nav-createAdvertButton',
        switchLanguage: 'switchLanguage',
    },
    [GACategories.CarOwnerLanding]: {
        createAdvertButtonLanding: 'landing-createAdvert',
    },
    [GACategories.CreateAdvert]: {
        nextStep: 'nextStep',
        prevStep: 'prevStep',
        finishForm: 'finishForm',
    },
    [GACategories.User]: {
        login: 'login',
        logout: 'logout',
        register: 'register',
        uploadProfileDocuments: 'uploadProfileDocuments',
        updateUserInfo: 'updateUserInfo',
    },
    [GACategories.RentRequest]: {
        createRentRequest: 'createRentRequest',
        setLocation: 'setLocation',
        declineRentRequest: 'declineRentRequest',
        reviewRentRequest: 'reviewRentRequest',
        setTime: 'setTime',
        setDateRange: 'setDateRange',
        goToSendRentRequest: 'goToSendRentRequest',
        resetDateRange: 'resetDateRange',
        setMessage: 'setMessage',
        showPhoneNumber: 'showPhoneNumber',
        goToRentRequestPanel: 'goToRentRequestPanel',
        loginBeforeCreateRentRequest: 'loginBeforeCreateRentRequest',
    },
    [GACategories.Advert]: {
        pauseAdvert: 'pauseAdvert',
        prolongAdvert: 'prolongAdvert',
        archiveAdvert: 'archiveAdvert',
        removeAdvert: 'removeAdvert',
        editAdvert: 'editAdvert',
        activateAdvert: 'activateAdvert',
    },
};
