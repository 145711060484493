import intlFormat from 'date-fns/intlFormat';
import { useTranslation } from 'react-i18next';

export const useFormatDate = () => {
    const { i18n } = useTranslation();
    const locale = i18n.language === 'en' ? 'en-US' : 'uk-UA';

    const formatDate = (date: Date): string => {
        return intlFormat(
            date,
            { localeMatcher: 'lookup' },
            {
                locale,
            }
        );
    };

    const formatDateWithWeek = (date: Date): string => {
        return intlFormat(
            date,
            { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' },
            {
                locale,
            }
        );
    };

    return {
        formatDate,
        formatDateWithWeek,
    };
};
