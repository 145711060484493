import React from 'react';
import { useTranslation } from 'react-i18next';
import Tag from 'rsuite/esm/Tag';
import TagGroup from 'rsuite/esm/TagGroup';

export interface ChipListItem {
    label: string;
    value: string;
    translatedParams?: Record<string, string>;
}

interface Props {
    chipList: ChipListItem[];
    deleteEverything(): void;
    deleteCurrent(item: string): void;
}

const ChipList: React.FC<Props> = ({ chipList, deleteEverything, deleteCurrent }) => {
    const { t } = useTranslation();
    return (
        <TagGroup>
            {chipList.map((chip) => (
                <Tag size="lg" key={chip.value} closable color="blue" onClose={() => deleteCurrent(chip.value)}>
                    {t(chip.label, {
                        ...chip.translatedParams,
                        perRentType: chip.translatedParams ? t(chip.translatedParams?.perRentType) : '',
                    })}
                </Tag>
            ))}
            {Boolean(chipList.length) && (
                <Tag
                    size="lg"
                    className="cursor-pointer border border-solid border-primary-blue text-primary-blue"
                    onClick={deleteEverything}
                >
                    {t('filterPage.clearAll')}
                </Tag>
            )}
        </TagGroup>
    );
};

export default ChipList;
