import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/Button';
import Form from 'rsuite/Form';
import Message from 'rsuite/Message';
import Schema from 'rsuite/Schema';
import toaster from 'rsuite/toaster';

import { PhoneInput } from '../../../components/PhoneInput/PhoneInput';
import { useStore } from '../../../hooks/useStore';
import AuthorizationPNG from '../../../images/authorization/Authorization.webp';
import { getFromLocalStorage } from '../../../server/localStorage';
import AccountContainer from '../components/AccountContainer';

const { StringType } = Schema.Types;

interface LocationState {
    redirectBack?: string;
}

function NumberRegistration() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { userStore } = useStore();
    const location = useLocation();
    const { redirectBack } = (location.state as LocationState) || {};

    const [form, setForm] = useState<any>({
        phoneNumber: '',
    });

    const numberRegistrationSchema = useMemo(
        () =>
            Schema.Model({
                phoneNumber: StringType().isRequired(t('formErrors.phoneNumberNotValid') || ''),
            }),
        [t]
    );

    useEffect(() => {
        const token = getFromLocalStorage('googleToken') || '';

        if (!token) {
            navigate('/login');
        }
    }, []);

    const loginNavigator = () => {
        if (userStore.errorStatus) {
            toaster.push(
                <Message showIcon type="error">
                    {t('loginPage.wrongPassword')}
                </Message>
            );
            setForm({
                ...form,
                password: '',
            });
        } else {
            toaster.push(
                <Message showIcon type="success">
                    {t('loginPage.succeed')}
                </Message>
            );
            if (redirectBack) {
                navigate(redirectBack);
            } else {
                navigate('/');
            }
        }
    };

    const onConfirmClick = async (checkStatus: boolean) => {
        if (checkStatus) {
            const { phoneNumber } = form;

            await userStore.googlePhoneRegistration({ phoneNumber });
            loginNavigator();
        }
    };

    return (
        <AccountContainer image={AuthorizationPNG}>
            <div className="max-w-prose">
                <div className="flex mb-6">
                    <p className="text-3xl text-black">{t('registerNumber.title')}</p>
                </div>

                <div className="my-5">
                    <p>{t('registerNumber.description')}</p>
                </div>

                <Form model={numberRegistrationSchema} onSubmit={onConfirmClick} onChange={setForm} formValue={form}>
                    <Form.Group>
                        <Form.ControlLabel>{t('userProfile.phoneNumber')}</Form.ControlLabel>
                        <Form.Control className="w-full" name="phoneNumber" accepter={PhoneInput} />
                    </Form.Group>
                    <Form.Group>
                        <Button className="px-4" type="submit" appearance="primary">
                            {t('global.ok')}
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </AccountContainer>
    );
}

export default NumberRegistration;
