import { observer } from 'mobx-react-lite';
import React from 'react';
import Checkbox from 'rsuite/Checkbox';
import Nav from 'rsuite/Nav';

import { CheckBoxData } from '../../../types/types';

interface Props {
    title: string;
    data: CheckBoxData[];

    onChange(item: CheckBoxData): void;
}

const CheckboxListAccordion: React.FC<Props> = ({ title, data, onChange }) => {
    return (
        <Nav.Menu className="bg-white border border-solid border-gray-100 rounded " title={title}>
            <div className="px-12 lg:px-6 xl:px-12 pb-4 mb-4 max-h-96 overflow-auto flex flex-col">
                {data.map((info) => (
                    <Checkbox
                        key={info.label}
                        checked={info.isChecked}
                        onChange={() => onChange(info)}
                        value={info.label}
                    >
                        {info.label}
                    </Checkbox>
                ))}
            </div>
        </Nav.Menu>
    );
};

export default observer(CheckboxListAccordion);
