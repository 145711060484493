import { makeAutoObservable } from 'mobx';

export class RequirementsForClientStore {
    constructor() {
        makeAutoObservable(this);
    }

    minAge?: string;
    minExperience?: string;
    deposit?: string;
    citizenship?: string;
    termAndConditional = false;

    setMinAge = (minAge: string) => {
        this.minAge = minAge;
    };
    setMinExperience = (minExperience: string) => {
        this.minExperience = minExperience;
    };
    setDeposit = (deposit: string) => {
        this.deposit = deposit;
    };
    setCitizenship = (citizenship: string) => {
        this.citizenship = citizenship;
    };

    toggleTermAndConditional = () => {
        this.termAndConditional = !this.termAndConditional;
    };

    get formValue() {
        return {
            minAge: this.minAge,
            minExperience: this.minExperience,
            deposit: this.deposit,
            citizenship: this.citizenship,
            termAndConditional: this.termAndConditional,
        };
    }
}
