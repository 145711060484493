import { AxiosError, AxiosResponse } from 'axios';
import { NavigateFunction } from 'react-router';

export class QueryStore {
    static async fetch<T>(
        query: () => Promise<AxiosResponse<T>>
    ): Promise<{ response?: T; errorStatus?: number; errorMessage?: string }> {
        let response: T | undefined;
        let errorStatus: number | undefined;
        let errorMessage: string | undefined;

        try {
            const queryResponse = await query();
            response = queryResponse.data || undefined;
        } catch (e) {
            errorStatus = (e as AxiosError).response?.status;
            errorMessage = ((e as AxiosError).response?.data as any)?.message as string;
        }

        return { response, errorStatus, errorMessage };
    }

    static handleError = (navigate: NavigateFunction, errorStatus?: number) => {
        if (errorStatus === 403) {
            navigate('/forbidden');
        } else if (errorStatus === 404) {
            navigate('/not-found');
        } else if (errorStatus === 401) {
            navigate('/login');
        } else if (errorStatus) {
            navigate('/something-went-wrong');
        }
    };
}
