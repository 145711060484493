import BanknotesIcon from '@heroicons/react/24/solid/BanknotesIcon';
import MapPinIcon from '@heroicons/react/24/solid/MapPinIcon';
import CalendarIcon from '@rsuite/icons/Calendar';
import HistoryIcon from '@rsuite/icons/History';
import TimeIcon from '@rsuite/icons/Time';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RentRequestEntityResponse } from '../../generated/api';
import { useFormatDate } from '../../hooks/useFormatDate';
import { useStore } from '../../hooks/useStore';
import { StatusText } from '../../pages/rent-request-list/components/RentRequestStatus';
import { Utils } from '../../stores/utils';

interface Props {
    rentRequestData: RentRequestEntityResponse;
    isSentTab: boolean;
}

const RentRequestTile: React.FC<Props> = ({ rentRequestData, isSentTab }) => {
    const { mainPhoto } = rentRequestData.advert.car;
    const { rentRequestListStore } = useStore();
    const { from, to, createdAt, totalPrice, address, pickUpTime } = rentRequestData;
    const { t } = useTranslation();
    const { formatDate } = useFormatDate();

    return (
        <div
            className="cursor-pointer rounded-md shadow-md mb-4 m-1 p-2"
            onClick={() => rentRequestListStore.reviewRentRequest(rentRequestData)}
        >
            <div className="flex">
                <img className="object-cover relative h-16 rounded-md" src={mainPhoto} alt="" />
                <div className="flex flex-col ml-4 justify-center text-base">
                    <h6>{Utils.generateCarName(rentRequestData.advert.car)}</h6>
                    <div className="flex items-center">
                        <span className="mr-2 font-semibold">{t('myRentRequests.status')}:</span>{' '}
                        {StatusText(rentRequestData)}
                    </div>
                </div>
            </div>
            <div className="flex flex-col text-gray-400 fill-gray-400 mt-2">
                <div className="flex items-center my-1 whitespace-nowrap">
                    <CalendarIcon />
                    <span className="ml-2">
                        {t('myRentRequests.period')}: {formatDate(new Date(from))} - {formatDate(new Date(to))}
                    </span>
                </div>
                <div className="flex items-center my-1 whitespace-nowrap">
                    <HistoryIcon />
                    <span className="ml-2">
                        {t('myRentRequests.created')}: {formatDate(new Date(createdAt))}
                    </span>
                </div>
                <div className="flex items-center my-1 whitespace-nowrap">
                    <BanknotesIcon className="w-4" />
                    <span className="ml-2">
                        {isSentTab ? t('myRentRequests.price') : t('myRentRequests.income')}:{' '}
                        {t('global.price', { price: totalPrice })}
                    </span>
                </div>
                <div className="flex my-1">
                    <MapPinIcon style={{ minWidth: '1rem' }} className="w-4 h-6" />
                    <span className="ml-2">
                        {t('myRentRequests.pickUpAddress')}: {address}
                    </span>
                </div>
                <div className="flex my-1 items-center">
                    <TimeIcon />
                    <span className="ml-2">
                        {t('myRentRequests.pickUpTime')}: {pickUpTime}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default RentRequestTile;
