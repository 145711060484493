import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import UserInfo from '@rsuite/icons/UserInfo';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import IconButton from 'rsuite/IconButton';
import Loader from 'rsuite/Loader';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';

import AvatarPreview from '../../components/AvatarPreview/AvatarPreview';
import { Container } from '../../components/Container';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { getGenderLabelBy } from '../../data/common.data';
import { useFormatDate } from '../../hooks/useFormatDate';
import { useStore } from '../../hooks/useStore';
import { QueryStore } from '../../stores/Query.store';

function UserDetailsPage() {
    const navigate = useNavigate();
    const { userDetailsStore } = useStore();
    const { t } = useTranslation();
    const params = useParams();
    const id = params.id;
    const { formatDateWithWeek } = useFormatDate();

    useEffect(() => {
        const fetchUser = async () => {
            if (!id) {
                navigate('/');
            } else {
                await userDetailsStore.fetchUser(id);
                QueryStore.handleError(navigate, userDetailsStore.errorStatus);
            }
        };
        fetchUser().catch(console.error);
    }, [userDetailsStore]);

    const goBack = () => {
        if (history.state.idx) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };
    const user = userDetailsStore.userDetails;

    return (
        <div>
            <NavBar />

            {userDetailsStore.isLoading || !user ? (
                <Container className="container-height mb-12">
                    <div className="flex justify-center items-center h-96">
                        <Loader size="md" content={t('global.loading')} />
                    </div>
                </Container>
            ) : (
                <Container className="container-height mb-12">
                    <div className="my-12 flex items-center">
                        <IconButton onClick={goBack} className="mr-4" size="md" circle icon={<PagePreviousIcon />} />

                        <h3>
                            {user.firstName} {user.lastName}
                        </h3>
                        {user.isVerified ? (
                            <Whisper speaker={<Tooltip>{t('userProfile.profileVerified')}</Tooltip>}>
                                {<UserInfo className="text-green-600 h-6 w-6 mx-2" />}
                            </Whisper>
                        ) : (
                            <Whisper speaker={<Tooltip>{t('userProfile.profileNotVerified')}</Tooltip>}>
                                {<UserInfo className="text-red-400 h-6 w-6 mx-2" />}
                            </Whisper>
                        )}
                    </div>

                    <div className="flex flex-wrap">
                        <div className="mb-8 w-64">
                            <div className="w-64 h-64">
                                <AvatarPreview
                                    avatar={user.avatar}
                                    lastName={user.lastName}
                                    firstName={user.firstName}
                                />
                            </div>
                            <div className="hidden md:block mt-8">
                                <FeedbackModal />
                            </div>
                        </div>
                        <div className="ml-0 md:ml-12 w-128 text-base flex flex-col gap-4">
                            <div className="flex items-center">
                                <p className="font-bold mr-2">{t('rentRequestModal.phone')}</p>
                                <div>{user.phoneNumber}</div>
                            </div>
                            <div className="flex items-center">
                                <p className="font-bold mr-2">{t('rentRequestModal.gender')}</p>
                                <div>{t(getGenderLabelBy(user.sex))}</div>
                            </div>
                            {user.email && (
                                <div className="flex items-center">
                                    <p className="font-bold mr-2">{t('userProfile.email')}:</p>
                                    <div>{user.email}</div>
                                </div>
                            )}
                            <div className="flex items-center">
                                <p className="font-bold mr-2">{t('userProfile.accountCreated')}:</p>
                                <div>{formatDateWithWeek(new Date(user.createdAt))}</div>
                            </div>
                            {user.description && (
                                <div className="flex flex-wrap">
                                    <p className="font-bold mr-2">{t('userProfile.aboutMe')}:</p>
                                    <div>{user.description}</div>
                                </div>
                            )}
                        </div>
                        <div>
                            <div className="block md:hidden mt-12">
                                <FeedbackModal />
                            </div>
                        </div>
                    </div>
                </Container>
            )}

            <Footer />
        </div>
    );
}

export default observer(UserDetailsPage);
