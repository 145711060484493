import FunnelIcon from '@rsuite/icons/Funnel';
import HomeIcon from '@rsuite/icons/legacy/Home';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Loader, Modal } from 'rsuite';
import Button from 'rsuite/esm/Button';
import IconButton from 'rsuite/IconButton';
import Pagination from 'rsuite/Pagination';

import { Container } from '../../components/Container';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { RentBodyRentType } from '../../generated/api';
import { useStore } from '../../hooks/useStore';
import { StringifyFilterQueryObject } from '../../types';
import ChipList from './components/ChipList';
import FoundList from './components/FoundList';
import MainFilter from './components/MainFilter';
import TileSwitcher, { SwitcherType } from './components/TileSwitcher';

function FilterContainer() {
    const { fullFilter } = useStore();
    const location = useLocation();
    const [tileType, setTileType] = useState<SwitcherType>('boxSwitcher');
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const goHome = () => {
        navigate('/');
    };

    const debounced = useMemo(
        () =>
            debounce(async () => {
                await fullFilter.fetchAdverts();
            }, 300),
        [fullFilter]
    );

    useEffect(() => {
        const parsedQuery: StringifyFilterQueryObject = queryString.parse(location.search);
        if (parsedQuery.bodyTypes) {
            fullFilter.bodyTypes.checkManyValues(parsedQuery.bodyTypes);
        }
        if (parsedQuery.brandTypes) {
            fullFilter.brandTypes.checkManyValues(parsedQuery.brandTypes);
        }
        if (parsedQuery.cities) {
            fullFilter.cities.checkManyValues(parsedQuery.cities);
        }
        if (parsedQuery.transmissions) {
            fullFilter.transmissions.checkManyValues(parsedQuery.transmissions);
        }
        if (parsedQuery.fuels) {
            fullFilter.fuels.checkManyValues(parsedQuery.fuels);
        }
        if (parsedQuery.rentPrice) {
            const [minStr, maxStr] = parsedQuery.rentPrice;
            fullFilter.rentPrice.setValue([Number(minStr), Number(maxStr)]);
        }
        if (parsedQuery.rentType) {
            fullFilter.rentPrice.setRentPeriodType(parsedQuery.rentType as RentBodyRentType);
        }
        if (parsedQuery.reservationPeriod) {
            const [fromStr, toStr] = parsedQuery.reservationPeriod;

            fullFilter.reservationPeriod.onChange([new Date(fromStr), new Date(toStr)]);
        }
        if (parsedQuery.dateOfIssue) {
            const [fromStr, toStr] = parsedQuery.dateOfIssue;

            fullFilter.dateOfIssue.setValue([Number(fromStr), Number(toStr)]);
        }
        if (parsedQuery.consumption) {
            const [fromStr, toStr] = parsedQuery.consumption;

            fullFilter.consumption.setValue([Number(fromStr), Number(toStr)]);
        }
        if (parsedQuery.mileage) {
            const [fromStr, toStr] = parsedQuery.mileage;

            fullFilter.mileage.setValue([Number(fromStr), Number(toStr)]);
        }
    }, [fullFilter]);

    useEffect(() => {
        if (!fullFilter.adverts.length && !fullFilter.isLoading) {
            fullFilter.fetchAdverts().catch(console.error);
        } else {
            debounced()?.catch(console.error);
        }

        navigate(`/filter?${fullFilter.filterUrlParam}`);
    }, [fullFilter.filterObject]);

    return (
        <div>
            <NavBar />
            <Container className="container-height mb-24">
                <div className="flex my-4 lg:mt-12 lg:mb-8">
                    <IconButton
                        onClick={goHome}
                        className="border-0.5 border-solid border-gray-200 mr-2 h-12 w-12"
                        size="md"
                        circle
                        icon={<HomeIcon />}
                    />

                    <div className="hidden lg:block w-9/12">
                        <ChipList
                            deleteCurrent={fullFilter.checkOutCurrent}
                            deleteEverything={fullFilter.checkOutAll}
                            chipList={fullFilter.getChipList()}
                        />
                    </div>
                    <div className="hidden lg:block w-3/12">
                        <TileSwitcher switcherType={tileType} onChange={setTileType} />
                    </div>

                    {/*For mobile*/}
                    <Button className="lg:hidden ml-auto px-6" size="lg" appearance="ghost" onClick={handleOpen}>
                        <FunnelIcon /> {t('filterPage.filters')} ({fullFilter.appliedFiltersCount})
                    </Button>
                </div>
                <div className="flex">
                    {/*For laptop*/}
                    <div className="hidden lg:block w-3/12 xl:w-4/12">
                        <MainFilter />
                        <FeedbackModal />
                    </div>
                    <div className="w-full lg:w-9/12 xl:w-8/12">
                        {fullFilter.isLoading && !fullFilter.adverts.length ? (
                            <div className="flex items-center justify-center h-full">
                                <Loader size="md" content={t('global.loading')} />
                            </div>
                        ) : (
                            <div className="h-full flex flex-col justify-between">
                                <FoundList tileType={tileType} adverts={fullFilter.adverts} />
                                <div className="flex items-center justify-center">
                                    <Pagination
                                        className="my-12"
                                        prev
                                        last
                                        next
                                        first
                                        size={isMobile ? 'sm' : 'md'}
                                        maxButtons={isMobile ? 3 : 6}
                                        total={fullFilter.totalAmount}
                                        limit={fullFilter.limit}
                                        activePage={fullFilter.activePage}
                                        onChangePage={fullFilter.setActivePage}
                                        onChangeLimit={fullFilter.setLimit}
                                        layout={
                                            isMobile ? ['limit', '|', 'pager'] : ['limit', '|', 'pager', '|', 'total']
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <Modal open={open} onClose={handleClose}>
                    <Modal.Header>
                        <Modal.Title>{t('filterPage.filters')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-8">
                            <ChipList
                                deleteCurrent={fullFilter.checkOutCurrent}
                                deleteEverything={fullFilter.checkOutAll}
                                chipList={fullFilter.getChipList()}
                            />
                        </div>
                        <MainFilter />
                        <FeedbackModal />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleClose} appearance="primary">
                            {t('global.apply')}
                        </Button>
                        <Button onClick={handleClose} appearance="subtle">
                            {t('global.cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
            <Footer />
        </div>
    );
}

export default observer(FilterContainer);
