import { action, computed, makeObservable, observable } from 'mobx';
import ReactGA from 'react-ga4';

import { GACategories } from '../../constants';
import { MinMaxBody } from '../../generated/api';
import { ChipListItem } from '../../pages/full-filter/components/ChipList';

export class FromToStore {
    constructor({ min, max, translationTitle }: { min: number; max: number; translationTitle?: string }) {
        makeObservable(this, {
            translationTitle: observable,
            min: observable,
            max: observable,
            chipData: computed,
            valueToQuery: computed,
            onDelete: action.bound,
            valueForSearching: computed,
            value: observable,
            setValue: action.bound,
        });
        this.translationTitle = translationTitle || '';
        this.min = min;
        this.max = max;
        this.value = [min, max];
    }

    value: [number, number] = [10, 650];
    translationTitle = '';
    min;
    max;

    setValue = (newValue: [number, number] | null) => {
        if (!newValue) return;
        this.value = newValue;

        ReactGA.event({
            category: GACategories.FullFilter,
            action: this.translationTitle,
            label: newValue.toString(),
        });
    };

    get chipData(): ChipListItem | undefined {
        const [minValue, maxValue] = this.value;

        if (minValue === this.min && maxValue === this.max) {
            return undefined;
        }

        return {
            label: this.translationTitle,
            value: this.translationTitle + JSON.stringify(this.value),
            translatedParams: {
                from: String(this.value[0]),
                to: String(this.value[1]),
            },
        };
    }

    public onDelete() {
        this.setValue([this.min, this.max]);
    }

    get valueForSearching() {
        const [minValue, maxValue] = this.value;

        if (minValue === this.min && maxValue === this.max) {
            return undefined;
        }

        return this.value;
    }

    get valueToQuery(): MinMaxBody | undefined {
        const value = this.valueForSearching;

        if (!value) return undefined;
        return {
            min: value[0],
            max: value[1],
        };
    }
}
