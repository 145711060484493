import LockClosedIcon from '@heroicons/react/24/solid/LockClosedIcon';
import HistoryIcon from '@rsuite/icons/History';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import VisibleIcon from '@rsuite/icons/Visible';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/Button';
import Dropdown from 'rsuite/Dropdown';
import Message from 'rsuite/Message';
import Popover from 'rsuite/Popover';
import toaster from 'rsuite/toaster';
import Whisper from 'rsuite/Whisper';

import { FullAdvertEntityResponse, FullAdvertEntityResponseAdvertStatus } from '../../generated/api';
import { useFormatDate } from '../../hooks/useFormatDate';
import { useStore } from '../../hooks/useStore';
import { Utils } from '../../stores/utils';
import { StatusText } from '../MyAdvertStatus/MyAdvertStatus';
import { RatingButton } from '../RatingButton/RatingButton';
import { verificationStatus } from '../VerificationStatus/VerificationStatus';
import { Price } from './Price';

interface Props {
    data: FullAdvertEntityResponse;
}

const AdvertMobileTile: React.FC<Props> = ({ data }) => {
    const navigate = useNavigate();
    const { myAdverts } = useStore();
    const { t } = useTranslation();
    const { formatDateWithWeek } = useFormatDate();

    const onAdvertEditClick = (slug: string) => {
        navigate(`/edit-advert?slug=${slug}`);
    };

    const onAdvertVerifyClick = (slug: string) => {
        navigate(`/verify-advert/${slug}`);
    };

    const onAdvertDetailClick = () => {
        navigate(`/advert-details/${data.slug}`);
    };

    const { mainPhoto, prices } = data.car;
    const { amountOfViews, expiredDate } = data;

    const renderMenu = (
        { onClose, left, top, className }: any,
        ref: React.RefCallback<HTMLElement>,
        slug: string,
        currentStatus: FullAdvertEntityResponseAdvertStatus,
        numberOfActiveRentRequests: number,
        isVerified: boolean
    ) => {
        const handleSelect = (selectFn: () => void) => {
            toaster.push(
                <Message showIcon type="success">
                    {t('myAdverts.success')}
                </Message>
            );
            selectFn();
            onClose();
        };

        const message = (
            <Message
                className="text-base"
                duration={8000}
                showIcon
                closable
                type="error"
                header="This advert has rent requests"
            >
                {t('myAdverts.cantRemoveModalSubtitle', { numberOfActiveRentRequests })}
            </Message>
        );

        const removeAdvert = async (slug: string) => {
            if (numberOfActiveRentRequests) {
                toaster.push(message);
            } else {
                await myAdverts.removeAdvert(slug);
            }
        };

        const archiveAdvert = async (slug: string) => {
            if (numberOfActiveRentRequests) {
                toaster.push(message);
            } else {
                await myAdverts.archiveAdvert(slug);
            }
        };

        const { canRemove, canArchive, canPause, canProlong, canActivate } =
            myAdverts.possibleActionList(currentStatus);

        return (
            <Popover ref={ref} className={className} style={{ left, top }} full>
                <Dropdown.Menu>
                    <Dropdown.Item className="w-60" onClick={() => handleSelect(() => onAdvertEditClick(slug))}>
                        {t('advertsActions.edit')}
                    </Dropdown.Item>
                    {!isVerified && (
                        <Dropdown.Item className="w-60" onClick={() => handleSelect(() => onAdvertVerifyClick(slug))}>
                            {t('advertsActions.verify')}
                        </Dropdown.Item>
                    )}
                    {canActivate && (
                        <Dropdown.Item onClick={() => handleSelect(() => myAdverts.activateAdvert(slug))}>
                            {t('advertsActions.activate')}
                        </Dropdown.Item>
                    )}
                    {canPause && (
                        <Dropdown.Item onClick={() => handleSelect(() => myAdverts.pauseAdvert(slug))}>
                            {t('advertsActions.pause')}
                        </Dropdown.Item>
                    )}
                    {canProlong && (
                        <Dropdown.Item onClick={() => handleSelect(() => myAdverts.prolongAdvert(slug))}>
                            {t('advertsActions.prolong')}
                        </Dropdown.Item>
                    )}
                    {canArchive && (
                        <Dropdown.Item onClick={() => handleSelect(() => archiveAdvert(slug))}>
                            {t('advertsActions.archive')}
                        </Dropdown.Item>
                    )}
                    {canRemove && (
                        <Dropdown.Item onClick={() => handleSelect(() => removeAdvert(slug))}>
                            {t('advertsActions.remove')}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Popover>
        );
    };

    return (
        <div className="cursor-pointer rounded-md shadow-md">
            <div className="relative h-64" onClick={onAdvertDetailClick}>
                <img className="w-full object-cover relative h-64 rounded-md" src={mainPhoto} alt="" />
                <Price price={prices.costPerWeek} />
                <p className="absolute text-white left-4 bottom-8 text-2xl text-outline">
                    {Utils.generateCarName(data.car)}
                </p>
                <div className="absolute -bottom-4 left-4">
                    <RatingButton />
                </div>
            </div>
            <div className="flex flex-col text-gray-400 fill-gray-400 my-4 ml-4 pb-4">
                <div className="flex items-center my-1 whitespace-nowrap">
                    <VisibleIcon />
                    <span className="ml-2">
                        {t('myAdverts.views')}: {amountOfViews}{' '}
                    </span>
                </div>
                <div className="flex items-center my-1 whitespace-nowrap">
                    <HistoryIcon />
                    <span className="ml-2">
                        {t('myAdverts.expired')}: {formatDateWithWeek(new Date(expiredDate))}
                    </span>
                </div>
                <div className="flex items-center my-1 whitespace-nowrap">
                    <InfoOutlineIcon />
                    <div className="ml-2 flex items-center">
                        <span>{t('myAdverts.status')}:</span>
                        <span className="ml-2">{StatusText(data)}</span>
                    </div>
                </div>
                <div className="flex items-center my-1 whitespace-nowrap">
                    <LockClosedIcon width={16} />
                    <div className="ml-1 flex items-center">
                        <span>{t('myAdverts.verification')}:</span>
                        <div className="flex items-center justify-center ml-2">
                            {verificationStatus(data, {
                                width: 20,
                                placement: 'bottom',
                                hasNextStepsText: false,
                            })}
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <Whisper
                        placement="autoVerticalStart"
                        trigger="click"
                        speaker={(props, ref) =>
                            renderMenu(
                                props,
                                ref,
                                data.slug,
                                data.advertStatus,
                                data.approvedRentRequestNumber,
                                Boolean(data.car.isVerified)
                            )
                        }
                    >
                        <Button className="w-60 my-4">{t('myAdverts.settings')}</Button>
                    </Whisper>
                </div>
            </div>
        </div>
    );
};

export default AdvertMobileTile;
