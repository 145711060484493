import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import SearchIcon from '@rsuite/icons/Search';
import SortDownIcon from '@rsuite/icons/SortDown';
import SortUpIcon from '@rsuite/icons/SortUp';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Badge from 'rsuite/Badge';
import Button from 'rsuite/Button';
import CheckPicker from 'rsuite/esm/CheckPicker';
import IconButton from 'rsuite/IconButton';
import Message from 'rsuite/Message';
import Nav from 'rsuite/Nav';

import { Container } from '../../components/Container';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { RentRequestEntityResponseStatus } from '../../generated/api';
import { useFormatDate } from '../../hooks/useFormatDate';
import { useStore } from '../../hooks/useStore';
import { getFromLocalStorage, saveToLocalStorage } from '../../server/localStorage';
import { QueryStore } from '../../stores/Query.store';
import { Utils } from '../../stores/utils';
import ReceivedRentRequestTable from './components/ReceivedRentRequestTable';
import RentRequestModal from './components/RentRequestModal';
import { MapRentRequestStatusValue } from './components/RentRequestStatus';
import SentRentRequestTable from './components/SentRentRequestTable';

function RentRequestListContainer() {
    const [active, setActive] = React.useState<'received' | 'sent'>('sent');
    const { rentRequestListStore } = useStore();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { formatDateWithWeek } = useFormatDate();
    const params = useParams();
    const tab = params.tab as 'received' | 'sent';

    useEffect(() => {
        const fetchRentRequests = async () => {
            await rentRequestListStore.fetchSentRentRequest();
            await rentRequestListStore.fetchReceivedRentRequest();

            QueryStore.handleError(navigate, rentRequestListStore.errorStatus);
        };

        fetchRentRequests().catch(console.error);
    }, [rentRequestListStore]);

    useEffect(() => {
        const activeTab = getFromLocalStorage('rentRequestTab');

        if (tab) {
            setActive(tab);
            navigate('/rent-request-list/');
        } else if (activeTab) {
            setActive(activeTab as 'received' | 'sent');
        }
    }, []);

    useEffect(() => {
        if (active) {
            saveToLocalStorage('rentRequestTab', active);
        }
    }, [active]);

    const goBack = () => {
        if (history.state.idx) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    const possibleStatuses = Object.values(RentRequestEntityResponseStatus).map((status) => ({
        label: t(MapRentRequestStatusValue[status]),
        value: status,
    }));

    const { closureReceivedRentRequest, closureSentRentRequest } = rentRequestListStore;

    return (
        <div>
            <NavBar />
            <Container className="mb-12 container-height">
                <div className="my-6 md:mt-12 md:mb-8 flex">
                    <IconButton onClick={goBack} className="mr-4" size="md" circle icon={<PagePreviousIcon />} />
                    <h3>{t('rentRequestPage.title')}</h3>
                </div>

                {closureReceivedRentRequest && (
                    <Message
                        showIcon
                        type="info"
                        closable
                        className="text-base"
                        header={t('rentRequestPage.reminderLeasing')}
                    >
                        <div className="flex">
                            <img
                                className="w-16 h-16 object-cover mr-4"
                                src={closureReceivedRentRequest?.advert.car.mainPhoto}
                                alt=""
                            />
                            <div>
                                <div>
                                    {t('rentRequestPage.youNeedBring')}{' '}
                                    <b>{Utils.generateCarName(closureReceivedRentRequest.advert.car)}</b>
                                    {t('rentRequestPage.to')} <b>{closureReceivedRentRequest.address}</b>{' '}
                                    {t('rentRequestPage.in')}{' '}
                                    <b>{formatDateWithWeek(new Date(closureReceivedRentRequest.from))}</b>{' '}
                                    {t('rentRequestPage.at')} <b>{closureReceivedRentRequest.pickUpTime}</b>
                                </div>
                                <Button
                                    className="text-base mt-2"
                                    size="sm"
                                    appearance="ghost"
                                    onClick={() => rentRequestListStore.reviewRentRequest(closureReceivedRentRequest)}
                                >
                                    <SearchIcon /> {t('rentRequestPage.review')}
                                </Button>
                            </div>
                        </div>
                    </Message>
                )}
                {closureSentRentRequest && (
                    <Message
                        showIcon
                        type="info"
                        closable
                        className="text-base"
                        header={t('rentRequestPage.reminderRenting')}
                    >
                        <div>
                            {t('rentRequestPage.youNeedGet')} <b>{closureSentRentRequest.address}</b>{' '}
                            {t('rentRequestPage.in')} <b>{formatDateWithWeek(new Date(closureSentRentRequest.from))}</b>{' '}
                            {t('rentRequestPage.at')} <b>{closureSentRentRequest.pickUpTime}</b>
                        </div>
                        <Button
                            className="text-base mt-2"
                            size="sm"
                            appearance="ghost"
                            onClick={() => rentRequestListStore.reviewRentRequest(closureSentRentRequest)}
                        >
                            <SearchIcon /> {t('rentRequestPage.review')}
                        </Button>
                    </Message>
                )}
                <div className="flex justify-between items-center flex-wrap">
                    <Nav className="text-base flex" appearance="subtle" activeKey={active} onSelect={setActive}>
                        <Nav.Item icon={<SortDownIcon />} eventKey="received">
                            <span className="mr-2">{t('rentRequestPage.received')}</span>
                            {rentRequestListStore.numberOfWaitingRentRequestsReceived ? (
                                <Badge
                                    maxCount={99}
                                    content={rentRequestListStore.numberOfWaitingRentRequestsReceived}
                                />
                            ) : (
                                ''
                            )}
                        </Nav.Item>

                        <Nav.Item icon={<SortUpIcon />} eventKey="sent">
                            {t('rentRequestPage.sent')}
                        </Nav.Item>
                    </Nav>

                    <div className="text-base md:text-end my-4">
                        <span className="mr-4">{t('rentRequestPage.filter')}</span>
                        <CheckPicker
                            className="text-base w-56 md:w-60"
                            searchable={false}
                            data={possibleStatuses}
                            value={rentRequestListStore.filterStatus}
                            onChange={rentRequestListStore.setFilterStatus}
                        />
                    </div>
                </div>

                <div className="md:bg-white text-base mb-16">
                    {active === 'received' && <ReceivedRentRequestTable />}
                    {active === 'sent' && <SentRentRequestTable />}
                </div>
                <RentRequestModal type={active} />
            </Container>
            <Footer />
        </div>
    );
}

export default observer(RentRequestListContainer);
