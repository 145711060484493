import React, { PropsWithChildren } from 'react';
import Input from 'rsuite/Input';

type Props = PropsWithChildren<{
    className: string;
    [key: string]: any;
}>;

export const Textarea = React.forwardRef((props: Props, ref: any) => (
    <Input {...props} as="textarea" className={`bg-transparent ${props.className}`} ref={ref} />
));
