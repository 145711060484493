import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/esm/Button';
import Modal from 'rsuite/esm/Modal';
import Message from 'rsuite/Message';

import { useStore } from '../../../hooks/useStore';

interface Props {
    open: boolean;
    handleClose(): void;
}

const NextStepModal: React.FC<Props> = ({ open, handleClose }) => {
    const { userStore } = useStore();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goToMyProfile = () => {
        navigate('/my-profile');
    };

    const hasUserEmail = userStore.user?.email;
    return (
        <Modal keyboard={false} open={open} onClose={handleClose} backdrop="static">
            <Modal.Header>
                <Modal.Header className="px-8">
                    <h5>{hasUserEmail ? t('nextStepModal.wellDone') : t('nextStepModal.cautious')}</h5>
                </Modal.Header>
            </Modal.Header>

            <Modal.Body className="text-base">
                <p>{t('nextStepModal.thankYou')}</p>
                {hasUserEmail ? (
                    <p>
                        {t('nextStepModal.weWillNotify')} <b>{userStore.user?.email}</b>
                    </p>
                ) : (
                    <div className="text-base">
                        <Message showIcon type="error" className="text-base my-4" title="Email is empty">
                            {t('nextStepModal.notEmail')}
                        </Message>
                        <p>{t('nextStepModal.setEmail')}</p>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} appearance="ghost">
                    {t('nextStepModal.gotIt')}
                </Button>
                {!hasUserEmail && (
                    <Button className="px-8 text-base" onClick={goToMyProfile} appearance="primary">
                        {t('nextStepModal.goToEdit')}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default NextStepModal;
