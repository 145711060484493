import LocationIcon from '@rsuite/icons/Location';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { verificationStatus } from '../../../components/VerificationStatus/VerificationStatus';
import { getBodyTypeLabelBy } from '../../../data/bodyType.data';
import { getCityLabelByValue } from '../../../data/cities.data';
import { FullAdvertEntityResponse } from '../../../generated/api';
import { ReactComponent as Calendar } from '../../../images/svg/calendar.svg';
import { ReactComponent as Car } from '../../../images/svg/car.svg';
import { ReactComponent as Mileage } from '../../../images/svg/mileage.svg';
import { ReactComponent as Petrol } from '../../../images/svg/petrol.svg';

const AttributeContainer: React.FC = (props) => <div className="flex items-center mb-4">{props.children}</div>;

const Information = (label: string, value: string, icon: React.ReactElement) => (
    <AttributeContainer>
        {icon}
        <p className="text-base mx-2 text-black">{label}</p>
        <p className="text-base mt-0 text-gray-400">{value}</p>
    </AttributeContainer>
);

interface Props {
    advert: FullAdvertEntityResponse | null;
}

const MainInfo: React.FC<Props> = ({ advert }) => {
    const { t } = useTranslation();

    if (!advert) return null;

    const { car: carInfo } = advert;
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return (
        <div className="bg-gray-100 border border-solid border-gray-200 rounded-lg p-4 w-full md:w-9/12 relative">
            <div
                className="absolute top-4 right-4 flex"
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                }}
            >
                {verificationStatus(advert, {
                    width: 25,
                    placement: isMobile ? 'bottomEnd' : 'bottom',
                    hasNextStepsText: false,
                })}
            </div>
            <h6 className="text-xl mb-4">{t('advertDetailsPAge.mainInfo')}</h6>

            {Information(t('params.bodyType'), t(getBodyTypeLabelBy(carInfo.bodyType)), <Car />)}
            {Information(t('params.yearOfIssue'), String(carInfo.yearsOfIssue), <Calendar />)}
            {Information(t('params.consumption'), `${carInfo.mileage} ${t('tile.mpg')}`, <Petrol />)}
            {Information(t('params.mileage'), `${carInfo.mileage} ${t('tile.thouMiles')}`, <Mileage />)}
            {Information(t('params.city'), t(getCityLabelByValue(carInfo.city)), <LocationIcon />)}
        </div>
    );
};

export default MainInfo;
