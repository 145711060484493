import React, { useImperativeHandle, useRef } from 'react';
import { FormInstance, FormProps } from 'rsuite/esm/Form/Form';
import Form from 'rsuite/Form';

export type FormRefType = { onSubmitStep(): boolean };

const FormRef = React.forwardRef<FormRefType, FormProps<any>>((props, ref) => {
    const formRef = useRef<FormInstance | null>(null);
    const onSubmitStep = () => {
        return Boolean(formRef.current?.check());
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                onSubmitStep,
            };
        },
        []
    );

    return (
        <Form autoComplete="off" ref={formRef} {...props}>
            {props.children}
        </Form>
    );
});

export default FormRef;
