import axios from 'axios';

import config from '../config';
import { Api } from '../generated/api';
import { removeTokenFromLocalStorage, saveTokenToLocalStorage } from './localStorage';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const api = new Api({
    baseURL: config.backendUrl,
});

export const setToken = (token: string) => {
    api.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    saveTokenToLocalStorage(token);
};

export const removeToken = () => {
    api.instance.defaults.headers.common['Authorization'] = '';

    removeTokenFromLocalStorage();
};
