import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Message from 'rsuite/Message';
import toaster from 'rsuite/toaster';

import { Container } from '../../components/Container';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import StepsContainer, { StepOption } from '../../components/Steps/Steps.container';
import { useStore } from '../../hooks/useStore';
import { AddAdvertStore } from '../../stores/add-advert-store/AddAdvert.store';
import { QueryStore } from '../../stores/Query.store';
import AdditionalInfo from './components/AdditionalInfo';
import AddPhoto from './components/AddPhoto';
import Description from './components/Description';
import MainInformation from './components/MainInformation';
import PricesInfo from './components/PricesInfo';
import RequirementsForClient from './components/RequirementsForClient';

function AddAdvertContainer() {
    const [addAdvertStore] = useState(new AddAdvertStore());
    const { userStore } = useStore();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (!userStore.user && !userStore.isLoading) {
            // User is not loading
            navigate('/login', {
                state: {
                    redirectBack: `/create-advert`,
                },
            });
        }
    }, [userStore.user]);

    const onSubmitForm = async () => {
        await addAdvertStore.onCreate();
        if (addAdvertStore.errorStatus) {
            QueryStore.handleError(navigate, addAdvertStore.errorStatus);
            toaster.push(
                <Message showIcon type="error">
                    {t('global.somethingWentWrong')}
                </Message>
            );
        } else {
            toaster.push(
                <Message showIcon type="success">
                    {t('addAdvert.created')}
                </Message>
            );
            navigate('/my-adverts');
        }
    };

    const stepOptions: StepOption[] = [
        {
            mainTitle: t('addAdvert.mainInfo.title'),
            render: (ref) => <MainInformation ref={ref} mainInformationStore={addAdvertStore.mainInformation} />,
            description: t('addAdvert.mainInfo.description'),
        },
        {
            mainTitle: t('addAdvert.addPhoto.title'),
            render: (ref) => <AddPhoto ref={ref} photoStore={addAdvertStore.photos} />,
            description: t('addAdvert.addPhoto.description'),
        },
        {
            mainTitle: t('addAdvert.additionalInfo.title'),
            render: (ref) => <AdditionalInfo ref={ref} additionalInfoStore={addAdvertStore.additionalInfo} />,
            description: t('addAdvert.additionalInfo.description'),
        },
        {
            mainTitle: t('addAdvert.carDescription.title'),
            render: (ref) => <Description ref={ref} descriptionStore={addAdvertStore.descriptionStore} />,
            description: t('addAdvert.carDescription.description'),
        },
        {
            mainTitle: t('addAdvert.pricesInfo.title'),
            render: (ref) => <PricesInfo ref={ref} additionalInfoStore={addAdvertStore.additionalInfo} />,
            description: t('addAdvert.pricesInfo.description'),
        },
        {
            mainTitle: t('addAdvert.requirementsForClient.title'),
            render: (ref) => (
                <RequirementsForClient ref={ref} requirementsForClientStore={addAdvertStore.requirementsForClient} />
            ),
            description: t('addAdvert.requirementsForClient.description'),
        },
    ];
    return (
        <div>
            <NavBar />
            <Container className="mb-24">
                <StepsContainer options={stepOptions} onSubmitForm={onSubmitForm} loading={addAdvertStore.isLoading} />
            </Container>
            <Footer />
        </div>
    );
}

export default observer(AddAdvertContainer);
