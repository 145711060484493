export const brandTypes = [
    { label: 'Acura', value: 'Acura' },
    { label: 'Alfa Romeo', value: 'AlfaRomeo' },
    { label: 'Aston Martin', value: 'AstonMartin' },
    { label: 'Audi', value: 'Audi' },
    { label: 'Bentley', value: 'Bentley' },
    { label: 'BMW', value: 'BMW' },
    { label: 'Brilliance', value: 'Brilliance' },
    { label: 'Bugatti', value: 'Bugatti' },
    { label: 'BYD', value: 'BYD' },
    { label: 'Cadillac', value: 'Cadillac' },
    { label: 'Changan', value: 'Changan' },
    { label: 'Chery', value: 'Chery' },
    { label: 'Chevrolet', value: 'Chevrolet' },
    { label: 'Chrysler', value: 'Chrysler' },
    { label: 'Citroen', value: 'Citroen' },
    { label: 'Daewoo', value: 'Daewoo' },
    { label: 'Datsun', value: 'Datsun' },
    { label: 'Dodge', value: 'Dodge' },
    { label: 'Dongfeng', value: 'Dongfeng' },
    { label: 'DS', value: 'DS' },
    { label: 'FAW', value: 'FAW' },
    { label: 'Ferrari', value: 'Ferrari' },
    { label: 'FIAT', value: 'FIAT' },
    { label: 'Ford', value: 'Ford' },
    { label: 'Foton', value: 'Foton' },
    { label: 'GAC', value: 'GAC' },
    { label: 'Geely', value: 'Geely' },
    { label: 'Genesis', value: 'Genesis' },
    { label: 'Great Wall', value: 'GreatWall' },
    { label: 'Haima', value: 'Haima' },
    { label: 'Haval', value: 'Haval' },
    { label: 'Honda', value: 'Honda' },
    { label: 'Hyundai', value: 'Hyundai' },
    { label: 'Infiniti', value: 'Infiniti' },
    { label: 'Isuzu', value: 'Isuzu' },
    { label: 'JAC', value: 'JAC' },
    { label: 'Jaguar', value: 'Jaguar' },
    { label: 'Jeep', value: 'Jeep' },
    { label: 'Kia', value: 'Kia' },
    { label: 'Lamborghini', value: 'Lamborghini' },
    { label: 'Land Rover', value: 'LandRover' },
    { label: 'Lexus', value: 'Lexus' },
    { label: 'Lifan', value: 'Lifan' },
    { label: 'Lotus', value: 'Lotus' },
    { label: 'Luxgen', value: 'Luxgen' },
    { label: 'Maserati', value: 'Maserati' },
    { label: 'Maybach', value: 'Maybach' },
    { label: 'Mazda', value: 'Mazda' },
    { label: 'Mercedes-Benz', value: 'MercedesBenz' },
    { label: 'MINI', value: 'MINI' },
    { label: 'Mitsubishi', value: 'Mitsubishi' },
    { label: 'Nissan', value: 'Nissan' },
    { label: 'Opel', value: 'Opel' },
    { label: 'Peugeot', value: 'Peugeot' },
    { label: 'Porsche', value: 'Porsche' },
    { label: 'Ravon', value: 'Ravon' },
    { label: 'Renault', value: 'Renault' },
    { label: 'Rolls-Royce', value: 'RollsRoyce' },
    { label: 'SEAT', value: 'SEAT' },
    { label: 'Skoda', value: 'Skoda' },
    { label: 'Smart', value: 'Smart' },
    { label: 'SsangYong', value: 'SsangYong' },
    { label: 'Subaru', value: 'Subaru' },
    { label: 'Suzuki', value: 'Suzuki' },
    { label: 'Saab', value: 'Saab' },
    { label: 'Toyota', value: 'Toyota' },
    { label: 'Volkswagen', value: 'Volkswagen' },
    { label: 'Volvo', value: 'Volvo' },
    { label: 'Zotye', value: 'Zotye' },
];
