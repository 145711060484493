import React from 'react';
import { useTranslation } from 'react-i18next';

import { getTransmissionLabelBy } from '../../data/common.data';
import { FullAdvertEntityResponse } from '../../generated/api';
import { ReactComponent as Engine } from '../../images/svg/engine.svg';
import { ReactComponent as Petrol } from '../../images/svg/petrol.svg';
import { ReactComponent as Sits } from '../../images/svg/sits.svg';
import { ReactComponent as Transmission } from '../../images/svg/transmission.svg';

interface Props {
    data: FullAdvertEntityResponse;
}

const TileParams: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation();

    const { engineCapacity, consumption, transmission, doorAmount } = data.car;
    return (
        <div className="flex justify-between items-center text-gray-400 fill-gray-400 px-2">
            <div className="hidden md:flex items-center">
                <Sits />
                <span className="ml-1">
                    {doorAmount} {t('tile.doors')}
                </span>
            </div>
            <div className="flex items-center">
                <Petrol />
                <span className="ml-1">
                    {consumption} {t('tile.mpg')}
                </span>
            </div>
            <div className="flex items-center">
                <Engine />
                <span className="ml-1">
                    {engineCapacity}
                    {t('tile.liters')}
                </span>
            </div>
            <div className="flex items-center w-1/3">
                <Transmission />
                <span className="ml-1">{t(getTransmissionLabelBy(transmission))}</span>
            </div>
        </div>
    );
};

export default TileParams;
