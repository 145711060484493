import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCitizenshipsLabelBy } from '../../../data/common.data';
import { CarEntityResponse } from '../../../generated/api';
import { ReactComponent as Warning } from '../../../images/svg/warning.svg';

interface Props {
    carInfo: CarEntityResponse;
}

const RequirementForClient: React.FC<Props> = ({ carInfo }) => {
    const { t } = useTranslation();

    const Important = (title: string, value: string) => (
        <div className="flex items-center mb-4">
            <Warning />
            <p className="text-base mx-2 text-black">{title}</p>
            <p className="text-base mt-0 text-gray-400">{value}</p>
        </div>
    );

    const { deposit, minAge, minExperience, citizenship } = carInfo.requirementForClient;
    return (
        <>
            <h6 className="text-xl mb-4">{t('advertDetailsPAge.requirementForClient')}</h6>
            {deposit && Important(t('params.deposit'), t('global.price', { price: deposit }))}
            {minAge && Important(t('params.minAge'), `${String(minAge)}  ${t('tile.years')}`)}
            {minExperience && Important(t('params.minExp'), `${String(minExperience)} ${t('tile.years')}`)}
            {citizenship && Important(t('params.citizenship'), t(getCitizenshipsLabelBy(citizenship)))}
        </>
    );
};

export default RequirementForClient;
