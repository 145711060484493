/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateUserDto {
    /** @default "Nick" */
    firstName: string;
    /** @default "Jefferson" */
    lastName: string;
    /** @default "Jefferson" */
    email: string;
    /** @default "+127563853" */
    phoneNumber: string;
    /** @default "123" */
    password: string;
}

export interface UserEntityResponse {
    id: string;
    firstName: string;
    lastName: string;
    avatar: string;
    sex: string;
    /** @format date-time */
    updatedAt: string;
    /** @format date-time */
    createdAt: string;
    description: string;
    isVerified: boolean;
    phoneNumber: string;
    role: UserEntityResponseRole;
    email?: string;
}

export interface CreateUserResponse {
    user: UserEntityResponse;
    numberOfWaitingRentRequests: number;
    token: string;
}

export interface GoogleAuthDto {
    token: string;
    phoneNumber?: string;
}

export interface GoogleLoginResponse {
    user?: UserEntityResponse;
    numberOfWaitingRentRequests?: number;
    token?: string;
    needPhoneNumber: boolean;
}

export interface LoginUserDto {
    /** @default "+127563853" */
    phoneNumber: string;
    /** @default "123" */
    password: string;
}

export interface UpdateUserDto {
    /** @default "New first name" */
    firstName?: string;
    /** @default "New last name" */
    lastName?: string;
    /** @default "new-email@gmail.com" */
    email?: string;
    /** @default "" */
    avatar?: string;
    /** @default "" */
    sex?: UpdateUserDtoSex;
    /** @default "" */
    description?: string;
    /** @default [] */
    documents?: string[];
}

export interface RentRequestDto {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
    advertSlug: string;
    /** @default "any adress" */
    address: string;
    /** @default 445 */
    totalPrice: number;
    /** @default "i am a description" */
    description: string;
    /** @default "12:00" */
    pickUpTime: string;
    /** @default 51.34345 */
    lat: number;
    /** @default 41.53432 */
    lng: number;
}

export interface RequirementForClientEntityResponse {
    id: number;
    minAge?: number;
    minExperience?: number;
    deposit?: number;
    citizenship?: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
}

export interface PricesEntityResponse {
    id: number;
    costPerDay: number;
    costPerWeek: number;
    costPerMonth?: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
}

export interface CarEntityResponse {
    id: number;
    model: string;
    modification?: string;
    bodyType: string;
    fuelType: string;
    description: string;
    photos: string[];
    city: string;
    mileage: number;
    yearsOfIssue: number;
    brandType: string;
    engineCapacity: number;
    doorAmount: number;
    requirementForClient: RequirementForClientEntityResponse;
    prices: PricesEntityResponse;
    consumption: number;
    transmission: string;
    documentsOnCar?: string[];
    isVerified?: boolean;
    mainPhoto: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
}

export interface DisabledDatesResponse {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
}

export interface AdvertEntityResponse {
    id: number;
    creator: UserEntityResponse;
    car: CarEntityResponse;
    advertStatus: AdvertEntityResponseAdvertStatus;
    slug: string;
    declineReason: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    /** @format date-time */
    expiredDate: string;
    amountOfViews: number;
    approvedRentRequestNumber: number;
    disabledDates: DisabledDatesResponse[];
}

export interface RentRequestEntityResponse {
    id: number;
    advert: AdvertEntityResponse;
    creator: UserEntityResponse;
    user: UserEntityResponse;
    status: RentRequestEntityResponseStatus;
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
    /** @format date-time */
    createdAt: string;
    totalPrice: number;
    pickUpTime: string;
    lat: number;
    lng: number;
    /** @format date-time */
    updatedAt: string;
    description?: string;
    declineReasonMessage?: string;
    declineReasonHeader?: string;
    address: string;
}

export interface RentRequestListResponse {
    rentRequests: RentRequestEntityResponse[];
    numberOfWaitingRentRequestsReceived: number;
    total: number;
}

export interface RentRequestResponse {
    rentRequest: RentRequestEntityResponse;
}

export interface DeclineRentRequestDto {
    declineReasonHeader: string;
    declineReasonMessage: string;
}

export interface RequirementForClientDto {
    /** @default 18 */
    minAge?: number;
    /** @default 5 */
    minExperience?: number;
    /** @default 1000 */
    deposit?: number;
    /** @default "Ukraine" */
    citizenship?: string;
}

export interface PriceDto {
    /** @default 25 */
    costPerDay: number;
    /** @default 350 */
    costPerWeek: number;
    /** @default 1500 */
    costPerMonth?: number;
}

export interface CreateAdvertDto {
    /** @default "Passat" */
    model: string;
    /** @default "Tesla" */
    brandType: string;
    /** @default "Hello i am description of this car" */
    description: string;
    /** @default "modification name" */
    modification?: string;
    /** @default 2010 */
    yearsOfIssue: number;
    /** @default "Petrol" */
    fuelType: string;
    /** @default 72 */
    mileage: number;
    /** @default ["url1"] */
    photos: string[];
    /** @default "Lviv" */
    city: string;
    /** @default "Sedan" */
    bodyType: string;
    /** @default 1.6 */
    engineCapacity: number;
    /** @default 4 */
    doorAmount: number;
    requirementForClient: RequirementForClientDto;
    prices: PriceDto;
    /** @default 10 */
    consumption: number;
    /** @default "Automatic" */
    transmission: string;
    /** @default "photo1" */
    mainPhoto: string;
}

export interface FullAdvertEntityResponse {
    id: number;
    creator: UserEntityResponse;
    car: CarEntityResponse;
    advertStatus: FullAdvertEntityResponseAdvertStatus;
    slug: string;
    declineReason: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    /** @format date-time */
    expiredDate: string;
    amountOfViews: number;
    approvedRentRequestNumber: number;
    disabledDates: DisabledDatesResponse[];
    isEditable: boolean;
    documentsSent: boolean;
}

export interface AdvertResponse {
    advert: FullAdvertEntityResponse;
}

export interface ReservationPeriodBody {
    /** @format date-time */
    from: string;
    /** @format date-time */
    to: string;
}

export interface MinMaxBody {
    min: number;
    max: number;
}

export interface RentBody {
    rentType: RentBodyRentType;
    rentPrice: MinMaxBody;
}

export interface FindAllBody {
    limit?: number;
    offset?: number;
    brandTypes?: string[];
    bodyTypes?: string[];
    reservationPeriod?: ReservationPeriodBody;
    cities?: string[];
    fuels?: string[];
    transmissions?: string[];
    dateOfIssue?: MinMaxBody;
    consumption?: MinMaxBody;
    mileage?: MinMaxBody;
    rent?: RentBody;
}

export interface AdvertListResponse {
    adverts: FullAdvertEntityResponse[];
    total: number;
}

export interface UpdateAdvertDto {
    /** @default "Passat" */
    model: string;
    /** @default "Tesla" */
    brandType: string;
    /** @default "Hello i am description of this car" */
    description: string;
    /** @default "modification name" */
    modification?: string;
    /** @default 2010 */
    yearsOfIssue: number;
    /** @default "Petrol" */
    fuelType: string;
    /** @default 72 */
    mileage: number;
    /** @default ["url1"] */
    photos: string[];
    /** @default "Lviv" */
    city: string;
    /** @default "Sedan" */
    bodyType: string;
    /** @default 1.6 */
    engineCapacity: number;
    /** @default 4 */
    doorAmount: number;
    requirementForClient: RequirementForClientDto;
    prices: PriceDto;
    /** @default 10 */
    consumption: number;
    /** @default "Automatic" */
    transmission: string;
    /** @default "photo1" */
    mainPhoto: string;
}

export interface UploadDocumentsDto {
    /** @default ["url1","url2"] */
    documentsOnCar: string[];
    /** @default ["url1","url2"] */
    documents?: string[];
}

export interface DeclineAdvertDto {
    /** @default "car-123fdsfs" */
    slug: string;
    /** @default "It is what it is" */
    declineReason: string;
}

export interface UploadFileDto {
    /** @format binary */
    file: File;
}

export interface FileResponse {
    url: string;
}

export interface CreateFeedbackDto {
    /** @default "Everything is great" */
    feedback: string;
    /** @default "" */
    contact: string;
}

export interface FeedbackEntityResponse {
    id: string;
    feedback: string;
    contact?: string;
}

export enum UserEntityResponseRole {
    Admin = 'admin',
    User = 'user',
}

/** @default "" */
export enum UpdateUserDtoSex {
    Male = 'male',
    Female = 'female',
}

export enum AdvertEntityResponseAdvertStatus {
    Active = 'active',
    Declined = 'declined',
    Expired = 'expired',
    Paused = 'paused',
    Deleted = 'deleted',
    Archived = 'archived',
    InRenting = 'in_renting',
}

export enum RentRequestEntityResponseStatus {
    Approved = 'approved',
    Active = 'active',
    Declined = 'declined',
    WaitingForReview = 'waiting-for-review',
    Completed = 'completed',
}

export enum FullAdvertEntityResponseAdvertStatus {
    Active = 'active',
    Declined = 'declined',
    Expired = 'expired',
    Paused = 'paused',
    Deleted = 'deleted',
    Archived = 'archived',
    InRenting = 'in_renting',
}

export enum RentBodyRentType {
    Week = 'week',
    Day = 'day',
    Month = 'month',
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType;
    /** request body */
    body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    securityWorker?: (
        securityData: SecurityDataType | null,
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
    secure?: boolean;
    format?: ResponseType;
}

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance;
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private secure?: boolean;
    private format?: ResponseType;

    constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method);

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem);
        } else {
            return `${formItem}`;
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent: any[] = property instanceof Array ? property : [property];

            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }

            return formData;
        }, new FormData());
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<AxiosResponse<T>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;

        if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
            body = this.createFormData(body as Record<string, unknown>);
        }

        if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
            body = JSON.stringify(body);
        }

        return this.instance.request({
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
            },
            params: query,
            responseType: responseFormat,
            data: body,
            url: path,
        });
    };
}

/**
 * @title Rent car
 * @version 1.0.0
 * @contact
 *
 * API for renting car
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    users = {
        /**
         * No description
         *
         * @tags Users
         * @name UsersControllerCreate
         * @request POST:/users
         * @secure
         */
        usersControllerCreate: (data: CreateUserDto, params: RequestParams = {}) =>
            this.request<CreateUserResponse, any>({
                path: `/users`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Users
         * @name UsersControllerFindAll
         * @request GET:/users
         * @secure
         */
        usersControllerFindAll: (params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Users
         * @name UsersControllerGoogleAuth
         * @request POST:/users/google-auth
         * @secure
         */
        usersControllerGoogleAuth: (data: GoogleAuthDto, params: RequestParams = {}) =>
            this.request<GoogleLoginResponse, any>({
                path: `/users/google-auth`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Users
         * @name UsersControllerLogin
         * @request POST:/users/login
         * @secure
         */
        usersControllerLogin: (data: LoginUserDto, params: RequestParams = {}) =>
            this.request<CreateUserResponse, any>({
                path: `/users/login`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Users
         * @name UsersControllerUnsubscribeUser
         * @request GET:/users/unsubscribe/{email}
         * @secure
         */
        usersControllerUnsubscribeUser: (email: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/unsubscribe/${email}`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Users
         * @name UsersControllerResubscribeUser
         * @request GET:/users/re-subscribe/{email}
         * @secure
         */
        usersControllerResubscribeUser: (email: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/re-subscribe/${email}`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Users
         * @name UsersControllerCurrentUser
         * @request GET:/users/user
         * @secure
         */
        usersControllerCurrentUser: (params: RequestParams = {}) =>
            this.request<CreateUserResponse, any>({
                path: `/users/user`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Users
         * @name UsersControllerUpdateCurrentUser
         * @request PATCH:/users/user
         * @secure
         */
        usersControllerUpdateCurrentUser: (data: UpdateUserDto, params: RequestParams = {}) =>
            this.request<CreateUserResponse, any>({
                path: `/users/user`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Users
         * @name UsersControllerFindOne
         * @request GET:/users/{id}
         * @secure
         */
        usersControllerFindOne: (id: string, params: RequestParams = {}) =>
            this.request<UserEntityResponse, any>({
                path: `/users/${id}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Users
         * @name UsersControllerRemove
         * @request DELETE:/users/{id}
         * @secure
         */
        usersControllerRemove: (id: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/users/${id}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),
    };
    rentRequests = {
        /**
         * No description
         *
         * @tags Rent request
         * @name RentRequestsControllerCreate
         * @request POST:/rent-requests
         * @secure
         */
        rentRequestsControllerCreate: (data: RentRequestDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/rent-requests`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Rent request
         * @name RentRequestsControllerFindAllReceived
         * @request GET:/rent-requests/received
         * @secure
         */
        rentRequestsControllerFindAllReceived: (params: RequestParams = {}) =>
            this.request<RentRequestListResponse, any>({
                path: `/rent-requests/received`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Rent request
         * @name RentRequestsControllerFindAllSent
         * @request GET:/rent-requests/sent
         * @secure
         */
        rentRequestsControllerFindAllSent: (params: RequestParams = {}) =>
            this.request<RentRequestListResponse, any>({
                path: `/rent-requests/sent`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Rent request
         * @name RentRequestsControllerFindOne
         * @request GET:/rent-requests/{id}
         * @secure
         */
        rentRequestsControllerFindOne: (id: number, params: RequestParams = {}) =>
            this.request<RentRequestResponse, any>({
                path: `/rent-requests/${id}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Rent request
         * @name RentRequestsControllerDeleteOne
         * @request DELETE:/rent-requests/{id}
         * @secure
         */
        rentRequestsControllerDeleteOne: (id: number, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/rent-requests/${id}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Rent request
         * @name RentRequestsControllerActivateRentRequest
         * @request PATCH:/rent-requests/{id}/activate
         * @secure
         */
        rentRequestsControllerActivateRentRequest: (id: number, params: RequestParams = {}) =>
            this.request<RentRequestResponse, any>({
                path: `/rent-requests/${id}/activate`,
                method: 'PATCH',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Rent request
         * @name RentRequestsControllerDeclineRentRequest
         * @request PATCH:/rent-requests/{id}/decline
         * @secure
         */
        rentRequestsControllerDeclineRentRequest: (
            id: number,
            data: DeclineRentRequestDto,
            params: RequestParams = {},
        ) =>
            this.request<RentRequestResponse, any>({
                path: `/rent-requests/${id}/decline`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    adverts = {
        /**
         * No description
         *
         * @tags Adverts
         * @name AdvertControllerCreate
         * @request POST:/adverts
         * @secure
         */
        advertControllerCreate: (data: CreateAdvertDto, params: RequestParams = {}) =>
            this.request<AdvertResponse, any>({
                path: `/adverts`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Adverts
         * @name AdvertControllerFindAll
         * @request POST:/adverts/find
         * @secure
         */
        advertControllerFindAll: (data: FindAllBody, params: RequestParams = {}) =>
            this.request<AdvertListResponse, any>({
                path: `/adverts/find`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Adverts
         * @name AdvertControllerFindMy
         * @request POST:/adverts/find-my
         * @secure
         */
        advertControllerFindMy: (params: RequestParams = {}) =>
            this.request<AdvertListResponse, any>({
                path: `/adverts/find-my`,
                method: 'POST',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Adverts
         * @name AdvertControllerFindOneBySlug
         * @request GET:/adverts/{slug}
         * @secure
         */
        advertControllerFindOneBySlug: (slug: string, params: RequestParams = {}) =>
            this.request<AdvertResponse, void>({
                path: `/adverts/${slug}`,
                method: 'GET',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Adverts
         * @name AdvertControllerDeleteBySlug
         * @request DELETE:/adverts/{slug}
         * @secure
         */
        advertControllerDeleteBySlug: (slug: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/adverts/${slug}`,
                method: 'DELETE',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Adverts
         * @name AdvertControllerUpdateBySlug
         * @request PATCH:/adverts/{slug}
         * @secure
         */
        advertControllerUpdateBySlug: (slug: string, data: UpdateAdvertDto, params: RequestParams = {}) =>
            this.request<AdvertResponse, any>({
                path: `/adverts/${slug}`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Adverts
         * @name AdvertControllerArchivedBySlug
         * @request POST:/adverts/{slug}/archive
         * @secure
         */
        advertControllerArchivedBySlug: (slug: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/adverts/${slug}/archive`,
                method: 'POST',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Adverts
         * @name AdvertControllerUploadDocuments
         * @request PATCH:/adverts/{slug}/update-documents
         * @secure
         */
        advertControllerUploadDocuments: (slug: string, data: UploadDocumentsDto, params: RequestParams = {}) =>
            this.request<AdvertResponse, any>({
                path: `/adverts/${slug}/update-documents`,
                method: 'PATCH',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Adverts
         * @name AdvertControllerProlongBySlug
         * @request POST:/adverts/{slug}/prolong
         * @secure
         */
        advertControllerProlongBySlug: (slug: string, params: RequestParams = {}) =>
            this.request<AdvertResponse, any>({
                path: `/adverts/${slug}/prolong`,
                method: 'POST',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Adverts
         * @name AdvertControllerActivateBySlug
         * @request POST:/adverts/{slug}/activate
         * @secure
         */
        advertControllerActivateBySlug: (slug: string, params: RequestParams = {}) =>
            this.request<AdvertResponse, any>({
                path: `/adverts/${slug}/activate`,
                method: 'POST',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Adverts
         * @name AdvertControllerPauseBySlug
         * @request POST:/adverts/{slug}/pause
         * @secure
         */
        advertControllerPauseBySlug: (slug: string, params: RequestParams = {}) =>
            this.request<AdvertResponse, any>({
                path: `/adverts/${slug}/pause`,
                method: 'POST',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Adverts
         * @name AdvertControllerDeclineAdvert
         * @request POST:/adverts/{slug}/unavailable-range
         * @secure
         */
        advertControllerDeclineAdvert: (slug: string, data: DeclineAdvertDto, params: RequestParams = {}) =>
            this.request<AdvertResponse, any>({
                path: `/adverts/${slug}/unavailable-range`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    };
    files = {
        /**
         * No description
         *
         * @tags Files
         * @name FilesControllerFindOne
         * @request GET:/files/{filename}
         * @secure
         */
        filesControllerFindOne: (filename: string, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/files/${filename}`,
                method: 'GET',
                secure: true,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Files
         * @name FilesControllerUploadFile
         * @request POST:/files/upload
         * @secure
         */
        filesControllerUploadFile: (data: UploadFileDto, params: RequestParams = {}) =>
            this.request<FileResponse, any>({
                path: `/files/upload`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.FormData,
                format: 'json',
                ...params,
            }),
    };
    feedbacks = {
        /**
         * No description
         *
         * @tags Feedbacks
         * @name FeedbacksControllerCreate
         * @request POST:/feedbacks
         */
        feedbacksControllerCreate: (data: CreateFeedbackDto, params: RequestParams = {}) =>
            this.request<void, any>({
                path: `/feedbacks`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags Feedbacks
         * @name FeedbacksControllerFindAll
         * @request GET:/feedbacks
         */
        feedbacksControllerFindAll: (params: RequestParams = {}) =>
            this.request<FeedbackEntityResponse[], any>({
                path: `/feedbacks`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Feedbacks
         * @name FeedbacksControllerFindOne
         * @request GET:/feedbacks/{id}
         */
        feedbacksControllerFindOne: (id: string, params: RequestParams = {}) =>
            this.request<FeedbackEntityResponse, any>({
                path: `/feedbacks/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),
    };
}
