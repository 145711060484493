import * as React from 'react';
import RRangeSlider from 'rsuite/esm/RangeSlider';

import InputFromTo from '../InputFromTo/InputFromTo';

interface Props {
    value: [number, number];
    min: number;
    max: number;
    title: string;
    size?: 'small' | 'medium';
    withCurrency?: boolean;
    onChange(newValue: [number, number] | null): void;
}

const RangeSlider: React.FC<Props> = ({ size = 'medium', title, withCurrency, value, onChange, min, max }) => {
    const handleChange = (value: [number, number]) => {
        const newValue = [...value] as [number, number];
        const [from, to] = newValue;

        if (from < min) {
            newValue[0] = min;
        }
        if (from > to) {
            newValue[0] = to - 1;
            newValue[1] = to;
        }
        if (to > max) {
            newValue[1] = max;
        }
        onChange(newValue);
    };

    return (
        <div>
            {title && <p className="text-base">{title}</p>}
            <InputFromTo withCurrency={withCurrency} value={value} onChange={handleChange} max={max} min={min} />
            <RRangeSlider value={value || [min, max]} min={min} max={max} onChange={handleChange} />
        </div>
    );
};

export default RangeSlider;
