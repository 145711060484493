import React from 'react';

import { ReactComponent as BoxTileSwitcherSVG } from '../../../images/svg/boxTileSwitcher.svg';
import { ReactComponent as LineTileSwitcherSVG } from '../../../images/svg/liteTileSwitcher.svg';

export type SwitcherType = 'lineSwitcher' | 'boxSwitcher';

interface Props {
    switcherType: SwitcherType;
    onChange(newValue: SwitcherType): void;
}

const TileSwitcher: React.FC<Props> = ({ switcherType, onChange }) => {
    return (
        <div className="flex justify-between ml-auto mr-8 w-11">
            <LineTileSwitcherSVG
                style={{
                    fill: switcherType === 'boxSwitcher' ? 'rgba(36, 36, 51, 0.5)' : 'rgba(92, 126, 229, 1)',
                }}
                onClick={() => onChange('lineSwitcher')}
                className="cursor-pointer"
            />
            <BoxTileSwitcherSVG
                style={{
                    fill: switcherType === 'lineSwitcher' ? 'rgba(36, 36, 51, 0.5)' : 'rgba(92, 126, 229, 1)',
                }}
                onClick={() => onChange('boxSwitcher')}
                className="cursor-pointer"
            />
        </div>
    );
};

export default TileSwitcher;
