import { makeAutoObservable } from 'mobx';

import { api } from '../../server/server';
import { QueryStore } from '../Query.store';

export class FeedbackStore {
    constructor() {
        makeAutoObservable(this);
    }

    feedback = '';
    contact = '';
    isLoading = false;
    errorStatus?: number;

    async createFeedback() {
        this.setIsLoading(true);

        const { errorStatus } = await QueryStore.fetch(() =>
            api.feedbacks.feedbacksControllerCreate({
                contact: this.contact,
                feedback: this.feedback,
            })
        );
        this.errorStatus = errorStatus;
        this.resetForm();

        this.setIsLoading(false);
    }

    get formState() {
        return {
            contact: this.contact,
            feedback: this.feedback,
        };
    }

    setIsLoading = (isLoading: boolean) => {
        return (this.isLoading = isLoading);
    };

    setFeedback = (feedback: string) => {
        this.feedback = feedback;
    };

    setContact = (contact: string) => {
        this.contact = contact;
    };

    resetForm() {
        this.contact = '';
        this.feedback = '';
    }
}
