import React from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'rsuite/Divider';

import { RentRequestStore } from '../../stores/rent-request-store/RentRequest.store';

interface Props {
    rentRequestStore: RentRequestStore;
}

const CalculatedPrice: React.FC<Props> = ({ rentRequestStore }) => {
    const { t } = useTranslation();

    return (
        <div>
            <h6 className="mb-4">{t('calculatedPrice.cost')}:</h6>
            <div className="flex justify-between items-center">
                <div className="text-base flex flex-wrap">
                    {rentRequestStore.formatPrices.map((price, index) => (
                        <div className="whitespace-nowrap mr-2" key={index}>
                            {index ? ' + ' : ''}
                            {price}
                        </div>
                    ))}
                </div>
                <p className="text-base mt-0 whitespace-nowrap">
                    {t('global.price', { price: rentRequestStore.price })}
                </p>
            </div>
            <div className="flex justify-between mt-2">
                <p className="text-base">{t('calculatedPrice.fee')}</p>
                <p className="text-base line-through mt-0">{t('global.price', { price: 0 })}</p>
            </div>

            <Divider />
            <div className="flex justify-between">
                <h6>{t('calculatedPrice.total')}: </h6>
                <h5>{t('global.price', { price: rentRequestStore.price })}</h5>
            </div>
        </div>
    );
};

export default CalculatedPrice;
