import TrashIcon from '@rsuite/icons/Trash';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'rsuite/esm/Button';
import Loader from 'rsuite/esm/Loader';
import Message from 'rsuite/esm/Message';
import Placeholder from 'rsuite/esm/Placeholder';

import AddPhotoSVG from '../../images/svg/add-photo.svg';
import { FileItem, PhotosStore } from './PhotosStore';

interface Props {
    photoStore: PhotosStore;
}

const PhotoUploader: React.FC<Props> = ({ photoStore }) => {
    const inputFile = useRef<HTMLInputElement | null>(null);
    const { t } = useTranslation();

    const onButtonClick = () => {
        inputFile.current?.click();
    };

    const helperText =
        photoStore.photoList.length > 1 ? t('photoUploader.selectMainPhoto') : t('photoUploader.addPhoto');

    const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        event.preventDefault();

        if (!event.target.files) {
            return;
        }

        await photoStore.uploadFiles(event.target.files);
    };

    const photoCard = (file: FileItem) => {
        if (file.isLoading) {
            return (
                <div className="relative" key={file.id}>
                    <Placeholder.Graph active className="w-60 h-36 mr-2 mt-2"></Placeholder.Graph>
                    <Loader size="md" center content={t('global.loading')} />
                </div>
            );
        }

        return (
            <div
                className={`flex justify-center items-center cursor-pointer relative rounded-lg mr-2 mt-2 border-4 p-1 border-solid ${
                    photoStore.mainPhoto?.url === file.url ? 'border-primary-blue' : 'border-transparent'
                }`}
                key={file.id}
                onClick={() => {
                    photoStore.setMainPhotoName(file);
                }}
            >
                <div
                    className="absolute top-1 right-1 z-10 opacity-40 bg-black p-3 rounded-lg hover:opacity-50 cursor-pointer"
                    onClick={(event) => {
                        event.stopPropagation();
                        photoStore.onRemoveFile(file);
                    }}
                >
                    <TrashIcon className="text-white text-xl" />
                </div>
                <img className="rounded-lg object-cover relative w-60 h-36" src={file.url} alt="" />
                {photoStore.mainPhoto?.url === file.url && (
                    <div className="text-white absolute bottom-4 left-4 text-lg">{t('photoUploader.mainPhoto')}</div>
                )}
            </div>
        );
    };

    return (
        <div>
            <p className="my-4 text-base">{helperText}</p>
            {photoStore.isValidationError && (
                <Message showIcon type="error" className="text-base">
                    {t('photoUploader.addAtLeast', { amount: photoStore.MIN_NUMBER_OF_PHOTOS })}
                </Message>
            )}
            <div className="flex relative items-center flex-wrap">
                <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    onChange={uploadFile}
                    multiple
                    accept="image/*"
                    style={{ display: 'none' }}
                />

                {photoStore.photoList.map(photoCard)}

                <Button
                    className="w-60 mt-2 h-36 rounded-lg border-4 p-1 border-dashed border-primary-blue"
                    onClick={onButtonClick}
                >
                    <img className="mr-2" src={AddPhotoSVG} alt="Add photo" />
                    <p className="text-base text-primary-blue">{t('photoUploader.addButton')}</p>
                </Button>
            </div>
        </div>
    );
};

export default observer(PhotoUploader);
