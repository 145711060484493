import { makeAutoObservable } from 'mobx';

interface Props {
    transmission: string;
    fuel: string;
    doorNumber: string;
    weekPrice: string;
    dayPrice: string;
    engineCapacity: string;
    monthPrice?: string;
}

export class AdditionalInfoStore {
    constructor(props: Props) {
        makeAutoObservable(this);

        this.fuel = props.fuel;
        this.transmission = props.transmission;
        this.doorNumber = props.doorNumber;
        this.weekPrice = props.weekPrice;
        this.engineCapacity = props.engineCapacity;
        this.dayPrice = props.dayPrice;
        this.monthPrice = props.monthPrice;
    }

    transmission = '';
    fuel = '';
    doorNumber = '';
    weekPrice = '';
    dayPrice = '';
    engineCapacity = '';
    monthPrice?: string = '';

    setTransmission = (transmission: string) => {
        this.transmission = transmission;
    };
    setFuel = (fuel: string) => {
        this.fuel = fuel;
    };
    setEngineCapacity = (engineCapacity: string) => {
        this.engineCapacity = engineCapacity;
    };
    setDoorNumber = (doorNumber: string) => {
        this.doorNumber = doorNumber;
    };
    setDayPrice = (dayPrice: string) => {
        this.dayPrice = dayPrice;
    };
    setWeekPrice = (weekPrice: string) => {
        this.weekPrice = weekPrice;
    };
    setMonthPrice = (monthPrice: string) => {
        this.monthPrice = monthPrice;
    };
}
