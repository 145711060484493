import React from 'react';
import { useTranslation } from 'react-i18next';
import Panel from 'rsuite/esm/Panel';

import { CarEntityResponse } from '../../generated/api';

interface Props {
    carInfo: CarEntityResponse;
}

const PriceDetails: React.FC<Props> = ({ carInfo }) => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center">
            <h6>{t('params.prices')}:</h6>
            <Panel
                header={t('price.perWeek', { price: carInfo.prices.costPerWeek })}
                defaultExpanded
                collapsible
                className="w-full md:w-6/12"
            >
                {carInfo.prices.costPerDay && (
                    <p style={{ marginTop: '-10px' }} className="text-base">
                        {t('price.perDay', { price: carInfo.prices.costPerDay })}
                    </p>
                )}
                {carInfo.prices.costPerMonth && (
                    <p className="text-base mt-0">{t('price.perMonth', { price: carInfo.prices.costPerMonth })}</p>
                )}
            </Panel>
        </div>
    );
};

export default PriceDetails;
