import Crossover from '../images/body-types/crossover.svg';
import Sedan from '../images/body-types/sedan.svg';
import Suv from '../images/body-types/suv.svg';
import Truck from '../images/body-types/truck.svg';

export const bodyTypeData = [
    {
        label: 'bodyTypes.sedan',
        value: 'Sedan',
        url: Sedan,
    },
    {
        label: 'bodyTypes.suv',
        value: 'Suv',
        url: Suv,
    },
    {
        label: 'bodyTypes.crossover',
        value: 'Crossover',
        url: Crossover,
    },
    {
        label: 'bodyTypes.hatchback',
        value: 'Hatchback',
        url: Crossover,
    },
    {
        label: 'bodyTypes.roadster',
        value: 'Roadster',
        url: Crossover,
    },
    {
        label: 'bodyTypes.pickup',
        value: 'Pickup',
        url: Crossover,
    },
    {
        label: 'bodyTypes.micro',
        value: 'Micro',
        url: Crossover,
    },
    {
        label: 'bodyTypes.van',
        value: 'Van',
        url: Crossover,
    },
    {
        label: 'bodyTypes.coupe',
        value: 'Coupe',
        url: Crossover,
    },
    {
        label: 'bodyTypes.supercar',
        value: 'Supercar',
        url: Crossover,
    },
    {
        label: 'bodyTypes.campervan',
        value: 'Campervan',
        url: Crossover,
    },
    {
        label: 'bodyTypes.miniTruck',
        value: 'Mini truck',
        url: Crossover,
    },
    {
        label: 'bodyTypes.cabriolet',
        value: 'Cabriolet',
        url: Crossover,
    },
    {
        label: 'bodyTypes.minivan',
        value: 'Minivan',
        url: Crossover,
    },
    {
        label: 'bodyTypes.truck',
        value: 'Truck',
        url: Truck,
    },
];

export const getBodyTypeLabelBy = (value: string): string => {
    return bodyTypeData.find((bodyType) => bodyType.value === value)?.label || '';
};
