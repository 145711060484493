import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Form from 'rsuite/Form';
import Schema from 'rsuite/Schema';

import { DayPriceInput, MonthPriceInput, WeekPriceInput } from '../../../components/Inputs/Inputs';
import FormRef, { FormRefType } from '../../../components/Steps/FormRef';
import { AdditionalInfoStore } from '../../../stores/add-advert-store/AdditionalInfo.store';

const { NumberType } = Schema.Types;

export const pricesInformationSchemaBuilder = (t: TFunction) =>
    Schema.Model({
        weekPrice: NumberType(t('formErrors.mustBeNumber') || '')
            .isRequired(t('formErrors.isRequired') || '')
            .max(99999, t('formErrors.weekPriceAtMost', { amount: '99 999' }) || ''),
        dayPrice: NumberType(t('formErrors.mustBeNumber') || '')
            .isRequired(t('formErrors.isRequired') || '')
            .max(99999, t('formErrors.dayPriceAtMost', { amount: '99 999' }) || ''),
        monthPrice: NumberType(t('formErrors.mustBeNumber') || '').max(
            99999,
            t('formErrors.monthPriceAtMost', { amount: '99 999' }) || ''
        ),
    });

interface Props {
    additionalInfoStore: AdditionalInfoStore;
}

const PricesInfo = React.forwardRef<FormRefType, Props>(({ additionalInfoStore }, ref) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const pricesInformationSchema = useMemo(() => pricesInformationSchemaBuilder(t), [t]);

    return (
        <div className="flex justify-between flex-wrap">
            <FormRef
                layout={isMobile ? 'vertical' : 'horizontal'}
                fluid
                className="w-full"
                model={pricesInformationSchema}
                ref={ref}
                formValue={additionalInfoStore.formValue}
            >
                <div className="mt-0 lg:mt-6">
                    <Form.Group>
                        <Form.ControlLabel className="text-gray-800 text-left text-base">
                            {t('price.pricePerWeek')}
                        </Form.ControlLabel>
                        <Form.Control
                            className="w-full md:w-96"
                            size="lg"
                            name="weekPrice"
                            value={additionalInfoStore.weekPrice}
                            onChange={additionalInfoStore.setWeekPrice}
                            accepter={WeekPriceInput}
                        />
                        <Form.HelpText>{t('global.required')}</Form.HelpText>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel className="text-gray-800 text-left text-base">
                            {t('price.pricePerDay')}
                        </Form.ControlLabel>
                        <Form.Control
                            className="w-full md:w-96"
                            size="lg"
                            name="dayPrice"
                            value={additionalInfoStore.dayPrice}
                            onChange={additionalInfoStore.setDayPrice}
                            accepter={DayPriceInput}
                        />
                        <Form.HelpText>{t('global.required')}</Form.HelpText>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel className="text-gray-800 text-left text-base">
                            {t('price.pricePerMonth')}
                        </Form.ControlLabel>
                        <Form.Control
                            className="w-full md:w-96"
                            size="lg"
                            name="monthPrice"
                            value={additionalInfoStore.monthPrice}
                            onChange={additionalInfoStore.setMonthPrice}
                            accepter={MonthPriceInput}
                        />
                    </Form.Group>
                </div>
            </FormRef>
        </div>
    );
});

export default observer(PricesInfo);
