import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rsuite/esm/Tooltip';
import Whisper from 'rsuite/esm/Whisper';

import { PhotosStore } from '../../../components/PhotoUploader/PhotosStore';
import PhotoUploader from '../../../components/PhotoUploader/PhotoUploader';

interface Props {
    photoStore: PhotosStore;
}

const EditPhoto: React.FC<Props> = ({ photoStore }) => {
    const { t } = useTranslation();

    return (
        <div className="mb-20">
            <div className="flex items-center mt-12 mb-4">
                <h4 className="mr-1">{t('editAdvertPage.editPhoto.title')}</h4>
                <Whisper speaker={<Tooltip>{t('editAdvertPage.editPhoto.tooltip')}</Tooltip>}>
                    <HelpOutlineIcon className="text-2xl" color="green" />
                </Whisper>
            </div>
            <PhotoUploader photoStore={photoStore} />
        </div>
    );
};

export default observer(EditPhoto);
