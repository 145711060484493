import React from 'react';
import { useTranslation } from 'react-i18next';

export const Price: React.FC<{ price: number }> = ({ price }) => {
    const { t } = useTranslation();

    return (
        <p className="absolute text-white top-4 left-4 text-lg font-semibold text-outline">
            {t('tile.perWeek', { price })}
        </p>
    );
};
