import { makeAutoObservable, toJS } from 'mobx';
import ReactGA from 'react-ga4';

import { PhotosStore } from '../../../components/PhotoUploader/PhotosStore';
import { GAActions, GACategories } from '../../../constants';
import { api } from '../../../server/server';
import { QueryStore } from '../../../stores/Query.store';
import { AdditionalInfoStore } from './AdditionalInfoStore';
import { MainInformationStore } from './MainInformationStore';
import { RequirementsForClientStore } from './RequirementsForClientStore';

export class EditAdvertStore {
    constructor() {
        makeAutoObservable(this);
    }

    photos: PhotosStore = new PhotosStore();
    additionalInfo: AdditionalInfoStore | null = null;
    mainInformation: MainInformationStore | null = null;
    requirementsForClient: RequirementsForClientStore | null = null;
    descriptionText = '';
    contactPhone = '';

    isLoading = false;
    errorStatus?: number;

    parseToString = (value?: number): string => {
        return value ? String(value) : '';
    };

    parseToNumber = (value?: string): number | undefined => {
        return value ? Number(value) : undefined;
    };

    setDescriptionText = (descriptionText: string) => {
        this.descriptionText = descriptionText;
    };

    setContactPhone = (contactPhone: string) => {
        this.contactPhone = contactPhone;
    };

    get defaultFormStore() {
        return {
            bodyType: this.mainInformation?.bodyType || '',
            brandType: this.mainInformation?.brandType || '',
            carMode: this.mainInformation?.modification || '',
            carModel: this.mainInformation?.carModel || '',
            citizenship: this.requirementsForClient?.citizenship || '',
            dayPrice: this.additionalInfo?.dayPrice || '',
            deposit: this.requirementsForClient?.deposit || '',
            doorNumber: this.additionalInfo?.doorNumber || '',
            engineCapacity: this.additionalInfo?.engineCapacity || '',
            fuel: this.additionalInfo?.fuel || '',
            mileage: this.mainInformation?.mileage || '',
            minAge: this.requirementsForClient?.minAge || '',
            minExperience: this.requirementsForClient?.minExperience || '',
            mixedConsumption: this.mainInformation?.consumption || '',
            monthPrice: this.additionalInfo?.monthPrice || '',
            city: this.mainInformation?.city || '',
            telephone: this.contactPhone || '',
            transmission: this.additionalInfo?.transmission || '',
            weekPrice: this.additionalInfo?.weekPrice || '',
            yearsOfIssue: this.mainInformation?.yearsOfIssue || '',
            description: this.descriptionText || 'help',
        };
    }

    async fetchAdvertDetail(slug: string) {
        this.isLoading = true;

        const { errorStatus, response } = await QueryStore.fetch(() => api.adverts.advertControllerFindOneBySlug(slug));
        this.errorStatus = errorStatus;

        if (!response) {
            this.isLoading = false;
            return;
        }

        const advertDetails = response.advert;

        this.photos.setStartedFileList(advertDetails.car.photos.map((photo) => ({ url: photo })));
        this.photos.setMainPhotoName({ url: advertDetails.car.mainPhoto });

        this.additionalInfo = new AdditionalInfoStore({
            transmission: advertDetails.car.transmission,
            fuel: advertDetails.car.fuelType,
            doorNumber: this.parseToString(advertDetails.car.doorAmount),
            weekPrice: this.parseToString(advertDetails.car.prices.costPerWeek),
            engineCapacity: this.parseToString(advertDetails.car.engineCapacity),
            dayPrice: this.parseToString(advertDetails.car.prices.costPerDay),
            monthPrice: this.parseToString(advertDetails.car.prices.costPerMonth),
        });

        this.mainInformation = new MainInformationStore({
            bodyType: advertDetails.car.bodyType,
            carModel: advertDetails.car.model,
            yearsOfIssue: this.parseToString(advertDetails.car.yearsOfIssue),
            consumption: this.parseToString(advertDetails.car.consumption),
            mileage: this.parseToString(advertDetails.car.mileage),
            city: advertDetails.car.city,
            brandType: advertDetails.car.brandType,
            modification: advertDetails.car.modification,
        });

        this.requirementsForClient = new RequirementsForClientStore({
            minAge: this.parseToString(advertDetails.car.requirementForClient.minAge),
            minExperience: this.parseToString(advertDetails.car.requirementForClient.minExperience),
            deposit: this.parseToString(advertDetails.car.requirementForClient.deposit),
            citizenship: advertDetails.car.requirementForClient.citizenship,
        });

        this.setDescriptionText(advertDetails.car.description);

        this.isLoading = false;
    }

    onEdit = async (slug: string) => {
        if (!this.mainInformation || !this.additionalInfo || !this.requirementsForClient) {
            return;
        }

        const payload = {
            photos: this.photos.photoList.map((file) => file.url),
            bodyType: this.mainInformation.bodyType,
            brandType: this.mainInformation.brandType,
            consumption: Number(this.mainInformation.consumption),
            description: this.descriptionText,
            doorAmount: Number(this.additionalInfo.doorNumber),
            engineCapacity: Number(this.additionalInfo.engineCapacity),
            fuelType: this.additionalInfo.fuel,
            mainPhoto: this.photos.mainPhoto?.url || '',
            mileage: Number(this.mainInformation.mileage),
            model: this.mainInformation.carModel,
            modification: this.mainInformation.modification,
            city: this.mainInformation.city,
            prices: {
                costPerWeek: Number(this.additionalInfo.weekPrice),
                costPerDay: Number(this.additionalInfo.dayPrice),
                costPerMonth: this.parseToNumber(this.additionalInfo.monthPrice),
            },
            requirementForClient: {
                minAge: this.parseToNumber(this.requirementsForClient.minAge),
                minExperience: this.parseToNumber(this.requirementsForClient.minExperience),
                deposit: this.parseToNumber(this.requirementsForClient.deposit),
                citizenship: this.requirementsForClient.citizenship,
            },
            transmission: this.additionalInfo.transmission,
            yearsOfIssue: Number(this.mainInformation.yearsOfIssue),
        };

        const { errorStatus, response } = await QueryStore.fetch(() =>
            api.adverts.advertControllerUpdateBySlug(slug, payload)
        );
        this.errorStatus = errorStatus;

        ReactGA.event({
            category: GACategories.Advert,
            action: GAActions[GACategories.Advert].editAdvert,
        });

        return response;
    };
}
