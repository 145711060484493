import { GoogleLogin } from '@react-oauth/google';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/Button';
import Message from 'rsuite/esm/Message';
import Form from 'rsuite/Form';
import Schema from 'rsuite/Schema';
import toaster from 'rsuite/toaster';

import { PhoneInput } from '../../../components/PhoneInput/PhoneInput';
import { useStore } from '../../../hooks/useStore';
import AuthorizationPNG from '../../../images/authorization/Authorization.webp';
import { passwordRegExp } from '../../regExps';
import AccountContainer from '../components/AccountContainer';

const { StringType } = Schema.Types;

const basicValue = {
    phoneNumber: '',
    password: '',
};

interface LocationState {
    redirectBack?: string;
}

function Authorization() {
    const navigate = useNavigate();
    const [form, setForm] = useState<any>(basicValue);
    const { userStore } = useStore();
    const location = useLocation();
    const { t } = useTranslation();

    const authorizationSchema = useMemo(
        () =>
            Schema.Model({
                phoneNumber: StringType().isRequired(t('formErrors.phoneNumberNotValid') || ''),
                password: StringType()
                    .isRequired(t('formErrors.isRequired') || '')
                    .maxLength(16, t('formErrors.maxSymbol', { amount: '16' }) || '')
                    .pattern(passwordRegExp, t('formErrors.legalChars') || ''),
            }),
        [t]
    );

    const { redirectBack } = (location.state as LocationState) || {};

    const moveToRegistration = () => {
        navigate('/registration', {
            state: {
                redirectBack,
            },
        });
    };

    useEffect(() => {
        window.onpopstate = () => {
            navigate('/');
        };

        return () => {
            window.onpopstate = null;
        };
    }, []);

    const loginNavigator = () => {
        if (userStore.errorStatus) {
            toaster.push(
                <Message showIcon type="error">
                    {t('loginPage.wrongPassword')}
                </Message>
            );
            setForm({
                ...form,
                password: '',
            });
        } else {
            toaster.push(
                <Message showIcon type="success">
                    {t('loginPage.succeed')}
                </Message>
            );
            if (redirectBack) {
                navigate(redirectBack);
            } else {
                navigate('/');
            }
        }
    };

    const onSignInClick = async (checkStatus: boolean) => {
        if (checkStatus) {
            const { phoneNumber, password } = form;
            await userStore.login({ password, phoneNumber });
            loginNavigator();
        }
    };

    return (
        <AccountContainer image={AuthorizationPNG}>
            <div>
                <div className="flex mb-6">
                    <p className="text-3xl text-black"> {t('loginPage.authorisation')}</p>
                    <div className="flex items-end">
                        <p className="mx-2">{t('loginPage.or')}</p>
                        <Button size="lg" className="p-0" onClick={moveToRegistration} appearance="link">
                            {t('loginPage.registration')}
                        </Button>
                    </div>
                </div>
                <Form
                    model={authorizationSchema}
                    checkTrigger="blur"
                    onSubmit={onSignInClick}
                    onChange={setForm}
                    formValue={form}
                    fluid
                >
                    <Form.Group>
                        <Form.ControlLabel>{t('userProfile.phoneNumber')}</Form.ControlLabel>
                        <Form.Control name="phoneNumber" accepter={PhoneInput} />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>{t('userProfile.password')}</Form.ControlLabel>
                        <Form.Control name="password" type="password" autoComplete="off" />
                    </Form.Group>
                    <Form.Group className="flex items-center gap-2">
                        <Button type="submit" appearance="primary" className="px-4" disabled={userStore.isLoading}>
                            {t('loginPage.signIn')}
                        </Button>
                        <GoogleLogin
                            onSuccess={async (credentialResponse) => {
                                const needPhoneNumber = await userStore.googleLogin({
                                    token: credentialResponse.credential || '',
                                });

                                if (needPhoneNumber) {
                                    navigate('/number-registration', {
                                        state: {
                                            redirectBack,
                                        },
                                    });
                                } else {
                                    loginNavigator();
                                }
                            }}
                            onError={() => {
                                toaster.push(
                                    <Message showIcon type="error">
                                        {t('global.somethingWentWrong')}
                                    </Message>
                                );
                            }}
                            useOneTap
                        />
                    </Form.Group>
                </Form>
            </div>
        </AccountContainer>
    );
}

export default Authorization;
