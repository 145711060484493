import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../../../components/Container';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';

const NotFound: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div>
            <NavBar />
            <Container className="container-height">
                <h2 className="pt-24">404: {t('errorPages.notFound')}</h2>
            </Container>
            <div className="mt-24">
                <Footer />
            </div>
        </div>
    );
};

export default NotFound;
