import SearchIcon from '@rsuite/icons/Search';
import addDays from 'date-fns/addDays';
import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/Button';
import { before } from 'rsuite/cjs/DateRangePicker/disabledDateUtils';
import DateRangePicker from 'rsuite/DateRangePicker';
import Divider from 'rsuite/Divider';
import SelectPicker from 'rsuite/SelectPicker';

import { Container } from '../../../components/Container';
import RangeSlider from '../../../components/RangeSlider/RangeSlider';
import { GAActions, GACategories } from '../../../constants';
import { cities } from '../../../data/cities.data';
import { useStore } from '../../../hooks/useStore';
import img from '../../../images/MainPhoto.webp';
import { Utils } from '../../../stores/utils';

const Filter = () => {
    const { shortFilter, fullFilter } = useStore();
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { t } = useTranslation();

    const moveToFilterPage = () => {
        fullFilter.resetFilter();

        ReactGA.event({
            category: GACategories.Main,
            action: GAActions[GACategories.Main].onSearchClick,
            label: shortFilter.filterUrlParam,
        });
        navigate(`/filter?${shortFilter.filterUrlParam}`);
    };

    const SearchButton = () => (
        <Button size={isMobile ? 'md' : 'lg'} appearance="primary" onClick={moveToFilterPage}>
            <SearchIcon className="mr-1" /> {t('mainPage.search')}
        </Button>
    );

    const Header = () => (
        <>
            <h3 className="text-3xl md:text-5xl mb-4">{t('mainPage.header')}</h3>
            <p className="text-lg">{t('mainPage.subHeader')}</p>
        </>
    );

    return (
        <div
            className="bg-center bg-cover w-full flex items-center py-8 md:py-16"
            style={{ backgroundImage: `url(${img})` }}
        >
            <Container className="md:flex">
                {/*FOR MOBILE*/}
                <div className="mb-4 md:mb-8 text-white md:hidden">
                    <Header />
                </div>

                <div className="md:w-80 bg-white rounded-md p-6 text-3xl">
                    <SelectPicker
                        label={t('params.city')}
                        size={isMobile ? 'md' : 'lg'}
                        block
                        placement={isMobile ? undefined : 'rightStart'}
                        data={Utils.makeDataTranslated(cities, t)}
                        value={shortFilter.city}
                        placeholder={t('addAdvert.select')}
                        onChange={shortFilter.setCity}
                    />
                    <Divider className="my-4" />
                    <RangeSlider
                        title={t('price.pricePerWeek')}
                        value={shortFilter.rentCost.value}
                        onChange={shortFilter.setRentCost}
                        withCurrency
                        min={500}
                        max={10_000}
                    />
                    <Divider className="my-4" />
                    <div>
                        <p className="pb-4 text-base">{t('mainPage.reservationPeriod')}</p>
                        <DateRangePicker
                            block
                            value={shortFilter.dateTimePicker}
                            showOneCalendar={isMobile}
                            ranges={[]}
                            disabledDate={before(addDays(new Date(), 1))}
                            onChange={shortFilter.setDateTimePicker}
                        />
                    </div>
                    {/*FOR MOBILE*/}
                    <div className="md:hidden flex items-center justify-center mt-4">
                        <SearchButton />
                    </div>
                </div>
                <div className="hidden md:flex text-white items-start justify-between flex-col lg:ml-12 ml-4">
                    <div className="w-96 lg:w-128 mt-20">
                        <Header />
                    </div>
                    <SearchButton />
                </div>
            </Container>
        </div>
    );
};

export default observer(Filter);
