import { makeAutoObservable } from 'mobx';

import { UserEntityResponse } from '../../generated/api';
import { api } from '../../server/server';
import { QueryStore } from '../Query.store';

export class UserDetailsStore {
    constructor() {
        makeAutoObservable(this);
    }

    userDetails: UserEntityResponse | null = null;
    isLoading = true;
    errorStatus?: number;

    fetchUser = async (id: string) => {
        this.setIsLoading(true);
        const { response, errorStatus } = await QueryStore.fetch(() => api.users.usersControllerFindOne(id));

        if (response) {
            this.setUserDetails(response);
        }

        this.setError(errorStatus);
        this.setIsLoading(false);
    };

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };
    setError(errorStatus?: number) {
        this.errorStatus = errorStatus;
    }

    setUserDetails = (user: UserEntityResponse) => {
        this.userDetails = user;
    };
}
