import DocumentTextIcon from '@heroicons/react/24/solid/DocumentTextIcon';
import LockClosedIcon from '@heroicons/react/24/solid/LockClosedIcon';
import UserIcon from '@heroicons/react/24/solid/UserIcon';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Form } from 'rsuite';
import Button from 'rsuite/Button';
import { FormControlProps } from 'rsuite/esm/FormControl/FormControl';
import { FileType } from 'rsuite/esm/Uploader/Uploader';
import Message from 'rsuite/Message';
import Schema from 'rsuite/Schema';
import toaster from 'rsuite/toaster';
import Uploader from 'rsuite/Uploader';

import { Container } from '../../components/Container';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import config from '../../config';
import { FileResponse } from '../../generated/api';
import { useStore } from '../../hooks/useStore';
import { getTokenFromLocalStorage } from '../../server/localStorage';
import { QueryStore } from '../../stores/Query.store';
import { Utils } from '../../stores/utils';

const { ArrayType } = Schema.Types;

const accepter: FormControlProps['accepter'] = (props) => (
    <Uploader
        {...props}
        draggable
        name="file"
        action={config.backendUrl + 'files/upload'}
        headers={{
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            Authorization: `Bearer ${props.token || ''}`,
        }}
        onError={() => {
            toaster.push(<Message type="error">{props.t('userProfilePage.uploadFailed')}</Message>);
        }}
    >
        <div style={{ height: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span>{props.t('global.DAndD')}</span>
        </div>
    </Uploader>
);

interface FileState {
    url: string;
    fileKey: string;
}

const VerifyAdvert: React.FC = () => {
    const params = useParams();
    const slug = params.slug;
    const navigate = useNavigate();
    const token = getTokenFromLocalStorage();
    const { advertDetailsStore, userStore } = useStore();
    const [passport, setPassport] = React.useState<FileState[]>([]);
    const [drivingLicence, setDrivingLicence] = React.useState<FileState[]>([]);
    const [carOwnership, setCarOwnership] = React.useState<FileState[]>([]);
    const [carInsurance, setCarInsurance] = React.useState<FileState[]>([]);
    const { t } = useTranslation();

    const carVerifyProfileSchema = useMemo(
        () =>
            Schema.Model({
                carOwnership: ArrayType().isRequired(t('formErrors.isRequired') || ''),
                insurance: ArrayType().isRequired(t('formErrors.isRequired') || ''),
            }),
        [t]
    );

    const userVerifyProfileSchema = useMemo(
        () =>
            Schema.Model({
                passport: ArrayType().isRequired(t('formErrors.isRequired') || ''),
                drivingLicence: ArrayType().isRequired(t('formErrors.isRequired') || ''),
            }),
        [t]
    );

    if (!slug) {
        navigate('/my-adverts');
        return null;
    }

    useEffect(() => {
        const uploadAdvertDetails = async () => {
            if (slug) {
                await advertDetailsStore.fetchAdvertDetail(slug);
                QueryStore.handleError(navigate, advertDetailsStore.errorStatus);
            } else {
                navigate(`/filter`);
            }

            if (
                !advertDetailsStore.advertDetails?.isEditable ||
                advertDetailsStore.advertDetails?.car.isVerified ||
                advertDetailsStore.advertDetails?.documentsSent
            ) {
                navigate('/my-adverts');
            }
        };

        uploadAdvertDetails().catch(console.error);
    });

    const addDocumentTo = (response: FileResponse, file: FileType, fn: Dispatch<SetStateAction<FileState[]>>) => {
        fn((prevState) => [
            ...prevState,
            {
                url: response.url,
                fileKey: String(file.fileKey),
            },
        ]);
    };

    const removeDocumentFrom = (file: FileType, fn: Dispatch<SetStateAction<FileState[]>>) => {
        fn((prevState) => prevState.filter((savedFile) => savedFile.fileKey !== file.fileKey));
    };

    const onAddPassport = (response: FileResponse, file: FileType) => {
        addDocumentTo(response, file, setPassport);
    };

    const onRemovePassport = (file: FileType) => {
        removeDocumentFrom(file, setPassport);
    };

    const onAddDrivingLicence = (response: FileResponse, file: FileType) => {
        addDocumentTo(response, file, setDrivingLicence);
    };

    const onRemoveDrivingLicence = (file: FileType) => {
        removeDocumentFrom(file, setDrivingLicence);
    };

    const onAddCarOwnership = (response: FileResponse, file: FileType) => {
        addDocumentTo(response, file, setCarOwnership);
    };

    const onRemoveCarOwnership = (file: FileType) => {
        removeDocumentFrom(file, setCarOwnership);
    };

    const onAddInsurance = (response: FileResponse, file: FileType) => {
        addDocumentTo(response, file, setCarInsurance);
    };

    const onRemoveInsurance = (file: FileType) => {
        removeDocumentFrom(file, setCarInsurance);
    };

    const onSubmit = async (checkStatus: boolean) => {
        if (checkStatus) {
            await advertDetailsStore.updateDocuments({
                documents:
                    passport?.length && drivingLicence?.length
                        ? [...passport, ...drivingLicence].map((d) => d.url)
                        : undefined,
                documentsOnCar:
                    carOwnership?.length && carInsurance?.length
                        ? [...carOwnership, ...carInsurance].map((d) => d.url)
                        : [],
            });
            QueryStore.handleError(navigate, advertDetailsStore.errorStatus);

            if (advertDetailsStore.errorStatus) {
                toaster.push(
                    <Message showIcon type="error">
                        {t('global.somethingWentWrong')}
                    </Message>
                );
            } else {
                toaster.push(
                    <Message showIcon type="success">
                        {t('verifyPage.sentToaster')}
                    </Message>
                );
                navigate('/my-adverts');
            }
        }
    };

    const onLaterClick = () => {
        navigate('/my-adverts');
    };

    const withUserForm = !userStore.user?.isVerified;
    const verifyProfileSchema = Schema.Model.combine(
        ...[carVerifyProfileSchema, withUserForm ? userVerifyProfileSchema : undefined].filter(Utils.nonNullable)
    );

    const userDocumentForm = (
        <div className="mt-12">
            <div className="flex items-center mb-8">
                <UserIcon className="w-10 h-10 mr-2" />
                <div className="flex items-center flex-row flex-wrap">
                    <h5 className="mr-2">{t('verifyPage.userVerificationTitle')}</h5>
                    <div>{t('verifyPage.userVerificationTitleHint')}</div>
                </div>
            </div>

            <Form.Group className="flex items-center my-4 flex-wrap">
                <Form.ControlLabel className="flex items-center mb-4 w-96">
                    <LockClosedIcon width={20} className="mr-2" />
                    {t('verifyPage.passport')}
                </Form.ControlLabel>

                <Form.Control
                    className="w-full md:w-128"
                    name="passport"
                    token={token}
                    t={t}
                    accepter={accepter}
                    onSuccess={onAddPassport}
                    onRemove={onRemovePassport}
                />
            </Form.Group>
            <Form.Group className="flex items-center my-4 flex-wrap">
                <Form.ControlLabel className="flex items-center mb-4 w-96">
                    <LockClosedIcon width={20} className="mr-2" />
                    {t('verifyPage.drivingLicence')}
                </Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-128"
                    name="drivingLicence"
                    accepter={accepter}
                    token={token}
                    t={t}
                    onSuccess={onAddDrivingLicence}
                    onRemove={onRemoveDrivingLicence}
                />
            </Form.Group>
        </div>
    );

    const carDocumentForm = (
        <div className="mt-12">
            <h5 className="flex items-center">
                <DocumentTextIcon className="w-10 h-10 mr-2" />
                <span>{t('verifyPage.carOwnership')}</span>
            </h5>
            <Form.Group className="flex items-center my-4 flex-wrap">
                <Form.ControlLabel className="flex items-center w-96 mb-4 pr-4">
                    <div>
                        <LockClosedIcon width={20} height={20} className="mr-2" />
                    </div>
                    <span>{t('verifyPage.ownership')}</span>
                </Form.ControlLabel>

                <Form.Control
                    className="w-full md:w-128"
                    name="carOwnership"
                    token={token}
                    accepter={accepter}
                    t={t}
                    onSuccess={onAddCarOwnership}
                    onRemove={onRemoveCarOwnership}
                />
            </Form.Group>
            <Form.Group className="flex items-center my-4 flex-wrap">
                <Form.ControlLabel className="flex items-center mb-4 w-96">
                    <LockClosedIcon width={20} className="mr-2" />
                    {t('verifyPage.insurance')}
                </Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-128"
                    name="insurance"
                    accepter={accepter}
                    token={token}
                    t={t}
                    onSuccess={onAddInsurance}
                    onRemove={onRemoveInsurance}
                />
            </Form.Group>
        </div>
    );

    return (
        <div>
            <NavBar />
            <Container className="container-height">
                <h3 className="pt-24 mb-12">{t('verifyPage.title')}</h3>

                <Form className="p-2" model={verifyProfileSchema} fluid autoComplete="off" onSubmit={onSubmit}>
                    <div className="text-base mb-4">{t('verifyPage.subtitle')}</div>
                    <Message showIcon type="info" header={t('verifyPage.protectedData')}>
                        <div>{t('verifyPage.decrypt')}</div>
                    </Message>

                    {withUserForm && userDocumentForm}

                    {carDocumentForm}

                    <div className="flex gap-2">
                        <Button onClick={onLaterClick} appearance="ghost">
                            {t('verifyPage.later')}
                        </Button>
                        <Button type="submit" appearance="primary">
                            {t('verifyPage.review')}
                        </Button>
                    </div>
                </Form>
            </Container>
            <div className="mt-24">
                <Footer />
            </div>
        </div>
    );
};

export default observer(VerifyAdvert);
