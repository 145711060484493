import { observer } from 'mobx-react-lite';
import * as React from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

import { GAActions, GACategories } from '../../constants';
import { saveToLocalStorage } from '../../server/localStorage';

const LanguageChange = () => {
    const { i18n } = useTranslation();

    const curFlag = i18n.language === 'en' ? 'gb' : 'ua';

    const switchLanguage = async () => {
        if (i18n.language === 'en') {
            await i18n.changeLanguage('ua');
            saveToLocalStorage('language', 'ua');
        } else {
            await i18n.changeLanguage('en');
            saveToLocalStorage('language', 'en');
        }

        ReactGA.event({
            category: GACategories.Main,
            action: GAActions[GACategories.Main].switchLanguage,
            label: i18n.language,
        });
    };

    return <span onClick={switchLanguage} className={`fi fi-${curFlag} w-12 h-12 cursor-pointer `} />;
};
export default observer(LanguageChange);
