export const enum DisableReasonKeys {
    ANOTHER_CAR = 'ANOTHER_CAR',
    ANOTHER_TIME = 'ANOTHER_TIME',
    CANT_CONNECT = 'CANT_CONNECT',
    OTHER = 'OTHER',
}

export const disableReason = [
    {
        value: DisableReasonKeys.ANOTHER_CAR,
        label: 'declineReason.anotherCar',
    },
    {
        value: DisableReasonKeys.ANOTHER_TIME,
        label: 'declineReason.timeOrLocation',
    },
    {
        value: DisableReasonKeys.CANT_CONNECT,
        label: 'declineReason.connection',
    },
    {
        value: DisableReasonKeys.OTHER,
        label: 'declineReason.other',
    },
];

export const getLabelFromValue = (value: string) => {
    return disableReason.find((reason) => reason.value === value)?.label || '';
};
