import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'rsuite/Form';
import Schema from 'rsuite/Schema';
import SelectPicker from 'rsuite/SelectPicker';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';

import { ConsumptionInput, MileageInput } from '../../../components/Inputs/Inputs';
import { bodyTypeData } from '../../../data/bodyType.data';
import { brandTypes } from '../../../data/brandType.data';
import { cities } from '../../../data/cities.data';
import { yearOfIssue } from '../../../data/common.data';
import { Utils } from '../../../stores/utils';
import { MainInformationStore } from '../store/MainInformationStore';

const { StringType, NumberType } = Schema.Types;

export const mainInformationSchemaBuilder = (t: TFunction) => ({
    brandType: StringType().isRequired(t('formErrors.isRequired') || ''),
    carModel: StringType()
        .isRequired(t('formErrors.isRequired') || '')
        .maxLength(120, t('formErrors.maxSymbol', { amount: '120' }) || ''),
    carMode: StringType().maxLength(120, t('formErrors.maxSymbol', { amount: '120' }) || ''),
    bodyType: StringType().isRequired(t('formErrors.isRequired') || ''),
    yearsOfIssue: StringType().isRequired(t('formErrors.isRequired') || ''),
    mixedConsumption: NumberType(t('formErrors.consumptionNumber') || '')
        .isRequired(t('formErrors.isRequired') || '')
        .max(100, t('formErrors.consumptionLimit', { amount: '100' }) || ''),
    mileage: NumberType(t('formErrors.mileageNumber') || '')
        .isRequired(t('formErrors.isRequired') || '')
        .max(1000, t('formErrors.mileageLimit', { amount: '1 000' }) || ''),
    city: StringType().isRequired(t('formErrors.isRequired') || ''),
});

interface Props {
    mainInformationStore: MainInformationStore;
}

const MainInformation: React.FC<Props> = ({ mainInformationStore }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="flex items-center mt-20 mb-8">
                <h4 className="mr-1">{t('editAdvertPage.mainInformation.title')}</h4>
                <Whisper speaker={<Tooltip>{t('editAdvertPage.mainInformation.tooltip')}</Tooltip>}>
                    <HelpOutlineIcon className="text-2xl" color="green" />
                </Whisper>
            </div>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.brandType')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="brandType"
                    value={mainInformationStore.brandType}
                    onChange={mainInformationStore.setBrandType as any}
                    accepter={SelectPicker}
                    placeholder={t('addAdvert.select')}
                    data={brandTypes}
                    block
                    size="lg"
                />
                <Form.HelpText>{t('global.required')}</Form.HelpText>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.model')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    placeholder={t('addAdvert.enter')}
                    value={mainInformationStore.carModel}
                    onChange={mainInformationStore.setCarModel}
                    name="carModel"
                    size="lg"
                    style={{ width: '100%' }}
                />
                <Form.HelpText>{t('global.required')}</Form.HelpText>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.modification')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    placeholder={t('addAdvert.enter')}
                    name="carMode"
                    value={mainInformationStore.modification}
                    onChange={mainInformationStore.setModification}
                    size="lg"
                    style={{ width: '100%' }}
                />
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.bodyType')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="bodyType"
                    accepter={SelectPicker}
                    placeholder={t('addAdvert.select')}
                    value={mainInformationStore.bodyType}
                    onChange={mainInformationStore.setBodyType as any}
                    data={Utils.makeDataTranslated(bodyTypeData, t)}
                    size="lg"
                    block
                />
                <Form.HelpText>{t('global.required')}</Form.HelpText>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.yearOfIssue')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="yearsOfIssue"
                    accepter={SelectPicker}
                    placeholder={t('addAdvert.select')}
                    data={yearOfIssue}
                    value={mainInformationStore.yearsOfIssue}
                    onChange={mainInformationStore.setYearsOfIssue as any}
                    size="lg"
                    block
                />
                <Form.HelpText>{t('global.required')}</Form.HelpText>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.consumption')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="mixedConsumption"
                    value={mainInformationStore.consumption}
                    onChange={mainInformationStore.setConsumption}
                    accepter={ConsumptionInput}
                />
                <Form.HelpText>{t('global.required')}</Form.HelpText>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.mileage')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="mileage"
                    value={mainInformationStore.mileage}
                    onChange={mainInformationStore.setMileage}
                    accepter={MileageInput}
                />
                <Form.HelpText>{t('global.required')}</Form.HelpText>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.city')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="cities"
                    accepter={SelectPicker}
                    placeholder={t('addAdvert.select')}
                    value={mainInformationStore.city}
                    onChange={mainInformationStore.setCity as any}
                    data={Utils.makeDataTranslated(cities, t)}
                    size="lg"
                    block
                />
                <Form.HelpText>{t('global.required')}</Form.HelpText>
            </Form.Group>
        </div>
    );
};

export default observer(MainInformation);
