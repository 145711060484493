import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Form from 'rsuite/Form';
import Schema from 'rsuite/Schema';
import SelectPicker from 'rsuite/SelectPicker';

import FormRef, { FormRefType } from '../../../components/Steps/FormRef';
import { bodyTypeData } from '../../../data/bodyType.data';
import { brandTypes } from '../../../data/brandType.data';
import { cities } from '../../../data/cities.data';
import { yearOfIssue } from '../../../data/common.data';
import { MainInformationStore } from '../../../stores/add-advert-store/MainInformation.store';
import { Utils } from '../../../stores/utils';

const { StringType } = Schema.Types;

interface Props {
    mainInformationStore: MainInformationStore;
}

const MainInformation = React.forwardRef<FormRefType, Props>(({ mainInformationStore }, ref) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const mainInformationSchema = useMemo(
        () =>
            Schema.Model({
                brandType: StringType().isRequired(t('formErrors.isRequired') || ''),
                carModel: StringType()
                    .isRequired(t('formErrors.isRequired') || '')
                    .maxLength(120, t('formErrors.maxSymbol', { amount: '120' }) || ''),
                modification: StringType().maxLength(120, t('formErrors.maxSymbol', { amount: '120' }) || ''),
                bodyType: StringType().isRequired(t('formErrors.isRequired') || ''),
                yearsOfIssue: StringType().isRequired(t('formErrors.isRequired') || ''),
                city: StringType().isRequired(t('formErrors.isRequired') || ''),
            }),
        [t]
    );

    return (
        <FormRef
            layout={isMobile ? 'vertical' : 'horizontal'}
            fluid
            formValue={mainInformationStore.formValue}
            model={mainInformationSchema}
            ref={ref}
        >
            <h4 className="mb-12">{t('addAdvert.mainInfo.mainInfo')}</h4>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.brandType')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="brandType"
                    value={mainInformationStore.brandType}
                    onChange={mainInformationStore.setBrandType as any}
                    accepter={SelectPicker}
                    placement="autoVerticalStart"
                    placeholder={t('addAdvert.select')}
                    data={brandTypes}
                    block
                    size="lg"
                />
                <Form.HelpText>{t('global.required')}</Form.HelpText>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.model')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    placeholder={t('addAdvert.enter')}
                    value={mainInformationStore.carModel}
                    onChange={mainInformationStore.setCarModel}
                    name="carModel"
                    size="lg"
                    style={{ width: '100%' }}
                />
                <Form.HelpText>{t('global.required')}</Form.HelpText>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.modification')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    placeholder={t('addAdvert.enter')}
                    name="modification"
                    value={mainInformationStore.modification}
                    onChange={mainInformationStore.setModification}
                    size="lg"
                    style={{ width: '100%' }}
                />
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.bodyType')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="bodyType"
                    accepter={SelectPicker}
                    placeholder={t('addAdvert.select')}
                    placement="autoVerticalStart"
                    value={mainInformationStore.bodyType}
                    onChange={mainInformationStore.setBodyType as any}
                    data={Utils.makeDataTranslated(bodyTypeData, t)}
                    size="lg"
                    block
                />
                <Form.HelpText>{t('global.required')}</Form.HelpText>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.yearOfIssue')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="yearsOfIssue"
                    accepter={SelectPicker}
                    placeholder={t('addAdvert.select')}
                    data={yearOfIssue}
                    placement="autoVerticalStart"
                    value={mainInformationStore.yearsOfIssue}
                    onChange={mainInformationStore.setYearsOfIssue as any}
                    size="lg"
                    block
                />
                <Form.HelpText>{t('global.required')}</Form.HelpText>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-base text-left">{t('params.city')}</Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="city"
                    accepter={SelectPicker}
                    placeholder={t('addAdvert.select')}
                    placement="autoVerticalStart"
                    value={mainInformationStore.city}
                    onChange={mainInformationStore.setCity as any}
                    data={Utils.makeDataTranslated(cities, t)}
                    size="lg"
                    block
                />
                <Form.HelpText>{t('global.required')}</Form.HelpText>
            </Form.Group>
        </FormRef>
    );
});

export default observer(MainInformation);
