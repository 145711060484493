import { makeAutoObservable } from 'mobx';

export class DescriptionStore {
    constructor() {
        makeAutoObservable(this);
    }

    description = '';

    setDescription = (description: string) => {
        this.description = description;
    };

    get descriptionLength() {
        return this.description.length;
    }

    get formValue() {
        return {
            description: this.description,
        };
    }
}
