import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Button from 'rsuite/Button';
import Checkbox from 'rsuite/Checkbox';
import Form from 'rsuite/Form';
import InputPicker from 'rsuite/InputPicker';
import Schema from 'rsuite/Schema';

import { DepositInput } from '../../../components/Inputs/Inputs';
import FormRef, { FormRefType } from '../../../components/Steps/FormRef';
import { citizenships, minAgeList, minExperienceList } from '../../../data/common.data';
import { RequirementsForClientStore } from '../../../stores/add-advert-store/RequirementsForClient.store';
import { Utils } from '../../../stores/utils';

const { StringType, NumberType, BooleanType } = Schema.Types;

const requirementInformationSchemaBuilder = (t: TFunction) =>
    Schema.Model({
        minAge: StringType(),
        minExperience: StringType(),
        deposit: NumberType(t('formErrors.mustBeNumber') || '').max(
            99999,
            t('formErrors.depositAtMost', { amount: '99 999' }) || ''
        ),
        citizenship: StringType(),
        termAndConditional: BooleanType()
            .isRequired(t('formErrors.isRequired') || '')
            .addRule((value: boolean) => value, t('formErrors.mustBeChecked') || ''),
    });

interface Props {
    requirementsForClientStore: RequirementsForClientStore;
}

const RequirementsForClient = React.forwardRef<FormRefType, Props>(({ requirementsForClientStore }, ref) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const requirementInformationSchema = useMemo(() => requirementInformationSchemaBuilder(t), [t]);

    const navigateToTermsAndConditions = () => {
        window.open('/terms-and-conditions', '_blank');
    };

    return (
        <FormRef
            layout={isMobile ? 'vertical' : 'horizontal'}
            fluid
            model={requirementInformationSchema}
            ref={ref}
            className="w-full"
            formValue={requirementsForClientStore.formValue}
        >
            <Form.Group>
                <Form.ControlLabel className="text-gray-800 text-left text-base">
                    {t('params.minAge')}
                </Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="minAge"
                    size="lg"
                    block
                    value={requirementsForClientStore.minAge}
                    onChange={requirementsForClientStore.setMinAge}
                    accepter={InputPicker}
                    placeholder={t('addAdvert.select')}
                    data={minAgeList}
                />
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-gray-800 text-left text-base">
                    {t('params.minExp')}
                </Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="minExperience"
                    size="lg"
                    block
                    value={requirementsForClientStore.minExperience}
                    onChange={requirementsForClientStore.setMinExperience}
                    accepter={InputPicker}
                    placeholder={t('addAdvert.select')}
                    data={minExperienceList}
                />
            </Form.Group>

            <Form.Group>
                <Form.ControlLabel className="text-gray-800 text-left text-base">
                    {t('params.deposit')}
                </Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    size="lg"
                    name="deposit"
                    value={requirementsForClientStore.deposit}
                    onChange={requirementsForClientStore.setDeposit}
                    accepter={DepositInput}
                />
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-gray-800 text-left text-base">
                    {t('params.citizenship')}
                </Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="citizenship"
                    size="lg"
                    block
                    searchable={false}
                    value={requirementsForClientStore.citizenship}
                    onChange={requirementsForClientStore.setCitizenship}
                    accepter={InputPicker}
                    placeholder={t('addAdvert.select')}
                    data={Utils.makeDataTranslated(citizenships, t)}
                />
            </Form.Group>
            <Form.Group className="mt-8 mb-0">
                <Form.Control
                    value={!requirementsForClientStore.termAndConditional}
                    name="termAndConditional"
                    accepter={Checkbox}
                    onChange={requirementsForClientStore.toggleTermAndConditional}
                >
                    <div className="flex items-center justify-center">
                        <p className="text-base">{t('global.agree')}</p>
                        <Button
                            className="text-base py-0 px-1"
                            appearance="link"
                            onClick={navigateToTermsAndConditions}
                        >
                            {t('global.termsAndConditions')}
                        </Button>
                    </div>
                </Form.Control>
            </Form.Group>
            <p className="mt-4">{t('addAdvert.confidentialInformation')}</p>
        </FormRef>
    );
});

export default observer(RequirementsForClient);
