import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'rsuite';
import IconButton from 'rsuite/IconButton';

import { Container } from '../../components/Container';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { useFormatDate } from '../../hooks/useFormatDate';
import { useStore } from '../../hooks/useStore';
import { QueryStore } from '../../stores/Query.store';
import { Utils } from '../../stores/utils';
import AdditionalInfo from './components/AdditionInfo';
import AdvertSettings from './components/AdvertSettings';
import CreatorDescription from './components/CreatorDescription';
import ImageGallery from './components/ImageGallery';
import MainInfo from './components/MainInfo';
import RequirementForClient from './components/RequirementForClient';
import SendRequestPanel from './components/SendRequestPanel';

function AdvertDetails() {
    const params = useParams();
    const slug = params.slug;
    const { advertDetailsStore, myAdverts, rentRequestStore } = useStore();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { formatDateWithWeek } = useFormatDate();

    useEffect(() => {
        advertDetailsStore.resetAdvertDetails();

        const uploadAdvertDetails = async () => {
            if (slug) {
                await advertDetailsStore.fetchAdvertDetail(slug);
                QueryStore.handleError(navigate, advertDetailsStore.errorStatus);
            } else {
                navigate(`/filter`);
            }
        };

        uploadAdvertDetails().catch(console.error);
    }, [advertDetailsStore, navigate, slug]);

    const goToSendRentRequest = () => {
        navigate(`/send-rent-request/${advertDetailsStore.advertDetails?.slug || ''}`);
        rentRequestStore.reset();
    };

    const { car, creator, createdAt, isEditable } = advertDetailsStore.advertDetails || {};

    const goBack = () => {
        if (history.state.idx) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    return (
        <div>
            <NavBar />
            <Container className="container-height mb-8">
                <div className="flex mt-12">
                    <IconButton onClick={goBack} className="mr-4" size="md" circle icon={<PagePreviousIcon />} />
                    <h3 className="text-2xl md:text-3xl">{Utils.generateCarName(car)}</h3>
                </div>

                {advertDetailsStore.isLoading && (
                    <div className="flex items-center justify-center h-96">
                        <Loader size="md" content={t('global.loading')} />
                    </div>
                )}

                {car && creator && (
                    <div className="flex mt-8 flex-wrap">
                        <ImageGallery photos={car.photos} />

                        <div className="lg:w-3/5 w-full">
                            <MainInfo advert={advertDetailsStore.advertDetails} />

                            {/*FOR MOBILE*/}
                            <div className="mt-12 block lg:hidden">
                                {isEditable ? (
                                    <AdvertSettings
                                        myAdvertsStore={myAdverts}
                                        advertDetailsStore={advertDetailsStore}
                                    />
                                ) : (
                                    <SendRequestPanel goToSendRentRequest={goToSendRentRequest} />
                                )}
                                <CreatorDescription userInfo={creator} isEditable={Boolean(isEditable)} />
                            </div>

                            <div className="mt-12">
                                <h6 className="text-xl mb-4">{t('advertDetailsPAge.description')}</h6>
                                <p className="text-base">{car.description}</p>
                            </div>
                            <div className="mt-12">
                                <AdditionalInfo carInfo={car} />
                            </div>
                            <div className="mt-12">
                                <RequirementForClient carInfo={car} />
                            </div>

                            <div className="my-12">
                                {createdAt && (
                                    <p className="text-base">
                                        {t('advertDetailsPAge.created')}: {formatDateWithWeek(new Date(createdAt))}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="lg:w-2/5 w-full">
                            {/*HIDE TO MoBILE*/}
                            <div className="hidden lg:flex w-full flex-col items-end">
                                {isEditable ? (
                                    <AdvertSettings
                                        myAdvertsStore={myAdverts}
                                        advertDetailsStore={advertDetailsStore}
                                    />
                                ) : (
                                    <SendRequestPanel goToSendRentRequest={goToSendRentRequest} />
                                )}
                                <CreatorDescription userInfo={creator} isEditable={Boolean(isEditable)} />
                            </div>
                            {/*<Comments carInfo={carInfo}/>*/}
                        </div>
                    </div>
                )}
            </Container>

            <Footer />
        </div>
    );
}

export default observer(AdvertDetails);
