import { makeAutoObservable } from 'mobx';

import { FullAdvertEntityResponse, UploadDocumentsDto } from '../../generated/api';
import { api } from '../../server/server';
import { QueryStore } from '../Query.store';

export class AdvertDetailsStore {
    constructor() {
        makeAutoObservable(this);
    }

    advertDetails: FullAdvertEntityResponse | null = null;
    isLoading = false;
    isLoadingSettingsPanel = false;
    errorStatus?: number;

    async fetchAdvertDetail(slug: string) {
        this.setIsLoading(true);

        const { response, errorStatus } = await QueryStore.fetch(() => api.adverts.advertControllerFindOneBySlug(slug));
        this.advertDetails = response?.advert || null;
        this.errorStatus = errorStatus;

        this.setIsLoading(false);
    }

    async updateCurrentAdvertDetail() {
        if (this.advertDetails?.slug) {
            this.setIsLoadingSettingsPanel(true);

            const { response, errorStatus } = await QueryStore.fetch(() =>
                api.adverts.advertControllerFindOneBySlug(this.advertDetails?.slug || '')
            );
            this.advertDetails = response?.advert || null;
            this.errorStatus = errorStatus;

            this.setIsLoadingSettingsPanel(false);
        }
    }

    async updateDocuments(documents: UploadDocumentsDto) {
        if (this.advertDetails?.slug) {
            this.setIsLoadingSettingsPanel(true);

            const { response, errorStatus } = await QueryStore.fetch(() =>
                api.adverts.advertControllerUploadDocuments(this.advertDetails?.slug || '', documents)
            );
            this.advertDetails = response?.advert || null;
            this.errorStatus = errorStatus;

            this.setIsLoadingSettingsPanel(false);
        }
    }

    setIsLoading = (isLoading: boolean) => {
        return (this.isLoading = isLoading);
    };

    setIsLoadingSettingsPanel = (isLoadingSettingsPanel: boolean) => {
        return (this.isLoadingSettingsPanel = isLoadingSettingsPanel);
    };

    resetAdvertDetails() {
        this.advertDetails = null;
    }
}
