import { googleLogout } from '@react-oauth/google';
import LogRocket from 'logrocket';
import { makeAutoObservable } from 'mobx';
import ReactGA from 'react-ga4';

import config from '../../config';
import { GAActions, GACategories } from '../../constants';
import { UpdateUserDto, UserEntityResponse } from '../../generated/api';
import {
    getFromLocalStorage,
    getTokenFromLocalStorage,
    removeFromLocalStorage,
    saveToLocalStorage,
} from '../../server/localStorage';
import { api, removeToken, setToken } from '../../server/server';
import { QueryStore } from '../Query.store';
import { RentRequestListStore } from '../rent-request-list-store/RentRequestList.store';

export class UserStore {
    constructor(rentRequestListStore: RentRequestListStore) {
        makeAutoObservable(this);

        // Don't move it out or you break subsequence of request, it may cause an issue with reloading in advert details page
        this.authenticate().catch(console.error);

        this.rentRequestListStore = rentRequestListStore;
    }

    private userEntity: UserEntityResponse | null = null;
    private rentRequestListStore: RentRequestListStore;
    isLoading = true;
    errorStatus?: number;
    errorMessage?: string;

    setUserResponse = (user: UserEntityResponse | null) => {
        if (user && config.logRocketKey) {
            LogRocket.identify(user.id, {
                name: `${user.firstName} ${user.lastName}`,
                phoneNumber: user.phoneNumber,
            });
        }

        if (user && config.googleAnalytics) {
            ReactGA.initialize(config.googleAnalytics, {
                gaOptions: {
                    userId: user.id,
                },
            });
        }

        this.userEntity = user;
    };

    get user(): UserEntityResponse | null {
        return this.userEntity;
    }

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    changeUserAvatar = (url: string) => {
        return this.updateUserInfo(
            {
                avatar: url,
            },
            false
        );
    };

    async login({ phoneNumber, password }: { phoneNumber: string; password: string }) {
        this.setIsLoading(true);

        const { response, errorStatus, errorMessage } = await QueryStore.fetch(() =>
            api.users.usersControllerLogin({
                phoneNumber,
                password,
            })
        );

        if (response) {
            setToken(response.token);
            this.setUserResponse(response.user);
            this.rentRequestListStore.setNumberOfWaitingRentRequestsReceived(response.numberOfWaitingRentRequests);
        }

        this.setError(errorStatus, errorMessage);
        this.setIsLoading(false);

        ReactGA.event({
            category: GACategories.User,
            action: GAActions[GACategories.User].login,
        });
    }

    async googleLogin({ token }: { token: string }): Promise<boolean> {
        this.setIsLoading(true);

        const { response, errorStatus, errorMessage } = await QueryStore.fetch(() =>
            api.users.usersControllerGoogleAuth({ token })
        );

        saveToLocalStorage('googleToken', token || '');

        this.setError(errorStatus, errorMessage);
        this.setIsLoading(false);

        if (response) {
            if (
                !response.needPhoneNumber &&
                response.token &&
                response.user &&
                response.numberOfWaitingRentRequests !== undefined
            ) {
                setToken(response.token);
                this.setUserResponse(response.user);
                this.rentRequestListStore.setNumberOfWaitingRentRequestsReceived(response.numberOfWaitingRentRequests);
            }
            return response.needPhoneNumber;
        } else {
            return false;
        }
    }

    async googlePhoneRegistration({ phoneNumber }: { phoneNumber: string }): Promise<boolean> {
        this.setIsLoading(true);

        const token = getFromLocalStorage('googleToken') || '';

        const { response, errorStatus, errorMessage } = await QueryStore.fetch(() =>
            api.users.usersControllerGoogleAuth({ token, phoneNumber })
        );

        this.setError(errorStatus, errorMessage);
        this.setIsLoading(false);

        if (
            !response?.needPhoneNumber &&
            response?.token &&
            response.user &&
            response.numberOfWaitingRentRequests !== undefined
        ) {
            setToken(response.token);
            this.setUserResponse(response.user);
            this.rentRequestListStore.setNumberOfWaitingRentRequestsReceived(response.numberOfWaitingRentRequests);
            return true;
        } else {
            removeToken();
            return false;
        }
    }

    async register({
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
    }: {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
        password: string;
    }) {
        this.isLoading = true;

        const { response, errorStatus, errorMessage } = await QueryStore.fetch(() =>
            api.users.usersControllerCreate({
                phoneNumber,
                password,
                firstName,
                email,
                lastName,
            })
        );
        if (response) {
            setToken(response.token);
            this.setUserResponse(response.user);
        }
        this.setError(errorStatus, errorMessage);

        this.isLoading = false;

        ReactGA.event({
            category: GACategories.User,
            action: GAActions[GACategories.User].register,
        });
    }

    logout() {
        this.setUserResponse(null);
        removeToken();
        googleLogout();

        ReactGA.event({
            category: GACategories.User,
            action: GAActions[GACategories.User].logout,
        });
    }

    async uploadDocuments({
        passport,
        drivingLicence,
    }: {
        passport: string[];
        drivingLicence: string[];
    }): Promise<void> {
        ReactGA.event({
            category: GACategories.User,
            action: GAActions[GACategories.User].uploadProfileDocuments,
        });

        return this.updateUserInfo({
            documents: [...passport, ...drivingLicence],
        });
    }

    async unsubscribe(email: string): Promise<void> {
        const { errorStatus, errorMessage } = await QueryStore.fetch(() =>
            api.users.usersControllerUnsubscribeUser(email)
        );
        this.errorMessage = errorMessage;
        this.errorStatus = errorStatus;
    }

    async resubscribe(email: string): Promise<void> {
        const { errorStatus, errorMessage } = await QueryStore.fetch(() =>
            api.users.usersControllerResubscribeUser(email)
        );
        this.errorMessage = errorMessage;
        this.errorStatus = errorStatus;
    }

    async authenticate(): Promise<boolean> {
        this.isLoading = true;

        if (this.userEntity) {
            this.setIsLoading(false);
            return true;
        }

        const token = getTokenFromLocalStorage();

        if (!token) {
            this.setIsLoading(false);
            return false;
        }
        setToken(token);
        removeFromLocalStorage('googleToken');

        const { response } = await QueryStore.fetch(api.users.usersControllerCurrentUser);

        if (response) {
            this.setUserResponse(response.user);
            this.rentRequestListStore.setNumberOfWaitingRentRequestsReceived(response.numberOfWaitingRentRequests);
        } else {
            console.warn(`Token: ${token} not valid`);
            removeToken();

            this.setIsLoading(false);
            return false;
        }

        this.setIsLoading(false);
        return true;
    }

    setError(errorStatus?: number, errorMessage?: string) {
        this.errorStatus = errorStatus;
        this.errorMessage = errorMessage;
    }

    async updateUserInfo(data: UpdateUserDto, withLoading = true) {
        if (withLoading) {
            this.setIsLoading(true);
        }

        this.setError(undefined, undefined);

        const { response, errorStatus, errorMessage } = await QueryStore.fetch(() =>
            api.users.usersControllerUpdateCurrentUser(data)
        );

        this.setIsLoading(false);

        if (response) {
            this.setUserResponse(response.user);
        }
        this.setError(errorStatus, errorMessage);

        ReactGA.event({
            category: GACategories.User,
            action: GAActions[GACategories.User].updateUserInfo,
        });
    }
}
