import { makeAutoObservable } from 'mobx';

export class MainInformationStore {
    constructor() {
        makeAutoObservable(this);
    }

    bodyType = '';
    carModel = '';
    yearsOfIssue = '';

    city = '';
    brandType = '';
    modification?: string = '';

    get formValue() {
        return {
            bodyType: this.bodyType,
            carModel: this.carModel,
            yearsOfIssue: this.yearsOfIssue,
            city: this.city,
            brandType: this.brandType,
            modification: this.modification,
        };
    }

    setBrandType = (brandType: string) => {
        this.brandType = brandType;
    };
    setBodyType = (bodyType: string) => {
        this.bodyType = bodyType;
    };
    setCarModel = (carModel: string) => {
        this.carModel = carModel;
    };

    setCity = (city: string) => {
        this.city = city;
    };
    setYearsOfIssue = (yearsOfIssue: string) => {
        this.yearsOfIssue = yearsOfIssue;
    };
    setModification = (modification: string) => {
        this.modification = modification;
    };
}
