import { toJS } from 'mobx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup } from 'rsuite';

interface Props {
    value: number[];
    max: number;
    min: number;
    withCurrency?: boolean;
    onChange(newValue: number[]): void;
}

const InputFromTo: React.FC<Props> = ({ value, max, withCurrency, min, onChange }) => {
    const [from, to] = value ? toJS(value) : [min, max];
    const { t } = useTranslation();

    const handleChangeFrom = (value: string) => {
        onChange([Number(value), to]);
    };

    const handleChangeTo = (value: string) => {
        onChange([from, Number(value)]);
    };

    return (
        <div className="flex justify-between items-center py-4">
            <InputGroup inside>
                <Input value={from} onChange={handleChangeFrom} />
                {withCurrency && <InputGroup.Addon>{t('global.currency')}</InputGroup.Addon>}
            </InputGroup>
            <div className="w-4 h-0.5 bg-gray-400 px-3 mx-3" />
            <InputGroup inside>
                <Input value={to} onChange={handleChangeTo} />
                {withCurrency && <InputGroup.Addon>{t('global.currency')}</InputGroup.Addon>}
            </InputGroup>
        </div>
    );
};

export default InputFromTo;
