import React from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { GAActions, GACategories } from '../../constants';
import { FullAdvertEntityResponse } from '../../generated/api';
import { Utils } from '../../stores/utils';
import { RatingButton } from '../RatingButton/RatingButton';
import { verificationStatus } from '../VerificationStatus/VerificationStatus';
import { Price } from './Price';
import TileParams from './TileParams';

interface Props {
    data: FullAdvertEntityResponse;
    isRecommendation?: boolean;
}

const BoxTile: React.FC<Props> = ({ data, isRecommendation = false }) => {
    const navigate = useNavigate();

    const onAdvertDetailClick = () => {
        if (isRecommendation) {
            ReactGA.event({
                category: GACategories.Recommendation,
                action: GAActions[GACategories.Recommendation].onRecommendationClick,
            });
        } else {
            ReactGA.event({
                category: GACategories.FullFilter,
                action: GAActions[GACategories.FullFilter].onBoxClick,
                label: 'box-tile',
            });
        }

        navigate(`/advert-details/${data.slug}`);
    };

    const { mainPhoto, prices, description } = data.car;

    return (
        <div className="cursor-pointer rounded-md shadow-md" onClick={onAdvertDetailClick}>
            <div className="relative h-64">
                <img className="w-full object-cover relative h-64 rounded-md" src={mainPhoto} alt="" />
                <Price price={prices.costPerWeek} />
                <p className="absolute text-white left-4 bottom-8 text-2xl text-outline">
                    {Utils.generateCarName(data.car)}
                </p>
                <div className="absolute -bottom-4 left-4">
                    <RatingButton />
                </div>
                <div
                    className="absolute top-4 right-4 flex"
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                >
                    {verificationStatus(data, {
                        width: 22,
                        placement: 'bottomEnd',
                        hasNextStepsText: false,
                    })}
                </div>
            </div>
            <div className="mt-6 pb-2">
                <TileParams data={data} />
                <div className="m-3 line-clamp-3 text-ellipsis">{description}</div>
            </div>
        </div>
    );
};

export default BoxTile;
