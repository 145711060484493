import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'rsuite/Input';
import InputGroup from 'rsuite/InputGroup';

interface Props {
    className?: string;
    [key: string]: any;
}

export const EngineCapacity: React.FC<Props> = ({ className, ...props }) => {
    const { t } = useTranslation();

    return (
        <InputGroup className={className}>
            <Input {...props} placeholder="1.6" size="lg" />
            <InputGroup.Addon>{t('tile.litersFull')}</InputGroup.Addon>
        </InputGroup>
    );
};

export const MileageInput: React.FC<Props> = ({ className, ...props }) => {
    const { t } = useTranslation();

    return (
        <InputGroup className={className}>
            <Input {...props} placeholder="20" size="lg" />
            <InputGroup.Addon>{t('tile.thouMiles')}</InputGroup.Addon>
        </InputGroup>
    );
};

export const ConsumptionInput: React.FC<Props> = ({ className, ...props }) => {
    const { t } = useTranslation();

    return (
        <InputGroup className={className}>
            <Input {...props} placeholder="6" size="lg" />
            <InputGroup.Addon>{t('tile.mpg')}</InputGroup.Addon>
        </InputGroup>
    );
};

const PriceInput = ({ className, ...props }: Props, addonText: string, placeholder: string) => (
    <InputGroup className={className}>
        <Input {...props} placeholder={placeholder} size="lg" />
        <InputGroup.Addon>{addonText}</InputGroup.Addon>
    </InputGroup>
);

export const DepositInput: React.FC<Props> = ({ className, ...props }) => {
    const { t } = useTranslation();

    return (
        <InputGroup className={className}>
            <Input {...props} placeholder="1000" size="lg" />
            <InputGroup.Addon>{t('global.currency')}</InputGroup.Addon>
        </InputGroup>
    );
};

export const MonthPriceInput = (props: Props) => {
    const { t } = useTranslation();

    return PriceInput(props, t('price.currencyPerMonth'), '3500');
};
export const WeekPriceInput = (props: Props) => {
    const { t } = useTranslation();

    return PriceInput(props, t('price.currencyPerWeek'), '850');
};
export const DayPriceInput = (props: Props) => {
    const { t } = useTranslation();

    return PriceInput(props, t('price.currencyPerDay'), '50');
};
