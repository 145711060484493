import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputPicker from 'rsuite/esm/InputPicker';
import Tooltip from 'rsuite/esm/Tooltip';
import Whisper from 'rsuite/esm/Whisper';
import Form from 'rsuite/Form';
import Schema from 'rsuite/Schema';

import { Container } from '../../../components/Container';
import { DayPriceInput, EngineCapacity, MonthPriceInput, WeekPriceInput } from '../../../components/Inputs/Inputs';
import { fuels, transmissions } from '../../../data/common.data';
import { Utils } from '../../../stores/utils';
import { AdditionalInfoStore } from '../store/AdditionalInfoStore';

const { StringType, NumberType } = Schema.Types;

export const additionalInformationSchemaBuilder = (t: TFunction) => ({
    transmission: StringType().isRequired(t('formErrors.isRequired') || ''),
    fuel: StringType().isRequired(t('formErrors.isRequired') || ''),
    engineCapacity: NumberType(t('formErrors.mustBeNumber') || '')
        .isRequired(t('formErrors.isRequired') || '')
        .max(10, t('formErrors.engineAtMost', { amount: '10' }) || ''),
    doorNumber: NumberType(t('formErrors.mustBeNumber') || '')
        .isRequired(t('formErrors.isRequired') || '')
        .max(10, t('formErrors.doorsAtMost', { amount: '10' }) || ''),
    weekPrice: NumberType(t('formErrors.mustBeNumber') || '')
        .isRequired(t('formErrors.isRequired') || '')
        .max(99999, t('formErrors.weekPriceAtMost', { amount: '99 999' }) || ''),
    dayPrice: NumberType(t('formErrors.mustBeNumber') || '')
        .isRequired(t('formErrors.isRequired') || '')
        .max(99999, t('formErrors.dayPriceAtMost', { amount: '99 999' }) || ''),
    monthPrice: NumberType(t('formErrors.mustBeNumber') || '').max(
        99999,
        t('formErrors.monthPriceAtMost', { amount: '99 999' }) || ''
    ),
});

interface Props {
    additionalInfoStore: AdditionalInfoStore;
}

const Subtitle = (subtitle: string, tooltip: string) => (
    <div className="flex items-center mb-8">
        <h4 className="mr-1 text-gray-800">{subtitle}</h4>
        <Whisper speaker={<Tooltip>{tooltip}</Tooltip>}>
            <HelpOutlineIcon className="text-2xl" color="green" />
        </Whisper>
    </div>
);

const AdditionalInfo: React.FC<Props> = ({ additionalInfoStore }) => {
    const { t } = useTranslation();

    return (
        <div className="text-white py-24 bg-orange-100">
            <Container>
                <div className="flex justify-between flex-wrap">
                    <div className="w-full md:w-1/2">
                        {Subtitle(
                            t('editAdvertPage.additionalInfo.subtitle'),
                            t('editAdvertPage.additionalInfo.tooltip')
                        )}
                        <Form.Group>
                            <Form.ControlLabel className="text-gray-800 text-left text-base">
                                {t('params.transmission')}
                            </Form.ControlLabel>
                            <Form.Control
                                className="w-full md:w-96"
                                name="transmission"
                                size="lg"
                                block
                                accepter={InputPicker}
                                placeholder={t('addAdvert.select')}
                                value={additionalInfoStore.transmission}
                                onChange={additionalInfoStore.setTransmission}
                                data={Utils.makeDataTranslated(transmissions, t)}
                            />
                            <Form.HelpText>{t('global.required')}</Form.HelpText>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel className="text-gray-800 text-left text-base">
                                {t('params.fuel')}
                            </Form.ControlLabel>
                            <Form.Control
                                className="w-full md:w-96"
                                value={additionalInfoStore.fuel}
                                onChange={additionalInfoStore.setFuel}
                                name="fuel"
                                size="lg"
                                block
                                accepter={InputPicker}
                                placeholder={t('addAdvert.select')}
                                data={Utils.makeDataTranslated(fuels, t)}
                            />
                            <Form.HelpText>{t('global.required')}</Form.HelpText>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel className="text-gray-800 text-left text-base">
                                {t('params.engineCapacity')}
                            </Form.ControlLabel>
                            <Form.Control
                                className="w-full md:w-96"
                                name="engineCapacity"
                                value={additionalInfoStore.engineCapacity}
                                onChange={additionalInfoStore.setEngineCapacity}
                                accepter={EngineCapacity}
                            />
                            <Form.HelpText>{t('global.required')}</Form.HelpText>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel className="text-gray-800 text-left text-base">
                                {t('params.doorsAmount')}
                            </Form.ControlLabel>
                            <Form.Control
                                className="w-full md:w-96"
                                size="lg"
                                placeholder="4"
                                name="doorNumber"
                                style={{ width: '100%' }}
                                value={additionalInfoStore.doorNumber}
                                onChange={additionalInfoStore.setDoorNumber}
                            />
                            <Form.HelpText>{t('global.required')}</Form.HelpText>
                        </Form.Group>
                    </div>
                    <div className="mt-16 xl:mt-0 w-full md:w-1/2">
                        {Subtitle(t('editAdvertPage.additionalInfo.prices'), 'Set prices')}

                        <Form.Group>
                            <Form.ControlLabel className="text-gray-800 text-left text-base">
                                {t('price.pricePerWeek')}
                            </Form.ControlLabel>
                            <Form.Control
                                className="w-full md:w-96"
                                size="lg"
                                name="weekPrice"
                                value={additionalInfoStore.weekPrice}
                                onChange={additionalInfoStore.setWeekPrice}
                                accepter={WeekPriceInput}
                            />
                            <Form.HelpText>{t('global.required')}</Form.HelpText>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel className="text-gray-800 text-left text-base">
                                {t('price.pricePerDay')}
                            </Form.ControlLabel>
                            <Form.Control
                                className="w-full md:w-96"
                                size="lg"
                                name="dayPrice"
                                value={additionalInfoStore.dayPrice}
                                onChange={additionalInfoStore.setDayPrice}
                                accepter={DayPriceInput}
                            />
                            <Form.HelpText>{t('global.required')}</Form.HelpText>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel className="text-gray-800 text-left text-base">
                                {t('price.pricePerMonth')}
                            </Form.ControlLabel>
                            <Form.Control
                                className="w-full md:w-96"
                                size="lg"
                                name="monthPrice"
                                value={additionalInfoStore.monthPrice}
                                onChange={additionalInfoStore.setMonthPrice}
                                accepter={MonthPriceInput}
                            />
                        </Form.Group>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default observer(AdditionalInfo);
