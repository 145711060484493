import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import DateRangePicker from 'rsuite/DateRangePicker';
import Button from 'rsuite/esm/Button';
import Form from 'rsuite/Form';
import Message from 'rsuite/Message';
import Schema from 'rsuite/Schema';

import CalculatedPrice from '../../../components/CalculatedPrice/CalculatedPrice';
import PriceTile from '../../../components/PriceDetails/PriceDetails';
import { RatingButton } from '../../../components/RatingButton/RatingButton';
import { GAActions, GACategories } from '../../../constants';
import { FullAdvertEntityResponseAdvertStatus } from '../../../generated/api';
import { useStore } from '../../../hooks/useStore';
import { Utils } from '../../../stores/utils';

const { ArrayType } = Schema.Types;

const sendRequestSchemaBuilder = (t: TFunction) =>
    Schema.Model({
        dateRange: ArrayType().isRequired(t('formErrors.isRequired') || ''),
    });

interface Props {
    goToSendRentRequest(): void;
}

const SendRequestPanel: React.FC<Props> = ({ goToSendRentRequest }) => {
    const { rentRequestStore, fullFilter } = useStore();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { t } = useTranslation();
    const sendRequestSchema = useMemo(() => sendRequestSchemaBuilder(t), [t]);

    useEffect(() => {
        if (!rentRequestStore.dateRange) {
            rentRequestStore.setDateRange(fullFilter.reservationPeriod.value || undefined);
        }
    }, [rentRequestStore]);

    const onSubmit = (checkStatus: boolean) => {
        if (checkStatus) {
            ReactGA.event({
                category: GACategories.RentRequest,
                action: GAActions[GACategories.RentRequest].goToRentRequestPanel,
            });
            goToSendRentRequest();
        }
    };

    const isStatusActive =
        rentRequestStore.advertDetailsStore.advertDetails?.advertStatus ===
            FullAdvertEntityResponseAdvertStatus.Active ||
        rentRequestStore.advertDetailsStore.advertDetails?.advertStatus ===
            FullAdvertEntityResponseAdvertStatus.InRenting;

    return (
        <div className="w-full md:w-9/12 lg:w-full xl:w-10/12 border border-solid border-gray-200 rounded-2xl p-5 bg-white">
            <Form
                model={sendRequestSchema}
                onSubmit={onSubmit}
                formDefaultValue={rentRequestStore.defaultFormValue}
                formValue={rentRequestStore.defaultFormValue}
            >
                <div className="flex justify-between">
                    <h5>{t('advertDetailsPAge.reservationTitle')}</h5>
                    <RatingButton />
                </div>
                <div>
                    {rentRequestStore.advertDetailsStore.advertDetails && (
                        <PriceTile carInfo={rentRequestStore.advertDetailsStore.advertDetails.car} />
                    )}

                    {isStatusActive ? (
                        <Form.Group className="my-4">
                            <Form.ControlLabel className="text-base">
                                {t('advertDetailsPAge.reservationPeriod')}
                            </Form.ControlLabel>
                            <Form.Control
                                value={rentRequestStore.dateRange}
                                onChange={rentRequestStore.setDateRange as any}
                                accepter={DateRangePicker}
                                showOneCalendar={isMobile}
                                name="dateRange"
                                placement="autoVerticalEnd"
                                shouldDisableDate={(data) =>
                                    Utils.disabledRentRequestDates(
                                        data,
                                        rentRequestStore.advertDetailsStore.advertDetails?.disabledDates
                                    )
                                }
                            />
                        </Form.Group>
                    ) : (
                        <Message showIcon type="info" className="text-base">
                            {t('advertStatusMessage.pausedMessage')}
                        </Message>
                    )}
                </div>
                {rentRequestStore.dateRange && <CalculatedPrice rentRequestStore={rentRequestStore} />}

                {isStatusActive && (
                    <div className="flex">
                        <Button className="text-base px-6 mt-2" type="submit" appearance="primary">
                            {t('advertDetailsPAge.reservationSend')}
                        </Button>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default observer(SendRequestPanel);
