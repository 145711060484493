import React from 'react';

import { ReactComponent as Star } from '../../images/svg/star.svg';

const RatingButtonWrapper: React.FC = (props) => (
    <div className="p-2 text-gray-800 rounded-3xl bg-yellow-300 flex items-center px-3">{props.children}</div>
);

export const RatingButton: React.FC = () => null;
// <RatingButtonWrapper>
//     <Star />
//     <div className="font-semibold mx-1 text-gray-800">4.5</div>
//     <div className="text-s text-gray-800 mr-1">/5</div>
// </RatingButtonWrapper>
