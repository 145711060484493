import toArray from 'lodash/toArray';
import uniqueId from 'lodash/uniqueId';
import { makeAutoObservable } from 'mobx';

import { FileResponse } from '../../generated/api';
import { api } from '../../server/server';
import { QueryStore } from '../../stores/Query.store';

export type FileItem = FileResponse & {
    isLoading: boolean;
    id: string;
};

export class PhotosStore {
    constructor() {
        makeAutoObservable(this);
        this.validateImage();
    }

    photoList: FileItem[] = [];
    mainPhoto: FileItem | null = null;
    isValidationError = false;

    MIN_NUMBER_OF_PHOTOS = 5;

    setMainPhotoName = (newMainPhoto: FileItem | FileResponse | null) => {
        if (!newMainPhoto) {
            this.mainPhoto = null;
        } else if (newMainPhoto.url) {
            this.mainPhoto = { id: uniqueId(), isLoading: false, ...newMainPhoto };
        }
    };

    setStartedFileList = (fileList: FileResponse[]) => {
        this.setFileList(
            fileList.map((file) => ({
                ...file,
                isLoading: false,
                id: uniqueId(),
            }))
        );

        this.validateImage();
    };

    onRemoveFile = (file: FileItem) => {
        this.photoList = this.photoList.filter((item: FileItem) => {
            if (file === item) {
                if (file.url === this.mainPhoto?.url) {
                    this.setMainPhotoName(this.photoList[0] || null);
                }
                return false;
            } else {
                return true;
            }
        });

        this.validateImage();
    };

    validateImage = () => {
        this.isValidationError = this.MIN_NUMBER_OF_PHOTOS > this.photoList.length;
    };

    uploadFiles = async (files: FileList): Promise<void> => {
        const arrayFiles = toArray(files);

        for (let i = 0; i < arrayFiles.length; i++) {
            const curFile = arrayFiles[i];
            const id = uniqueId();
            const newFile = { id, url: '', isLoading: true };
            this.setFileList([...this.photoList, newFile]);

            const { response, errorStatus } = await QueryStore.fetch(() =>
                api.files.filesControllerUploadFile({ file: curFile })
            );

            if (response) {
                this.uploadFileItem(id, response.url);
            }

            if (errorStatus) {
                this.errorFileItem(id);
            }
        }

        if (!this.isMainPhotoValid()) {
            this.defineMainPhoto();
        }

        this.validateImage();
    };

    setFileList = (fileList: FileItem[]) => {
        this.photoList = fileList;
    };

    uploadFileItem = (id: string, url: string) => {
        const foundFile = this.photoList.find((file) => file.id === id);

        if (foundFile) {
            foundFile.url = url;
            foundFile.isLoading = false;
        }
    };

    errorFileItem = (id: string) => {
        const foundFile = this.photoList.find((file) => file.id === id);

        if (foundFile) {
            foundFile.url = '';
            foundFile.isLoading = false;
        }
    };

    defineMainPhoto = () => {
        if (this.photoList.length) this.setMainPhotoName(this.photoList[0]);
        else {
            this.setMainPhotoName(null);
        }
    };

    isMainPhotoValid = () => {
        if (!this.mainPhoto) {
            this.defineMainPhoto();
            return false;
        }

        return Boolean(this.photoList.find((file) => file.id === this.mainPhoto?.id));
    };
}
