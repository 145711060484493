import { CountryCode, getCountries, getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'rsuite/esm/Input';
import SelectPicker from 'rsuite/esm/SelectPicker';

import { getCountryName } from '../../data/countries.data';
import { getFromLocalStorage, saveToLocalStorage } from '../../server/localStorage';
import { Utils } from '../../stores/utils';

const dataCountries = getCountries()
    .map((country) => {
        if (!getCountryName(country)) {
            return null;
        }
        return {
            label: `${getCountryName(country)} (+${getCountryCallingCode(country)})`,
            value: country,
        };
    })
    .filter(Utils.nonNullable);

export const PhoneInput = ({ onChange, name, ...props }: any) => {
    const [nationalPhone, setNationalPhone] = useState('');
    const [country, setCountry] = useState<CountryCode | null>('UA');
    const { t } = useTranslation();

    useEffect(() => {
        if (!nationalPhone) {
            return;
        }

        try {
            const phoneNumber = parsePhoneNumber(nationalPhone, country || undefined);

            if (phoneNumber.country && phoneNumber.country !== country) {
                setCountry(phoneNumber.country);
            }

            if (phoneNumber.formatNational() !== nationalPhone) {
                setNationalPhone(phoneNumber.formatNational());
            }

            if (phoneNumber.isValid()) {
                onChange(phoneNumber.number);
            } else {
                onChange('');
            }
        } catch (e) {
            onChange('');
        }
    }, [country, nationalPhone]);

    useEffect(() => {
        if (country) {
            saveToLocalStorage('countryCode', country);
        } else {
            const prevCountry = getFromLocalStorage('countryCode');
            setCountry(prevCountry as CountryCode);
        }
    }, [country]);

    return (
        <div {...props} className="flex gap-1 w-full">
            <SelectPicker
                value={country}
                data={dataCountries}
                onChange={(newValue) => {
                    setCountry(newValue);
                    setNationalPhone('');
                }}
                cleanable={false}
                placeholder="Country"
                renderMenuItem={(value, item) => {
                    const icon = <span className={`fi fi-${String(item.value)?.toLowerCase()} mr-4`} />;
                    return (
                        <>
                            {icon}
                            {value}
                        </>
                    );
                }}
                renderValue={(value) => {
                    return <span className={`fi fi-${value.toLowerCase()} px-4`} />;
                }}
            />
            <Input
                className="w-full"
                name={name}
                value={nationalPhone}
                onChange={setNationalPhone}
                placeholder={t('userProfile.phoneNumber') || ''}
            />
        </div>
    );
};
