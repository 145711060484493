import React from 'react';
import ReactGA from 'react-ga4';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { GAActions, GACategories } from '../../constants';
import { FullAdvertEntityResponse } from '../../generated/api';
import { Utils } from '../../stores/utils';
import { RatingButton } from '../RatingButton/RatingButton';
import { verificationStatus } from '../VerificationStatus/VerificationStatus';
import { Price } from './Price';
import TileParams from './TileParams';

interface Props {
    data: FullAdvertEntityResponse;
}

const TallTile: React.FC<Props> = ({ data }) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const onAdvertDetailClick = () => {
        ReactGA.event({
            category: GACategories.FullFilter,
            action: GAActions[GACategories.FullFilter].onBoxClick,
            label: 'tall-tile',
        });

        navigate(`/advert-details/${data.slug}`);
    };

    const { mainPhoto, prices, description } = data.car;

    return (
        <div className="cursor-pointer rounded-md shadow-md flex mb-4 ml-4 h-56" onClick={onAdvertDetailClick}>
            <div className="relative h-full w-96">
                <img className="w-96 object-cover relative h-56 rounded-md" src={mainPhoto} alt="" />
                <Price price={prices.costPerWeek} />
                <p className="absolute text-white left-4 bottom-8 text-2xl text-outline">
                    {Utils.generateCarName(data.car)}
                </p>
                <div className="absolute top-4 right-4">
                    <RatingButton />
                </div>
                <div
                    className="absolute top-4 right-4 flex"
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                >
                    {verificationStatus(data, {
                        width: 22,
                        placement: isMobile ? 'bottomEnd' : 'bottom',
                        hasNextStepsText: false,
                    })}
                </div>
            </div>
            <div className="mt-6">
                <TileParams data={data} />
                <div className="m-3 pb-3">{description}</div>
            </div>
        </div>
    );
};

export default TallTile;
