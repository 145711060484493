import { TFunction } from 'i18next';
import { makeAutoObservable } from 'mobx';
import ReactGA from 'react-ga4';

import { GAActions, GACategories } from '../../constants';
import { AutocompleteData } from '../../types/types';
import { CheckBoxItemStore } from './CheckBoxItem.store';

export class CheckBoxStore {
    constructor(values: AutocompleteData[]) {
        makeAutoObservable(this);
        this.values = this.convertDataToCheckboxData(values);
    }

    values: CheckBoxItemStore[] = [];

    convertDataToCheckboxData = (fromData: AutocompleteData[]): CheckBoxItemStore[] => {
        return fromData.map((info) => new CheckBoxItemStore(info.label, info.value, false));
    };

    getChipOfSelectedCheckboxes = () => {
        return this.values
            .filter((store) => store.isChecked)
            .map((store) => ({
                label: store.toString(),
                value: store.toValue(),
            }));
    };

    getTranslatedValue = (t: TFunction) => {
        return this.values.map((item) => ({
            ...item,
            label: t(item.label),
        }));
    };

    getValuesOfSelectedCheckboxes = () => {
        return this.values.filter((store) => store.isChecked).map((store) => store.toValue());
    };

    checkDataByValue = (value: string) => {
        const item = this.values.find((info) => info.value === value);

        if (!item) {
            console.error('Wrong item name:', value, 'in checkbox array:', this.values);
            return;
        }
        item.isChecked = true;
    };

    checkManyValues = (values: string[] | string) => {
        // One item in array toString
        if (typeof values === 'string') {
            this.checkDataByValue(values);
        } else {
            values.forEach((value) => {
                this.checkDataByValue(value);
            });
        }
    };

    toggleData = (checkBoxItemStore: CheckBoxItemStore) => {
        const item = this.values.find((info) => info.value === checkBoxItemStore.value);

        if (!item) {
            console.error('Wrong item value:', checkBoxItemStore.value, 'in checkbox array:', this.values);
            return;
        }

        item.isChecked = !item.isChecked;

        if (item.isChecked) {
            ReactGA.event({
                category: GACategories.FullFilter,
                action: GAActions[GACategories.FullFilter].checkFilterBox,
                label: checkBoxItemStore.value,
            });
        }
    };

    deleteAll = () => {
        this.values.forEach((value) => {
            value.onDelete();
        });
    };

    deleteOne = (deletedValue: string) => {
        return this.values.find((store) => store.value === deletedValue)?.onDelete();
    };

    get valueForSearching() {
        const selectedCheckBoxList = this.getValuesOfSelectedCheckboxes();
        return selectedCheckBoxList.length ? selectedCheckBoxList : undefined;
    }
}
