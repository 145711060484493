import LockClosedIcon from '@heroicons/react/24/solid/LockClosedIcon';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'rsuite';
import Button from 'rsuite/Button';
import { FormControlProps } from 'rsuite/esm/FormControl/FormControl';
import { FileType } from 'rsuite/esm/Uploader/Uploader';
import Message from 'rsuite/Message';
import Schema from 'rsuite/Schema';
import toaster from 'rsuite/toaster';
import Uploader from 'rsuite/Uploader';

import config from '../../../config';
import { FileResponse } from '../../../generated/api';
import { useStore } from '../../../hooks/useStore';
import { getTokenFromLocalStorage } from '../../../server/localStorage';

const { ArrayType } = Schema.Types;

interface Props {
    isOpen: boolean;
    onClose(): void;
}

interface FileState {
    url: string;
    fileKey: string;
}

const accepter: FormControlProps['accepter'] = (props) => (
    <Uploader
        {...props}
        draggable
        name="file"
        action={config.backendUrl + 'files/upload'}
        headers={{
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            Authorization: `Bearer ${props.token || ''}`,
        }}
        onError={() => {
            toaster.push(<Message type="error">Upload failed</Message>);
        }}
    >
        <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span>{props.t('global.DAndD')}</span>
        </div>
    </Uploader>
);

const VerifyProfileModal: React.FC<Props> = ({ isOpen, onClose }) => {
    const token = getTokenFromLocalStorage();
    const { userStore } = useStore();
    const { t } = useTranslation();
    const [passport, setPassport] = React.useState<FileState[]>([]);
    const [drivingLicence, setDrivingLicence] = React.useState<FileState[]>([]);

    const verifyProfileSchema = useMemo(
        () =>
            Schema.Model({
                passport: ArrayType().isRequired(t('formErrors.isRequired') || ''),
                drivingLicence: ArrayType().isRequired(t('formErrors.isRequired') || ''),
            }),
        [t]
    );

    const onSubmit = async (checkStatus: boolean) => {
        if (checkStatus) {
            await userStore.uploadDocuments({
                passport: passport.map((file) => file.url),
                drivingLicence: drivingLicence.map((file) => file.url),
            });

            toaster.push(
                <Message showIcon type="success" header="Documents has been uploaded" duration={5000}>
                    {t('userProfilePage.verificationToaster')}
                </Message>
            );
            onClose();
        }
    };

    const onAddPassport = (response: FileResponse, file: FileType) => {
        setPassport([
            ...passport,
            {
                url: response.url,
                fileKey: String(file.fileKey),
            },
        ]);
    };

    const onRemovePassport = (file: FileType) => {
        setPassport(passport.filter((savedFile) => savedFile.fileKey !== file.fileKey));
    };

    const onAddDrivingLicence = (response: FileResponse, file: FileType) => {
        setDrivingLicence([
            ...drivingLicence,
            {
                url: response.url,
                fileKey: String(file.fileKey),
            },
        ]);
    };

    const onRemoveDrivingLicence = (file: FileType) => {
        setDrivingLicence(drivingLicence.filter((savedFile) => savedFile.fileKey !== file.fileKey));
    };

    useEffect(() => {
        setPassport([]);
        setDrivingLicence([]);
    }, [isOpen]);

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Form
                layout={'vertical'}
                className="p-2"
                model={verifyProfileSchema}
                autoComplete="off"
                onSubmit={onSubmit}
                fluid
            >
                <Modal.Header>
                    <h5> {t('userProfilePage.verifyProfileModel.title')}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-base mb-4">{t('userProfilePage.verifyProfileModel.subtitle')}</div>
                    <Message showIcon type="info" header={t('userProfilePage.verifyProfileModel.protectedData')}>
                        <div>{t('userProfilePage.verifyProfileModel.decryption')}</div>
                    </Message>
                    <Form.Group className="flex items-center justify-between my-4 flex-wrap">
                        <Form.ControlLabel className="flex items-center w-full md:w-1/3">
                            <LockClosedIcon width={20} className="mr-2" />
                            {t('userProfilePage.verifyProfileModel.passport')}
                        </Form.ControlLabel>

                        <Form.Control
                            name="passport"
                            token={token}
                            t={t}
                            accepter={accepter}
                            onSuccess={onAddPassport}
                            onRemove={onRemovePassport}
                        />
                    </Form.Group>
                    <Form.Group className="flex items-center justify-between my-4 flex-wrap">
                        <Form.ControlLabel className="flex items-center w-full md:w-1/3">
                            <LockClosedIcon width={20} className="mr-2" />
                            {t('userProfilePage.verifyProfileModel.licence')}
                        </Form.ControlLabel>
                        <Form.Control
                            name="drivingLicence"
                            accepter={accepter}
                            token={token}
                            t={t}
                            onSuccess={onAddDrivingLicence}
                            onRemove={onRemoveDrivingLicence}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" appearance="primary">
                        {t('global.send')}
                    </Button>
                    <Button onClick={onClose} appearance="subtle">
                        {t('global.cancel')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default observer(VerifyProfileModal);
