import AttachmentIcon from '@rsuite/icons/Attachment';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import HistoryIcon from '@rsuite/icons/History';
import PauseRoundIcon from '@rsuite/icons/PauseRound';
import TimeIcon from '@rsuite/icons/Time';
import WarningRoundIcon from '@rsuite/icons/WarningRound';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Popover from 'rsuite/Popover';
import Whisper from 'rsuite/Whisper';

import { FullAdvertEntityResponse, FullAdvertEntityResponseAdvertStatus } from '../../generated/api';

export const MapAdvertStatusValue = {
    [FullAdvertEntityResponseAdvertStatus.Active]: 'advertStatus.active',
    [FullAdvertEntityResponseAdvertStatus.Expired]: 'advertStatus.expired',
    [FullAdvertEntityResponseAdvertStatus.Deleted]: 'advertStatus.deleted',
    [FullAdvertEntityResponseAdvertStatus.Paused]: 'advertStatus.paused',
    [FullAdvertEntityResponseAdvertStatus.Declined]: 'advertStatus.rejected',
    [FullAdvertEntityResponseAdvertStatus.Archived]: 'advertStatus.archived',
    [FullAdvertEntityResponseAdvertStatus.InRenting]: 'advertStatus.inRenting',
};

export const StatusText = (rowData: FullAdvertEntityResponse) => {
    const { t } = useTranslation();

    const { advertStatus, declineReason } = rowData;
    const declinePopover = (
        <Popover className="text-sm w-72" title={t('advertStatus.declinePopover.title')}>
            <p className="text-sm">
                <h6 className="text-sm">{t('global.nextSteps')}</h6> {declineReason}
            </p>

            <div className="text-sm mt-2">
                <h6 className="text-sm">{t('advertStatus.reason')}</h6>
                <p className="mt-0">{t('advertStatus.declinePopover.openAdverts')}</p>
                <p className="mt-0">{t('advertStatus.declinePopover.edit')}</p>
                <p className="mt-0">{t('advertStatus.declinePopover.waitReview')}</p>
            </div>
        </Popover>
    );

    const expiredPopover = (
        <Popover className="text-sm w-72" title={t('advertStatus.expiredPopover.title')}>
            <p className="text-sm">{t('advertStatus.expiredPopover.subtitle')}</p>

            <div className="text-sm mt-2">
                <h6 className="text-sm">{t('advertStatus.reason')}</h6>
                <p className="mt-0">{t('advertStatus.expiredPopover.settings')}</p>
                <p className="mt-0">{t('advertStatus.expiredPopover.prolong')}</p>
            </div>
        </Popover>
    );

    switch (advertStatus) {
        case FullAdvertEntityResponseAdvertStatus.Active:
            return (
                <>
                    <CheckRoundIcon className="text-green-600 text-xl mr-2" /> {t(MapAdvertStatusValue[advertStatus])}
                </>
            );
        case FullAdvertEntityResponseAdvertStatus.InRenting:
            return (
                <>
                    <HistoryIcon className="text-blue-400 text-xl mr-2" /> {t(MapAdvertStatusValue[advertStatus])}
                </>
            );
        case FullAdvertEntityResponseAdvertStatus.Archived:
            return (
                <>
                    <AttachmentIcon className="text-grey-100 text-xl mr-2" /> {t(MapAdvertStatusValue[advertStatus])}
                </>
            );
        case FullAdvertEntityResponseAdvertStatus.Expired:
            return (
                <Whisper placement="bottom" speaker={expiredPopover}>
                    <div className="flex items-center justify-center py-1">
                        <TimeIcon className="text-orange-500 text-xl mr-2" /> {t(MapAdvertStatusValue[advertStatus])}
                    </div>
                </Whisper>
            );
        case FullAdvertEntityResponseAdvertStatus.Declined:
            return (
                <Whisper placement="bottom" speaker={declinePopover}>
                    <div className="flex items-center justify-center py-1">
                        <WarningRoundIcon className="text-red-300 text-xl mr-2" />
                        <p>{t(MapAdvertStatusValue[advertStatus])}</p>
                    </div>
                </Whisper>
            );
        case FullAdvertEntityResponseAdvertStatus.Paused:
            return (
                <>
                    <PauseRoundIcon className="text-grey-300 text-xl mr-2" /> {t(MapAdvertStatusValue[advertStatus])}
                </>
            );
        case FullAdvertEntityResponseAdvertStatus.Deleted:
            return (
                <>
                    <WarningRoundIcon className="text-red-700 text-xl mr-2" /> {t(MapAdvertStatusValue[advertStatus])}
                </>
            );
    }
};
