import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Route, Routes, useNavigate } from 'react-router-dom';

import AddAdvertScreen from './pages/add-advert/AddAdvert.container';
import AdvertDetails from './pages/advert-details/AdvertDetails.container';
import EditAdvert from './pages/edit-advert/EditAdvert.container';
import ErrorHandler from './pages/error-pages/ErrorHandler';
import Forbidden from './pages/error-pages/forbidden/Forbidden';
import NotFound from './pages/error-pages/not-found/NotFound';
import SomethingWentWrong from './pages/error-pages/something-went-wrong/SomethingWentWrong';
import FilterContainer from './pages/full-filter/Filter.container';
import CarOwnerLanding from './pages/landings/carOwnerLanding';
import MainScreen from './pages/main/Main.container';
import MyAdverts from './pages/my-adverts/MyAdverts.container';
import MyProfileContainer from './pages/my-profile/MyProfile.container';
import RentRequestList from './pages/rent-request-list/RentRequestList.container';
import SendRentRequest from './pages/send-rent-request/SendRentRequest.container';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';
import UnsubscribePage from './pages/unsubscribe-page/UnsubscribePage';
import Authorization from './pages/user-account/authorization/Authorization';
import NumberRegistration from './pages/user-account/numberRegistration/NumberRegistration';
import Registration from './pages/user-account/registration/Registration';
import UserDetailsPage from './pages/user-details-page/UserDetails.container';
import VerifyAdvert from './pages/verify-advert/VerifyAdvert';
import { getFromLocalStorage } from './server/localStorage';

const Nav = () => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        let page = location.pathname;

        if (location.pathname.includes('advert-detail')) {
            page = '/advert-details';
        } else if (location.pathname.includes('user-details')) {
            page = '/user-details';
        } else if (location.pathname.includes('send-rent-request')) {
            page = '/send-rent-request';
        } else if (location.pathname.includes('verify-advert')) {
            page = '/verify-advert';
        }

        ReactGA.send({ hitType: 'pageview', page: page, title: page });
    }, [navigate]);

    useEffect(() => {
        const language = getFromLocalStorage('language');

        if (language) {
            setTimeout(() => {
                i18n.changeLanguage(language).catch(console.error);
            }, 0);
        }
    }, [i18n]);

    return (
        <ErrorHandler>
            <Routes>
                <Route path="/" element={<MainScreen />} />
                <Route path="/my-profile" element={<MyProfileContainer />} />
                <Route path="/create-advert" element={<AddAdvertScreen />} />
                <Route path="/car-owner-landing" element={<CarOwnerLanding />} />
                <Route path="/filter" element={<FilterContainer />} />
                <Route path="/unsubscribe/:email" element={<UnsubscribePage />} />
                <Route path="/advert-details/:slug" element={<AdvertDetails />} />
                <Route path="/send-rent-request/:slug" element={<SendRentRequest />} />
                <Route path="/edit-advert" element={<EditAdvert />} />
                <Route path="/verify-advert/:slug" element={<VerifyAdvert />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/login" element={<Authorization />} />
                <Route path="/my-adverts" element={<MyAdverts />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/number-registration" element={<NumberRegistration />} />

                <Route path="/rent-request-list">
                    <Route path=":tab" element={<RentRequestList />} />
                    <Route path="" element={<RentRequestList />} />
                </Route>

                <Route path="/user-details/:id" element={<UserDetailsPage />} />
                <Route path="/forbidden" element={<Forbidden />} />
                <Route path="/something-went-wrong" element={<SomethingWentWrong />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </ErrorHandler>
    );
};

export default Nav;
