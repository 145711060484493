import './index.scss';
import './i18n';

import { GoogleOAuthProvider } from '@react-oauth/google';
import LogRocket from 'logrocket';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';

import config from './config';
import Navigator from './Navigator';
import * as serviceWorker from './serviceWorker';
import store, { StoreContext } from './stores/store';

if (config.logRocketKey) {
    LogRocket.init(config.logRocketKey);
}

if (config.googleAnalytics) {
    ReactGA.initialize(config.googleAnalytics);
}

ReactDOM.render(
    <React.StrictMode>
        <StoreContext.Provider value={store}>
            <BrowserRouter>
                <GoogleOAuthProvider clientId={config.googleClientId}>
                    <Navigator />
                </GoogleOAuthProvider>
            </BrowserRouter>
        </StoreContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
