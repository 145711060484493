import addDays from 'date-fns/addDays';
import React from 'react';
import { before } from 'rsuite/cjs/DateRangePicker/disabledDateUtils';
import DateRangePicker from 'rsuite/DateRangePicker';
import Nav from 'rsuite/Nav';

interface Props {
    title: string;
    value: [Date, Date] | null;
    onChange(newValue: [Date, Date] | null): void;
}

const ReservationPeriodAccordion: React.FC<Props> = ({ title, value, onChange }) => {
    return (
        <Nav.Menu className="bg-white border border-solid border-gray-100 rounded" title={title}>
            <div className="px-12 lg:px-6 xl:px-12 pb-4 mb-4">
                <DateRangePicker
                    block
                    value={value}
                    disabledDate={before(addDays(new Date(), 1))}
                    onChange={onChange}
                />
            </div>
        </Nav.Menu>
    );
};

export default ReservationPeriodAccordion;
