import PlusCircleIcon from '@heroicons/react/24/outline/PlusCircleIcon';
import QueueListIcon from '@heroicons/react/24/outline/QueueListIcon';
import UserIcon from '@heroicons/react/24/outline/UserIcon';
import ArrowLeftOnRectangleIcon from '@heroicons/react/24/solid/ArrowLeftOnRectangleIcon';
import ArrowRightOnRectangleIcon from '@heroicons/react/24/solid/ArrowRightOnRectangleIcon';
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
import SearchIcon from '@rsuite/icons/Search';
import SortDownIcon from '@rsuite/icons/SortDown';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { WithAsProps } from 'rsuite/esm/@types/common';
import Badge from 'rsuite/esm/Badge';
import Button from 'rsuite/esm/Button';
import Dropdown from 'rsuite/esm/Dropdown';
import Modal from 'rsuite/Modal';

import { GAActions, GACategories } from '../constants';
import { useStore } from '../hooks/useStore';
import logo from '../images/Dark-logo.png';
import User from '../images/svg/user.svg';
import { saveToLocalStorage } from '../server/localStorage';
import { Container } from './Container';
import LanguageChange from './LanguageChange/LanguageChange';

const NavBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const { userStore, rentRequestListStore, resetAllStore } = useStore();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const isCreateAdvert = location.pathname.includes('create-advert');
    const curFlag = i18n.language === 'en' ? 'gb' : 'ua';
    const moveToAddAdvert = () => {
        ReactGA.event({
            category: GACategories.Main,
            action: GAActions[GACategories.Main].createAdvertButtonNav,
        });
        navigate('/car-owner-landing');
    };

    const moveToHome = () => {
        navigate('/');
    };

    const switchLanguage = async () => {
        if (i18n.language === 'en') {
            await i18n.changeLanguage('ua');
            saveToLocalStorage('language', 'ua');
        } else {
            await i18n.changeLanguage('en');
            saveToLocalStorage('language', 'en');
        }

        ReactGA.event({
            category: GACategories.Main,
            action: GAActions[GACategories.Main].switchLanguage,
            label: i18n.language,
        });
    };

    const moveToMyProfile = () => {
        navigate('/my-profile');
    };

    const moveToRentRequestList = () => {
        navigate('/rent-request-list');
    };

    const moveToMyAdverts = () => {
        navigate('/my-adverts');
    };

    const logout = () => {
        userStore.logout();
        resetAllStore();
        navigate('/login');
    };

    const login = () => {
        navigate('/login');
    };
    const renderButton = (props: WithAsProps, ref: React.Ref<any>) => {
        const btn = (
            <Button
                {...props}
                ref={ref}
                size="lg"
                appearance="ghost"
                className="text-white border-white hover:text-white hover:border-white hover:bg-gray-700 text-base"
            >
                <img className="mr-2" src={User} alt="User" /> {t('nav.myAccount')}
            </Button>
        );

        return rentRequestListStore.numberOfWaitingRentRequestsReceived ? (
            <Badge maxCount={99} content={rentRequestListStore.numberOfWaitingRentRequestsReceived}>
                {btn}
            </Badge>
        ) : (
            btn
        );
    };

    const myRentRequestsItem = () => (
        <Dropdown.Item onClick={moveToRentRequestList}>{t('nav.rentRequests')}</Dropdown.Item>
    );

    return (
        <div className="bg-gray-800">
            <Container className="flex items-center justify-between py-5">
                <img className="w-36 md:w-40 cursor-pointer" src={logo} alt="" onClick={moveToHome} />

                {/*MOBILE VIEW*/}
                <div className="flex md:hidden">
                    <Button
                        size="lg"
                        appearance="ghost"
                        className="text-white border-white hover:text-white hover:border-white hover:bg-gray-700 text-base flex items-center justify-center"
                        onClick={handleOpen}
                    >
                        <Bars3Icon width={20} />
                    </Button>

                    <Modal size="lg" open={open} onClose={handleClose}>
                        <Modal.Header>
                            <h5>{t('nav.menu')}</h5>
                        </Modal.Header>
                        <Modal.Body className="text-lg">
                            {userStore.user ? (
                                <>
                                    <h6 className="mb-4">{t('nav.adverts')}</h6>
                                    <div className="flex gap-4 flex-col">
                                        <div onClick={moveToAddAdvert} className="text-base flex items-center w-full">
                                            <PlusCircleIcon className="mr-2" width={20} />
                                            {t('nav.createAnAdvert')}
                                        </div>
                                        <div onClick={moveToMyAdverts} className="text-base flex items-center w-full">
                                            <QueueListIcon className="mr-2" width={20} /> {t('nav.myAdverts')}
                                        </div>
                                    </div>
                                    <h6 className="my-4">{t('nav.other')}</h6>
                                    <div className="flex gap-4 flex-col">
                                        <div onClick={moveToMyProfile} className="text-base flex items-center w-full">
                                            <UserIcon className="mr-2" width={20} />
                                            {t('nav.myProfile')}
                                        </div>
                                        <div
                                            className="text-base flex items-center w-full"
                                            onClick={moveToRentRequestList}
                                        >
                                            {rentRequestListStore.numberOfWaitingRentRequestsReceived ? (
                                                <Badge
                                                    className="w-full"
                                                    maxCount={99}
                                                    content={rentRequestListStore.numberOfWaitingRentRequestsReceived}
                                                >
                                                    <SortDownIcon width={20} className="mr-2" /> {t('nav.rentRequests')}
                                                </Badge>
                                            ) : (
                                                <>
                                                    <SortDownIcon width={20} className="mr-2" /> {t('nav.rentRequests')}
                                                </>
                                            )}
                                        </div>
                                        <div className="text-base" onClick={switchLanguage}>
                                            <span className={`fi fi-${curFlag} w-5 h-5 cursor-pointer mr-2`} />
                                            <span>{t('nav.language')}</span>
                                        </div>

                                        <div onClick={logout} className="text-base flex items-center w-full">
                                            <ArrowLeftOnRectangleIcon className="mr-2" width={20} /> {t('nav.signOut')}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="flex gap-4 flex-col">
                                    <div onClick={moveToAddAdvert} className="text-base flex items-center w-full">
                                        <PlusCircleIcon className="mr-2" width={20} />
                                        {t('nav.createAnAdvert')}
                                    </div>
                                    <div onClick={login} className="text-base flex items-center w-full">
                                        <ArrowRightOnRectangleIcon className="mr-2" width={20} /> {t('nav.login')}
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                    </Modal>
                </div>

                {/*DESKTOP VIEW*/}
                <div className="hidden md:flex justify-between w-full md:w-auto gap-4">
                    {!isCreateAdvert && (
                        <Button className="text-base" size="lg" appearance="primary" onClick={moveToAddAdvert}>
                            <SearchIcon className="mr-2" /> {t('nav.createAnAdvert')}
                        </Button>
                    )}
                    {userStore.user ? (
                        <Dropdown className="text-base" renderToggle={renderButton}>
                            <Dropdown.Item onClick={moveToMyProfile}>{t('nav.myProfile')}</Dropdown.Item>
                            <Dropdown.Item onClick={moveToMyAdverts}>{t('nav.myAdverts')}</Dropdown.Item>
                            {rentRequestListStore.numberOfWaitingRentRequestsReceived ? (
                                <Badge
                                    className="w-full"
                                    maxCount={99}
                                    content={rentRequestListStore.numberOfWaitingRentRequestsReceived}
                                >
                                    {myRentRequestsItem()}
                                </Badge>
                            ) : (
                                myRentRequestsItem()
                            )}
                            <Dropdown.Item onClick={logout}>{t('nav.signOut')}</Dropdown.Item>
                        </Dropdown>
                    ) : (
                        <Button
                            onClick={login}
                            loading={userStore.isLoading}
                            size="lg"
                            appearance="ghost"
                            className="text-white border-white hover:text-white hover:border-white hover:bg-gray-700 ml-2"
                        >
                            {t('nav.login')}
                        </Button>
                    )}
                    <LanguageChange />
                </div>
            </Container>
        </div>
    );
};
export default observer(NavBar);
