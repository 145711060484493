import { makeAutoObservable } from 'mobx';

import { CheckBoxData } from '../../types/types';

export class CheckBoxItemStore implements CheckBoxData {
    constructor(label: string, value: string, isChecked: boolean) {
        makeAutoObservable(this);
        this.label = label;
        this.isChecked = isChecked;
        this.value = value;
    }

    label = '';
    value = '';
    isChecked = false;

    toString = () => {
        return this.label;
    };

    toValue = () => {
        return this.value;
    };

    onDelete = () => {
        this.isChecked = false;
    };
}
