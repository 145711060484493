import intlFormat from 'date-fns/intlFormat';
import { makeAutoObservable } from 'mobx';
import ReactGA from 'react-ga4';

import { GAActions, GACategories } from '../../constants';
import { ChipListItem } from '../../pages/full-filter/components/ChipList';

export class ReservationPeriodStore {
    constructor() {
        makeAutoObservable(this);
    }

    from?: Date;
    to?: Date;

    onChange = (newValue: [Date, Date] | null) => {
        if (!newValue) {
            this.onDelete();
            return;
        }

        const [from, to] = newValue;
        this.from = from;
        this.to = to;

        ReactGA.event({
            category: GACategories.FullFilter,
            action: GAActions[GACategories.FullFilter].setReservationPeriod,
            label: newValue.toString(),
        });
    };

    get value(): [Date, Date] | null {
        if (!this.from || !this.to) {
            return null;
        }

        return [this.from, this.to];
    }

    onDelete() {
        this.from = undefined;
        this.to = undefined;
    }

    get valueForSearching() {
        if (!this.from || !this.to) {
            return undefined;
        }

        return {
            from: this.from.toISOString(),
            to: this.to.toISOString(),
        };
    }

    get valueForQuery(): [string, string] | undefined {
        if (!this.from || !this.to) {
            return undefined;
        }

        return [this.from.toISOString(), this.to.toISOString()];
    }

    get chipData(): ChipListItem | undefined {
        const value = this.value;

        if (!value) {
            return undefined;
        }

        const [from, to] = value;

        return {
            label: 'filterPage.reservationPeriod',
            value: 'filterPage.reservationPeriod' + JSON.stringify(value),
            translatedParams: {
                fromDate: String(
                    intlFormat(
                        from,
                        { localeMatcher: 'lookup' },
                        {
                            locale: 'ua',
                        }
                    )
                ),
                toDate: String(
                    intlFormat(
                        to,
                        { localeMatcher: 'lookup' },
                        {
                            locale: 'ua',
                        }
                    )
                ),
            },
        };
    }
}
