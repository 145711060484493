import { makeAutoObservable } from 'mobx';
import ReactGA from 'react-ga4';
import { SortType } from 'rsuite-table/lib/@types/common';

import { GAActions, GACategories } from '../../constants';
import { FullAdvertEntityResponse, FullAdvertEntityResponseAdvertStatus } from '../../generated/api';
import { api } from '../../server/server';
import { QueryStore } from '../Query.store';

type SortColumn = 'carName' | 'amountOfViews' | 'expiredDate' | 'advertStatus';

export class MyAdvertsStore {
    constructor() {
        makeAutoObservable(this);
    }

    myAdvertList: FullAdvertEntityResponse[] = [];

    total = 0;
    isLoading = false;

    sortColumn?: SortColumn = 'advertStatus';
    sortType?: SortType = 'desc';
    showArchived = false;
    errorStatus?: number;

    fetchMyAdverts = async () => {
        this.setIsLoading(true);

        const { response, errorStatus } = await QueryStore.fetch(() => api.adverts.advertControllerFindMy());

        if (response) {
            this.setMyAdvertsList(response.adverts);
            this.setTotal(response.total);
        }

        this.errorStatus = errorStatus;

        this.setIsLoading(false);
    };

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    setMyAdvertsList = (myAdvertList: FullAdvertEntityResponse[]) => {
        this.myAdvertList = myAdvertList;
    };

    setTotal = (total: number) => {
        this.total = total;
    };

    activateAdvert = async (slug: string) => {
        this.setIsLoading(true);

        const { errorStatus } = await QueryStore.fetch(() => api.adverts.advertControllerActivateBySlug(slug));
        this.errorStatus = errorStatus;

        await this.fetchMyAdverts();
        this.setIsLoading(false);

        ReactGA.event({
            category: GACategories.Advert,
            action: GAActions[GACategories.Advert].activateAdvert,
        });
    };

    pauseAdvert = async (slug: string) => {
        this.setIsLoading(true);

        const { errorStatus } = await QueryStore.fetch(() => api.adverts.advertControllerPauseBySlug(slug));
        this.errorStatus = errorStatus;

        await this.fetchMyAdverts();

        this.setIsLoading(false);

        ReactGA.event({
            category: GACategories.Advert,
            action: GAActions[GACategories.Advert].pauseAdvert,
        });
    };

    prolongAdvert = async (slug: string) => {
        this.setIsLoading(true);

        const { errorStatus } = await QueryStore.fetch(() => api.adverts.advertControllerProlongBySlug(slug));
        this.errorStatus = errorStatus;

        await this.fetchMyAdverts();
        this.setIsLoading(false);

        ReactGA.event({
            category: GACategories.Advert,
            action: GAActions[GACategories.Advert].prolongAdvert,
        });
    };

    archiveAdvert = async (slug: string) => {
        this.setIsLoading(true);

        const { errorStatus } = await QueryStore.fetch(() => api.adverts.advertControllerArchivedBySlug(slug));
        this.errorStatus = errorStatus;

        await this.fetchMyAdverts();
        this.setIsLoading(false);

        ReactGA.event({
            category: GACategories.Advert,
            action: GAActions[GACategories.Advert].archiveAdvert,
        });
    };

    removeAdvert = async (slug: string) => {
        this.setIsLoading(true);

        const { errorStatus } = await QueryStore.fetch(() => api.adverts.advertControllerDeleteBySlug(slug));
        this.errorStatus = errorStatus;

        await this.fetchMyAdverts();
        this.setIsLoading(false);

        ReactGA.event({
            category: GACategories.Advert,
            action: GAActions[GACategories.Advert].removeAdvert,
        });
    };

    handleSortColumn = (dataKey: string, sortType?: SortType) => {
        this.sortColumn = dataKey as SortColumn;
        this.sortType = sortType;
    };

    get myAdvertsList() {
        if (!this.showArchived) {
            return this.sortedMyAdverts.filter(
                (advert) => advert.advertStatus !== FullAdvertEntityResponseAdvertStatus.Archived
            );
        }

        return this.sortedMyAdverts;
    }

    get sortedMyAdverts() {
        if (!this.sortColumn || !this.sortColumn) {
            return this.myAdvertList;
        }

        return [...this.myAdvertList].sort((a, b) => {
            let x: string | number;
            let y: string | number;

            switch (this.sortColumn) {
                case 'carName': {
                    x = a.car.brandType;
                    y = b.car.brandType;
                    break;
                }
                case 'advertStatus': {
                    x = a.advertStatus;
                    y = b.advertStatus;

                    if (x === FullAdvertEntityResponseAdvertStatus.Active) {
                        return this.sortType === 'asc' ? 1 : -1;
                    }
                    if (y === FullAdvertEntityResponseAdvertStatus.Active) {
                        return this.sortType === 'asc' ? -1 : 1;
                    }
                    break;
                }
                case 'expiredDate': {
                    x = new Date(a.expiredDate).getTime();
                    y = new Date(b.expiredDate).getTime();
                    break;
                }
                default: {
                    x = a[this.sortColumn!];
                    y = b[this.sortColumn!];
                }
            }

            if (typeof x === 'string') {
                x = x.charCodeAt(0);
            }
            if (typeof y === 'string') {
                y = y.charCodeAt(0);
            }

            if (this.sortType === 'asc') {
                return x - y;
            } else {
                return y - x;
            }
        });
    }

    setShowArchived = (showArchived: boolean) => {
        this.showArchived = showArchived;
    };

    get showHideArchiveButton() {
        return this.myAdvertList.some(
            (advert) => advert.advertStatus === FullAdvertEntityResponseAdvertStatus.Archived
        );
    }

    possibleActionList = (currentStatus: FullAdvertEntityResponseAdvertStatus) => {
        const canActivate =
            currentStatus === FullAdvertEntityResponseAdvertStatus.Archived ||
            currentStatus === FullAdvertEntityResponseAdvertStatus.Paused;
        const canPause = currentStatus === FullAdvertEntityResponseAdvertStatus.Active;
        const canProlong = currentStatus === FullAdvertEntityResponseAdvertStatus.Expired;
        const canArchive =
            currentStatus === FullAdvertEntityResponseAdvertStatus.Expired ||
            currentStatus === FullAdvertEntityResponseAdvertStatus.Active ||
            currentStatus === FullAdvertEntityResponseAdvertStatus.Paused;
        const canRemove = currentStatus === FullAdvertEntityResponseAdvertStatus.Archived;
        const canEdit = currentStatus !== FullAdvertEntityResponseAdvertStatus.Deleted;

        return {
            canPause,
            canActivate,
            canProlong,
            canArchive,
            canRemove,
            canEdit,
        };
    };
}
