import MoreIcon from '@rsuite/icons/More';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/Button';
import Dropdown from 'rsuite/Dropdown';
import IconButton from 'rsuite/IconButton';
import Message from 'rsuite/Message';
import Popover from 'rsuite/Popover';
import Table from 'rsuite/Table';
import toaster from 'rsuite/toaster';
import Whisper from 'rsuite/Whisper';

import { StatusText } from '../../../components/MyAdvertStatus/MyAdvertStatus';
import { verificationStatus } from '../../../components/VerificationStatus/VerificationStatus';
import { FullAdvertEntityResponse, FullAdvertEntityResponseAdvertStatus } from '../../../generated/api';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useStore } from '../../../hooks/useStore';
import { Utils } from '../../../stores/utils';

const { Column, HeaderCell, Cell } = Table;

function MyAdvertsTable() {
    const { myAdverts } = useStore();
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { t } = useTranslation();
    const { formatDateWithWeek } = useFormatDate();

    const onAdvertDetailClick = (slug: string) => {
        navigate(`/advert-details/${slug}`);
    };

    const onAdvertEditClick = (slug: string) => {
        navigate(`/edit-advert?slug=${slug}`);
    };

    const onAdvertVerifyClick = (slug: string) => {
        navigate(`/verify-advert/${slug}`);
    };

    const renderMenu = (
        { onClose, left, top, className }: any,
        ref: React.RefCallback<HTMLElement>,
        slug: string,
        currentStatus: FullAdvertEntityResponseAdvertStatus,
        numberOfActiveRentRequests: number,
        isVerified: boolean
    ) => {
        const handleSelect = (selectFn: () => void) => {
            selectFn();
            onClose();
        };

        const message = (
            <Message
                className="text-base"
                duration={8000}
                showIcon
                closable
                type="error"
                header={t('myAdverts.cantRemoveModalTitle')}
            >
                {t('myAdverts.cantRemoveModalSubtitle', { numberOfActiveRentRequests })}
            </Message>
        );

        const removeAdvert = async (slug: string) => {
            if (numberOfActiveRentRequests) {
                toaster.push(message);
            } else {
                await myAdverts.removeAdvert(slug);
            }
        };

        const archiveAdvert = async (slug: string) => {
            if (numberOfActiveRentRequests) {
                toaster.push(message);
            } else {
                await myAdverts.archiveAdvert(slug);
            }
        };

        const { canRemove, canArchive, canPause, canProlong, canActivate } =
            myAdverts.possibleActionList(currentStatus);

        return (
            <Popover ref={ref} className={className} style={{ left, top }} full>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleSelect(() => onAdvertEditClick(slug))}>
                        {t('advertsActions.edit')}
                    </Dropdown.Item>
                    {!isVerified && (
                        <Dropdown.Item className="w-60" onClick={() => handleSelect(() => onAdvertVerifyClick(slug))}>
                            {t('advertsActions.verify')}
                        </Dropdown.Item>
                    )}
                    {canActivate && (
                        <Dropdown.Item onClick={() => handleSelect(() => myAdverts.activateAdvert(slug))}>
                            {t('advertsActions.activate')}
                        </Dropdown.Item>
                    )}
                    {canPause && (
                        <Dropdown.Item onClick={() => handleSelect(() => myAdverts.pauseAdvert(slug))}>
                            {t('advertsActions.pause')}
                        </Dropdown.Item>
                    )}
                    {canProlong && (
                        <Dropdown.Item onClick={() => handleSelect(() => myAdverts.prolongAdvert(slug))}>
                            {t('advertsActions.prolong')}
                        </Dropdown.Item>
                    )}
                    {canArchive && (
                        <Dropdown.Item onClick={() => handleSelect(() => archiveAdvert(slug))}>
                            {t('advertsActions.archive')}
                        </Dropdown.Item>
                    )}
                    {canRemove && (
                        <Dropdown.Item onClick={() => handleSelect(() => removeAdvert(slug))}>
                            {t('advertsActions.remove')}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Popover>
        );
    };

    return (
        <Table
            sortColumn={myAdverts.sortColumn}
            sortType={myAdverts.sortType}
            onSortColumn={myAdverts.handleSortColumn}
            height={650}
            minHeight={650}
            rowHeight={80}
            headerHeight={50}
            loading={myAdverts.isLoading && !myAdverts.myAdvertsList.length}
            data={myAdverts.myAdvertsList}
        >
            <Column width={40} align="center" verticalAlign="middle">
                <HeaderCell className="text-base">#</HeaderCell>
                <Cell>{(rowData: FullAdvertEntityResponse, rowIndex: number) => rowIndex + 1}</Cell>
            </Column>
            <Column minWidth={70} align="center" flexGrow={2} verticalAlign="middle" sortable>
                <HeaderCell className="text-base">{t('myAdverts.car')}</HeaderCell>
                <Cell dataKey="carName">
                    {(advert: FullAdvertEntityResponse) => (
                        <Button
                            appearance="link"
                            className="text-base"
                            onClick={() => {
                                onAdvertDetailClick(advert.slug);
                            }}
                        >
                            {Utils.generateCarName(advert.car)}
                        </Button>
                    )}
                </Cell>
            </Column>
            <Column align="center" verticalAlign="middle">
                <HeaderCell className="text-base">{t('myAdverts.photo')}</HeaderCell>
                <Cell>
                    {(advert: FullAdvertEntityResponse) => (
                        <img className="w-16 h-16 object-cover" src={advert.car.mainPhoto} alt="" />
                    )}
                </Cell>
            </Column>
            <Column align="center" verticalAlign="middle" sortable>
                <HeaderCell className="text-base">{t('myAdverts.views')}</HeaderCell>
                <Cell dataKey="amountOfViews" />
            </Column>
            <Column minWidth={150} align="center" verticalAlign="middle" flexGrow={1} sortable>
                <HeaderCell className="text-base">{t('myAdverts.expired')}</HeaderCell>
                <Cell dataKey="expiredDate">
                    {(advert: FullAdvertEntityResponse) => {
                        return formatDateWithWeek(new Date(advert.expiredDate));
                    }}
                </Cell>
            </Column>

            <Column minWidth={110} align="center" flexGrow={1} verticalAlign="middle" sortable>
                <HeaderCell className="text-base">{t('myAdverts.status')}</HeaderCell>
                <Cell dataKey="advertStatus">{StatusText}</Cell>
            </Column>

            <Column minWidth={50} align="center" verticalAlign="middle">
                <HeaderCell className="text-base">{t('myAdverts.verification')}</HeaderCell>
                <Cell dataKey="advertStatus">
                    {(advert: FullAdvertEntityResponse) => {
                        return (
                            <div className="flex items-center justify-center">
                                {verificationStatus(advert, {
                                    width: 22,
                                    placement: isMobile ? 'bottomEnd' : 'bottom',
                                    hasNextStepsText: true,
                                })}
                            </div>
                        );
                    }}
                </Cell>
            </Column>

            <Column align="center" width={80} verticalAlign="middle">
                <HeaderCell className="text-base">...</HeaderCell>
                <Cell className="link-group">
                    {(advert: FullAdvertEntityResponse) => (
                        <Whisper
                            placement="autoVerticalStart"
                            trigger="click"
                            speaker={(props, ref) =>
                                renderMenu(
                                    props,
                                    ref,
                                    advert.slug,
                                    advert.advertStatus,
                                    advert.approvedRentRequestNumber,
                                    Boolean(advert.car.isVerified || advert.documentsSent)
                                )
                            }
                        >
                            <IconButton appearance="subtle" icon={<MoreIcon />} />
                        </Whisper>
                    )}
                </Cell>
            </Column>
        </Table>
    );
}

export default observer(MyAdvertsTable);
