import daysToWeeks from 'date-fns/daysToWeeks';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';
import intervalToDuration from 'date-fns/intervalToDuration';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import { makeAutoObservable } from 'mobx';
import ReactGA from 'react-ga4';

import { GAActions, GACategories } from '../../constants';
import { api } from '../../server/server';
import { AdvertDetailsStore } from '../adverts-details-store/AdvertDetails.store';
import { QueryStore } from '../Query.store';

export type PlaceDetailsPayload = { lat(): number; lng(): number; formattedAddress: string };
export type PlaceDetails = { lat: number; lng: number; formattedAddress: string };

export class RentRequestStore {
    constructor(advertDetails: AdvertDetailsStore) {
        makeAutoObservable(this);

        this.advertDetailsStore = advertDetails;
    }

    advertDetailsStore: AdvertDetailsStore;
    dateRange: [Date, Date] | undefined = undefined;
    message = '';
    placeDetails?: PlaceDetails;
    isPhoneNumberShared = false;
    termAndConditional = false;
    time?: Date;
    address = '';

    isLoading = false;
    errorStatus?: number;

    setDateRange = (dateRange: [Date, Date] | undefined) => {
        this.dateRange = dateRange;

        if (dateRange) {
            ReactGA.event({
                category: GACategories.RentRequest,
                action: GAActions[GACategories.RentRequest].setDateRange,
                label: `${dateRange[0].toISOString()} - ${dateRange[1].toISOString()}`,
            });
        } else {
            ReactGA.event({
                category: GACategories.RentRequest,
                action: GAActions[GACategories.RentRequest].resetDateRange,
            });
        }
    };

    get defaultFormValue() {
        return {
            dateRange: this.dateRange || undefined,
            message: this.message || '',
            isPhoneNumberShared: this.isPhoneNumberShared || false,
            termAndConditional: this.termAndConditional || false,
            city: this.advertDetailsStore.advertDetails?.car.city || '',
            address: this.address,
            time: this.time,
        };
    }

    setTime = (time: Date) => {
        this.time = time;

        ReactGA.event({
            category: GACategories.RentRequest,
            action: GAActions[GACategories.RentRequest].setTime,
            label: time.toISOString(),
        });
    };

    setMessage = (message: string) => {
        this.message = message;

        ReactGA.event({
            category: GACategories.RentRequest,
            action: GAActions[GACategories.RentRequest].setMessage,
        });
    };

    async createRentRequest() {
        this.setIsLoading(true);

        if (
            !this.dateRange ||
            !this.placeDetails ||
            !this.placeDetails ||
            !this.time ||
            !this.advertDetailsStore.advertDetails
        ) {
            return;
        }

        const [from, to] = this.dateRange;

        const payload = {
            from: setMinutes(setHours(from, this.time.getHours()), this.time.getMinutes()).toISOString(),
            to: endOfDay(to).toISOString(),
            advertSlug: this.advertDetailsStore.advertDetails.slug,
            description: this.message,
            address: this.placeDetails.formattedAddress,
            totalPrice: this.price,
            pickUpTime: format(this.time, 'hh:mm'),
            lat: this.placeDetails.lat,
            lng: this.placeDetails.lng,
        };

        const { errorStatus } = await QueryStore.fetch(() => api.rentRequests.rentRequestsControllerCreate(payload));

        this.errorStatus = errorStatus;

        this.setIsLoading(false);

        ReactGA.event({
            category: GACategories.RentRequest,
            action: GAActions[GACategories.RentRequest].createRentRequest,
        });

        this.reset();
    }

    reset = () => {
        this.message = '';
        this.isPhoneNumberShared = false;
        this.termAndConditional = false;
        this.placeDetails = undefined;
        this.time = undefined;
        this.address = '';
    };

    setPlaceDetails = (placeDetails?: PlaceDetailsPayload) => {
        if (!placeDetails) {
            this.placeDetails = undefined;
            return;
        }

        this.placeDetails = {
            lat: placeDetails.lat(),
            lng: placeDetails.lng(),
            formattedAddress: placeDetails?.formattedAddress,
        };

        ReactGA.event({
            category: GACategories.RentRequest,
            action: GAActions[GACategories.RentRequest].setLocation,
            label: placeDetails?.formattedAddress,
        });
    };

    onAddressChange = (address: string) => {
        this.address = address;
    };

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    setIsPhoneNumberShared = (isPhoneNumberShared: boolean) => {
        this.isPhoneNumberShared = isPhoneNumberShared;
    };

    setTermAndConditional = (termAndConditional: boolean) => {
        this.termAndConditional = termAndConditional;
    };

    get carPrices() {
        return this.advertDetailsStore.advertDetails?.car.prices;
    }

    get price() {
        if (!this.carPrices) {
            return 0;
        }

        let totalPrice = 0;
        if (this.carPrices.costPerMonth) {
            totalPrice += this.differenceInMonths * this.carPrices.costPerMonth;
            totalPrice += this.differenceInWeeks * this.carPrices.costPerWeek;
        } else {
            totalPrice += this.fullDifferenceInWeek * this.carPrices.costPerWeek;
        }

        totalPrice += this.differenceInDays * this.carPrices.costPerDay;

        return totalPrice;
    }

    get formatPrices(): string[] {
        if (!this.carPrices) {
            return [];
        }

        const strArray = [];

        if (this.carPrices.costPerMonth && this.differenceInMonths) {
            strArray.push(`${this.differenceInMonths} months x ${this.carPrices.costPerMonth} ₴`);

            if (this.differenceInWeeks) {
                strArray.push(`${this.differenceInWeeks} weeks x ${this.carPrices.costPerWeek} ₴`);
            }
        } else {
            if (this.fullDifferenceInWeek) {
                strArray.push(`${this.fullDifferenceInWeek} weeks x ${this.carPrices.costPerWeek} ₴`);
            }
        }

        if (this.carPrices.costPerDay && this.differenceInDays) {
            strArray.push(`${this.differenceInDays} days x ${this.carPrices.costPerDay} ₴`);
        }

        return strArray;
    }

    get interval() {
        if (!this.dateRange) {
            return null;
        }

        const [start, end] = this.dateRange;

        return intervalToDuration({ start, end });
    }

    get differenceInDays() {
        if (this.interval?.days) {
            return this.interval.days % 7;
        }

        return 0;
    }

    get differenceInMonths() {
        return this.interval?.months || 0;
    }

    get differenceInWeeks() {
        if (this.interval?.days) {
            return daysToWeeks(this.interval.days);
        }

        return 0;
    }

    get fullDifferenceInWeek() {
        if (!this.dateRange) {
            return 0;
        }

        const [start, end] = this.dateRange;

        return differenceInWeeks(end, start);
    }
}
