import { action, computed, makeObservable, observable } from 'mobx';
import ReactGA from 'react-ga4';

import { GAActions, GACategories } from '../../constants';
import { RentBody, RentBodyRentType } from '../../generated/api';
import { ChipListItem } from '../../pages/full-filter/components/ChipList';
import { FromToStore } from './FromTo.store';

export class RentPriceStore extends FromToStore {
    constructor({ min, max }: { min: number; max: number }) {
        super({ min, max });
        makeObservable(this, {
            onDelete: false,
            chipData: false,
            valueForSearching: false,
            rentTypeToQuery: computed,
            rentType: observable,
            setRentPeriodType: action.bound,
        });

        this.translationTitle = 'filterPage.price';
    }

    rentType: RentBodyRentType = RentBodyRentType.Week;

    setRentPeriodType = (rentType: RentBodyRentType) => {
        this.rentType = rentType;

        ReactGA.event({
            category: GACategories.FullFilter,
            action: GAActions[GACategories.FullFilter].setRentPeriodType,
            label: rentType,
        });
    };

    onDelete() {
        this.rentType = RentBodyRentType.Week;
        super.onDelete();
    }

    get valueForSearching() {
        const [minValue, maxValue] = this.value;

        if (minValue === this.min && maxValue === this.max && this.rentType === RentBodyRentType.Week) {
            return undefined;
        }

        return this.value;
    }

    get rentTypeToQuery(): RentBody | undefined {
        const value = this.valueForSearching;

        if (!value) return undefined;
        return {
            rentPrice: {
                min: value[0],
                max: value[1],
            },
            rentType: this.rentType,
        };
    }

    get chipData(): ChipListItem | undefined {
        const [minValue, maxValue] = this.value;

        if (minValue === this.min && maxValue === this.max) {
            return undefined;
        }
        return {
            label: 'filterPage.price',
            value: 'filterPage.price' + JSON.stringify(this.value),
            translatedParams: {
                from: String(this.value[0]),
                to: String(this.value[1]),
                perRentType: 'filterPage.' + this.rentType,
            },
        };
    }
}
