import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IconButton from 'rsuite/IconButton';
import Toggle from 'rsuite/Toggle';

import { Container } from '../../components/Container';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { useStore } from '../../hooks/useStore';
import { QueryStore } from '../../stores/Query.store';
import MyAdvertsMobile from './components/MyAdvertsMobile';
import MyAdvertsTable from './components/MyAdvertsTable';

function MyAdvertsContainer() {
    const { myAdverts } = useStore();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goBack = () => {
        if (history.state.idx) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    useEffect(() => {
        async function fetchMyAdverts() {
            await myAdverts.fetchMyAdverts();
            QueryStore.handleError(navigate, myAdverts.errorStatus);
        }

        fetchMyAdverts().catch(console.error);
    }, [myAdverts]);

    return (
        <div>
            <NavBar />
            <Container className="container-height mb-12">
                <div className="my-6 md:mt-12 md:mb-8 flex">
                    <IconButton onClick={goBack} className="mr-4" size="md" circle icon={<PagePreviousIcon />} />

                    <h3>{t('myAdverts.title')}</h3>
                </div>

                {myAdverts.showHideArchiveButton && (
                    <div className="text-base text-end my-4">
                        <Toggle
                            checked={myAdverts.showArchived}
                            onChange={myAdverts.setShowArchived}
                            className="mr-2"
                        />
                        {t('myAdverts.showArchived')}
                    </div>
                )}

                <div className="hidden md:block bg-white text-base">
                    <MyAdvertsTable />
                </div>

                <div className="block md:hidden bg-white text-base">
                    <MyAdvertsMobile />
                </div>
            </Container>
            <Footer />
        </div>
    );
}

export default observer(MyAdvertsContainer);
