import React from 'react';
import { useTranslation } from 'react-i18next';
import Nav from 'rsuite/Nav';
import Radio from 'rsuite/Radio';
import RadioGroup from 'rsuite/RadioGroup';

import RangeSlider from '../../../components/RangeSlider/RangeSlider';
import { RentBodyRentType } from '../../../generated/api';

interface Props {
    title: string;
    withRadio?: boolean;
    value: [number, number];
    min: number;
    max: number;
    radioValue?: RentBodyRentType;

    withCurrency?: boolean;

    onChange(newValue: [number, number] | null): void;

    onRadioChange?(value: RentBodyRentType): void;
}

const radioData = [
    {
        name: 'price.pricePerDay',
        value: 'day',
    },
    {
        name: 'price.pricePerWeek',
        value: 'week',
    },
    {
        name: 'price.pricePerMonth',
        value: 'month',
    },
];

const SliderAccordion: React.FC<Props> = ({
    min,
    max,
    title,
    withRadio = false,
    value,
    onChange,
    onRadioChange,
    radioValue,
    withCurrency,
}) => {
    const { t } = useTranslation();

    return (
        <Nav.Menu className="bg-white border border-solid border-gray-100 rounded" title={title}>
            <div className="px-12 lg:px-6 xl:px-12 pb-4 mb-4">
                {withRadio && onRadioChange && (
                    <RadioGroup className="max-h-96 overflow-auto" value={radioValue} onChange={onRadioChange as any}>
                        {radioData.map((radio) => (
                            <Radio key={radio.name} value={radio.value}>
                                {t(radio.name)}
                            </Radio>
                        ))}
                    </RadioGroup>
                )}
                <RangeSlider
                    withCurrency={withCurrency}
                    value={value}
                    onChange={onChange}
                    min={min}
                    max={max}
                    title=""
                    size="small"
                />
            </div>
        </Nav.Menu>
    );
};

export default SliderAccordion;
