import { GoogleLogin } from '@react-oauth/google';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/Button';
import Form from 'rsuite/Form';
import Message from 'rsuite/Message';
import Schema from 'rsuite/Schema';
import toaster from 'rsuite/toaster';

import { PhoneInput } from '../../../components/PhoneInput/PhoneInput';
import { useStore } from '../../../hooks/useStore';
import RegistrationPNG from '../../../images/authorization/Registration.webp';
import { passwordRegExp } from '../../regExps';
import AccountContainer from '../components/AccountContainer';

const basicValue = {
    phoneNumber: '',
    firstName: '',
    lastName: '',
    password: '',
};

interface LocationState {
    redirectBack?: string;
}

const { StringType } = Schema.Types;

function Registration() {
    const navigate = useNavigate();
    const [form, setForm] = useState<any>(basicValue);
    const { userStore } = useStore();
    const { t } = useTranslation();
    const location = useLocation();

    const { redirectBack } = (location.state as LocationState) || {};

    const registrationSchema = useMemo(
        () =>
            Schema.Model({
                firstName: StringType()
                    .isRequired(t('formErrors.isRequired') || '')
                    .maxLength(16, t('formErrors.maxSymbol', { amount: '16' }) || ''),
                lastName: StringType()
                    .isRequired(t('formErrors.isRequired') || '')
                    .maxLength(16, t('formErrors.maxSymbol', { amount: '16' }) || ''),
                email: StringType()
                    .isRequired(t('formErrors.isRequired') || '')
                    .isEmail(t('formErrors.validEmail') || ''),
                phoneNumber: StringType().isRequired(t('formErrors.phoneNumberNotValid') || ''),
                password: StringType()
                    .isRequired(t('formErrors.isRequired') || '')
                    .maxLength(16, t('formErrors.maxSymbol', { amount: '16' }) || '')
                    .pattern(passwordRegExp, t('formErrors.legalChars') || ''),
                verifyPassword: StringType()
                    .addRule((value, data) => {
                        return value === data.password;
                    }, t('formErrors.passwordNotMatched') || '')
                    .isRequired(t('formErrors.isRequired') || ''),
            }),
        [t]
    );

    const moveToAuthorization = () => {
        navigate('/login');
    };

    const onSignUpClick = async (checkStatus: boolean) => {
        if (checkStatus) {
            const { phoneNumber, password, firstName, lastName, email } = form;

            try {
                await userStore.register({
                    phoneNumber,
                    password,
                    email,
                    firstName,
                    lastName,
                });

                toaster.push(
                    <Message showIcon type="success">
                        {t('registerPage.succeed')}
                    </Message>
                );
                if (redirectBack) {
                    navigate(redirectBack);
                } else {
                    navigate('/');
                }
            } catch (e) {
                toaster.push(
                    <Message showIcon type="error">
                        {t('registerPage.phoneNumberTaken')}
                    </Message>
                );
            }
        }
    };

    return (
        <AccountContainer image={RegistrationPNG}>
            <div>
                <div className="flex mb-6">
                    <p className="text-3xl text-black">{t('registerPage.registration')}</p>
                    <div className="flex items-end">
                        <p className="mx-2">{t('registerPage.or')}</p>
                        <Button size="lg" className="p-0" onClick={moveToAuthorization} appearance="link">
                            {t('registerPage.authorisation')}
                        </Button>
                    </div>
                </div>
                <Form
                    checkTrigger="blur"
                    model={registrationSchema}
                    onSubmit={onSignUpClick}
                    onChange={setForm}
                    formValue={form}
                    fluid
                >
                    <Form.Group>
                        <Form.ControlLabel>{t('userProfile.firstName')}</Form.ControlLabel>
                        <Form.Control placeholder="Jack" name="firstName" />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>{t('userProfile.lastName')}</Form.ControlLabel>
                        <Form.Control placeholder="Jonson" name="lastName" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>{t('userProfile.email')}</Form.ControlLabel>
                            <Form.Control placeholder="qwerty@gmail.com" name="email" />
                        </Form.Group>
                        <Form.ControlLabel>{t('userProfile.phoneNumber')}</Form.ControlLabel>
                        <Form.Control className="w-full" name="phoneNumber" accepter={PhoneInput} />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>{t('userProfile.password')}</Form.ControlLabel>
                        <Form.Control name="password" type="password" autoComplete="off" />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>{t('registerPage.verifyPassword')}</Form.ControlLabel>
                        <Form.Control name="verifyPassword" type="password" autoComplete="off" />
                    </Form.Group>
                    <Form.Group className="flex gap-2">
                        <Button type="submit" appearance="primary">
                            {t('registerPage.signUp')}
                        </Button>
                        <GoogleLogin
                            onSuccess={async (credentialResponse) => {
                                const needPhoneNumber = await userStore.googleLogin({
                                    token: credentialResponse.credential || '',
                                });

                                if (needPhoneNumber) {
                                    navigate('/number-registration', {
                                        state: {
                                            redirectBack,
                                        },
                                    });
                                } else {
                                    toaster.push(
                                        <Message showIcon type="error">
                                            {t('registerPage.phoneNumberTaken')}
                                        </Message>
                                    );
                                }
                            }}
                            onError={() => {
                                toaster.push(
                                    <Message showIcon type="error">
                                        {t('global.somethingWentWrong')}
                                    </Message>
                                );
                            }}
                            useOneTap
                        />
                    </Form.Group>
                </Form>
            </div>
        </AccountContainer>
    );
}

export default Registration;
