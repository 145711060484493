export default {
    nav: {
        myAccount: 'My account',
        createAnAdvert: 'Create an advert',
        rentRequests: 'Rent requests',
        myAdverts: 'My adverts',
        myProfile: 'My profile',
        login: 'Login',
        signOut: 'Sign out',
        adverts: 'Adverts',
        menu: 'Menu',
        other: 'Other',
        language: 'English',
    },
    mainPage: {
        header: 'Rent a car now',
        subHeader:
            'Our service for car rental from private individuals to other private individuals is a convenient and safe way to rent a car at an affordable price.',
        search: 'Search',
        recommendation: 'Recommendation',
        searchBodyType: 'Search by body type',
        reservationPeriod: 'Reservation period',
    },
    filterPage: {
        filters: 'Filters',
        clearAll: 'Clear all',
        notFound: {
            title: 'We cant find any adverts with these conditions',
            subtitle: 'Please, reset some filter conditions',
            reset: 'Reset all filters',
        },
        mileage: 'Mileage: from {{ from }} to {{ to }} thou. km.',
        consumption: 'Consumption: from {{ from }} to {{ to }} l/100km',
        dateOfIssue: 'Date of Issue: from {{ from }} to {{ to }}',
        reservationPeriod: 'Reservation period: from {{ fromDate }} to {{ toDate }}',
        price: 'Price: from {{ from }}₴ to {{ to }}₴ {{ perRentType }}',
        week: 'per week',
        day: 'per day',
        month: 'per month',
    },
    global: {
        loading: 'Loading...',
        send: 'Send',
        apply: 'Apply',
        ok: 'Ok',
        cancel: 'Cancel',
        approve: 'Approve',
        decline: 'Decline',
        required: 'Required',
        somethingWentWrong: 'Something went wrong',
        price: '{{price}} ₴',
        currency: '₴',

        agree: 'I agree with the',
        termsAndConditions: 'terms and conditions',
        nextSteps: 'Next steps:',
        DAndD: 'Click or Drag files to this area to upload',
    },
    steps: {
        previous: 'Previous',
        done: 'Done',
        next: 'Next',
    },
    tile: {
        perWeek: '{{price}} per week',
        doors: 'doors',
        mpg: 'l/100km',
        thouMiles: 'thou. km.',
        liters: 'L.',
        litersFull: 'liters',
        years: 'years',
    },
    params: {
        bodyType: 'Body type',
        cities: 'Cities',
        brandType: 'Brand type',
        reservationPeriod: 'Reservation period',
        prices: 'Prices',
        price: 'Price',
        car: 'Car',
        model: 'Car Model',
        modification: 'Modification',
        yearOfIssue: 'Year of issue',
        consumption: 'Consumption (l/100km)',
        mileage: 'Mileage (thou. ml.)',
        transmission: 'Transmission',
        engineCapacity: 'Engine Capacity',
        doorsAmount: 'Door number',
        fuel: 'Fuel',
        city: 'City',
        deposit: 'Deposit',
        minAge: 'Minimal age for client',
        minExp: 'Minimal experience for client',
        citizenship: 'Citizenship',
    },
    feedback: {
        title: 'Your opinion matters',
        subtitle: 'Please tell us a feedback or proposition, you may stay unnamed',
        contact: 'Contact (Optional)',
        feedback: 'Feedback',
        ourContacts: 'Our contacts',
        emailOrPhone: 'Email or phone',
        inputField: 'Type here something',
        success: 'Feedback has been send',
    },
    advertDetailsPAge: {
        mainInfo: 'Main information',
        description: 'Description',
        requirementForClient: 'Requirement for client',
        additionInfo: 'Additional Information',
        reservationTitle: 'Send rent request',
        reservationPeriod: 'Reservation period',
        reservationSend: 'Send rent request',
        showAll: 'Show all',
        photoGallery: 'Photo gallery',
        creator: 'Advert creator',
        profileVerified: 'This profile is verified',
        profileNotVerified: 'This profile is not verified',
        showANumber: 'Show a number',
        advertSettings: {
            title: 'Advert settings',
        },
        created: 'Created',
    },
    price: {
        perDay: '{{price}} ₴/day',
        perWeek: '{{price}} ₴/week',
        perMonth: '{{price}} ₴/month',
        currencyPerWeek: '₴/week',
        currencyPerDay: '₴/day',
        currencyPerMonth: '₴/month',
        pricePerWeek: 'Price per week',
        pricePerDay: 'Price per day',
        pricePerMonth: 'Price per month',
    },
    advertStatusMessage: {
        pausedMessage: 'This advert is paused you cant create rent request',
    },
    calculatedPrice: {
        cost: 'Cost',
        fee: 'Service fee',
        total: 'Total before taxes',
    },
    reservationPage: {
        title: 'Create rent request',
        dateRange: 'Date range',
        pickUpTime: 'Pick-up time',
        pickUpAddress: 'Pick-up address',
        pickUpAddressPlaceholder: 'Ave 5 st. 45',
        message: 'Message to car owner',
        messagePlaceholder: 'Please add a specific address, time or any question to car owner',
        sharePhone: 'Share my phone number to car owner',
        loginContinue: 'Login and continue',
        outsideCity: 'This address is outside the city',
        ownerNeedApprove: 'Please be aware that owner should approve your request',
        payToCarOwner: "You will pay only to car owner, please don't do any prepayment",
        requestSent: 'Your request has been sent',
    },
    userProfile: {
        phoneNumber: 'Phone number',
        password: 'Password',
        gender: 'Gender',
        aboutMe: 'Information about myself',
        aboutMePlaceholder: 'Please, tell car owner about you',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        accountCreated: 'Account created',
    },
    userProfilePage: {
        verificationTip:
            'According to statistics verified profile increases renting probability on 300%, please download your document in order to verify it.',
        verifyProfileButton: 'Verify profile',
        updateInfo: 'Update my info',
        profileVerified: 'Your profile is verified',
        profileNotVerified: 'Your profile is not verified',
        userUpdated: 'User data has been updated',
        uploadSuccessfully: 'Uploaded successfully',
        uploadFailed: 'Upload failed',
        verificationToaster: 'We will check you document and verify your profile during 24 hours',
        verifyProfileModel: {
            title: 'Verify profile',
            subtitle: 'Please upload your documents in order to we can verify your profile',
            decryption:
                'We use modern encryption and data protection technologies to ensure that your personal and confidential documents remain confidential and protected from unauthorized access.',
            passport: 'Passport',
            licence: 'Driving licence',
            protectedData: 'All data is protected',
        },
    },
    loginPage: {
        authorisation: 'Authorisation',
        or: 'or',
        registration: 'Registration',
        signIn: 'Sign in',
        succeed: 'Authorisation succeed',
        wrongPassword: 'Wrong phone number or password',
    },
    registerPage: {
        authorisation: 'Authorisation',
        or: 'or',
        registration: 'Registration',
        verifyPassword: 'Verify password',
        signUp: 'Sign up',
        succeed: 'Registration succeed',
        phoneNumberTaken: 'Phone number has been taken',
    },
    registerNumber: {
        title: 'Finish registration',
        description: 'Please enter your phone number so that car owners or renters can contact you',
    },
    verificationStatus: {
        accountVerified: 'This account has been verified',
        carVerified: 'This car has been verified',
        checkingUser: "We are checking user's documents",
        userNotVerified: {
            accountNotVerified: 'This user account has not been verified',
            sendDocuments: 'Please sent as your document that we can verify your profile',
            myAccountStep: '1. Click to "My Account"',
            myProfileStep: '2. Navigate to "My Profile"',
            verifyProfileStep: '3. Click "Verify profile"',
        },
        carNotVerified: {
            title: 'This car has not been verified',
            sendDocuments: 'Please sent as car ownership document that we can verify your car',
            clickStep: '1. Click "..." in advert list ',
            verifyCarStep: '2. Click "Verify car"',
        },
    },
    myAdverts: {
        title: 'My adverts',
        showArchived: 'Show archived',
        car: 'Car',
        cantRemoveModalTitle: 'This advert has rent requests',
        cantRemoveModalSubtitle:
            'You are not able to remove this advert until {{numberOfActiveRentRequests}} rent request has the active status. You need to decline them all first.',
        photo: 'Photo',
        views: 'Views',
        expired: 'Expired',
        status: 'Status',
        settings: 'settings',
        verification: 'Verification',
        success: 'Operation success',
    },
    myRentRequests: {
        period: 'Period',
        status: 'Status',
        created: 'Created At',
        income: 'Income',
        review: 'Review',
        price: 'Price',
        pickUpAddress: 'Pick-up address',
        pickUpTime: 'Pick-up time',
    },
    advertsActions: {
        verify: 'Verify car',
        edit: 'Edit',
        activate: 'Activate',
        pause: 'Pause',
        prolong: 'Prolong',
        archive: 'Archive',
        delete: 'Delete',
        remove: 'Remove',
    },
    advertStatus: {
        active: 'Active',
        expired: 'Expired',
        deleted: 'Deleted',
        paused: 'Paused',
        rejected: 'Rejected',
        archived: 'Archived',
        inRenting: 'In renting',

        reason: 'Reason:',
        declinePopover: {
            title: 'Your advert violated the rules of the service, so it was rejected by the administrator.',
            openAdverts: "1. Open the advert's setting",
            edit: '2. Edit the advert',
            waitReview: "3. Wait for the administrator's review",
        },
        expiredPopover: {
            title: 'This advert has been expired',
            subtitle:
                'All adverts could be active for a month therefore you need to prolong it or it will not be visible',
            settings: "1. Open the advert's setting",
            prolong: "2. Prolong the advert or delete it in the case you don't need it",
        },
    },
    verifyPage: {
        title: 'The advert verification',
        subtitle: 'Please upload your documents in order to we can check and verify your advert',
        protectedData: 'All data is protected',
        decrypt:
            'We use modern encryption and data protection technologies to ensure that your personal and confidential documents remain confidential and protected from unauthorized access.',
        userVerificationTitle: 'User verification',
        userVerificationTitleHint: '(You will do it only once)',
        passport: 'Passport',
        drivingLicence: 'Driving licence',
        carOwnership: 'Car ownership',
        ownership:
            'Document confirming ownership of the car (such as a certificate of registration, proof of purchase and transfer of ownership, etc.)',
        insurance: 'Car insurance documents',
        later: 'Do it later',
        review: 'Send for review',
        sentToaster: 'Documents has been sent to review',
    },
    rentRequestPage: {
        title: 'My rent requests',
        review: 'Review',
        received: 'Received',
        sent: 'Sent',
        filter: 'Filter status:',
        reminderLeasing: 'Reminder: Leasing period',
        reminderRenting: 'Reminder: Renting period',
        in: 'in',
        to: ' to the',
        at: 'at',
        youNeedBring: 'You need to bring the car:',
        youNeedGet: 'You need to get to the ',
        emptyReceivedNode: {
            title: "We can't fount any received rent request",
            makeSure: 'Make sure you',
            created: ' created ',
            resetFilter: 'Or filter above has been reset',
            advert: 'an adverb and it has been successfully verified',
        },
        sentRR: {
            emptyNode: {
                title: 'You have not sent any rent request yet',
                youNeedTo: 'You need to',
                find: ' find ',
                createRRNext: 'it and create rent request then',
                filter: 'Or filter above has been reset',
            },
            period: 'Period',
        },
    },
    rentRequestStatus: {
        approved: 'Approved',
        awaiting: 'Awaiting',
        declined: 'Declined',
        completed: 'Completed',
        active: 'Active',
        reason: 'Reason:',
        activeSpeaker: {
            carRenter: 'This car has been rented',
            carBusy: 'The car will be busy from {{from}} to {{to}}',
        },
        approvedSpeaker: {
            title: 'This rent request has been already approved',
            needToAgreed: 'You need to get to the agreed place in agreed time.',
            where: 'Where:',
            when: 'When:',
        },
    },
    rentRequestModal: {
        creator: 'by creator',
        user: 'by user',
        approved: 'Rent request was approved',
        hasBeenApproved: 'Rent request has been already approved',
        cantApprove: "You can't approve this rent request because advert status is {{status}}",
        periodBooked: 'This period has been already booked',
        cantApproveBecauseWaiting: "Unfortunately, you can't approve this rent request because you have",
        rentRequest: '{{amount}} rent requests',
        withPeriod: 'with this period.',
        RRInfo: 'Rent request information',

        rentingDate: 'Renting date',
        pickUpTime: 'Pick-up time',
        address: 'Address',
        income: 'Possible income',
        status: 'Status',
        description: 'Description:',
        userDetails: 'User Details',
        phone: 'Phone number:',
        gender: 'Gender:',
        createdDate: 'Created date:',
    },
    declineModal: {
        title: 'Decline',
        declined: 'Rent request has been declined',
        subtitle: 'You will not be able to undo this action',
        selectReason: 'Select relevant reason',
        comment: 'Add comment',
        warning: 'Warning',
        commentPlaceholder: 'Enter a decline reason here please',
    },
    photoUploader: {
        selectMainPhoto: 'Click on photo to select the main one',
        addPhoto: "Click 'Add photos' to download a photo from your device",
        mainPhoto: 'Main photo',
        addAtLeast: 'Please add at least {{ amount }} photos',
        addButton: 'Add photo',
    },
    addAdvert: {
        enter: 'Enter',
        select: 'Select',
        created: 'Advert was created',
        confidentialInformation: 'Your personal information will remain strictly confidential',
        mainInfo: {
            title: 'Create an advert',
            mainInfo: 'Main information',
            description: 'To get started please select main information about the car',
        },
        addPhoto: {
            title: 'Main photo & Photos',
            description: 'Upload files and select the main photo',
        },
        additionalInfo: {
            title: 'Additional information',
            description: 'Set up your additional information',
        },
        carDescription: {
            title: 'Car description',
            description: 'Add some description, try to describe the most valuable things',
            recommendation:
                'We recommend that you provide additional information about the product, describe it in detail equipment, as well as features of use. This will help the tenant decide choice and save you from additionalquestions.',
            placeholder: 'Enter the description',
        },
        pricesInfo: {
            title: 'Prices information',
            description: 'Input information about price of rent',
        },
        requirementsForClient: {
            title: 'Requirements for client',
            description: 'Define your requirement for client',
        },
    },
    errorPages: {
        forbidden: 'Forbidden',
        notFound: 'Not found',
    },
    editAdvertPage: {
        edited: 'Advert has been edited',
        title: 'Edit an advert',
        button: 'Edit advert',
        additionalInfo: {
            subtitle: 'Additional information',
            tooltip: 'Set additional info',
            prices: 'Prices',
        },
        descriptionPage: {
            title: 'Description',
            tooltip: 'Description will show in the advert',
        },
        editPhoto: {
            title: 'Edit photos',
            tooltip: 'Photos will show in the advert',
        },
        mainInformation: {
            title: 'Main information',
            tooltip: 'Main information will show in the advert',
        },
        requirement: {
            title: 'Requirements for client',
            tooltip: 'Requirements for client',
        },
    },
    nextStepModal: {
        wellDone: 'Well done',
        cautious: 'Cautious',
        thankYou: 'Thank you for requesting to rent this car. Please wait for the owner to confirm the reservation.',
        weWillNotify: 'We will notify you to your email address:',
        notEmail: 'We dont know your email address, you dont get the notification when the owner approve it',
        setEmail: 'Please set the email address in user profile that we could notify you.',
        gotIt: 'Got it',
        goToEdit: 'Go to edit profile',
    },
    landingPage: {
        title: 'Earn money with your car',
        actionButton: 'Create an advertisement',
        offerTitle: 'Roadieze service offers',
        offerSubTitle:
            'A simple way to earn money with your car without the need to sell it. Place an advertisement and rent out your vehicle.',
        step1: 'Car owner places their advertisement on the Roadieze service',
        step2: 'Receives a rental request with information about the renter',
        step3: 'Once the request is accepted, the renter can pick up the car from the owner at the specified time and place.',
        documentTitle: 'Documents for renting a car (Optional)',
        documentSubtitle: '(All documents can be submitted later)',
        document1:
            'Document confirming ownership of the car (such as a certificate of registration, proof of purchase and transfer of ownership, etc.)',
        document2: "Documents confirming the right to drive the car, including a driver's license.",
        document3: 'Car insurance documents, including comprehensive and third-party liability insurance',
        advantages: 'Advantages',
        other: 'Other',
        priceControl: 'Price control',
        RPriceControl: 'Owners can set their own prices',
        OtherPriceControl: 'Prices are set by the service',
        flexibility: 'Flexibility of the rental system',
        RFlexibility: 'Owners can choose renters and rental terms',
        OtherFlexibility: 'Limited flexibility',
        renterIdentification: 'Renter identification',
        RRenterIdentification: "The system verifies the renter's right to drive the car",
        OtherRenterIdentification: 'Limited safety and insurance measures',
        serviceFees: 'Service fees',
        yes: 'Yes',
        no: 'No',
        faq: 'FAQ',
        faqQ1: 'How is the rental price of the car determined?',
        faqA1: 'The rental price of the car is determined by the car owner. The owner can set different prices depending on the rental period, location, and other factors.',
        faqQ2: 'When does the user pay for the car?',
        faqA2: 'The renter pays for the rental as agreed upon with the car owner. We recommend making a deposit to ensure a smooth transaction.',
        faqQ3: 'How often can I rent out my car on Roadieze?',
        faqA3: 'The car owner can rent out their car on Roadieze as many times as they wish, provided they comply with the service requirements and state laws.',
    },
    transmissions: {
        manual: 'Manual',
        automatic: 'Automatic',
        automatedManual: 'Automated Manual',
        continuouslyVariable: 'Continuously Variable',
    },
    fuels: {
        petrol: 'Petrol',
        diesel: 'Diesel',
        cng: 'Compressed Natural Gas (CNG)',
        electric: 'Electric',
        bioDiesel: 'Bio-Diesel',
        lpg: 'Liquid Petroleum Gas (LPG)',
        ethanolMethanol: 'Ethanol or Methanol',
        other: 'Other',
    },
    citizenships: {
        ukrainian: 'Ukrainian',
        all: 'All',
    },
    bodyTypes: {
        sedan: 'Sedan',
        suv: 'Suv',
        crossover: 'Crossover',
        hatchback: 'Hatchback',
        roadster: 'Roadster',
        pickup: 'Pickup',
        micro: 'Micro',
        van: 'Van',
        coupe: 'Coupe',
        supercar: 'Supercar',
        miniTruck: 'Mini truck',
        cabriolet: 'Cabriolet',
        minivan: 'Minivan',
        truck: 'Truck',
    },
    cities: {
        kyiv: 'Kiyv',
        kharkiv: 'Kharkiv',
        odesa: 'Odesa',
        dnipro: 'Dnipro',
        zaporizhzhia: 'Zaporizhzhia',
        lviv: 'Lviv',
        krivyiRig: 'Krivyi Rig',
        mykolaiv: 'Mykolaiv',
        vinnytsia: 'Vinnytsia',
        kherson: 'Kherson',
        poltava: 'Poltava',
        chernihiv: 'Chernihiv',
        cherkasy: 'Cherkasy',
        zhytomyr: 'Zhytomyr',
        sumy: 'Sumy',
        khmelnytskyi: 'Khmelnytskyi',
        rivne: 'Rivne',
        ivanoFrankivsk: 'Ivano-Frankivsk',
        ternopil: 'Ternopil',
        lutsk: 'Lutsk',
        bilaTserkva: 'Bila Tserkva',
        uzhhorod: 'Uzhhorod',
    },
    genders: {
        male: 'Male',
        female: 'Female',
    },
    formErrors: {
        phoneNumberNotValid: 'Phone number is not valid',
        isRequired: 'This field is required',
        maxSymbol: 'This field must be at most {{ amount }} characters',
        minSymbol: 'This field must be at least {{ amount }} characters',
        legalChars: 'Please enter legal characters',
        passwordNotMatched: 'The two passwords do not match',
        validEmail: 'Please enter a valid email address',
        mustBeChecked: 'Must be checked',
        consumptionLimit: 'Consumption must be at most {{ amount }} l/100km',
        mileageLimit: 'Mileage must be at most {{ amount }} thou. miles',
        mileageNumber: 'Mileage must be a number',
        consumptionNumber: 'Consumption must be a number',
        mustBeNumber: 'It must be a number',
        engineAtMost: 'Engine Capacity must be at most {{amount}} liter',
        doorsAtMost: 'Door number must be at most {{amount}}',
        weekPriceAtMost: 'Week price must be at most {{ amount }} ₴',
        dayPriceAtMost: 'Day price must be at most {{ amount }} ₴',
        monthPriceAtMost: 'Month price must be at most {{ amount }} ₴',
        depositAtMost: 'Deposit must be at most {{ amount }} ₴',
    },
    declineReason: {
        anotherCar: "This car doesn't suit me",
        connection: "I can't reach the person",
        timeOrLocation: "This time or location doesn't work for me",
        other: 'Other',
        declineTitle: 'This rent request has been declined {{ user }}',
    },
    unsubscribe: {
        unsubscribe: "You've been unsubscribed",
        emailAddress: 'Your email address',
        willNotReceive: 'You will not receive any more emails from',
        byAccident: 'Unsubscribe by accident?',
        reSubscribe: 'Re-subscribe',
        resubscribedSuccess: "You've been re-subscribed successfully",
    },
};
