import { makeAutoObservable } from 'mobx';

interface Props {
    minAge?: string;
    minExperience?: string;
    deposit?: string;
    citizenship?: string;
}

export class RequirementsForClientStore {
    constructor(props: Props) {
        makeAutoObservable(this);

        this.minExperience = props.minExperience;
        this.minAge = props.minAge;
        this.deposit = props.deposit;
        this.citizenship = props.citizenship;
    }

    minAge?: string;
    minExperience?: string;
    deposit?: string;
    citizenship?: string;

    setMinAge = (minAge: string) => {
        this.minAge = minAge;
    };
    setMinExperience = (minExperience: string) => {
        this.minExperience = minExperience;
    };
    setDeposit = (deposit: string) => {
        this.deposit = deposit;
    };
    setCitizenship = (citizenship: string) => {
        this.citizenship = citizenship;
    };
}
