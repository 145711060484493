export default {
    nav: {
        myAccount: 'Мій акаунт',
        createAnAdvert: 'Зароби на своєму авто',
        rentRequests: 'Запити оренди',
        myAdverts: 'Мої оголошення',
        myProfile: 'Мій профіль',
        login: 'Вхід',
        signOut: 'Вихід',
        adverts: 'Оголошення',
        menu: 'Меню',
        other: 'Інше',
        language: 'Українська мова',
    },
    mainPage: {
        header: 'Орендуй авто',
        subHeader:
            'Наш сервіс прокату автомобілів від власників приватним особам - це зручний та безпечний спосіб оренди автомобіля за доступну ціну.',
        search: 'Пошук',
        recommendation: 'Рекомендації',
        searchBodyType: 'Пошук за типом кузова',
        reservationPeriod: 'Період оренди',
    },
    filterPage: {
        filters: 'Фільтр',
        clearAll: 'Очистити все',
        notFound: {
            title: 'Ми не можемо знайти жодного оголошення за цими умовами',
            subtitle: 'Будь ласка, скиньте деякі умови фільтру',
            reset: 'Скинути всі фільтри',
        },
        mileage: 'Пробіг: від {{ from }} до {{ to }} тис.км.',
        consumption: 'Споживання: від {{ from }} до {{ to }} л/100км',
        dateOfIssue: 'Дата випуску: від {{ from }} до {{ to }}',
        reservationPeriod: 'Період бронювання: від {{ fromDate }} до {{ toDate }}',
        price: 'Ціна: від {{ from }}₴ до {{ to }}₴ {{ perRentType }}',
        week: 'за тиждень',
        day: 'за день',
        month: 'за місяць',
    },
    global: {
        loading: 'Завантаження...',
        send: 'Відправити',
        apply: 'Прийняти',
        ok: 'Ок',
        cancel: 'Назад',
        approve: 'Підтвердити',
        decline: 'Відхилити',
        required: 'Обовʼязкове',
        somethingWentWrong: 'Щось пішло не так',
        price: '{{price}} ₴',
        currency: '₴',

        agree: 'Я погоджуюсь з',
        termsAndConditions: 'умови та положення',
        nextSteps: 'Наступні кроки:',
        DAndD: 'Натисніть або перетягніть файли в цю область',
    },
    steps: {
        previous: 'Назад',
        done: 'Готово',
        next: 'Далі',
    },
    tile: {
        perWeek: '{{price}} ₴/тиждень',
        doors: 'дверей',
        mpg: 'л/100км',
        thouMiles: 'тисяч км',
        liters: 'л.',
        litersFull: 'літрів',
        years: 'років',
    },
    params: {
        bodyType: 'Тип кузова',
        cities: 'Міста',
        brandType: 'Бренд',
        reservationPeriod: 'Період оренди',
        prices: 'Ціни',
        price: 'Ціна',
        car: 'Авто',
        model: 'Модель',
        modification: 'Модифікація',
        yearOfIssue: 'Рік випуску',
        consumption: 'Споживання (л/100км)',
        mileage: 'Пробіг (тис. км.)',
        transmission: 'Коробка передач',
        engineCapacity: 'Обʼєм двигуна',
        doorsAmount: 'Кількість дверей',
        fuel: 'Тип палива',
        city: 'Місто',
        deposit: 'Залог',
        minAge: 'Мінімальний вік',
        minExp: 'Мінімальний стаж',
        citizenship: 'Громадянство',
    },
    feedback: {
        title: 'Ваш відгук цінний для нас',
        subtitle: 'Будь ласка, надішліть нам відгук або пропозицію, ви можете залишитися анонімним',
        contact: 'Контакти (Не овʼязково)',
        feedback: 'Відгук',
        ourContacts: 'Наші контакти',
        emailOrPhone: 'Email або телефон',
        inputField: 'Напишіть відгук',
        success: 'Відгук відправлено успішно',
    },
    advertDetailsPAge: {
        mainInfo: 'Основна інформація',
        description: 'Опис',
        requirementForClient: 'Вимоги до клієнта',
        additionInfo: 'Додаткова інформація',
        reservationTitle: 'Запит на оренду',
        reservationPeriod: 'Період оренди',
        reservationSend: 'Надіслати запит на оренду',
        showAll: 'Показати все',
        photoGallery: 'Галерея фото',
        creator: 'Автор оголошення',
        profileVerified: 'Цей профіль підтверджено',
        profileNotVerified: 'Цей профіль не підтверджено',
        showANumber: 'Показати номер',
        advertSettings: {
            title: 'Налаштування оголошення',
        },
        created: 'Створено',
    },
    price: {
        perDay: '{{price}} ₴/день',
        perWeek: '{{price}} ₴/тиждень',
        perMonth: '{{price}} ₴/місяць',
        currencyPerWeek: '₴/тиждень',
        currencyPerDay: '₴/день',
        currencyPerMonth: '₴/місяць',
        pricePerWeek: 'Ціна за тиждень',
        pricePerDay: 'Ціна за день',
        pricePerMonth: 'Ціна за місяць',
    },
    advertStatusMessage: {
        pausedMessage: 'Це оголошення призупинено, ви не можете створити заявку на оренду',
    },
    calculatedPrice: {
        cost: 'Ціна',
        fee: 'Сервісний збір',
        total: 'Всьго',
    },
    reservationPage: {
        title: 'Створити запис оренди',
        dateRange: 'Період оренди',
        pickUpTime: 'Час отримання',
        pickUpAddress: 'Адреса отримання авто',
        pickUpAddressPlaceholder: 'пр. Шевченка 36',
        message: 'Повідомлення власнику авто',
        messagePlaceholder: 'Ви можете додати будь які інформацію або залишить будь-яке запитання до власника авто',
        sharePhone: 'Повідомити власнику автомобіля мій номер телефону',
        loginContinue: 'Увійти та продовжити',
        outsideCity: 'Ця адреса за межами міста',
        ownerNeedApprove: 'Майте на увазі, що власник повинен схвалити ваш запит',
        payToCarOwner: 'Ви будете платити тільки власнику автомобіля, будь ласка, не робіть передоплату',
        requestSent: 'Ваш запит надіслано',
    },
    userProfile: {
        phoneNumber: 'Телефон',
        password: 'Пароль',
        gender: 'Стать',
        aboutMe: 'Інформація про себе',
        aboutMePlaceholder: 'Будь ласка, напишіть інформацію про себе',
        firstName: 'Імʼя',
        lastName: 'Прізвище',
        email: 'Email',
        accountCreated: 'Акаунт створено',
    },
    userProfilePage: {
        verificationTip:
            'За статистикою перевірений профіль збільшує ймовірність оренди на 300%, будь ласка, завантажте свій документ, щоб підтвердити акаунт.',
        verifyProfileButton: 'Завантажити документи',
        updateInfo: 'Оновити профіль',
        profileVerified: 'Ваш профіль підтверджено',
        profileNotVerified: 'Ваш профіль не підтверджено',
        userUpdated: 'Дані користувача оновлено',
        uploadSuccessfully: 'Завантажено успішно',
        uploadFailed: 'Помилка завантаження',
        verificationToaster: 'Ми перевіримо ваші документи та підтвердимо ваш профіль протягом 24 годин',
        verifyProfileModel: {
            title: 'Завантажити документи',
            subtitle: 'Будь ласка, завантажте свої документи, щоб ми могли перевірити ваш профіль',
            decryption:
                'Ми використовуємо сучасні технології шифрування та захисту даних, щоб переконатися, що ваші особисті та конфіденційні документи залишаються конфіденційними та захищеними від несанкціонованого доступу.',
            passport: 'Паспорт',
            licence: 'Права водія',
            protectedData: 'Всі дані захищені',
        },
    },
    loginPage: {
        authorisation: 'Авторизація',
        or: 'або',
        registration: 'Реєстрація',
        signIn: 'Увійти',
        succeed: 'Авторизація успішна',
        wrongPassword: 'Неправильний номер телефону або пароль',
    },
    registerPage: {
        authorisation: 'Авторизація',
        or: 'або',
        registration: 'Реєстрація',
        verifyPassword: 'Verify password',
        signUp: 'Зареєструватися',
        succeed: 'Реєстрація пройшла успішно',
        phoneNumberTaken: 'Номер телефону зайнято',
    },
    registerNumber: {
        title: 'Закінчити реєстрацію',
        description: 'Будь ласка, вкажіть ваш номер телефону, щоб власники авто або орендарі могли звʼязатись з вами',
    },
    verificationStatus: {
        accountVerified: 'Цей акаунт підтверджено',
        carVerified: 'Цей автомобіль перевірено',
        checkingUser: 'Ми перевіряємо документи користувача',
        userNotVerified: {
            accountNotVerified: 'Цей акаунт не підтверджено',
            sendDocuments: 'Будь ласка, надішліть як документ, щоб ми могли підтвердити ваш профіль',
            myAccountStep: '1. Натисніть "Мій акаунт"',
            myProfileStep: '2. Перейдіть до «Мій профіль»',
            verifyProfileStep: '3. Натисніть «Завантажити документи»',
        },
        carNotVerified: {
            title: 'Документи на автомобіль не перевірено',
            sendDocuments:
                'Будь ласка, надішліть як документ про право власності на автомобіль, щоб ми могли підтвердити ваш автомобіль',
            clickStep: '1. В списку оголошень натисніть "..."',
            verifyCarStep: '2. Натисніть «Підтвердити авто»',
        },
    },
    myAdverts: {
        title: 'Мої оголошення',
        showArchived: 'Показати заархівовані',
        car: 'Авто',
        cantRemoveModalTitle: 'У цьому оголошенні є заявки на оренду',
        cantRemoveModalSubtitle:
            'Ви не зможете видалити це оголошення, доки {{numberOfActiveRentRequests}} запитів на оренду має активний статус. Спочатку вам потрібно їх усіх відхилити.',
        photo: 'Фото',
        views: 'Перегляди',
        expired: 'Термін дії',
        status: 'Статус',
        settings: 'Налаштування',
        verification: 'Веріфікація',
        success: 'Успішно',
    },
    myRentRequests: {
        period: 'Період',
        status: 'Статус',
        created: 'Створено',
        income: 'Дохід',
        review: 'Відкрити',
        price: 'Ціна',
        pickUpAddress: 'Адреса',
        pickUpTime: 'Час отримання',
    },
    advertsActions: {
        verify: 'Завантажити документи',
        edit: 'Редагувати',
        activate: 'Активувати',
        pause: 'Зупинити',
        prolong: 'Продовжити',
        archive: 'Архівувати',
        delete: 'Видалити',
        remove: 'Видалити',
    },
    advertStatus: {
        active: 'Активний',
        expired: 'Закінчився',
        deleted: 'Видалено',
        paused: 'Зупинено',
        rejected: 'Відхилено',
        archived: 'Заархівовано',
        inRenting: 'В оренді',

        reason: 'Причини:',
        declinePopover: {
            title: 'Ваше оголошення порушило правила сервісу, тому його було відхилено адміністратором.',
            openAdverts: '1. Відкрийте налаштування оголошення',
            edit: '2. Відредагуйте оголошення',
            waitReview: '3. Зачекайте перегляду адміністратором',
        },
        expiredPopover: {
            title: 'Це оголошення застаріло',
            subtitle:
                'Всі оголошення можуть бути активними протягом місяця, тому вам потрібно продовжити його або видалити, якщо воно вам не потрібне',
            settings: '1. Відкрийте налаштування оголошення',
            prolong: '2. Продовжте термін публікації оголошення або видаліть його, якщо воно вам не потрібне',
        },
    },
    verifyPage: {
        title: 'Перевірка оголошення',
        subtitle: 'Будь ласка, завантажте свої документи для перевірки та підтвердження вашого оголошення',
        protectedData: 'Всі дані захищені',
        decrypt:
            'Ми використовуємо сучасні технології шифрування та захисту даних, щоб забезпечити конфіденційність та захист ваших особистих та конфіденційних документів від несанкціонованого доступу.',
        userVerificationTitle: 'Перевірка користувача',
        userVerificationTitleHint: '(Ви зробите це лише один раз)',
        passport: 'Паспорт',
        drivingLicence: 'Права водія',
        carOwnership: 'Власність автомобіля',
        ownership:
            'Документ, що підтверджує власність на автомобіль (наприклад, свідоцтво про реєстрацію, докази купівлі та передачі власності тощо)',
        insurance: 'Страхування',
        later: 'Пізніше',
        review: 'Надіслати на перевірку',
        sentToaster: 'Документи надіслано на перевірку',
    },
    rentRequestPage: {
        title: 'Мої запити на оренду',
        review: 'Переглянути',
        received: 'Отримані',
        sent: 'Відправлені',
        filter: 'Фільтр статусу:',
        reminderLeasing: 'Нагадування: Термін лізингу',
        reminderRenting: 'Нагадування: Термін оренди',
        in: 'в',
        to: ' до',
        at: 'в',
        youNeedBring: 'Вам потрібно привезти автомобіль:',
        youNeedGet: 'Вам потрібно приїхати в',
        emptyReceivedNode: {
            title: 'Ми не знайшли жодних отриманих запитів на оренду',
            makeSure: 'Переконайтесь, що ви',
            created: ' створили ',
            resetFilter: 'Або скиньте фільтри вище',
            advert: 'оголошення і воно успішно перевірене',
        },
        sentRR: {
            emptyNode: {
                title: 'Ви ще не відправили жодного запиту на оренду',
                youNeedTo: 'Вам потрібно',
                find: ' знайти ',
                createRRNext: 'його і створити запит на оренду',
                filter: 'Або скинути фільтри',
            },
            period: 'Період',
        },
    },
    rentRequestStatus: {
        approved: 'Затверджено',
        awaiting: 'Очікує',
        declined: 'Відхилено',
        completed: 'Завершено',
        active: 'Активний',
        reason: 'Причина:',
        activeSpeaker: {
            carRenter: 'Цей автомобіль вже орендований',
            carBusy: 'Автомобіль буде в оренді з {{from}} до {{to}}',
        },
        approvedSpeaker: {
            title: 'Цей запит на оренду вже затверджено',
            needToAgreed: 'Вам потрібно прибути в узгоджене місце в узгоджений час.',
            where: 'Де:',
            when: 'Коли:',
        },
    },
    rentRequestModal: {
        creator: 'власником',
        user: 'користувачем',
        approved: 'Запит на оренду був затверджений',
        hasBeenApproved: 'Запит на оренду вже затверджено',
        cantApprove: 'Ви не можете затвердити цей запит на оренду, оскільки статус оголошення - {{status}}',
        periodBooked: 'Цей період вже заброньовано',
        cantApproveBecauseWaiting: 'На жаль, ви не можете затвердити цей запит на оренду, оскільки у вас є ',
        rentRequest: '{{amount}} запитів на оренду',
        withPeriod: ' з цим періодом.',
        RRInfo: 'Інформація про запит на оренду',

        rentingDate: 'Дата оренди',
        pickUpTime: 'Час отримання',
        address: 'Адреса',
        income: 'Потенційний дохід',
        status: 'Статус',
        description: 'Опис:',
        userDetails: 'Дані користувача',
        phone: 'Номер телефону:',
        gender: 'Стать:',
        createdDate: 'Дата створення:',
    },
    declineModal: {
        title: 'Відхилити',
        declined: 'Запит на оренду відхилений',
        subtitle: 'Ви не зможете відмінити цю дію потім',
        selectReason: 'Виберіть відповідну причину',
        warning: 'Обережно',
        comment: 'Додати коментар',
        commentPlaceholder: 'Введіть причину відхилення тут',
    },
    photoUploader: {
        selectMainPhoto: 'Клацніть на фото, щоб вибрати основне',
        addPhoto: "Клацніть 'Додати фото', щоб завантажити фото з вашого пристрою",
        mainPhoto: 'Основне фото',
        addAtLeast: 'Будь ласка, додайте принаймні {{ amount }} фото',
        addButton: 'Додати фото',
    },
    addAdvert: {
        enter: 'Ввести',
        select: 'Вибрати',
        created: 'Оголошення створено',
        confidentialInformation: 'Ваша особиста інформація залишиться строго конфіденційною',
        mainInfo: {
            title: 'Створити оголошення',
            mainInfo: 'Основна інформація',
            description: 'Для початку виберіть основну інформацію про автомобіль',
        },
        addPhoto: {
            title: 'Основне фото та фото',
            description: 'Завантажте файли та виберіть основне фото',
        },
        additionalInfo: {
            title: 'Додаткова інформація',
            description: 'Налаштуйте додаткову інформацію',
        },
        carDescription: {
            title: 'Опис автомобіля',
            description: 'Додайте опис, намагайтесь описати найцінніші речі',
            recommendation:
                'Ми рекомендуємо надати додаткову інформацію про продукт, детально описати його обладнання, а також особливості використання. Це допоможе орендарю прийняти рішення і заощадить вас від додаткових питань.',
            placeholder: 'Введіть опис',
        },
        pricesInfo: {
            title: 'Інформація про ціни',
            description: 'Введіть інформацію про ціну оренди',
        },
        requirementsForClient: {
            title: 'Вимоги до клієнта',
            description: 'Визначте свої вимоги до клієнта',
        },
    },
    errorPages: {
        forbidden: 'Заборонено',
        notFound: 'Не знайдено',
    },
    editAdvertPage: {
        edited: 'Оголошення було відредаговано',
        title: 'Редагувати оголошення',
        button: 'Редагувати оголошення',
        additionalInfo: {
            subtitle: 'Додаткова інформація',
            tooltip: 'Встановити додаткову інформацію',
            prices: 'Ціни',
        },
        descriptionPage: {
            title: 'Опис',
            tooltip: 'Опис буде відображатись в оголошенні',
        },
        editPhoto: {
            title: 'Редагувати фото',
            tooltip: 'Фото будуть відображатись в оголошенні',
        },
        mainInformation: {
            title: 'Основна інформація',
            tooltip: 'Основна інформація буде відображатись в оголошенні',
        },
        requirement: {
            title: 'Вимоги до клієнта',
            tooltip: 'Редагуйте свої вимоги до клієнта',
        },
    },
    nextStepModal: {
        wellDone: 'Добре зроблено',
        cautious: 'Обережно',
        thankYou:
            'Дякуємо за запит на оренду цього автомобіля. Будь ласка, зачекайте, поки власник підтвердить бронювання.',
        weWillNotify: 'Ми повідомимо вас на вашу електронну адресу:',
        notEmail: 'Ми не знаємо вашу електронну адресу, ви не отримаєте сповіщення, коли власник підтвердить його',
        setEmail: 'Будь ласка, вкажіть електронну адресу в профілі користувача, щоб ми могли вас повідомити.',
        gotIt: 'Зрозуміло',
        goToEdit: 'Перейти до редагування профілю',
    },
    landingPage: {
        title: 'Заробляй на своєму автомобілі',
        actionButton: 'Створити оголошення',
        offerTitle: 'Сервіс Roadieze пропонує',
        offerSubTitle:
            'Простий спосіб заробити на своєму авто без необхідності продавати його. Розмісти оголошення і здавай авто в оренду',
        step1: 'Власник авто розміщує своє оголошення на сервісі Roadieze',
        step2: 'Отримує запит оренди з інформацією про замовника',
        step3: 'Коли запит прийнято, орендар може забрати автомобіль від власника в зазначений час і місце',
        documentTitle: 'Необхідно для здачі авто в оренду',
        documentSubtitle: '(Документи не є обовʼязковими, але підвищуть шанс оренди)',
        document1:
            'Документ, що підтверджує власність на автомобіль (наприклад, свідоцтво про реєстрацію транспортного засобу, договір купівлі-продажу тощо)',
        document2: 'Документи, що підтверджують право керування автомобілем, зокрема, водійське посвідчення',
        document3: 'Страхування автомобіля по КАСКО і ОСАГО',
        advantages: 'Переваги',
        other: 'Інші',
        priceControl: 'Контроль цін',
        RPriceControl: 'Власники можуть самостійно встановлювати ціни',
        OtherPriceControl: 'Ціни встановлюються сервісом',
        flexibility: 'Гнучка система оренди',
        RFlexibility: 'Власники можуть вибирати орендарів та терміни оренди',
        OtherFlexibility: 'Обмежена гнучкість',
        renterIdentification: 'Ідентифікація орендарів',
        RRenterIdentification: 'Система перевіряє право керування автомобілем орендаря',
        OtherRenterIdentification: 'Обмежені заходи безпеки та страхування',
        serviceFees: 'Сервісні платежі',
        yes: 'Є',
        no: 'Немає',
        faq: 'Запитання й відповіді',
        faqQ1: 'Як визначається ціна оренди автомобіля?',
        faqA1: 'Ціна оренди автомобіля визначається власником авто. Власник може встановлювати різні ціни залежно від терміну оренди, місця розташування та інших факторів',
        faqQ2: 'Коли користувач оплачує авто?',
        faqA2: 'Орендар оплачує оренду так як він домовиться з власником авто',
        faqQ3: 'Як часто я можу здавати свій автомобіль в оренду на Roadieze?',
        faqA3: 'Власник автомобіля може здавати свій автомобіль в оренду на Roadieze стільки разів, скільки він бажає, за умови дотримання вимог сервісу та державних законів',
    },
    transmissions: {
        manual: 'Механічна',
        automatic: 'Автоматична',
        automatedManual: 'Автоматизована механічна',
        continuouslyVariable: 'Безступінчаста',
    },
    fuels: {
        petrol: 'Бензин',
        diesel: 'Дизель',
        cng: 'Стиснений природний газ (CNG)',
        electric: 'Електричний',
        bioDiesel: 'Біодизель',
        lpg: 'Рідкий нафтовий газ (LPG)',
        ethanolMethanol: 'Етанол або метанол',
        other: 'Інше',
    },
    citizenships: {
        ukrainian: 'Українське',
        all: 'Всі',
    },
    bodyTypes: {
        sedan: 'Седан',
        suv: 'Позашляховик',
        crossover: 'Кросовер',
        hatchback: 'Хетчбек',
        roadster: 'Родстер',
        pickup: 'Пікап',
        micro: 'Мікроавтобус',
        van: 'Фургон',
        coupe: 'Купе',
        supercar: 'Суперкар',
        miniTruck: 'Міні-вантажівка',
        cabriolet: 'Кабріолет',
        minivan: 'Мінівен',
        truck: 'Вантажівка',
    },
    cities: {
        kyiv: 'Київ',
        kharkiv: 'Харків',
        odesa: 'Одеса',
        dnipro: 'Дніпро',
        zaporizhzhia: 'Запоріжжя',
        lviv: 'Львів',
        krivyiRig: 'Кривий Ріг',
        mykolaiv: 'Миколаїв',
        vinnytsia: 'Вінниця',
        kherson: 'Херсон',
        poltava: 'Полтава',
        chernihiv: 'Чернігів',
        cherkasy: 'Черкаси',
        zhytomyr: 'Житомир',
        sumy: 'Суми',
        khmelnytskyi: 'Хмельницький',
        rivne: 'Рівне',
        ivanoFrankivsk: 'Івано-Франківськ',
        ternopil: 'Тернопіль',
        lutsk: 'Луцьк',
        bilaTserkva: 'Біла Церква',
        uzhhorod: 'Ужгород',
    },
    genders: {
        male: 'Чоловік',
        female: 'Жінка',
    },
    formErrors: {
        phoneNumberNotValid: 'Номер телефону недійсний',
        isRequired: "Це поле обов'язкове",
        maxSymbol: 'Це поле повинно містити не більше {{ amount }} символів',
        minSymbol: 'Це поле повинно містити не менше {{ amount }} символів',
        legalChars: 'Будь ласка, введіть дозволені символи',
        passwordNotMatched: 'Два введені паролі не співпадають',
        validEmail: 'Будь ласка, введіть дійсну адресу електронної пошти',
        mustBeChecked: 'Повинно бути позначено',
        consumptionLimit: 'Споживання повинно бути не більше {{ amount }} л/100 км',
        mileageLimit: 'Пробіг повинен бути не більше {{ amount }} тис. миль',
        mileageNumber: 'Пробіг повинен бути числом',
        consumptionNumber: 'Споживання повинно бути числом',
        mustBeNumber: 'Це повинно бути число',
        engineAtMost: "Об'єм двигуна повинен бути не більше {{ amount }} літрів",
        doorsAtMost: 'Кількість дверей повинна бути не більше {{ amount }}',
        weekPriceAtMost: 'Ціна за тиждень повинна бути не більше {{ amount }} ₴',
        dayPriceAtMost: 'Ціна за день повинна бути не більше {{ amount }} ₴',
        monthPriceAtMost: 'Ціна за місяць повинна бути не більше {{ amount }} ₴',
        depositAtMost: 'Залог повинен бути не більше {{ amount }} ₴',
    },
    declineReason: {
        anotherCar: 'Це авто не підходить мені',
        connection: "Не можу зв'язатися з людиною",
        timeOrLocation: 'Цей час або локація мені не підходить',
        other: 'Інше',
        declineTitle: 'Цей запит на оренду було відхилено {{ user }}',
    },
    unsubscribe: {
        unsubscribe: 'Ви були відписані',
        emailAddress: 'Ваша адреса електронної пошти',
        willNotReceive: 'Ви більше не будете отримувати електронні листи від',
        byAccident: 'Відписалися випадково?',
        reSubscribe: 'Повторно підписатися',
        resubscribedSuccess: 'Ви були підписані повторно',
    },
};
