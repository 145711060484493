import 'react-multi-carousel/lib/styles.css';

import LocationIcon from '@rsuite/icons/Location';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import { Loader } from 'rsuite';
import Dropdown from 'rsuite/Dropdown';
import { WithAsProps } from 'rsuite/esm/@types/common';
import Button from 'rsuite/esm/Button';

import { Container } from '../../../components/Container';
import BoxTile from '../../../components/Tiles/BoxTile';
import { cities, getCityLabelByValue } from '../../../data/cities.data';
import { useStore } from '../../../hooks/useStore';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 767 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
    },
};

function Recommendation() {
    const { shortFilter } = useStore();
    const { t } = useTranslation();

    const renderButton = (props: WithAsProps, ref: React.Ref<any>) => (
        <Button {...props} ref={ref} className="text-base rounded-3xl ml-4" appearance="ghost">
            <LocationIcon className="mr-1" /> {t(getCityLabelByValue(shortFilter.recommendationCity))}
        </Button>
    );

    return (
        <div className="mt-12 md:mt-24">
            <Container>
                <div className="flex mb-12">
                    <h3 className="md:text-3xl text-2xl">{t('mainPage.recommendation')}</h3>
                    <Dropdown
                        className="ml-4 text-base max-h-96"
                        renderToggle={renderButton}
                        menuStyle={{ overflowY: 'auto' }}
                    >
                        {cities.map((region) => (
                            <Dropdown.Item
                                key={region.value}
                                onClick={() => shortFilter.setRecommendationCity(region.value)}
                            >
                                {t(region.label)}
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                </div>

                {shortFilter.isLoading && !shortFilter.recommendations.length ? (
                    <div className="flex justify-center">
                        <Loader size="md" content={t('global.loading')} />
                    </div>
                ) : (
                    <>
                        <div className="hidden md:block">
                            <Carousel swipeable={false} draggable={false} responsive={responsive} itemClass="px-1 pb-1">
                                {shortFilter.recommendations.map((advert) => (
                                    <BoxTile key={advert.id} data={advert} isRecommendation />
                                ))}
                            </Carousel>
                        </div>

                        <div className="block md:hidden">
                            {shortFilter.recommendations.map((advert) => (
                                <div className="my-4" key={advert.id}>
                                    <BoxTile key={advert.id} data={advert} isRecommendation />
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </Container>
        </div>
    );
}

export default observer(Recommendation);
