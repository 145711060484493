import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Nav from 'rsuite/esm/Nav';
import Sidenav from 'rsuite/esm/Sidenav';

import { useStore } from '../../../hooks/useStore';
import CheckboxListAccordion from './CheckboxListAccordion';
import ReservationPeriodAccordion from './ReservationPeriodAccordion';
import SliderAccordion from './SliderAccordion';

function MainFilter() {
    const { fullFilter } = useStore();
    const { t } = useTranslation();

    return (
        <Sidenav appearance="subtle">
            <Sidenav.Body>
                <Nav>
                    <CheckboxListAccordion
                        onChange={fullFilter.bodyTypes.toggleData}
                        title={t('params.bodyType')}
                        data={fullFilter.bodyTypes.getTranslatedValue(t)}
                    />
                    <CheckboxListAccordion
                        onChange={fullFilter.cities.toggleData}
                        title={t('params.city')}
                        data={fullFilter.cities.getTranslatedValue(t)}
                    />
                    <CheckboxListAccordion
                        onChange={fullFilter.brandTypes.toggleData}
                        title={t('params.brandType')}
                        data={fullFilter.brandTypes.values}
                    />
                    <ReservationPeriodAccordion
                        onChange={fullFilter.reservationPeriod.onChange}
                        title={t('params.reservationPeriod')}
                        value={fullFilter.reservationPeriod.value}
                    />
                    <SliderAccordion
                        value={fullFilter.rentPrice.value}
                        onChange={fullFilter.rentPrice.setValue}
                        onRadioChange={fullFilter.rentPrice.setRentPeriodType}
                        min={fullFilter.rentPrice.min}
                        max={fullFilter.rentPrice.max}
                        title={t('params.prices')}
                        withRadio
                        withCurrency
                        radioValue={fullFilter.rentPrice.rentType}
                    />
                    <SliderAccordion
                        title={t('params.yearOfIssue')}
                        value={fullFilter.dateOfIssue.value}
                        min={fullFilter.dateOfIssue.min}
                        max={fullFilter.dateOfIssue.max}
                        onChange={fullFilter.dateOfIssue.setValue}
                    />
                    <SliderAccordion
                        title={t('params.consumption')}
                        value={fullFilter.consumption.value}
                        min={fullFilter.consumption.min}
                        max={fullFilter.consumption.max}
                        onChange={fullFilter.consumption.setValue}
                    />
                    <SliderAccordion
                        title={t('params.mileage')}
                        value={fullFilter.mileage.value}
                        min={fullFilter.mileage.min}
                        max={fullFilter.mileage.max}
                        onChange={fullFilter.mileage.setValue}
                    />
                    <CheckboxListAccordion
                        onChange={fullFilter.transmissions.toggleData}
                        title={t('params.transmission')}
                        data={fullFilter.transmissions.getTranslatedValue(t)}
                    />
                    <CheckboxListAccordion
                        onChange={fullFilter.fuels.toggleData}
                        title={t('params.fuel')}
                        data={fullFilter.fuels.getTranslatedValue(t)}
                    />
                </Nav>
            </Sidenav.Body>
        </Sidenav>
    );
}

export default observer(MainFilter);
