import CheckIcon from '@rsuite/icons/Check';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import HomeIcon from '@rsuite/icons/legacy/Home';
import PageNextIcon from '@rsuite/icons/PageNext';
import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import React, { MutableRefObject, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IconButton from 'rsuite/IconButton';
import Panel from 'rsuite/Panel';
import Progress from 'rsuite/Progress';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';

import { GAActions, GACategories } from '../../constants';

export type StepOption = {
    mainTitle: string;
    mainTitleHint?: string;
    description: string;
    render: (ref: MutableRefObject<{ onSubmitStep(): boolean } | null>) => React.ReactNode;
};

interface Props {
    options: StepOption[];
    loading: boolean;
    onSubmitForm(): void;
}

const StepsContainer: React.FC<Props> = (props) => {
    const [step, setStep] = useState(0);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const lastIndex = props.options.length - 1;
    const onChange = (nextStep: number) => {
        setStep(nextStep < 0 ? 0 : nextStep > lastIndex ? lastIndex : nextStep);
    };
    const ref = useRef<{ onSubmitStep(): boolean } | null>(null);

    const currentStep = props.options[step];

    const onNext = () => {
        if (ref.current?.onSubmitStep()) {
            onChange(step + 1);
            ReactGA.event({
                category: GACategories.CreateAdvert,
                action: GAActions[GACategories.CreateAdvert].nextStep,
                value: step + 1,
            });
        }
    };
    const onPrevious = () => {
        onChange(step - 1);
        ReactGA.event({
            category: GACategories.CreateAdvert,
            action: GAActions[GACategories.CreateAdvert].prevStep,
            value: step - 1,
        });
    };

    const onSubmit = () => {
        if (ref.current?.onSubmitStep()) {
            props.onSubmitForm();
            ReactGA.event({
                category: GACategories.CreateAdvert,
                action: GAActions[GACategories.CreateAdvert].finishForm,
            });
        }
    };

    const goHome = () => {
        navigate('/');
    };

    const percent = ((step + 1) / (lastIndex + 1)) * 100;
    const isLastStep = step === lastIndex;

    const navigateButtons = (
        <div className="flex justify-between">
            <IconButton
                className="pr-8"
                onClick={onPrevious}
                disabled={step === 0}
                icon={<PagePreviousIcon />}
                placement="left"
            >
                {t('steps.previous')}
            </IconButton>

            {isLastStep ? (
                <IconButton
                    className="pl-8"
                    appearance="primary"
                    onClick={onSubmit}
                    icon={<PageNextIcon />}
                    placement="right"
                    loading={props.loading}
                >
                    {t('steps.done')}
                </IconButton>
            ) : (
                <IconButton
                    className="pl-8"
                    appearance="primary"
                    onClick={onNext}
                    icon={<CheckIcon />}
                    placement="right"
                >
                    {t('steps.next')}
                </IconButton>
            )}
        </div>
    );

    const navigateButtonPanel = (
        <>
            <div className="hidden lg:block">{navigateButtons}</div>
            <Progress.Line
                className="hidden lg:block my-4 mt-6 px-0"
                percent={percent}
                status="active"
                showInfo={false}
            />
            <h6 className="text-primary-blue">
                Step: {step + 1}/{lastIndex + 1}
            </h6>
            <p className="mt-2">{currentStep.description}</p>
            <div className="block lg:hidden mt-8">{navigateButtons}</div>
        </>
    );
    return (
        <div className="container-height">
            <div className="flex items-center py-12">
                <div className="flex">
                    <IconButton onClick={goHome} className="mr-4" size="md" circle icon={<HomeIcon />} />
                    <h3 className="mr-1 text-gray-800">{currentStep.mainTitle}</h3>
                </div>
                {currentStep.mainTitleHint && (
                    <Whisper speaker={<Tooltip>{currentStep.mainTitleHint}</Tooltip>}>
                        <HelpOutlineIcon className="text-3xl" color="green" />
                    </Whisper>
                )}
            </div>

            <div className="flex justify-between flex-wrap">
                <div className="w-full lg:w-2/3">{currentStep.render(ref)}</div>

                <div className="w-80 lg:w-auto">
                    <Panel shaded className="hidden lg:block py-3 w-80">
                        {navigateButtonPanel}
                    </Panel>
                    <div className="block lg:hidden mt-12">{navigateButtonPanel}</div>
                </div>
            </div>
        </div>
    );
};

export default StepsContainer;
