import endOfDay from 'date-fns/endOfDay';
import intlFormat from 'date-fns/intlFormat';
import isPast from 'date-fns/isPast';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import { TFunction } from 'i18next';

import { CarEntityResponse, DisabledDatesResponse } from '../generated/api';

export class Utils {
    static generateCarName = (car?: CarEntityResponse): string => {
        if (!car) return '';
        return `${car.brandType} ${car.model} ${car.modification || ''} ${car.yearsOfIssue}`;
    };

    static nonNullable = <T>(value: T): value is NonNullable<T> => {
        return value !== null && value !== undefined;
    };

    static formatDate = (date: Date): string => {
        return intlFormat(
            date,
            { localeMatcher: 'lookup' },
            {
                locale: 'uk-UA',
            }
        );
    };
    static makeDataTranslated = (data: { label: string; value: string }[], t: TFunction) => {
        return data.map((item) => ({
            ...item,
            label: t(item.label),
        }));
    };

    static disabledRentRequestDates = (data: Date, disableDates?: DisabledDatesResponse[]) => {
        if (isPast(data)) {
            return true;
        }

        if (!disableDates) {
            return false;
        }

        return disableDates.some((disabledDate) => {
            return isWithinInterval(data, {
                start: startOfDay(new Date(disabledDate.from)),
                end: endOfDay(new Date(disabledDate.to)),
            });
        });
    };
}
