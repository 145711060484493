import { AutocompleteData } from '../types/types';

export const yearOfIssue: AutocompleteData[] = [];

for (let i = new Date().getFullYear(); i >= 1980; i--) {
    yearOfIssue.push({
        label: i.toString(),
        value: i.toString(),
    });
}

export const minAgeList: AutocompleteData[] = [];

for (let i = 16; i <= 40; i++) {
    minAgeList.push({
        label: i.toString(),
        value: i.toString(),
    });
}

export const minExperienceList: AutocompleteData[] = [];

for (let i = 1; i <= 15; i++) {
    minExperienceList.push({
        label: i.toString(),
        value: i.toString(),
    });
}

export const transmissions = [
    {
        label: 'transmissions.manual',
        value: 'Manual',
    },
    {
        label: 'transmissions.automatic',
        value: 'Automatic',
    },
    {
        label: 'transmissions.automatedManual',
        value: 'Automated Manual',
    },
    {
        label: 'transmissions.continuouslyVariable',
        value: 'Continuously Variable',
    },
];

export const getTransmissionLabelBy = (value: string): string => {
    return transmissions.find((transmission) => transmission.value === value)?.label || '';
};

export const fuels = [
    {
        label: 'fuels.petrol',
        value: 'Petrol',
    },
    {
        label: 'fuels.diesel',
        value: 'Diesel',
    },
    {
        label: 'fuels.cng',
        value: 'CNG',
    },
    {
        label: 'fuels.electric',
        value: 'Electric',
    },
    {
        label: 'fuels.bioDiesel',
        value: 'BioDiesel',
    },
    {
        label: 'fuels.lpg',
        value: 'LPG',
    },
    {
        label: 'fuels.ethanolMethanol',
        value: 'EthanolMethanol',
    },
    {
        label: 'fuels.other',
        value: 'Other',
    },
];

export const getFuelLabelBy = (value: string): string => {
    return fuels.find((fuel) => fuel.value === value)?.label || '';
};

export const citizenships = [
    {
        label: 'citizenships.ukrainian',
        value: 'ukrainian',
    },
    {
        label: 'citizenships.all',
        value: 'all',
    },
];

export const getCitizenshipsLabelBy = (value: string): string => {
    return citizenships.find((citizenship) => citizenship.value === value)?.label || '';
};

export const genders = [
    {
        label: 'genders.male',
        value: 'male',
    },
    {
        label: 'genders.female',
        value: 'female',
    },
];

export const getGenderLabelBy = (value: string): string => {
    return genders.find((gender) => gender.value === value)?.label || '';
};
