import { makeAutoObservable } from 'mobx';

export class AdditionalInfoStore {
    constructor() {
        makeAutoObservable(this);
    }

    transmission = '';
    fuel = '';
    doorNumber = '';
    weekPrice = '';
    consumption = '';
    mileage = '';
    dayPrice = '';
    engineCapacity = '';
    monthPrice?: string = '';

    setTransmission = (transmission: string) => {
        this.transmission = transmission;
    };
    setMileage = (mileage: string) => {
        this.mileage = mileage;
    };
    setFuel = (fuel: string) => {
        this.fuel = fuel;
    };
    setEngineCapacity = (engineCapacity: string) => {
        this.engineCapacity = engineCapacity;
    };
    setDoorNumber = (doorNumber: string) => {
        this.doorNumber = doorNumber;
    };
    setDayPrice = (dayPrice: string) => {
        this.dayPrice = dayPrice;
    };
    setWeekPrice = (weekPrice: string) => {
        this.weekPrice = weekPrice;
    };
    setMonthPrice = (monthPrice: string) => {
        this.monthPrice = monthPrice;
    };
    setConsumption = (consumption: string) => {
        this.consumption = consumption;
    };

    get formValue() {
        return {
            transmission: this.transmission,
            fuel: this.fuel,
            doorNumber: this.doorNumber,
            weekPrice: this.weekPrice,
            consumption: this.consumption,
            mileage: this.mileage,
            engineCapacity: this.engineCapacity,
            dayPrice: this.dayPrice,
            monthPrice: this.monthPrice,
        };
    }
}
