import SearchIcon from '@rsuite/icons/Search';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/Button';
import Table from 'rsuite/Table';

import RentRequestTile from '../../../components/Tiles/RentRequestTile';
import { RentRequestEntityResponse } from '../../../generated/api';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useStore } from '../../../hooks/useStore';
import emptyImage from '../../../images/box.png';
import { Utils } from '../../../stores/utils';
import { StatusText } from './RentRequestStatus';

const { Column, HeaderCell, Cell } = Table;

function ReceivedRentRequestTable() {
    const { rentRequestListStore } = useStore();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { formatDate } = useFormatDate();

    const navigateToCreateAdvert = () => {
        navigate('/car-owner-landing');
    };

    const EmptyNode = () => (
        <div className="flex items-center justify-center h-full flex-col mt-4 relative">
            <img className="w-16 h-16" src={emptyImage} alt="" />
            <div className="text-center mt-4">
                <p>{t('rentRequestPage.emptyReceivedNode.title')}</p>
                <div className="flex items-center justify-center">
                    <p className="text-base">
                        {t('rentRequestPage.emptyReceivedNode.makeSure')}{' '}
                        <a onClick={navigateToCreateAdvert}>{t('rentRequestPage.emptyReceivedNode.created')}</a>
                        {t('rentRequestPage.emptyReceivedNode.advert')}
                    </p>
                </div>
                <p>{t('rentRequestPage.emptyReceivedNode.resetFilter')}</p>
            </div>
        </div>
    );

    return (
        <>
            <div className="block md:hidden">
                {!rentRequestListStore.sortedReceivedRentRequests.length && EmptyNode()}
                {rentRequestListStore.sortedReceivedRentRequests.map((rentRequest) => (
                    <RentRequestTile key={rentRequest.id} rentRequestData={rentRequest} isSentTab={true} />
                ))}
            </div>
            <div className="hidden md:block">
                <Table
                    autoHeight
                    rowHeight={80}
                    height={650}
                    minHeight={650}
                    loading={rentRequestListStore.isLoading && !rentRequestListStore.receivedRentRequestList.length}
                    data={rentRequestListStore.sortedReceivedRentRequests}
                    renderEmpty={EmptyNode}
                    className="min-h-128"
                    sortColumn={rentRequestListStore.sortColumn}
                    sortType={rentRequestListStore.sortType}
                    onSortColumn={rentRequestListStore.handleSortColumn}
                >
                    <Column width={40} align="center" verticalAlign="middle">
                        <HeaderCell className="text-base">#</HeaderCell>
                        <Cell>{(rowData: RentRequestEntityResponse, rowIndex: number) => rowIndex + 1}</Cell>
                    </Column>
                    <Column minWidth={130} align="center" flexGrow={2} verticalAlign="middle" sortable>
                        <HeaderCell className="text-base">{t('myAdverts.car')}</HeaderCell>
                        <Cell dataKey="carName">
                            {(rowData: RentRequestEntityResponse) => (
                                <Button
                                    appearance="link"
                                    className="text-base"
                                    onClick={() => {
                                        navigate(`/advert-details/${rowData.advert.slug}`);
                                    }}
                                >
                                    {Utils.generateCarName(rowData.advert.car)}
                                </Button>
                            )}
                        </Cell>
                    </Column>
                    <Column align="center" verticalAlign="middle">
                        <HeaderCell className="text-base">{t('myAdverts.photo')}</HeaderCell>
                        <Cell>
                            {(rowData: RentRequestEntityResponse) => (
                                <img className="w-16 h-16 object-cover" src={rowData.advert.car.mainPhoto} alt="" />
                            )}
                        </Cell>
                    </Column>
                    <Column minWidth={190} align="center" flexGrow={2} verticalAlign="middle">
                        <HeaderCell className="text-base">{t('myRentRequests.period')}</HeaderCell>
                        <Cell>
                            {(rowData: RentRequestEntityResponse) => {
                                const { from, to } = rowData;
                                return `${formatDate(new Date(from))} - ${formatDate(new Date(to))}`;
                            }}
                        </Cell>
                    </Column>
                    <Column minWidth={130} align="center" flexGrow={2} verticalAlign="middle">
                        <HeaderCell className="text-base">{t('myRentRequests.pickUpAddress')}</HeaderCell>
                        <Cell dataKey="address" />
                    </Column>

                    <Column minWidth={110} align="center" flexGrow={1} verticalAlign="middle" sortable>
                        <HeaderCell className="text-base">{t('myRentRequests.status')}</HeaderCell>
                        <Cell dataKey="status">{StatusText}</Cell>
                    </Column>

                    <Column minWidth={110} align="center" flexGrow={1} verticalAlign="middle" sortable>
                        <HeaderCell className="text-base">{t('myRentRequests.created')}</HeaderCell>
                        <Cell dataKey="createdAt">
                            {(rowData: RentRequestEntityResponse) => {
                                const { createdAt } = rowData;
                                return formatDate(new Date(createdAt));
                            }}
                        </Cell>
                    </Column>

                    <Column minWidth={80} align="center" verticalAlign="middle" sortable>
                        <HeaderCell className="text-base">{t('myRentRequests.income')}</HeaderCell>
                        <Cell dataKey="totalPrice">
                            {(rowData: RentRequestEntityResponse) => {
                                return t('global.price', { price: rowData.totalPrice });
                            }}
                        </Cell>
                    </Column>

                    <Column align="center" width={120} verticalAlign="middle">
                        <HeaderCell className="text-base">...</HeaderCell>
                        <Cell>
                            {(rowData: RentRequestEntityResponse) => {
                                return (
                                    <Button
                                        className="text-base"
                                        size="sm"
                                        appearance="ghost"
                                        onClick={() => rentRequestListStore.reviewRentRequest(rowData)}
                                    >
                                        <SearchIcon /> {t('myRentRequests.review')}
                                    </Button>
                                );
                            }}
                        </Cell>
                    </Column>
                </Table>
            </div>
        </>
    );
}

export default observer(ReceivedRentRequestTable);
