import EmailFillIcon from '@rsuite/icons/EmailFill';
import PhoneFillIcon from '@rsuite/icons/PhoneFill';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Form, Modal } from 'rsuite';
import Button from 'rsuite/Button';
import Message from 'rsuite/Message';
import Panel from 'rsuite/Panel';
import Schema from 'rsuite/Schema';
import toaster from 'rsuite/toaster';

import { useStore } from '../../hooks/useStore';
import telegram from '../../images/logos/telegram.png';
import viber from '../../images/logos/viber.png';
import whatsapp from '../../images/logos/whatsapp.png';
import { QueryStore } from '../../stores/Query.store';
import { Textarea } from '../Textarea/Textarea';

const { StringType } = Schema.Types;

const FeedbackModal: React.FC = () => {
    const [feedbackOpen, setFeedbackOpen] = React.useState(false);
    const { feedbackStore } = useStore();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const handleFeedBackOpen = () => setFeedbackOpen(true);
    const handleFeedBackClose = () => setFeedbackOpen(false);

    const feedbackSchema = useMemo(
        () =>
            Schema.Model({
                feedback: StringType().isRequired(t('formErrors.isRequired') || ''),
                contact: StringType(),
            }),
        [t]
    );

    const onSubmit = async (checkStatus: boolean) => {
        if (checkStatus) {
            await feedbackStore.createFeedback();
            if (feedbackStore.errorStatus) {
                QueryStore.handleError(navigate, feedbackStore.errorStatus);
            } else {
                toaster.push(
                    <Message showIcon type="success">
                        {t('feedback.success')}
                    </Message>
                );
            }
            handleFeedBackClose();
        }
    };

    return (
        <>
            <Panel className="m-2" bordered shaded header={t('feedback.title')}>
                <div>{t('feedback.subtitle')}</div>
                <Button className="mt-4" appearance="ghost" onClick={handleFeedBackOpen}>
                    {t('global.send')}
                </Button>
            </Panel>
            <Modal open={feedbackOpen} onClose={handleFeedBackClose}>
                <Form model={feedbackSchema} autoComplete="off" onSubmit={onSubmit} formValue={feedbackStore.formState}>
                    <Modal.Header>
                        <h5>{t('feedback.title')}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-base mb-4">{t('feedback.subtitle')}</div>

                        <Form.Group>
                            <Form.ControlLabel>{t('feedback.contact')}</Form.ControlLabel>

                            <Form.Control
                                value={feedbackStore.contact}
                                onChange={feedbackStore.setContact}
                                placeholder={t('feedback.emailOrPhone')}
                                name="contact"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>{t('feedback.feedback')}</Form.ControlLabel>

                            <Form.Control
                                placeholder={t('feedback.inputField')}
                                name="feedback"
                                accepter={Textarea}
                                rows={4}
                                value={feedbackStore.feedback}
                                onChange={feedbackStore.setFeedback}
                                style={{ width: isMobile ? '' : '32rem' }}
                            />
                            <Form.HelpText>{t('global.required')}</Form.HelpText>
                        </Form.Group>

                        <div className="mb-4">
                            <h6 className="mb-2">{t('feedback.ourContacts')}:</h6>
                            <div className="my-2">
                                <EmailFillIcon className="mr-2" />
                                contact@roadieze.com
                            </div>
                            <div className="flex items-center">
                                <PhoneFillIcon className="mr-2" />
                                +38 (093) 189 0673
                                <div className="flex ml-2 gap-1">
                                    <img
                                        className="w-6 cursor-pointer"
                                        src={viber}
                                        onClick={() => (window.location.href = 'viber://chat?number=380931890673')}
                                        alt=""
                                    />
                                    <img
                                        className="w-6 cursor-pointer"
                                        src={telegram}
                                        onClick={() => (window.location.href = 'https://t.me/Roadieze')}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" appearance="primary">
                            {t('global.send')}
                        </Button>
                        <Button onClick={handleFeedBackClose} appearance="subtle">
                            {t('global.cancel')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default observer(FeedbackModal);
