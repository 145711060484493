import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import { useNavigate } from 'react-router-dom';

import { Container } from '../../../components/Container';
import { bodyTypeData } from '../../../data/bodyType.data';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

function SearchByBodyType() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const moveToFilterPage = (bodyTypeValue: string) => {
        navigate(`/filter?bodyTypes=${bodyTypeValue}`);
    };

    return (
        <Container className="mt-24">
            <h3 className="mb-12">{t('mainPage.searchBodyType')}</h3>
            <Carousel swipeable={false} draggable={false} responsive={responsive} itemClass="px-1">
                {bodyTypeData.map((bodyTypeInfo) => (
                    <div
                        className="cursor-pointer flex items-center justify-center rounded-lg border-gray-400 border-2 border-solid h-32"
                        key={bodyTypeInfo.label}
                        onClick={() => moveToFilterPage(bodyTypeInfo.value)}
                    >
                        <img className="w-2/5" src={bodyTypeInfo.url} alt="" />
                        <p className="absolute bottom-4">{t(bodyTypeInfo.label)}</p>
                    </div>
                ))}
            </Carousel>
        </Container>
    );
}

export default observer(SearchByBodyType);
