import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'rsuite/esm/Form';
import Schema from 'rsuite/Schema';

import FormRef, { FormRefType } from '../../../components/Steps/FormRef';
import { Textarea } from '../../../components/Textarea/Textarea';
import { DescriptionStore } from '../../../stores/add-advert-store/Description.store';

interface Props {
    descriptionStore: DescriptionStore;
}

const { StringType } = Schema.Types;

const Description = React.forwardRef<FormRefType, Props>(({ descriptionStore }, ref) => {
    const { t } = useTranslation();

    const descriptionSchema = useMemo(
        () =>
            Schema.Model({
                description: StringType()
                    .isRequired(t('formErrors.isRequired') || '')
                    .maxLength(5000, t('formErrors.maxSymbol', { amount: '5 000' }) || '')
                    .minLength(120, t('formErrors.minSymbol', { amount: '120' }) || ''),
            }),
        []
    );

    return (
        <FormRef fluid model={descriptionSchema} ref={ref} formValue={descriptionStore.formValue}>
            <div className="text-base mb-8">{t('addAdvert.carDescription.recommendation')}</div>
            <Form.Group controlId="textarea">
                <Form.Control
                    rows={10}
                    value={descriptionStore.description}
                    name="description"
                    accepter={Textarea}
                    onChange={descriptionStore.setDescription}
                    placeholder={t('addAdvert.carDescription.placeholder')}
                />
                <Form.HelpText className="text-right pt-2">{descriptionStore.descriptionLength}/5000</Form.HelpText>
            </Form.Group>
        </FormRef>
    );
});

export default observer(Description);
