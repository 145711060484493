import CheckRoundIcon from '@rsuite/icons/CheckRound';
import HistoryIcon from '@rsuite/icons/History';
import RemindRoundIcon from '@rsuite/icons/RemindRound';
import WarningRoundIcon from '@rsuite/icons/WarningRound';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Popover from 'rsuite/esm/Popover';
import Whisper from 'rsuite/esm/Whisper';

import { RentRequestEntityResponse, RentRequestEntityResponseStatus } from '../../../generated/api';
import { useFormatDate } from '../../../hooks/useFormatDate';

export const MapRentRequestStatusValue = {
    [RentRequestEntityResponseStatus.Approved]: 'rentRequestStatus.approved',
    [RentRequestEntityResponseStatus.WaitingForReview]: 'rentRequestStatus.awaiting',
    [RentRequestEntityResponseStatus.Declined]: 'rentRequestStatus.declined',
    [RentRequestEntityResponseStatus.Completed]: 'rentRequestStatus.completed',
    [RentRequestEntityResponseStatus.Active]: 'rentRequestStatus.active',
};

export const StatusText = (rowData: RentRequestEntityResponse) => {
    const { status, declineReasonHeader, declineReasonMessage } = rowData;
    const isLaptop = useMediaQuery({ maxWidth: 1023 });
    const { t } = useTranslation();
    const { formatDateWithWeek } = useFormatDate();

    const placement = isLaptop ? 'bottom' : undefined;

    const declineSpeaker = (
        <Popover className="text-sm w-72" title={declineReasonHeader}>
            <p className="text-sm">
                <h6 className="text-sm">{t('rentRequestStatus.reason')}</h6> {declineReasonMessage}
            </p>
        </Popover>
    );

    const approvedSpeaker = (
        <Popover className="text-sm w-72" title={t('rentRequestStatus.approvedSpeaker.title')}>
            <p className="text-sm">{t('rentRequestStatus.approvedSpeaker.needToAgreed')}</p>
            <p className="text-sm">
                <h6 className="text-sm">{t('rentRequestStatus.approvedSpeaker.where')}</h6> {rowData.address}
            </p>
            <p className="text-sm">
                <h6 className="text-sm">{t('rentRequestStatus.approvedSpeaker.when')}</h6>
                {formatDateWithWeek(new Date(rowData.from))} {rowData.pickUpTime}
            </p>
        </Popover>
    );

    const activeSpeaker = (
        <Popover className="text-sm w-72" title={t('rentRequestStatus.activeSpeaker.carRenter')}>
            <p className="text-sm">
                {t('rentRequestStatus.activeSpeaker.carBusy', {
                    from: formatDateWithWeek(new Date(rowData.from)),
                    to: formatDateWithWeek(new Date(rowData.to)),
                })}
            </p>
        </Popover>
    );

    switch (status) {
        case RentRequestEntityResponseStatus.Approved:
            return (
                <Whisper placement={placement} trigger="hover" speaker={approvedSpeaker}>
                    <div className="py-1">
                        <CheckRoundIcon className="text-green-600 text-xl mr-2" />
                        {t(MapRentRequestStatusValue[status])}
                    </div>
                </Whisper>
            );
        case RentRequestEntityResponseStatus.Active:
            return (
                <Whisper placement={placement} trigger="hover" speaker={activeSpeaker}>
                    <div className="py-1">
                        <HistoryIcon className="text-blue-400 text-xl mr-2" />
                        {t(MapRentRequestStatusValue[status])}
                    </div>
                </Whisper>
            );
        case RentRequestEntityResponseStatus.WaitingForReview:
            return (
                <>
                    <RemindRoundIcon className="text-blue-300 text-xl mr-2" />
                    {t(MapRentRequestStatusValue[status])}
                </>
            );
        case RentRequestEntityResponseStatus.Declined:
            return (
                <Whisper placement={placement} trigger="hover" speaker={declineSpeaker}>
                    <div className="py-1">
                        <WarningRoundIcon className="text-red-300 text-xl mr-2" />
                        {t(MapRentRequestStatusValue[status])}
                    </div>
                </Whisper>
            );
        case RentRequestEntityResponseStatus.Completed:
            return (
                <>
                    <CheckRoundIcon className="text-blue-500 text-xl mr-2" />
                    {t(MapRentRequestStatusValue[status])}
                </>
            );
    }
};
