import DocumentCheckIcon from '@heroicons/react/24/outline/DocumentCheckIcon';
import DocumentMagnifyingGlassIcon from '@heroicons/react/24/outline/DocumentMagnifyingGlassIcon';
import DocumentMinusIcon from '@heroicons/react/24/outline/DocumentMinusIcon';
import UserInfo from '@rsuite/icons/UserInfo';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TypeAttributes } from 'rsuite/esm/@types/common';
import Popover from 'rsuite/Popover';
import Whisper from 'rsuite/Whisper';

import { FullAdvertEntityResponse } from '../../generated/api';

interface Options {
    width: number;
    placement: TypeAttributes.Placement;
    hasNextStepsText: boolean;
}

export const carVerificationStatus = (
    advert: FullAdvertEntityResponse,
    { width, hasNextStepsText, placement }: Options
) => {
    const { t } = useTranslation();

    const carVerifiedInProgressPopover = (
        <Popover className="text-sm w-72" title={t('verificationStatus.checkingUser')}></Popover>
    );

    const carVerifiedPopover = <Popover className="text-sm w-72" title={t('verificationStatus.carVerified')}></Popover>;

    const carNotVerifiedPopover = (
        <Popover className="text-sm w-72" title={t('verificationStatus.carNotVerified.title')}>
            {hasNextStepsText && (
                <>
                    <p className="text-sm">{t('verificationStatus.carNotVerified.sendDocuments')}</p>

                    <div className="text-sm mt-2">
                        <h6 className="text-sm">{t('global.nextSteps')}</h6>
                        <p className="mt-0">{t('verificationStatus.carNotVerified.clickStep')}</p>
                        <p className="mt-0">{t('verificationStatus.carNotVerified.verifyCarStep')}</p>
                    </div>
                </>
            )}
        </Popover>
    );

    if (!advert.car.isVerified) {
        if (advert.documentsSent) {
            return (
                <Whisper placement={placement} speaker={carVerifiedInProgressPopover}>
                    <div className="flex items-center justify-center py-1">
                        <DocumentMagnifyingGlassIcon width={width} height={width} className="text-blue-400 text-xl" />
                    </div>
                </Whisper>
            );
        }
        return (
            <Whisper placement={placement} speaker={carNotVerifiedPopover}>
                <div className="flex items-center justify-center py-1">
                    <DocumentMinusIcon width={width} height={width} className="text-red-500 text-xl" />
                </div>
            </Whisper>
        );
    } else {
        return (
            <Whisper placement={placement} speaker={carVerifiedPopover}>
                <div className="flex items-center justify-center py-1">
                    <DocumentCheckIcon width={width} height={width} className="text-green-600 text-xl" />
                </div>
            </Whisper>
        );
    }
};

export const verificationStatus = (
    advert: FullAdvertEntityResponse,
    { width, hasNextStepsText = false, placement }: Options
) => {
    const { t } = useTranslation();

    const userNotVerifiedPopover = (
        <Popover className="text-sm w-72" title={t('verificationStatus.userNotVerified.accountNotVerified')}>
            {hasNextStepsText && (
                <>
                    <p className="text-sm">{t('verificationStatus.userNotVerified.sendDocuments')}</p>

                    <div className="text-sm mt-2">
                        <h6 className="text-sm">{t('global.nextSteps')}</h6>
                        <p className="mt-0">{t('verificationStatus.userNotVerified.myAccountStep')}</p>
                        <p className="mt-0">{t('verificationStatus.userNotVerified.myProfileStep')}</p>
                        <p className="mt-0">{t('verificationStatus.userNotVerified.verifyProfileStep')}</p>
                    </div>
                </>
            )}
        </Popover>
    );

    return (
        <>
            {advert.creator.isVerified ? (
                <Whisper
                    placement={placement}
                    speaker={
                        <Popover className="text-sm w-72" title={t('verificationStatus.accountVerified')}></Popover>
                    }
                >
                    <div className="flex items-center justify-center py-1">
                        <UserInfo width={width} height={width} className="text-green-500 text-xl mr-1" />
                    </div>
                </Whisper>
            ) : (
                <Whisper placement={placement} speaker={userNotVerifiedPopover}>
                    <div className="flex items-center justify-center py-1">
                        <UserInfo width={width} height={width} className="text-red-500 text-xl mr-1" />
                    </div>
                </Whisper>
            )}
            {carVerificationStatus(advert, { width: width + 2, placement, hasNextStepsText })}
        </>
    );
};
