import Calendar from '@rsuite/icons/Calendar';
import Growth from '@rsuite/icons/Growth';
import HelpOutline from '@rsuite/icons/HelpOutline';
import LocationIcon from '@rsuite/icons/Location';
import TimeIcon from '@rsuite/icons/Time';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/Button';
import Modal from 'rsuite/esm/Modal';
import Message from 'rsuite/Message';
import toaster from 'rsuite/toaster';

import AvatarPreview from '../../../components/AvatarPreview/AvatarPreview';
import { MapAdvertStatusValue } from '../../../components/MyAdvertStatus/MyAdvertStatus';
import { AdvertEntityResponseAdvertStatus, RentRequestEntityResponseStatus } from '../../../generated/api';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useStore } from '../../../hooks/useStore';
import { QueryStore } from '../../../stores/Query.store';
import { Utils } from '../../../stores/utils';
import DeclineModal from './DeclineModal';
import { MapRentRequestStatusValue } from './RentRequestStatus';

const AttributeContainer: React.FC = (props) => <div className="flex items-center mb-4">{props.children}</div>;

const Information = (label: string, value: string, icon: React.ReactElement) => (
    <AttributeContainer>
        {icon}
        <p className="text-base mx-2 text-black">{label}</p>
        <p className="text-base mt-0 text-gray-400">{value}</p>
    </AttributeContainer>
);

interface Props {
    type: 'received' | 'sent';
}

function RentRequestModal(props: Props) {
    const { rentRequestListStore } = useStore();
    const [openDeclineModal, setOpenDeclineModal] = React.useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { formatDateWithWeek } = useFormatDate();

    const reviewedRentRequest = rentRequestListStore.reviewedRentRequest;
    const car = reviewedRentRequest?.advert.car;
    const creator = reviewedRentRequest?.creator;

    const handleClose = () => {
        rentRequestListStore.closeRentRequest();
    };

    const userOrCreator = props.type === 'received' ? t('rentRequestModal.creator') : t('rentRequestModal.user');

    const approve = async () => {
        try {
            await rentRequestListStore.activateRentRequest();
            toaster.push(
                <Message showIcon type="success">
                    {t('rentRequestModal.approved')}
                </Message>
            );
            handleClose();
            // TODO: HIDE IT BACK
            await rentRequestListStore.fetchReceivedRentRequest();
            await rentRequestListStore.fetchSentRentRequest();

            QueryStore.handleError(navigate, rentRequestListStore.errorStatus);
        } catch (error) {
            handleClose();
            toaster.push(
                <Message showIcon type="error" header="Error">
                    {t('global.somethingWentWrong')}
                </Message>
            );
        }
    };

    const rentingDate = reviewedRentRequest
        ? `${formatDateWithWeek(new Date(reviewedRentRequest?.from))} - ${formatDateWithWeek(
              new Date(reviewedRentRequest?.to)
          )}`
        : '';
    const createdAt = reviewedRentRequest ? formatDateWithWeek(new Date(reviewedRentRequest?.createdAt)) : '';

    const showApproveButton = props.type === 'received' && rentRequestListStore.canActivateCurrentRentRequest;

    const showDeclineButton =
        reviewedRentRequest?.status === RentRequestEntityResponseStatus.WaitingForReview ||
        reviewedRentRequest?.status === RentRequestEntityResponseStatus.Approved;

    return (
        <Modal size="md" open={Boolean(reviewedRentRequest)} onClose={handleClose}>
            <Modal.Header>
                <h5>{Utils.generateCarName(car)}</h5>
            </Modal.Header>
            <Modal.Body className="p-4">
                <div className="mb-8">
                    <img className="h-60" src={car?.mainPhoto} alt="" />
                </div>

                {reviewedRentRequest &&
                    reviewedRentRequest.status === RentRequestEntityResponseStatus.WaitingForReview &&
                    reviewedRentRequest?.advert.advertStatus !== AdvertEntityResponseAdvertStatus.Active &&
                    reviewedRentRequest?.advert.advertStatus !== AdvertEntityResponseAdvertStatus.InRenting && (
                        <Message className="text-base" type="error">
                            <p>
                                {t('rentRequestModal.cantApprove', {
                                    status: t(MapAdvertStatusValue[reviewedRentRequest.advert.advertStatus]),
                                })}
                            </p>
                        </Message>
                    )}
                {reviewedRentRequest?.declineReasonHeader && reviewedRentRequest?.declineReasonMessage && (
                    <Message className="text-base" type="error">
                        <h6>{reviewedRentRequest.declineReasonHeader}</h6>
                        <p>{reviewedRentRequest.declineReasonMessage}</p>
                    </Message>
                )}
                {reviewedRentRequest?.status === RentRequestEntityResponseStatus.Approved && (
                    <Message className="text-base" type="success">
                        {t('rentRequestModal.hasBeenApproved')}
                    </Message>
                )}

                {reviewedRentRequest?.status === RentRequestEntityResponseStatus.WaitingForReview &&
                    rentRequestListStore.getOverlappingRentRequests && (
                        <Message showIcon type="error" header={t('rentRequestModal.periodBooked')}>
                            {t('rentRequestModal.cantApproveBecauseWaiting')}
                            <b>
                                {t('rentRequestModal.rentRequest', {
                                    amount: rentRequestListStore.getOverlappingRentRequests.length,
                                })}
                            </b>
                            {t('rentRequestModal.withPeriod')}
                        </Message>
                    )}

                <div className="bg-gray-100 border border-solid border-gray-200 rounded-lg p-4 w-full md:w-9/12 my-8">
                    <h6 className="text-lg mb-4">{t('rentRequestModal.RRInfo')}</h6>

                    {Information(t('rentRequestModal.rentingDate'), rentingDate, <Calendar className="text-lg" />)}
                    {reviewedRentRequest?.pickUpTime &&
                        Information(
                            t('rentRequestModal.pickUpTime'),
                            reviewedRentRequest.pickUpTime,
                            <TimeIcon className="text-lg" />
                        )}
                    {reviewedRentRequest?.address &&
                        Information(
                            t('rentRequestModal.address'),
                            reviewedRentRequest.address,
                            <LocationIcon className="text-lg" />
                        )}
                    {reviewedRentRequest?.totalPrice &&
                        Information(
                            t('rentRequestModal.income'),
                            t('global.price', { price: reviewedRentRequest.totalPrice }),
                            <Growth className="text-lg" />
                        )}
                    {reviewedRentRequest?.status &&
                        Information(
                            t('rentRequestModal.status'),
                            t(MapRentRequestStatusValue[reviewedRentRequest.status]),
                            <HelpOutline className="text-lg" />
                        )}
                </div>

                {reviewedRentRequest?.description && (
                    <div className="my-8 text-base">
                        <h6 className="mb-2">{t('rentRequestModal.description')}</h6>
                        <p>{reviewedRentRequest.description}</p>
                    </div>
                )}
                {props.type === 'received' && (
                    <div className="my-8">
                        <h6 className="text-lg mb-4">{t('rentRequestModal.userDetails')}</h6>

                        <div className="flex">
                            <div className="w-16 h-16">
                                <AvatarPreview
                                    avatar={creator?.avatar}
                                    lastName={creator?.lastName || ''}
                                    firstName={creator?.firstName || ''}
                                />
                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <Button
                                    appearance="link"
                                    className="flex text-base items-center"
                                    onClick={() => {
                                        if (creator?.id) {
                                            navigate(`/user-details/${creator.id}`);
                                        }
                                    }}
                                >
                                    <p className="ml-2">
                                        {creator?.firstName} {creator?.lastName}
                                    </p>
                                </Button>
                                <p className="text-base text-black">{creator?.phoneNumber}</p>
                            </div>
                        </div>
                    </div>
                )}

                <div className="text-base">
                    {t('rentRequestModal.createdDate')} {createdAt}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {showApproveButton && (
                    <Button
                        onClick={approve}
                        appearance="primary"
                        disabled={Boolean(rentRequestListStore.getOverlappingRentRequests)}
                    >
                        {t('global.approve')}
                    </Button>
                )}
                {showDeclineButton && (
                    <Button
                        onClick={() => {
                            setOpenDeclineModal(true);
                        }}
                        appearance="ghost"
                    >
                        {t('global.decline')}
                    </Button>
                )}
                <Button onClick={handleClose} appearance="subtle">
                    {t('global.cancel')}
                </Button>
            </Modal.Footer>

            <DeclineModal
                open={openDeclineModal}
                close={() => setOpenDeclineModal(false)}
                onSubmit={handleClose}
                userOrCreator={userOrCreator}
            />
        </Modal>
    );
}

export default observer(RentRequestModal);
