export const cities = [
    { label: 'cities.lviv', value: 'lviv' },
    { label: 'cities.kyiv', value: 'kyiv' },
    { label: 'cities.kharkiv', value: 'kharkiv' },
    { label: 'cities.odesa', value: 'odesa' },
    { label: 'cities.dnipro', value: 'dnipro' },
    { label: 'cities.zaporizhzhia', value: 'zaporizhzhia' },
    { label: 'cities.krivyiRig', value: 'krivyi-rig' },
    { label: 'cities.mykolaiv', value: 'mykolaiv' },
    { label: 'cities.vinnytsia', value: 'vinnytsia' },
    { label: 'cities.kherson', value: 'kherson' },
    { label: 'cities.poltava', value: 'poltava' },
    { label: 'cities.chernihiv', value: 'chernihiv' },
    { label: 'cities.cherkasy', value: 'cherkasy' },
    { label: 'cities.zhytomyr', value: 'zhytomyr' },
    { label: 'cities.sumy', value: 'sumy' },
    { label: 'cities.khmelnytskyi', value: 'khmelnytskyi' },
    { label: 'cities.rivne', value: 'rivne' },
    { label: 'cities.ivanoFrankivsk', value: 'ivano-frankivsk' },
    { label: 'cities.ternopil', value: 'ternopil' },
    { label: 'cities.lutsk', value: 'lutsk' },
    { label: 'cities.bilaTserkva', value: 'bila-tserkva' },
    { label: 'cities.uzhhorod', value: 'uzhhorod' },
];

export const getCityLabelByValue = (value: string) => {
    return cities.find((city) => city.value === value)?.label || '';
};
