import FolderOpenIcon from '@heroicons/react/24/solid/FolderOpenIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'rsuite/esm/Button';

import BoxTile from '../../../components/Tiles/BoxTile';
import TallTile from '../../../components/Tiles/TallTile';
import { FullAdvertEntityResponse } from '../../../generated/api';
import { useStore } from '../../../hooks/useStore';
import { SwitcherType } from './TileSwitcher';

interface Props {
    tileType: SwitcherType;
    adverts: FullAdvertEntityResponse[];
}

const FoundList: React.FC<Props> = ({ tileType, adverts }) => {
    const { fullFilter } = useStore();
    const { t } = useTranslation();

    return (
        <div className="flex flex-wrap">
            {!adverts.length && (
                <div className="flex h-72 items-center justify-center flex-col w-full">
                    <FolderOpenIcon className="h-12 w-12 mb-12" />
                    <h5 className="text-center">{t('filterPage.notFound.title')}</h5>
                    <p className="text-base">{t('filterPage.notFound.subtitle')}</p>
                    <Button className="text-base my-4" size="md" appearance="ghost" onClick={fullFilter.checkOutAll}>
                        {t('filterPage.notFound.reset')}
                    </Button>
                </div>
            )}
            {adverts.map((advert) => (
                <React.Fragment key={advert.id}>
                    {tileType === 'boxSwitcher' ? (
                        <div className="pl-4 pb-4 w-full md:w-6/12">
                            <BoxTile data={advert} />
                        </div>
                    ) : (
                        <TallTile data={advert} />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default FoundList;
