import './AccountContainer.styles.scss';

import React from 'react';

import Footer from '../../../components/Footer';

interface Props {
    image: string;
}

const AccountContainer: React.FC<Props> = (props) => {
    return (
        <div>
            <div className="flex account_container">
                <div className="w-6/12">
                    <img className="h-full w-full object-cover" src={props.image} alt="AuthorizationCar" />
                </div>
                <div className="flex items-center content-center mx-4 md:ml-10 md:mr-0">{props.children}</div>
            </div>

            <Footer />
        </div>
    );
};

export default AccountContainer;
