import GoogleMapReact from 'google-map-react';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import ReactGA from 'react-ga4';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import AutoComplete from 'rsuite/AutoComplete';
import Button from 'rsuite/Button';
import Checkbox from 'rsuite/Checkbox';
import { ItemDataType } from 'rsuite/cjs/@types/common';
import RsuiteDateRangePicker from 'rsuite/DateRangePicker';
import DatePicker from 'rsuite/esm/DatePicker';
import Form from 'rsuite/Form';
import Message from 'rsuite/Message';
import Schema from 'rsuite/Schema';
import toaster from 'rsuite/toaster';

import { Textarea } from '../../../components/Textarea/Textarea';
import config from '../../../config';
import { GAActions, GACategories } from '../../../constants';
import { useStore } from '../../../hooks/useStore';
import { Utils } from '../../../stores/utils';
import NextStepModal from './NextStepModal';

const { StringType, ArrayType, BooleanType, DateType } = Schema.Types;

const Marker: React.FC<{ lat: number; lng: number }> = () => <div className="bg-red-600 w-4 h-4 rounded-full" />;

const SendRentRequestForm: React.FC = () => {
    const { rentRequestStore, userStore } = useStore();
    const [open, setOpen] = React.useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { t } = useTranslation();

    const sendRequestSchema = useMemo(
        () =>
            Schema.Model({
                message: StringType().maxLength(500, t('formErrors.maxSymbol', { amount: '500' }) || ''),
                dateRange: ArrayType().isRequired(t('formErrors.isRequired') || ''),
                time: DateType().isRequired(t('formErrors.isRequired') || ''),
                isPhoneNumberShared: BooleanType()
                    .isRequired(t('formErrors.isRequired') || '')
                    .addRule((value: boolean) => value, t('formErrors.mustBeChecked') || ''),
                termAndConditional: BooleanType()
                    .isRequired(t('formErrors.isRequired') || '')
                    .addRule((value: boolean) => value, t('formErrors.mustBeChecked') || ''),
                city: StringType().isRequired(t('formErrors.isRequired') || ''),
                address: StringType()
                    .isRequired(t('formErrors.isRequired') || '')
                    .maxLength(200, t('formErrors.maxSymbol', { amount: '200' }) || ''),
            }),
        [t]
    );

    const handleOpenNextStepModal = () => setOpen(true);
    const handleClose = () => {
        navigate(`/rent-request-list/sent`);
        setOpen(false);
    };

    const navigate = useNavigate();

    const navigateToTermsAndConditions = () => {
        window.open('/terms-and-conditions', '_blank');
    };

    const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
        apiKey: config.googleMapsApiKey,
        options: {
            componentRestrictions: { country: 'ua' },
            fields: ['geometry.location', 'address_components', 'formatted_address'],
        },
        language: 'uk',
    });

    const send = async (checkStatus: boolean) => {
        if (checkStatus) {
            if (!userStore.user) {
                navigate('/login', {
                    state: {
                        redirectBack: `/send-rent-request/${
                            rentRequestStore.advertDetailsStore.advertDetails?.slug || ''
                        }`,
                    },
                });

                ReactGA.event({
                    category: GACategories.RentRequest,
                    action: GAActions[GACategories.RentRequest].loginBeforeCreateRentRequest,
                });
                return;
            }
            try {
                await rentRequestStore.createRentRequest();
                handleOpenNextStepModal();
            } catch (error) {
                toaster.push(
                    <Message showIcon type="error" header="Error">
                        {t('global.somethingWentWrong')}
                    </Message>
                );
            }
        }
    };

    const data = placePredictions.map(
        (pl): ItemDataType<string> => ({
            value: pl.description as string,
            label: pl.description as string,
            placeId: pl.place_id as string,
        })
    );

    const onSelectHandler = (address: string, item: ItemDataType<string>) => {
        if (placePredictions.length)
            placesService?.getDetails(
                {
                    placeId: item.placeId,
                },
                (placeDetails: {
                    formatted_address: string;
                    geometry: { location: { lat(): number; lng(): number } };
                }) =>
                    rentRequestStore.setPlaceDetails({
                        ...placeDetails?.geometry.location,
                        formattedAddress: placeDetails.formatted_address,
                    })
            );
    };

    const DateRangePicker: React.FC<any> = React.forwardRef((props, ref) => (
        <RsuiteDateRangePicker {...props} className="w-96" ref={ref} />
    ));

    const isUserCreator = userStore.user?.id === rentRequestStore.advertDetailsStore.advertDetails?.creator.id;
    return (
        <>
            <Form
                className="text-base"
                autoComplete="off"
                fluid
                layout="vertical"
                model={sendRequestSchema}
                formValue={rentRequestStore.defaultFormValue}
                onSubmit={send}
            >
                <div className="flex flex-col md:flex-row md:w-128 justify-between">
                    <Form.Group>
                        <Form.ControlLabel>{t('reservationPage.dateRange')}</Form.ControlLabel>
                        <Form.Control
                            value={rentRequestStore.dateRange}
                            onChange={rentRequestStore.setDateRange as any}
                            accepter={DateRangePicker}
                            showOneCalendar={isMobile}
                            name="dateRange"
                            size="lg"
                            shouldDisableDate={(data: Date) =>
                                Utils.disabledRentRequestDates(
                                    data,
                                    rentRequestStore.advertDetailsStore.advertDetails?.disabledDates
                                )
                            }
                        />
                    </Form.Group>
                    <Form.Group className="lg:ml-2 mb-8 lg:mb-0">
                        <Form.ControlLabel>{t('reservationPage.pickUpTime')}</Form.ControlLabel>
                        <Form.Control
                            name="time"
                            placeholder="15:00"
                            size="lg"
                            value={rentRequestStore.time}
                            hideMinutes={(minute: number) => {
                                return Boolean(minute % 10);
                            }}
                            onChange={rentRequestStore.setTime as any}
                            ranges={[]}
                            accepter={DatePicker}
                            format="HH:mm"
                        />
                    </Form.Group>
                </div>

                <Form.Group>
                    <Form.ControlLabel>{t('params.city')}</Form.ControlLabel>
                    <Form.Control
                        name="city"
                        size="lg"
                        className="md:w-128"
                        value={rentRequestStore.defaultFormValue.city}
                        disabled
                    />
                </Form.Group>

                <Form.Group>
                    <Form.ControlLabel>{t('reservationPage.pickUpAddress')}</Form.ControlLabel>
                    <Form.Control
                        name="address"
                        placeholder={t('reservationPage.pickUpAddressPlaceholder')}
                        size="lg"
                        className="md:w-128"
                        accepter={AutoComplete}
                        onChange={(address: string) => {
                            rentRequestStore.onAddressChange(address);
                            getPlacePredictions({ input: address });
                        }}
                        value={rentRequestStore.address}
                        onSelect={onSelectHandler as any}
                        filterBy={() => true}
                        data={data}
                    />
                </Form.Group>
                {rentRequestStore.placeDetails && (
                    <div style={{ height: '400px' }} className="w-full md:w-128 mb-8">
                        <GoogleMapReact
                            center={{
                                lat: rentRequestStore.placeDetails.lat,
                                lng: rentRequestStore.placeDetails.lng,
                            }}
                            bootstrapURLKeys={{ key: config.googleMapsApiKey }}
                            defaultZoom={11}
                        >
                            <Marker lat={rentRequestStore.placeDetails.lat} lng={rentRequestStore.placeDetails.lng} />
                        </GoogleMapReact>
                    </div>
                )}
                <Form.Group>
                    <Form.ControlLabel>{t('reservationPage.message')}</Form.ControlLabel>
                    <Form.Control
                        value={rentRequestStore.message}
                        onChange={rentRequestStore.setMessage}
                        style={{ width: isMobile ? '' : '32rem' }}
                        rows={3}
                        accepter={Textarea}
                        name="message"
                        className="text-base"
                        placeholder={t('reservationPage.messagePlaceholder')}
                    />
                </Form.Group>
                <Form.Group className="mb-0">
                    <Form.Control
                        name="isPhoneNumberShared"
                        value={!rentRequestStore.isPhoneNumberShared}
                        onChange={rentRequestStore.setIsPhoneNumberShared}
                        accepter={Checkbox}
                        defaultChecked={rentRequestStore.isPhoneNumberShared}
                    >
                        {t('reservationPage.sharePhone')}
                    </Form.Control>
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Control
                        value={!rentRequestStore.termAndConditional}
                        name="termAndConditional"
                        accepter={Checkbox}
                        onChange={rentRequestStore.setTermAndConditional}
                        defaultChecked={rentRequestStore.termAndConditional}
                    >
                        <div className="flex items-center justify-center">
                            <p className="text-base">{t('global.agree')}</p>
                            <Button
                                className="text-base py-0 px-1"
                                appearance="link"
                                onClick={navigateToTermsAndConditions}
                            >
                                {t('global.termsAndConditions')}
                            </Button>
                        </div>
                    </Form.Control>
                </Form.Group>

                <div className="lg:w-128 mb-8 flex">
                    {userStore.user ? (
                        <Button
                            className="text-base px-6"
                            type="submit"
                            disabled={isUserCreator}
                            appearance="primary"
                            size="lg"
                        >
                            {t('global.send')}
                        </Button>
                    ) : (
                        <Button className="text-base px-6" type="submit" appearance="primary" size="lg">
                            {t('reservationPage.loginContinue')}
                        </Button>
                    )}
                </div>
            </Form>
            <NextStepModal open={open} handleClose={handleClose} />
        </>
    );
};

export default observer(SendRentRequestForm);
