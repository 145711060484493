import { makeAutoObservable } from 'mobx';

interface Props {
    bodyType: string;
    carModel: string;
    yearsOfIssue: string;
    consumption: string;
    mileage: string;
    city: string;
    brandType: string;
    modification?: string;
}

export class MainInformationStore {
    constructor(props: Props) {
        makeAutoObservable(this);

        this.bodyType = props.bodyType;
        this.carModel = props.carModel;
        this.yearsOfIssue = props.yearsOfIssue;
        this.consumption = props.consumption;
        this.mileage = props.mileage;
        this.city = props.city;
        this.brandType = props.brandType;
        this.modification = props.modification;
    }

    bodyType = '';
    carModel = '';
    yearsOfIssue = '';
    consumption = '';
    mileage = '';
    city = '';
    brandType = '';
    modification?: string;

    setBrandType = (brandType: string) => {
        this.brandType = brandType;
    };
    setBodyType = (bodyType: string) => {
        this.bodyType = bodyType;
    };
    setCarModel = (carModel: string) => {
        this.carModel = carModel;
    };
    setMileage = (mileage: string) => {
        this.mileage = mileage;
    };
    setCity = (city: string) => {
        this.city = city;
    };
    setYearsOfIssue = (yearsOfIssue: string) => {
        this.yearsOfIssue = yearsOfIssue;
    };
    setModification = (modification: string) => {
        this.modification = modification;
    };
    setConsumption = (consumption: string) => {
        this.consumption = consumption;
    };
}
