import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/esm/Button';
import Form from 'rsuite/esm/Form';
import IconButton from 'rsuite/IconButton';
import Loader from 'rsuite/Loader';
import Message from 'rsuite/Message';
import Schema from 'rsuite/Schema';
import toaster from 'rsuite/toaster';

import { Container } from '../../components/Container';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import AdditionalInfo, { additionalInformationSchemaBuilder } from './components/AdditionalInfo';
import Description from './components/Description';
import AddPhoto from './components/EditPhoto';
import MainInformation, { mainInformationSchemaBuilder } from './components/MainInformation';
import RequirementsForClient, { requirementInformationSchemaBuilder } from './components/RequirementsForClient';
import { EditAdvertStore } from './store/EditAdvertStore';

const { StringType } = Schema.Types;

const editAdvertSchemaBuilder = (t: TFunction) =>
    Schema.Model({
        ...mainInformationSchemaBuilder(t),
        ...additionalInformationSchemaBuilder(t),
        ...requirementInformationSchemaBuilder(t),
        description: StringType()
            .isRequired(t('formErrors.isRequired') || '')
            .maxLength(5000, t('formErrors.maxSymbol', { amount: '5 000' }) || '')
            .minLength(120, t('formErrors.minSymbol', { amount: '120' }) || ''),
    });

function EditAdvertContainer() {
    const [editAdvertStore] = useState(new EditAdvertStore());
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const slug = query.get('slug');
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const editAdvertSchema = useMemo(() => editAdvertSchemaBuilder(t), [t]);
    useEffect(() => {
        if (!slug) {
            navigate('/');
        } else {
            editAdvertStore.fetchAdvertDetail(slug).catch(console.error);
        }
    }, [editAdvertStore]);

    const onSubmit = async (checkStatus: boolean) => {
        if (!editAdvertStore.photos.isValidationError && checkStatus && slug) {
            const advert = await editAdvertStore.onEdit(slug);

            if (!advert) {
                toaster.push(
                    <Message showIcon type="error">
                        {t('global.somethingWentWrong')}
                    </Message>
                );
            } else {
                toaster.push(
                    <Message showIcon type="success">
                        {t('editAdvertPage.edited')}
                    </Message>
                );
                navigate('/advert-details/' + slug);
            }
        } else {
            window.scrollTo(0, 0);
        }
    };

    const goBack = () => {
        if (history.state.idx) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    return (
        <div>
            <NavBar />

            {editAdvertStore.mainInformation &&
            editAdvertStore.additionalInfo &&
            editAdvertStore.requirementsForClient ? (
                <Form
                    layout={isMobile ? 'vertical' : 'horizontal'}
                    fluid
                    autoComplete="off"
                    formValue={editAdvertStore.defaultFormStore}
                    model={editAdvertSchema}
                    onSubmit={onSubmit}
                >
                    <Container className="mb-16">
                        <div className="mt-12 flex">
                            <IconButton
                                onClick={goBack}
                                className="mr-4"
                                size="md"
                                circle
                                icon={<PagePreviousIcon />}
                            />
                            <h3>{t('editAdvertPage.title')}</h3>
                        </div>
                        <AddPhoto photoStore={editAdvertStore.photos} />
                        <MainInformation mainInformationStore={editAdvertStore.mainInformation} />
                    </Container>
                    <Description
                        description={editAdvertStore.descriptionText}
                        setDescription={editAdvertStore.setDescriptionText}
                    />
                    <AdditionalInfo additionalInfoStore={editAdvertStore.additionalInfo} />
                    <Container>
                        <RequirementsForClient requirementsForClientStore={editAdvertStore.requirementsForClient} />
                        <p className="mt-8">{t('addAdvert.confidentialInformation')}</p>

                        <div className="flex justify-center items-center my-8">
                            <Button size="lg" type="submit" appearance="primary">
                                {t('editAdvertPage.button')}
                            </Button>
                        </div>
                    </Container>
                </Form>
            ) : (
                <Container className="container-height">
                    <div className="flex justify-center items-center h-96">
                        <Loader size="md" content={t('global.loading')} />
                    </div>
                </Container>
            )}

            <Footer />
        </div>
    );
}

export default observer(EditAdvertContainer);
