import EditIcon from '@rsuite/icons/Edit';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import PageIcon from '@rsuite/icons/Page';
import PauseOutlineIcon from '@rsuite/icons/PauseOutline';
import PlayOutlineIcon from '@rsuite/icons/PlayOutline';
import TimeIcon from '@rsuite/icons/Time';
import TrashIcon from '@rsuite/icons/Trash';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IconButton from 'rsuite/IconButton';
import Message from 'rsuite/Message';
import toaster from 'rsuite/toaster';

import { StatusText } from '../../../components/MyAdvertStatus/MyAdvertStatus';
import { verificationStatus } from '../../../components/VerificationStatus/VerificationStatus';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { AdvertDetailsStore } from '../../../stores/adverts-details-store/AdvertDetails.store';
import { MyAdvertsStore } from '../../../stores/my-adverts-store/MyAdverts.store';

interface Props {
    myAdvertsStore: MyAdvertsStore;
    advertDetailsStore: AdvertDetailsStore;
}

const AdvertSettings: React.FC<Props> = ({ advertDetailsStore, myAdvertsStore }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { formatDateWithWeek } = useFormatDate();

    const { advertDetails, isLoadingSettingsPanel } = advertDetailsStore;
    const numberOfActiveRentRequests = advertDetailsStore.advertDetails?.approvedRentRequestNumber || 0;
    if (!advertDetails) {
        return null;
    }

    const message = (
        <Message
            className="text-base"
            duration={8000}
            showIcon
            closable
            type="error"
            header={t('myAdverts.cantRemoveModalTitle')}
        >
            {t('myAdverts.cantRemoveModalSubtitle', { numberOfActiveRentRequests })}
        </Message>
    );

    const onAdvertEditClick = () => {
        navigate(`/edit-advert?slug=${advertDetails.slug}`);
    };

    const onVerifyClick = () => {
        navigate(`/verify-advert/${advertDetails.slug}`);
    };

    const { canRemove, canArchive, canPause, canProlong, canActivate, canEdit } = myAdvertsStore.possibleActionList(
        advertDetails.advertStatus
    );

    const showVerified = Boolean(!advertDetails?.car.isVerified && !advertDetails?.documentsSent);

    const activateAdvert = async () => {
        advertDetailsStore.setIsLoadingSettingsPanel(true);
        await myAdvertsStore.activateAdvert(advertDetails?.slug || '');
        await advertDetailsStore.updateCurrentAdvertDetail();
    };

    const prolongAdvert = async () => {
        advertDetailsStore.setIsLoadingSettingsPanel(true);
        await myAdvertsStore.prolongAdvert(advertDetails?.slug || '');
        await advertDetailsStore.updateCurrentAdvertDetail();
    };

    const pauseAdvert = async () => {
        advertDetailsStore.setIsLoadingSettingsPanel(true);
        await myAdvertsStore.pauseAdvert(advertDetails?.slug || '');
        await advertDetailsStore.updateCurrentAdvertDetail();
    };

    const archiveAdvert = async () => {
        if (numberOfActiveRentRequests) {
            toaster.push(message);
        } else {
            advertDetailsStore.setIsLoadingSettingsPanel(true);
            await myAdvertsStore.archiveAdvert(advertDetails?.slug || '');
            await advertDetailsStore.updateCurrentAdvertDetail();
        }
    };

    const removeAdvert = async () => {
        if (numberOfActiveRentRequests) {
            toaster.push(message);
        } else {
            advertDetailsStore.setIsLoadingSettingsPanel(true);
            await myAdvertsStore.removeAdvert(advertDetails?.slug || '');
            await advertDetailsStore.updateCurrentAdvertDetail();
        }
    };

    return (
        <div className="w-full md:w-9/12 lg:w-full xl:w-10/12 border border-solid border-gray-200 rounded-2xl p-5 bg-white">
            <h5 className="mb-4">{t('advertDetailsPAge.advertSettings.title')}</h5>
            <div className="flex text-base flex-col">
                <div className="flex mt-2">
                    <h6 className="mr-2 text-base">{t('myAdverts.status')}:</h6>
                    {StatusText(advertDetails)}
                </div>
                <div className="flex mt-2">
                    <div className="flex text-base">
                        <h6 className="mr-2">{t('myRentRequests.price')}:</h6>
                    </div>
                    <div className="flex text-base flex-col">
                        <p>{t('price.perWeek', { price: advertDetails.car.prices.costPerWeek })}</p>
                        <p className="mr-2 mt-0">
                            {t('price.perWeek', { price: advertDetails.car.prices.costPerDay })}
                        </p>
                        {advertDetails.car.prices.costPerMonth && (
                            <p className="mr-2 mt-0">
                                {t('price.perWeek', { price: advertDetails.car.prices.costPerMonth })}
                            </p>
                        )}
                    </div>
                </div>

                <div className="flex mt-2">
                    <h6 className="mr-2">{t('myAdverts.views')}:</h6>
                    {advertDetails.amountOfViews}
                </div>
                <div className="flex mt-2 items-center">
                    <h6 className="mr-2">{t('myAdverts.verification')}:</h6>
                    {verificationStatus(advertDetails, {
                        width: 24,
                        placement: 'bottom',
                        hasNextStepsText: true,
                    })}
                </div>
                <div className="flex mt-2">
                    <h6 className="mr-2">{t('myAdverts.expired')}:</h6>
                    {formatDateWithWeek(new Date(advertDetails.expiredDate))}
                </div>
            </div>

            <div className="flex flex-col w-2/3 gap-3 pt-4">
                {canEdit && (
                    <IconButton onClick={onAdvertEditClick} icon={<EditIcon />} appearance="ghost">
                        {t('advertsActions.edit')}
                    </IconButton>
                )}
                {showVerified && (
                    <IconButton onClick={onVerifyClick} icon={<PageIcon />} appearance="ghost">
                        {t('advertsActions.verify')}
                    </IconButton>
                )}
                {canActivate && (
                    <IconButton
                        loading={isLoadingSettingsPanel}
                        onClick={activateAdvert}
                        icon={<PlayOutlineIcon />}
                        appearance="ghost"
                    >
                        {t('advertsActions.activate')}
                    </IconButton>
                )}
                {canProlong && (
                    <IconButton
                        loading={isLoadingSettingsPanel}
                        onClick={prolongAdvert}
                        icon={<TimeIcon />}
                        appearance="ghost"
                    >
                        {t('advertsActions.prolong')}
                    </IconButton>
                )}
                {canPause && (
                    <IconButton
                        loading={isLoadingSettingsPanel}
                        onClick={pauseAdvert}
                        icon={<PauseOutlineIcon />}
                        appearance="ghost"
                    >
                        {t('advertsActions.pause')}
                    </IconButton>
                )}
                {canArchive && (
                    <IconButton
                        loading={isLoadingSettingsPanel}
                        onClick={archiveAdvert}
                        icon={<FileDownloadIcon />}
                        appearance="ghost"
                    >
                        {t('advertsActions.archive')}
                    </IconButton>
                )}
                {canRemove && (
                    <IconButton
                        loading={isLoadingSettingsPanel}
                        onClick={removeAdvert}
                        icon={<TrashIcon />}
                        appearance="ghost"
                    >
                        {t('advertsActions.delete')}
                    </IconButton>
                )}
            </div>
        </div>
    );
};

export default observer(AdvertSettings);
