import { makeAutoObservable } from 'mobx';

import { PhotosStore } from '../../components/PhotoUploader/PhotosStore';
import { api } from '../../server/server';
import { QueryStore } from '../Query.store';
import { AdditionalInfoStore } from './AdditionalInfo.store';
import { DescriptionStore } from './Description.store';
import { MainInformationStore } from './MainInformation.store';
import { RequirementsForClientStore } from './RequirementsForClient.store';

export class AddAdvertStore {
    constructor() {
        makeAutoObservable(this);
    }

    photos: PhotosStore = new PhotosStore();
    additionalInfo: AdditionalInfoStore = new AdditionalInfoStore();
    mainInformation: MainInformationStore = new MainInformationStore();
    requirementsForClient: RequirementsForClientStore = new RequirementsForClientStore();
    descriptionStore: DescriptionStore = new DescriptionStore();
    isLoading = false;
    errorStatus?: number;

    onCreate = async (): Promise<void> => {
        const payload = {
            photos: this.photos.photoList.map((file) => file.url),
            bodyType: this.mainInformation.bodyType,
            brandType: this.mainInformation.brandType,
            consumption: Number(this.additionalInfo.consumption),
            description: this.descriptionStore.description,
            doorAmount: Number(this.additionalInfo.doorNumber),
            engineCapacity: Number(this.additionalInfo.engineCapacity),
            fuelType: this.additionalInfo.fuel,
            mainPhoto: this.photos.mainPhoto?.url || '',
            mileage: Number(this.additionalInfo.mileage),
            model: this.mainInformation.carModel,
            modification: this.mainInformation.modification,
            city: this.mainInformation.city || '',
            prices: {
                costPerWeek: Number(this.additionalInfo.weekPrice),
                costPerDay: Number(this.additionalInfo.dayPrice),
                costPerMonth: this.additionalInfo.monthPrice ? Number(this.additionalInfo.monthPrice) : undefined,
            },
            requirementForClient: {
                minAge: this.requirementsForClient.minAge ? Number(this.requirementsForClient.minAge) : undefined,
                minExperience: this.requirementsForClient.minExperience
                    ? Number(this.requirementsForClient.minExperience)
                    : undefined,
                deposit: this.requirementsForClient.deposit ? Number(this.requirementsForClient.deposit) : undefined,
                citizenship: this.requirementsForClient.citizenship,
            },
            transmission: this.additionalInfo.transmission,
            yearsOfIssue: Number(this.mainInformation.yearsOfIssue),
        };

        this.setIsLoading(true);

        const { errorStatus } = await QueryStore.fetch(() => api.adverts.advertControllerCreate(payload));

        this.setError(errorStatus);
        this.setIsLoading(false);
    };

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };
    setError(errorStatus?: number) {
        this.errorStatus = errorStatus;
    }
}
