import { makeAutoObservable } from 'mobx';
import queryString from 'query-string';
import ReactGA from 'react-ga4';

import { GAActions, GACategories } from '../../constants';
import { cities } from '../../data/cities.data';
import { FullAdvertEntityResponse } from '../../generated/api';
import { api } from '../../server/server';
import { FilterQueryObject } from '../../types';
import { FromToStore } from '../full-filter-store/FromTo.store';
import { QueryStore } from '../Query.store';

export class ShortFilterStore {
    constructor() {
        makeAutoObservable(this);
    }

    bodyType = '';
    city = 'lviv';
    rentCost = new FromToStore({ min: 500, max: 10_000 });
    dateTimePicker: [Date, Date] | null = null;

    recommendations: FullAdvertEntityResponse[] = [];
    isLoading = false;
    errorStatus?: number;

    recommendationCity = cities[0].value;

    fetchRecommendation = async () => {
        this.setIsLoading(true);

        const { response, errorStatus } = await QueryStore.fetch(() =>
            api.adverts.advertControllerFindAll({
                limit: 5,
                cities: [this.recommendationCity],
            })
        );

        if (response) {
            this.setRecommendations(response.adverts);
        }
        this.setErrorStatus(errorStatus);

        this.setIsLoading(false);
    };

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    setErrorStatus = (errorStatus?: number) => {
        this.errorStatus = errorStatus;
    };

    setRecommendations = (recommendations: FullAdvertEntityResponse[]) => {
        this.recommendations = recommendations;
    };

    setBodyType = (bodyType: string | null) => {
        ReactGA.event({
            category: GACategories.Main,
            action: GAActions[GACategories.Main].bodyTypeModified,
            label: bodyType || undefined,
        });

        this.bodyType = bodyType || '';
    };

    setCity = (city: string | null) => {
        ReactGA.event({
            category: GACategories.Main,
            action: GAActions[GACategories.Main].cityModified,
            label: city || undefined,
        });

        this.city = city || '';
    };

    setRentCost = (rentCost: [number, number]) => {
        ReactGA.event({
            category: GACategories.Main,
            action: GAActions[GACategories.Main].priceModified,
            label: rentCost?.toString() || undefined,
        });

        this.rentCost.setValue(rentCost);
    };

    setDateTimePicker = (dateTimePicker: [Date, Date] | null) => {
        ReactGA.event({
            category: GACategories.Main,
            action: GAActions[GACategories.Main].periodModified,
            label: dateTimePicker?.toString() || undefined,
        });

        this.dateTimePicker = dateTimePicker || null;
    };

    setRecommendationCity = (recommendationCity: string) => {
        ReactGA.event({
            category: GACategories.Recommendation,
            action: GAActions[GACategories.Recommendation].setRecommendationCity,
            label: recommendationCity,
        });

        this.recommendationCity = recommendationCity;
    };

    get filterUrlParam() {
        const reservationPeriod: [string, string] | undefined = this.dateTimePicker
            ? [this.dateTimePicker[0].toISOString(), this.dateTimePicker[1].toISOString()]
            : undefined;
        const data: FilterQueryObject = {
            bodyTypes: [this.bodyType] || undefined,
            cities: [this.city] || undefined,
            rentPrice: this.rentCost?.valueForSearching,
            reservationPeriod: reservationPeriod,
        };

        return queryString.stringify(data);
    }
}
