import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import setYear from 'date-fns/setYear';
import { makeAutoObservable } from 'mobx';
import ReactGA from 'react-ga4';
import { SortType } from 'rsuite-table/lib/@types/common';

import { GAActions, GACategories } from '../../constants';
import {
    AdvertEntityResponseAdvertStatus,
    RentRequestEntityResponse,
    RentRequestEntityResponseStatus,
} from '../../generated/api';
import { api } from '../../server/server';
import { QueryStore } from '../Query.store';

type SortColumn = 'carName' | 'status' | 'totalPrice' | 'createdAt';

export class RentRequestListStore {
    constructor() {
        makeAutoObservable(this);
    }

    receivedRentRequestList: RentRequestEntityResponse[] = [];
    sentRentRequestList: RentRequestEntityResponse[] = [];

    total = 0;
    isLoading = false;
    filterStatus: RentRequestEntityResponseStatus[] = [
        RentRequestEntityResponseStatus.Approved,
        RentRequestEntityResponseStatus.WaitingForReview,
    ];
    sortColumn?: SortColumn = 'createdAt';
    sortType?: SortType = 'asc';

    errorStatus?: number;
    numberOfWaitingRentRequestsReceived = 0;

    reviewedRentRequest: RentRequestEntityResponse | null = null;

    fetchReceivedRentRequest = async () => {
        this.setIsLoading(true);

        const { response, errorStatus } = await QueryStore.fetch(() =>
            api.rentRequests.rentRequestsControllerFindAllReceived()
        );
        if (response) {
            this.setReceivedRentRequestList(response.rentRequests);
            this.setTotal(response.total);
            this.setNumberOfWaitingRentRequestsReceived(response.numberOfWaitingRentRequestsReceived);
        }
        this.errorStatus = errorStatus;

        this.setIsLoading(false);
    };

    fetchSentRentRequest = async () => {
        this.setIsLoading(true);

        const { response, errorStatus } = await QueryStore.fetch(() =>
            api.rentRequests.rentRequestsControllerFindAllSent()
        );
        if (response) {
            this.setSentRentRequestList(response.rentRequests);
            this.setTotal(response.total);
            this.setNumberOfWaitingRentRequestsReceived(response.numberOfWaitingRentRequestsReceived);
        }

        this.errorStatus = errorStatus;

        this.setIsLoading(false);
    };

    handleSortColumn = (dataKey: string, sortType?: SortType) => {
        this.sortColumn = dataKey as SortColumn;
        this.sortType = sortType;
    };

    async activateRentRequest() {
        const reviewedRentRequest = this.reviewedRentRequest;
        if (!reviewedRentRequest) {
            return null;
        }

        const { errorStatus } = await QueryStore.fetch(() =>
            api.rentRequests.rentRequestsControllerActivateRentRequest(reviewedRentRequest?.id)
        );
        this.errorStatus = errorStatus;
    }

    setFilterStatus = (filterStatus: RentRequestEntityResponseStatus[]) => {
        this.filterStatus = filterStatus;
    };

    get getOverlappingRentRequests(): RentRequestEntityResponse[] | null {
        if (!this.reviewedRentRequest) {
            return null;
        }

        const overlappingRentRequests = this.receivedRentRequestList.filter((rentRequest) => {
            if (
                rentRequest.advert.id === this.reviewedRentRequest?.advert.id &&
                rentRequest.status === RentRequestEntityResponseStatus.Approved
            ) {
                return areIntervalsOverlapping(
                    {
                        start: new Date(this.reviewedRentRequest.from),
                        end: new Date(this.reviewedRentRequest.to),
                    },
                    {
                        start: new Date(rentRequest.from),
                        end: new Date(rentRequest.to),
                    }
                );
            }
        });

        return overlappingRentRequests.length ? overlappingRentRequests : null;
    }

    get canActivateCurrentRentRequest(): boolean {
        if (!this.reviewedRentRequest) {
            return false;
        }

        const isLaterThenToday = new Date(this.reviewedRentRequest.from) > new Date();
        const isAdvertStatusValid =
            this.reviewedRentRequest.advert.advertStatus === AdvertEntityResponseAdvertStatus.Active ||
            this.reviewedRentRequest.advert.advertStatus === AdvertEntityResponseAdvertStatus.InRenting;

        return (
            isLaterThenToday &&
            this.reviewedRentRequest.status === RentRequestEntityResponseStatus.WaitingForReview &&
            isAdvertStatusValid
        );
    }

    async declineRentRequest({
        declineReasonHeader,
        declineReasonMessage,
    }: {
        declineReasonMessage: string;
        declineReasonHeader: string;
    }) {
        const reviewedRentRequest = this.reviewedRentRequest;
        if (!reviewedRentRequest) {
            return null;
        }

        const { errorStatus } = await QueryStore.fetch(() =>
            api.rentRequests.rentRequestsControllerDeclineRentRequest(reviewedRentRequest?.id, {
                declineReasonHeader,
                declineReasonMessage,
            })
        );
        this.errorStatus = errorStatus;

        await this.fetchReceivedRentRequest();
        await this.fetchSentRentRequest();

        ReactGA.event({
            category: GACategories.RentRequest,
            action: GAActions[GACategories.RentRequest].declineRentRequest,
            label: `${declineReasonHeader} ${declineReasonMessage}`,
        });
    }

    reviewRentRequest = (reviewedRentRequest: RentRequestEntityResponse) => {
        ReactGA.event({
            category: GACategories.RentRequest,
            action: GAActions[GACategories.RentRequest].declineRentRequest,
        });

        this.reviewedRentRequest = reviewedRentRequest;
    };

    get closureReceivedRentRequest(): RentRequestEntityResponse | undefined {
        return this.findClosureRentRequest(this.receivedRentRequestList);
    }

    private findClosureRentRequest(rentRequestList: RentRequestEntityResponse[]) {
        const approvedRentRequest = rentRequestList.filter(
            (rentRequest) => rentRequest.status === RentRequestEntityResponseStatus.Approved
        );

        if (!approvedRentRequest.length) {
            return undefined;
        }

        let closureReceivedRentRequestDate = setYear(new Date(), 2100);
        let closureReceivedRentRequestDetail: RentRequestEntityResponse | undefined;

        approvedRentRequest.forEach((rentRequest) => {
            if (
                new Date(rentRequest.from) > new Date() &&
                new Date(rentRequest.from) < closureReceivedRentRequestDate
            ) {
                closureReceivedRentRequestDate = new Date(rentRequest.from);
                closureReceivedRentRequestDetail = rentRequest;
            }
        });

        return closureReceivedRentRequestDetail;
    }

    get closureSentRentRequest(): RentRequestEntityResponse | undefined {
        return this.findClosureRentRequest(this.sentRentRequestList);
    }

    closeRentRequest = () => {
        this.reviewedRentRequest = null;
    };

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    setNumberOfWaitingRentRequestsReceived = (numberOfWaitingRentRequests: number) => {
        this.numberOfWaitingRentRequestsReceived = numberOfWaitingRentRequests;
    };

    setReceivedRentRequestList = (receivedRentRequestList: RentRequestEntityResponse[]) => {
        this.receivedRentRequestList = receivedRentRequestList;
    };

    setSentRentRequestList = (sentRentRequestList: RentRequestEntityResponse[]) => {
        this.sentRentRequestList = sentRentRequestList;
    };

    setTotal = (total: number) => {
        this.total = total;
    };

    filterRentRequestsList(rentRequestList: RentRequestEntityResponse[]) {
        if (this.filterStatus.length) {
            return rentRequestList.filter((rentRequest) => this.filterStatus.includes(rentRequest.status));
        }

        return rentRequestList;
    }

    sortRentRequestList = (rentRequestList: RentRequestEntityResponse[]): RentRequestEntityResponse[] => {
        if (!this.sortColumn || !this.sortColumn) {
            return rentRequestList;
        }

        return [...rentRequestList].sort((a, b) => {
            let x: string | number;
            let y: string | number;

            switch (this.sortColumn) {
                case 'carName': {
                    x = a.advert.car.brandType;
                    y = b.advert.car.brandType;
                    break;
                }
                case 'status': {
                    x = a.status;
                    y = b.status;

                    if (x === RentRequestEntityResponseStatus.Approved) {
                        return this.sortType === 'asc' ? 1 : -1;
                    }
                    if (y === RentRequestEntityResponseStatus.Approved) {
                        return this.sortType === 'asc' ? -1 : 1;
                    }
                    break;
                }
                case 'createdAt': {
                    x = new Date(a.createdAt).getTime();
                    y = new Date(b.createdAt).getTime();

                    return this.sortType === 'asc' ? y - x : x - y;
                }
                default: {
                    x = a[this.sortColumn!];
                    y = b[this.sortColumn!];
                }
            }

            if (typeof x === 'string') {
                x = x.charCodeAt(0);
            }
            if (typeof y === 'string') {
                y = y.charCodeAt(0);
            }

            if (this.sortType === 'asc') {
                return x - y;
            } else {
                return y - x;
            }
        });
    };

    get sortedReceivedRentRequests() {
        const receivedRentRequest = this.filterRentRequestsList(this.receivedRentRequestList);
        return this.sortRentRequestList(receivedRentRequest);
    }

    get sortedSentRentRequests() {
        const sentRentRequestList = this.filterRentRequestsList(this.sentRentRequestList);
        return this.sortRentRequestList(sentRentRequestList);
    }
}
