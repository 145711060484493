import EmailIcon from '@rsuite/icons/Email';
import PhoneIcon from '@rsuite/icons/Phone';
import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'rsuite/Button';
import Divider from 'rsuite/esm/Divider';
import Panel from 'rsuite/Panel';

import LanguageChange from '../../components/LanguageChange/LanguageChange';
import { GAActions, GACategories } from '../../constants';
import { useStore } from '../../hooks/useStore';
import telegram from '../../images/logos/telegram.png';
import viber from '../../images/logos/viber.png';
import whatsapp from '../../images/logos/whatsapp.png';
import background from './assets/background.webp';
import logo from './assets/light-logo.png';
import mainPhoto from './assets/main-photo.webp';
import ownerPlaceIcon from './assets/owner-place-icon.png';
import pickUpIcon from './assets/pick-up-icon.png';
import userMessage from './assets/user-message-icon.png';

const LandingContainer: React.FC<{ className?: string }> = (props) => (
    <div className={`w-11/12 lg:w-10/12 xl:w-landing-container m-auto flex gap-4 flex-col ${props.className || ''}`}>
        {props.children}
    </div>
);

function CarOwnerLanding() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { userStore } = useStore();

    const moveToCreateAdvert = () => {
        ReactGA.event({
            category: GACategories.CarOwnerLanding,
            action: GAActions[GACategories.CarOwnerLanding].createAdvertButtonLanding,
            label: userStore.user ? 'old user' : 'new user',
        });

        if (userStore.user) {
            navigate('/create-advert');
        } else {
            navigate('/login');
        }
    };

    const moveToHome = () => {
        navigate('/');
    };

    return (
        <div className="text-base" style={{ backgroundImage: `url(${background})` }}>
            <LandingContainer>
                <div className="flex justify-between pt-4 items-center">
                    <img className="h-6 md:h-8 cursor-pointer" src={logo} alt="" onClick={moveToHome} />
                    <div className="flex gap-2 md:gap-12 flex-col md:flex-row">
                        <div className="flex flex-col md:flex-row">
                            <div className="flex items-center gap-2">
                                <PhoneIcon />
                                <div>+38 (093) 189 0673</div>
                            </div>

                            <div className="flex gap-1 justify-center items-center md:mx-2">
                                <img
                                    className="w-5 h-5 cursor-pointer"
                                    onClick={() => (window.location.href = 'viber://chat?number=380931890673')}
                                    src={viber}
                                    alt=""
                                />
                                <img
                                    className="w-5 h-5 cursor-pointer"
                                    src={telegram}
                                    onClick={() => (window.location.href = 'https://t.me/Roadieze')}
                                    alt=""
                                />
                            </div>

                            <div className="hidden md:flex items-center gap-2 ml-8">
                                <EmailIcon />
                                <div>contact@roadieze.com</div>
                            </div>
                        </div>
                    </div>
                    <LanguageChange />
                </div>
                <div className="flex items-center justify-between pb-4 flex-wrap">
                    <div className="w-full md:w-1/3 mt-8 md:mt-0">
                        <h2>{t('landingPage.title')}</h2>
                        <Button onClick={moveToCreateAdvert} className="mt-2" appearance="primary" size="lg">
                            {t('landingPage.actionButton')}
                        </Button>
                    </div>
                    <img src={mainPhoto} alt="" className="w-full md:w-2/3" />
                </div>
                <div className="flex items-center gap-8 mb-12 flex-wrap md:flex-nowrap">
                    <div className="flex gap-4 flex-col text-center md:text-left">
                        <h3>{t('landingPage.offerTitle')}</h3>
                        <div>{t('landingPage.offerSubTitle')}</div>
                    </div>
                    <div className="flex flex-col gap-8">
                        <div className="flex items-center gap-4">
                            <img src={ownerPlaceIcon} alt="" />
                            <div>{t('landingPage.step1')}</div>
                        </div>
                        <div className="flex items-center gap-4">
                            <img src={userMessage} alt="" />
                            <div>{t('landingPage.step2')}</div>
                        </div>
                        <div className="flex items-center gap-4">
                            <img src={pickUpIcon} alt="" />
                            <div>{t('landingPage.step3')}</div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center flex-col my-12 text-center">
                    <h3>{t('landingPage.documentTitle')}</h3>
                    <div>{t('landingPage.documentSubtitle')}</div>
                    <div className="flex justify-between w-full my-8 text-center gap-6 flex-wrap md:flex-nowrap">
                        <div className="flex w-full md:w-96 flex-col items-center justify-center border rounded-3xl bg-white p-4 shadow-lg h-60 gap-6">
                            <h1 className="text-blue-200 text-6xl">01</h1>
                            <div>{t('landingPage.document1')}</div>
                        </div>

                        <div className="flex w-full md:w-96 flex-col items-center justify-center border rounded-3xl bg-white p-4 shadow-lg gap-6">
                            <h1 className="text-blue-200 text-6xl">02</h1>
                            <div>{t('landingPage.document2')}</div>
                        </div>

                        <div className="flex w-full md:w-96 flex-col items-center justify-center border rounded-3xl bg-white p-4 shadow-lg gap-6">
                            <h1 className="text-blue-200 text-6xl">03</h1>
                            <div>{t('landingPage.document3')}</div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center flex-col my-4 md:my-12">
                    <h3>{t('landingPage.advantages')}</h3>
                    <div className="flex justify-between w-full my-8 gap-4 lg:gap-0">
                        <div className="flex w-96 flex-col items-center justify-center border rounded-3xl bg-white shadow-lg h-108">
                            <h5 className="h-1/5 flex flex-col justify-center">Roadieze</h5>
                            <div className="bg-blue-50 w-full px-8 h-1/5 flex flex-col justify-center">
                                <h6>{t('landingPage.priceControl')}</h6>
                                <div>{t('landingPage.RPriceControl')}</div>
                            </div>
                            <div className="w-full px-8 h-1/5 flex flex-col justify-center">
                                <h6>{t('landingPage.flexibility')}</h6>
                                <div>{t('landingPage.RFlexibility')}</div>
                            </div>
                            <div className="bg-blue-50 w-full px-8 h-1/5 flex flex-col justify-center">
                                <h6>{t('landingPage.renterIdentification')}</h6>
                                <div>{t('landingPage.RRenterIdentification')}</div>
                            </div>
                            <div className="w-full px-8 h-1/5 flex flex-col justify-center">
                                <h6>{t('landingPage.serviceFees')}</h6>
                                <div>{t('landingPage.yes')}</div>
                            </div>
                        </div>
                        <div className="hidden md:flex w-96 flex-col items-center justify-center border rounded-3xl bg-white shadow-lg h-108">
                            <h5 className="h-1/5 flex flex-col justify-center">{t('landingPage.other')}</h5>
                            <div className="bg-blue-50 w-full h-1/5 px-8 flex flex-col justify-center">
                                <h6>{t('landingPage.priceControl')}</h6>
                                <div>{t('landingPage.OtherPriceControl')}</div>
                            </div>
                            <div className="w-full h-1/5 px-8 flex flex-col justify-center">
                                <h6>{t('landingPage.flexibility')}</h6>
                                <div>{t('landingPage.OtherFlexibility')}</div>
                            </div>
                            <div className="bg-blue-50 w-full h-1/5 px-8 flex flex-col justify-center">
                                <h6>{t('landingPage.renterIdentification')}</h6>
                                <div>{t('landingPage.OtherRenterIdentification')}</div>
                            </div>
                            <div className="w-full h-1/5 px-8 flex flex-col justify-center">
                                <h6>{t('landingPage.serviceFees')}</h6>
                                <div>{t('landingPage.no')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-between flex-wrap">
                    <h3 className="text-center md:text-left w-full md:w-1/12">{t('landingPage.faq')}</h3>
                    <div className="w-full md:w-1/2">
                        <Panel className="font-bold" header={t('landingPage.faqQ1')} collapsible>
                            <div className="font-normal">{t('landingPage.faqA1')}</div>
                        </Panel>
                        <Panel className="font-bold" header={t('landingPage.faqQ2')} collapsible>
                            <div className="font-normal">{t('landingPage.faqA2')}</div>
                        </Panel>
                        <Panel className="font-bold" header={t('landingPage.faqQ3')} collapsible>
                            <div className="font-normal">{t('landingPage.faqA3')}</div>
                        </Panel>
                    </div>
                </div>
                <Divider />
                <div className="flex justify-between mb-8">
                    <img className="h-8 cursor-pointer" src={logo} alt="" onClick={moveToHome} />
                    <div className="flex gap-2 md:gap-12 flex-col md:flex-row">
                        <div className="flex items-center gap-2">
                            <PhoneIcon />
                            <div>+38 (093) 189 0673</div>
                            <div className="flex ml-2 gap-1">
                                <img
                                    className="w-5 cursor-pointer"
                                    onClick={() => (window.location.href = 'viber://chat?number=380931890673')}
                                    src={viber}
                                    alt=""
                                />
                                <img
                                    className="w-5 cursor-pointer"
                                    src={telegram}
                                    onClick={() => (window.location.href = 'https://t.me/Roadieze')}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            <EmailIcon />
                            <div>contact@roadieze.com</div>
                        </div>
                    </div>
                </div>
            </LandingContainer>
        </div>
    );
}

export default observer(CarOwnerLanding);
