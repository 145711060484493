import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import { useStore } from '../../hooks/useStore';
import { QueryStore } from '../../stores/Query.store';
import Filter from './components/Filter';
import Recommendation from './components/Recommendation';
import SearchByBodyType from './components/SearchByBodyType';

function MainContainer() {
    const { shortFilter } = useStore();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRecommendation = async () => {
            await shortFilter.fetchRecommendation();
            QueryStore.handleError(navigate, shortFilter.errorStatus);
        };

        fetchRecommendation().catch(console.error);
    }, [navigate, shortFilter, shortFilter.recommendationCity]);

    return (
        <div>
            <NavBar />
            <Filter />
            <Recommendation />
            <SearchByBodyType />
            <div className="mt-24">
                <Footer />
            </div>
        </div>
    );
}

export default observer(MainContainer);
