import { makeAutoObservable } from 'mobx';
import React from 'react';

import { EditAdvertStore } from '../pages/edit-advert/store/EditAdvertStore';
import { AdvertDetailsStore } from './adverts-details-store/AdvertDetails.store';
import { FeedbackStore } from './feedback-store/Feedback.store';
import { FullFilterStore } from './full-filter-store/FullFilter.store';
import { MyAdvertsStore } from './my-adverts-store/MyAdverts.store';
import { RentRequestListStore } from './rent-request-list-store/RentRequestList.store';
import { RentRequestStore } from './rent-request-store/RentRequest.store';
import { ShortFilterStore } from './short-filter-store/ShortFilter.store';
import { UserDetailsStore } from './user-details-store/UserDetails.store';
import { UserStore } from './user-store/User.store';

class Store {
    constructor() {
        makeAutoObservable(this);
    }
    shortFilter = new ShortFilterStore();
    fullFilter = new FullFilterStore();
    advertDetailsStore = new AdvertDetailsStore();
    editAdvertStore = new EditAdvertStore();
    feedbackStore = new FeedbackStore();
    userDetailsStore = new UserDetailsStore();
    rentRequestListStore = new RentRequestListStore();
    myAdverts = new MyAdvertsStore();
    userStore = new UserStore(this.rentRequestListStore);
    rentRequestStore = new RentRequestStore(this.advertDetailsStore);

    resetAllStore = () => {
        this.shortFilter = new ShortFilterStore();
        this.fullFilter = new FullFilterStore();
        this.advertDetailsStore = new AdvertDetailsStore();
        this.editAdvertStore = new EditAdvertStore();
        this.feedbackStore = new FeedbackStore();
        this.userDetailsStore = new UserDetailsStore();
        this.rentRequestListStore = new RentRequestListStore();
        this.myAdverts = new MyAdvertsStore();
        this.userStore = new UserStore(this.rentRequestListStore);
        this.rentRequestStore = new RentRequestStore(this.advertDetailsStore);
    };
}

export const StoreContext = React.createContext<Store>({} as Store);

export default new Store();

// /* HOC to inject stores to any functional or class component */
// export const withStore = (Component) => (props) => {
//     return <Component {...props} stores={useStore()} />;
// };
