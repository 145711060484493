import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../images/Dark-logo.png';
import telegram from '../images/logos/telegram.png';
import viber from '../images/logos/viber.png';
import whatsapp from '../images/logos/whatsapp.png';
import { Container } from './Container';

const Footer = () => {
    const navigate = useNavigate();

    const onLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="h-24 md:h-28 text-white bg-gray-800">
            <Container className="flex items-center justify-between h-full">
                <div>
                    <img className="w-36 md:w-40 cursor-pointer" src={logo} alt="" onClick={onLogoClick} />
                    <p className="text-gray-400 text-base mt-2">Privacy policy</p>
                </div>

                <div>
                    <p className="text-gray-400">contact@roadieze.com</p>
                    <div className="flex gap-1 items-center mt-2">
                        <p className="text-gray-400">+38 (093) 189 0673</p>
                        <div className="flex gap-1 items-center">
                            <img
                                className="w-5 cursor-pointer"
                                src={viber}
                                onClick={() => (window.location.href = 'viber://chat?number=380931890673')}
                                alt=""
                            />
                            <img
                                className="w-5 cursor-pointer"
                                src={telegram}
                                onClick={() => (window.location.href = 'https://t.me/Roadieze')}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
export default Footer;
