import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'rsuite/esm/Tooltip';
import Form from 'rsuite/Form';
import InputPicker from 'rsuite/InputPicker';
import Schema from 'rsuite/Schema';
import Whisper from 'rsuite/Whisper';

import { DepositInput } from '../../../components/Inputs/Inputs';
import { citizenships, minAgeList, minExperienceList } from '../../../data/common.data';
import { Utils } from '../../../stores/utils';
import { RequirementsForClientStore } from '../store/RequirementsForClientStore';

const { StringType, NumberType } = Schema.Types;

export const requirementInformationSchemaBuilder = (t: TFunction) => ({
    minAge: StringType(),
    minExperience: StringType(),
    deposit: NumberType(t('formErrors.mustBeNumber') || '').max(
        99999,
        t('formErrors.depositAtMost', { amount: '99 999' }) || ''
    ),
    citizenship: StringType(),
});

interface Props {
    requirementsForClientStore: RequirementsForClientStore;
}

const RequirementsForClient: React.FC<Props> = ({ requirementsForClientStore }) => {
    const { t } = useTranslation();

    return (
        <div className="mt-24">
            <div className="flex items-center mt-20 mb-8">
                <h4 className="mr-1">{t('editAdvertPage.requirement.title')}</h4>
                <Whisper speaker={<Tooltip>{t('editAdvertPage.requirement.tooltip')}</Tooltip>}>
                    <HelpOutlineIcon className="text-2xl" color="green" />
                </Whisper>
            </div>
            <Form.Group>
                <Form.ControlLabel className="text-gray-800 text-left text-base">
                    {t('params.minAge')}
                </Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="minAge"
                    size="lg"
                    block
                    value={requirementsForClientStore.minAge}
                    onChange={requirementsForClientStore.setMinAge}
                    accepter={InputPicker}
                    placeholder={t('addAdvert.select')}
                    data={minAgeList}
                />
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-gray-800 text-left text-base">
                    {t('params.minExp')}
                </Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="minExperience"
                    size="lg"
                    block
                    value={requirementsForClientStore.minExperience}
                    onChange={requirementsForClientStore.setMinExperience}
                    accepter={InputPicker}
                    placeholder={t('addAdvert.select')}
                    data={minExperienceList}
                />
            </Form.Group>

            <Form.Group>
                <Form.ControlLabel className="text-gray-800 text-left text-base">
                    {t('params.deposit')}
                </Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    size="lg"
                    name="deposit"
                    value={requirementsForClientStore.deposit}
                    onChange={requirementsForClientStore.setDeposit}
                    accepter={DepositInput}
                />
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel className="text-gray-800 text-left text-base">
                    {t('params.citizenship')}
                </Form.ControlLabel>
                <Form.Control
                    className="w-full md:w-96"
                    name="citizenship"
                    size="lg"
                    block
                    searchable={false}
                    value={requirementsForClientStore.citizenship}
                    onChange={requirementsForClientStore.setCitizenship}
                    accepter={InputPicker}
                    placeholder={t('addAdvert.select')}
                    data={Utils.makeDataTranslated(citizenships, t)}
                />
            </Form.Group>
        </div>
    );
};

export default observer(RequirementsForClient);
