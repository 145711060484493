import React from 'react';
import Avatar from 'rsuite/Avatar';

interface Props {
    avatar?: string;
    firstName: string;
    lastName: string;
}
const AvatarPreview: React.FC<Props> = ({ avatar, lastName, firstName }) => {
    return (
        <>
            {avatar ? (
                <img className="object-cover w-full h-full" src={avatar} alt="" />
            ) : (
                <Avatar className="w-full h-full bg-gray-600 text-white rounded-3xl text-center">
                    {firstName} {lastName}
                </Avatar>
            )}
        </>
    );
};

export default AvatarPreview;
