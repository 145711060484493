import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'rsuite/esm/Form';
import Tooltip from 'rsuite/esm/Tooltip';
import Whisper from 'rsuite/Whisper';

import { Container } from '../../../components/Container';
import { Textarea } from '../../../components/Textarea/Textarea';

interface Props {
    description: string;
    setDescription(value: string): void;
}

const Description: React.FC<Props> = ({ description, setDescription }) => {
    const { t } = useTranslation();

    return (
        <div className="text-white bg-gray-800">
            <Container className="py-24">
                <div className="flex items-center mb-4">
                    <h4 className="mr-1">{t('editAdvertPage.descriptionPage.title')}</h4>
                    <Whisper speaker={<Tooltip>{t('editAdvertPage.descriptionPage.tooltip')}</Tooltip>}>
                        <HelpOutlineIcon className="text-2xl" color="green" />
                    </Whisper>
                </div>
                <div className="text-base text-gray-400 mb-8">{t('addAdvert.carDescription.recommendation')}</div>
                <Form.Group className="form-control-child" controlId="textarea">
                    <Form.Control
                        rows={10}
                        value={description}
                        name="description"
                        className="w-full text-gray-300"
                        accepter={Textarea}
                        onChange={setDescription}
                        placeholder="Enter the description"
                    />
                    <Form.HelpText className="text-right pt-2">{description.length}/5000</Form.HelpText>
                </Form.Group>
            </Container>
        </div>
    );
};

export default observer(Description);
