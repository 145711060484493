import React from 'react';
import { useTranslation } from 'react-i18next';

import { getFuelLabelBy, getTransmissionLabelBy } from '../../../data/common.data';
import { CarEntityResponse } from '../../../generated/api';
import { ReactComponent as Engine } from '../../../images/svg/engine.svg';
import { ReactComponent as Petrol } from '../../../images/svg/petrol.svg';
import { ReactComponent as Sits } from '../../../images/svg/sits.svg';
import { ReactComponent as Transmission } from '../../../images/svg/transmission.svg';

interface Props {
    carInfo: CarEntityResponse;
}

const AdditionInfo: React.FC<Props> = ({ carInfo }) => {
    const { t } = useTranslation();

    const Data = (title: string, value: string, icon: React.ReactElement) => (
        <div className="flex items-center mb-4">
            {icon}
            <p className="text-base mx-2 text-black">{title}</p>
            <p className="text-base mt-0 text-gray-400">{value}</p>
        </div>
    );

    return (
        <>
            <h6 className="text-xl mb-4">{t('advertDetailsPAge.additionInfo')}</h6>
            {Data(t('params.transmission'), t(getTransmissionLabelBy(carInfo.transmission)), <Transmission />)}
            {Data(t('params.fuel'), t(getFuelLabelBy(carInfo.fuelType)), <Petrol />)}
            {Data(t('params.engineCapacity'), `${String(carInfo.engineCapacity)} ${t('tile.liters')}`, <Engine />)}
            {Data(t('params.doorsAmount'), String(carInfo.doorAmount), <Sits />)}
        </>
    );
};

export default AdditionInfo;
