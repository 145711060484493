import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import InputPicker from 'rsuite/esm/InputPicker';
import Form from 'rsuite/Form';
import Schema from 'rsuite/Schema';

import { ConsumptionInput, EngineCapacity, MileageInput } from '../../../components/Inputs/Inputs';
import FormRef, { FormRefType } from '../../../components/Steps/FormRef';
import { fuels, transmissions } from '../../../data/common.data';
import { AdditionalInfoStore } from '../../../stores/add-advert-store/AdditionalInfo.store';
import { Utils } from '../../../stores/utils';

const { StringType, NumberType } = Schema.Types;

export const additionalInformationSchemaBuilder = (t: TFunction) =>
    Schema.Model({
        transmission: StringType().isRequired(t('formErrors.isRequired') || ''),
        fuel: StringType().isRequired(t('formErrors.isRequired') || ''),
        engineCapacity: NumberType(t('formErrors.mustBeNumber') || '')
            .isRequired(t('formErrors.isRequired') || '')
            .max(10, t('formErrors.engineAtMost', { amount: '10' }) || ''),
        doorNumber: NumberType(t('formErrors.mustBeNumber') || '')
            .isRequired(t('formErrors.isRequired') || '')
            .max(10, t('formErrors.doorsAtMost', { amount: '10' }) || ''),
        consumption: NumberType(t('formErrors.consumptionNumber') || '')
            .isRequired(t('formErrors.isRequired') || '')
            .max(100, t('formErrors.consumptionLimit', { amount: '100' }) || ''),
        mileage: NumberType(t('formErrors.mileageNumber') || '')
            .isRequired(t('formErrors.isRequired') || '')
            .max(1000, t('formErrors.mileageLimit', { amount: '1 000' }) || ''),
    });

interface Props {
    additionalInfoStore: AdditionalInfoStore;
}

const AdditionalInfo = React.forwardRef<FormRefType, Props>(({ additionalInfoStore }, ref) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const additionalInformationSchema = useMemo(() => additionalInformationSchemaBuilder(t), [t]);

    return (
        <div className="flex justify-between flex-wrap">
            <FormRef
                layout={isMobile ? 'vertical' : 'horizontal'}
                fluid
                model={additionalInformationSchema}
                formValue={additionalInfoStore.formValue}
                ref={ref}
                className="w-full"
            >
                <Form.Group>
                    <Form.ControlLabel className="text-gray-800 text-left text-base">
                        {t('params.transmission')}
                    </Form.ControlLabel>
                    <Form.Control
                        className="w-full md:w-96"
                        name="transmission"
                        size="lg"
                        block
                        accepter={InputPicker}
                        placeholder={t('addAdvert.select')}
                        value={additionalInfoStore.transmission}
                        onChange={additionalInfoStore.setTransmission}
                        data={Utils.makeDataTranslated(transmissions, t)}
                    />
                    <Form.HelpText>{t('global.required')}</Form.HelpText>
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel className="text-gray-800 text-left text-base">
                        {t('params.fuel')}
                    </Form.ControlLabel>
                    <Form.Control
                        value={additionalInfoStore.fuel}
                        onChange={additionalInfoStore.setFuel}
                        name="fuel"
                        size="lg"
                        className="w-full md:w-96"
                        accepter={InputPicker}
                        placeholder={t('addAdvert.select')}
                        data={Utils.makeDataTranslated(fuels, t)}
                    />
                    <Form.HelpText>{t('global.required')}</Form.HelpText>
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel className="text-gray-800 text-left text-base">
                        {t('params.doorsAmount')}
                    </Form.ControlLabel>
                    <Form.Control
                        size="lg"
                        placeholder="4"
                        name="doorNumber"
                        className="w-full md:w-96"
                        value={additionalInfoStore.doorNumber}
                        onChange={additionalInfoStore.setDoorNumber}
                    />
                    <Form.HelpText>{t('global.required')}</Form.HelpText>
                </Form.Group>

                <Form.Group>
                    <Form.ControlLabel className="text-gray-800 text-base text-left">
                        {t('params.consumption')}
                    </Form.ControlLabel>
                    <Form.Control
                        name="consumption"
                        className="w-full md:w-96"
                        value={additionalInfoStore.consumption}
                        onChange={additionalInfoStore.setConsumption}
                        accepter={ConsumptionInput}
                    />
                    <Form.HelpText>{t('global.required')}</Form.HelpText>
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel className="text-gray-800 text-base text-left">
                        {t('params.mileage')}
                    </Form.ControlLabel>
                    <Form.Control
                        name="mileage"
                        className="w-full md:w-96"
                        value={additionalInfoStore.mileage}
                        onChange={additionalInfoStore.setMileage}
                        accepter={MileageInput}
                    />
                    <Form.HelpText>{t('global.required')}</Form.HelpText>
                </Form.Group>

                <Form.Group>
                    <Form.ControlLabel className="text-gray-800 text-left text-base">
                        {t('params.engineCapacity')}
                    </Form.ControlLabel>
                    <Form.Control
                        name="engineCapacity"
                        className="w-full md:w-96"
                        value={additionalInfoStore.engineCapacity}
                        onChange={additionalInfoStore.setEngineCapacity}
                        accepter={EngineCapacity}
                    />
                    <Form.HelpText>{t('global.required')}</Form.HelpText>
                </Form.Group>
            </FormRef>
        </div>
    );
});

export default observer(AdditionalInfo);
