export const saveTokenToLocalStorage = (token: string) => {
    localStorage.setItem('token', token);
};

export const getTokenFromLocalStorage = (): string | null => {
    return localStorage.getItem('token');
};

export const removeTokenFromLocalStorage = () => {
    localStorage.removeItem('token');
};

export const saveToLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
};

export const removeFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};

export const getFromLocalStorage = (key: string): string | null => {
    return localStorage.getItem(key);
};
