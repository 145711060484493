import React, { ErrorInfo } from 'react';

import SomethingWentWrong from './something-went-wrong/SomethingWentWrong';

class ErrorBoundary extends React.Component {
    state = { hasError: false };

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <SomethingWentWrong />;
        }

        return <>{this.props.children}</>;
    }
}

export default ErrorBoundary;
